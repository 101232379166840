import { useEffect } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
  Box,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CustomToast } from 'components/authenticated/shared';
import { useQuery, useMutation } from 'react-query';
import { getAllNotifications, deleteNotification } from 'apis/notifications';
import notification from 'assets/images/icons/notification.svg';
import { useTranslation } from 'react-i18next';
import { onMessageListener } from 'push-notification';
import notificationSound from 'assets/sounds/notification.mp3';

const Notification = ({ children, ...props }) => {
  const toast = useToast();
  const audio = new Audio(notificationSound);

  const user = localStorage.getItem('user');
  const userId = JSON.parse(user).user.id;
  const { t } = useTranslation();

  const { data, refetch } = useQuery(
    ['getAllNotifications', userId],
    getAllNotifications,
    {
      refetchInterval: 2000,
      refetchIntervalInBackground: true,
    }
  );

  const notificationsItems = data?.data?.items;

  const { mutate } = useMutation(deleteNotification, {
    onSuccess: () => {
      refetch();
    },
  });

  const unReadNotifications = notificationsItems?.filter(e => {
    return e.readAt == null;
  });

  useEffect(() => {
    onMessageListener()
      .then(payload => {
        audio.play();
        toast({
          render: props => {
            return (
              <CustomToast
                title={payload.data.title}
                description={payload.data.body}
                status="success"
                onClose={props.onClose}
              />
            );
          },
          position: 'bottom-right',
        });
      })
      .catch(err => console.log('failed error : ', err));
  }, [data]);

  return (
    <Menu pos="relative">
      <Box pos="relative">
        <MenuButton
          as={IconButton}
          aria-label="Options"
          variant="outline"
          display="grid"
          placeItems="center"
          pos="relative"
          minW="25px"
          h="25px"
          p="0"
          borderRadius="5px"
          border="1px solid #F4F9FF"
          icon={<Image src={notification} />}
          _hover={{}}
          _active={{}}
          _focus={{}}
          {...props}
        />
        <Box
          as="span"
          pos="absolute"
          top="-8px"
          bg="bg.500"
          w="18px"
          h="18px"
          right="-5px"
          color="white"
          display="grid"
          placeItems="center"
          borderRadius="50%"
          fontSize="12px"
          pointerEvents="none"
        >
          {unReadNotifications?.length}
        </Box>
      </Box>

      <MenuList
        pos="relative"
        minW="200px"
        maxW="600px"
        p="0"
        // left="33px" top="-87px"
      >
        {notificationsItems?.length > 0 ? (
          <>
            {notificationsItems.map((e, i) => {
              return (
                <MenuItem
                  display="block"
                  bg={!e.readAt ? '#e1e9f1' : ''}
                  borderBottom={
                    i == notificationsItems?.length - 1
                      ? '0'
                      : '1px solid #cdcdcd'
                  }
                  onClick={() => mutate(e.id)}
                >
                  <Text fontWeight="bold">{e.title}</Text>
                  <Text>{e.text}</Text>
                </MenuItem>
              );
            })}
          </>
        ) : (
          <Box textAlign="center" p="15px">
            {t('account.noData')}
          </Box>
        )}
      </MenuList>
    </Menu>
  );
};

export default Notification;
