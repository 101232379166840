import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Text, Box, Flex, useToast, Spinner, Grid } from '@chakra-ui/react';
import {
  CustomInput,
  CustomUploadFile,
  UploadFile,
  PhoneInput,
  MultiSelect,
  Select,
  CustomButton,
  SimpleModal,
  ErrorMsg,
} from 'components/shared';
import {
  DeletePopup,
  ActivePopup,
  CustomToast,
} from 'components/authenticated/shared';
import { getAllDepartments } from 'apis/settings';
import { getAllShifts } from 'apis/settings';
import { useQuery } from 'react-query';
import { Formik, Form } from 'formik';
import { deleteUser, activateUser, updateEmployee } from 'apis/employee';
import { useMutation, useQueryClient } from 'react-query';
import { AddUserSchema } from 'schemas/employee/AddUserSchema';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import arabicDate from 'moment-arabic';

const Profile = ({ data }) => {
  arabicDate();

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [read, setRead] = useState(false);
  const toast = useToast();
  const [errors, setErrors] = useState([]);
  const [userImage, setUserImage] = useState(null);

  const [showDelete, setShowDelete] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [initBranch, setInitBranch] = useState(
    data.branches.filter(elm => {
      if (elm.deletedAt) {
        return { label: elm.name.en, value: elm.id };
      }
    })
  );
  const [branch, setBranch] = useState(
    data.branches.map(elm => ({ label: elm.name.en, value: elm.id }))
  );
  const [initDepartment, setInitDepartment] = useState(
    data.department
      ? {
          label: data.department.name,
          value: data.department.id,
        }
      : {
          label: t('account.department'),
          value: null,
        }
  );
  const [department, setDepartment] = useState(
    data.department
      ? {
          label: data.department.name,
          value: data.department.id,
        }
      : {
          label: t('account.department'),
          value: null,
        }
  );
  const [initShift, setInitShift] = useState(
    data.shifts.map(elm => ({ label: elm.name, value: elm.id }))
  );
  const [shift, setShift] = useState(
    data.shifts.map(elm => ({ label: elm.name, value: elm.id }))
  );

  const { user } = useContext(userContext);

  //handle functions for (save , deactivate , departments , branches)

  const showDeleteModal = () => {
    setShowDelete(true);
  };

  const showActiveModal = () => {
    setShowActive(true);
  };

  const handleFile = (file, fileName) => {
    setUserImage(fileName);
  };

  const handleBranch = (value, check) => {
    if (!check) {
      let filteredBranches = branch.filter(elm => elm.value !== value.value);
      setBranch(filteredBranches);
    } else setBranch(prevState => [...prevState, value]);
  };

  const handleDepartment = value => {
    setDepartment(value);
  };

  const handleShift = (value, check) => {
    if (!check) {
      let filteredShifts = shift.filter(elm => elm.value !== value.value);
      setShift(filteredShifts);
    } else {
      if (shift.length > 3) {
        return;
      } else {
        setShift(prevState => [...prevState, value]);
      }
    }
  };

  //requests

  const { data: departments, isSuccess: isSuccess1 } = useQuery(
    ['getDepartments', data.id],
    getAllDepartments
  );

  const { data: shifts, isSuccess: isSuccess2 } = useQuery(
    ['getAllShifts', data.id],
    getAllShifts
  );

  const handleDelete = useMutation(() => deleteUser([data.id]), {
    onSuccess: result => {
      setShowDelete(false);
      data.deletedAt = new Date();

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateEmployee')}
              description={t('toasts.titles.deactivateEmployee')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateEmployee')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleActivate = useMutation(() => activateUser(data.id), {
    onSuccess: result => {
      setShowActive(false);
      data.deletedAt = null;

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateEmployee')}
              description={t('toasts.titles.activateEmployee')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateEmployee')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleSubmit = useMutation(
    ({ values, branch, userImage, userId, department, shift }) =>
      updateEmployee(values, branch, userImage, userId, department, shift),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getUserById');

        setInitDepartment(
          result.data.department
            ? {
                label: result.data.department.name,
                value: result.data.department.id,
              }
            : {
                label: t('account.department'),
                value: null,
              }
        );

        setInitBranch(
          result.data.branches.map(elm => ({
            label: elm.name.en,
            value: elm.id,
          }))
        );

        setInitShift(
          result.data.shifts.map(elm => ({ label: elm.name, value: elm.id }))
        );
        setErrors([]);
        setRead(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateEmployee')}
                description={t('toasts.employeeUpdated')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },

      onError: result => {
        if (result.response.data.errors?.phoneNumber?.isPhoneNumber) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.updateEmployee')}
                  description={t('signup.isPhoneNumber')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else if (result.response.data.errors?.emailAddress?.alreadyExists) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.updateEmployee')}
                  description={t('signup.alreadyExists')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else if (
          result.response.data.errors?.phoneNumber?.phoneAlreadyExists
        ) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.updateEmployee')}
                  description={t('signup.phoneAlreadyExists')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else if (result.response.data.errors?.shifts?.overlapping) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.updateEmployee')}
                  description={t('signup.shiftsOverlaping', {
                    shifts: result.response.data.errors?.shifts?.overlapping,
                  })}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.updateEmployee')}
                  description={t('signup.error')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        }
      },
    }
  );

  return (
    <Box py="8" ml="8" maxW="800px">
      <Formik
        initialValues={{
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.emailAddress,
          phone: data.phoneNumber,
          image:
            data.profileImageUrl?.replace(
              `${process.env.REACT_APP_API_BASE_URL}/uploads/image/`,
              ''
            ) || '',
        }}
        validationSchema={AddUserSchema}
        onSubmit={values => {
          if (branch.length <= 0) {
            setErrors(prevErrors => [...prevErrors, 'branch']);
            return;
          }

          handleSubmit.mutate({
            values,
            branch,
            userImage,
            userId: data.id,
            department,
            shift,
          });
        }}
      >
        {formik => (
          <Form>
            <Flex justifyContent="space-between" alignItems="start" mb="8">
              <Box>
                <Box w="full" mb="24px">
                  {/* <UploadFile
                    radius
                    src={data?.profileImageUrl}
                    handleFile={handleFile}
                    letter={data.firstName[0]}
                  /> */}
                  <CustomUploadFile
                    textTitle={t('account.uploadPhoto')}
                    letter={data.firstName[0]}
                    radius
                    defaultImage={data.profileImageUrl}
                    formikChange={e => formik.setFieldValue('image', e)}
                    disabled={!read}
                    mb="0"
                  />
                </Box>
                <Text color="text.1200" fontSize="12" mt="-22px">
                  {t('myProfile.joined')}{' '}
                  {moment(data.createdAt)
                    .locale(t('common.lang'))
                    .format('DD-MM-YYYY')}
                  {/* .format('ddd MMM DD yyyy')} */}
                </Text>
              </Box>

              <Box
                bg="bg.600"
                borderRadius="5px"
                color="white"
                fontSize="12px"
                px="2"
                py="0.5"
              >
                <Text>ID: {data.searchId}</Text>
              </Box>
            </Flex>

            <Flex justifyContent="space-between" mb="24px">
              <Box w="48%" mr="4">
                <CustomInput
                  type="text"
                  label={t('signup.firstName')}
                  value={!read ? data.firstName : formik.values.firstName}
                  onChange={formik.handleChange('firstName')}
                  onBlur={formik.handleBlur('firstName')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <ErrorMsg text={t(formik.errors.firstName)} />
                )}
              </Box>
              <Box w="48%">
                <CustomInput
                  type="text"
                  label={t('signup.lastName')}
                  value={!read ? data.lastName : formik.values.lastName}
                  onChange={formik.handleChange('lastName')}
                  onBlur={formik.handleBlur('lastName')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <ErrorMsg text={t(formik.errors.lastName)} />
                )}
              </Box>
            </Flex>

            <Flex justifyContent="space-between" mb="24px">
              <Box w="48%" mr="4">
                <CustomInput
                  type="email"
                  label={t('signup.email')}
                  placeholder={t('signup.email')}
                  value={!read ? data.emailAddress : formik.values.email}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.email && formik.touched.email && (
                  <ErrorMsg text={t(formik.errors.email)} />
                )}
              </Box>
              <Box w="48%">
                <PhoneInput
                  label={t('signup.phone')}
                  value={!read ? data.phoneNumber : formik.values.phone}
                  onChange={formik.handleChange('phone')}
                  onBlur={formik.handleBlur('phone')}
                  disabled={read ? false : true}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <ErrorMsg text={t(formik.errors.phone)} />
                )}
              </Box>
            </Flex>

            <Flex justifyContent="space-between" mb="24px">
              <Box w="48%" mr="4">
                <Text
                  textTransform="capitalize"
                  color="text.600"
                  fontSize="14px"
                  mb="5px"
                  fontWeight="semibold"
                >
                  {t('filter.branch')}
                </Text>
                <Box h="60px">
                  <MultiSelect
                    w="100%"
                    h="100%"
                    label={t('home.employees.branch')}
                    labelForMulti={t('home.employees.branches')}
                    select={!read ? initBranch : branch}
                    handleSelect={handleBranch}
                    selections={user?.user?.branches?.map((item, index) => ({
                      label: item.name.en,
                      value: item.id,
                    }))}
                    isDisabled={!read}
                  />
                  {errors.includes('branch') && (
                    <ErrorMsg text={t('signup.fieldRequired')} />
                  )}
                </Box>
              </Box>

              <Box w="48%">
                <Text
                  textTransform="capitalize"
                  color="text.600"
                  fontSize="14px"
                  mb="5px"
                  fontWeight="semibold"
                >
                  {t('account.department')}
                </Text>
                <Box h="60px">
                  <Select
                    w="100%"
                    h="100%"
                    label={t('account.department')}
                    select={!read ? initDepartment : department}
                    handleSelect={handleDepartment}
                    selections={
                      isSuccess1
                        ? departments?.data?.map(e => {
                            return { label: e.name, value: e.id };
                          })
                        : []
                    }
                    isDisabled={!read}
                  />
                </Box>
              </Box>
            </Flex>

            <Grid gridTemplateColumns="repeat(1,1fr)" gap="30px" mb="24px">
              {/* <Box>
                <CustomInput
                  type="number"
                  label={'salary'}
                  placeholder={'employer salary'}
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                />
                {formik.errors.email && formik.touched.email && (
                  <ErrorMsg text={t(formik.errors.email)} />
                )}
                <Text color="red">
                  {email?.errName && `email is used before`}
                </Text>
              </Box> */}

              <Box w="full">
                <Text
                  textTransform="capitalize"
                  color="text.600"
                  fontSize="14px"
                  mb="5px"
                  fontWeight="semibold"
                >
                  {t('account.shifts')}
                </Text>
                <Box h="60px">
                  <MultiSelect
                    w="100%"
                    h="100%"
                    label={t('account.shift')}
                    labelForMulti={t('home.employees.shifts')}
                    select={!read ? initShift : shift}
                    handleSelect={handleShift}
                    selections={
                      isSuccess2
                        ? shifts?.data?.map(e => {
                            return { label: e.name, value: e.id };
                          })
                        : []
                    }
                    isDisabled={!read}
                  />
                </Box>
              </Box>
            </Grid>

            {Permission('Employees') == 'Edit' ? (
              !read ? (
                <CustomButton
                  bg
                  name={t('myProfile.update')}
                  w="100%"
                  display="block"
                  m="auto"
                  mt="30px"
                  onClick={() => setRead(true)}
                />
              ) : (
                <Box>
                  <Flex justifyContent="space-between" alignItems="center">
                    <CustomButton
                      bg
                      type="submit"
                      name={t('account.saveChanges')}
                      w="48%"
                      mt="30px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      loading={handleSubmit.isLoading && <Spinner ms="5px" />}
                      disabled={handleSubmit.isLoading}
                    />
                    <CustomButton
                      name={
                        data.deletedAt
                          ? t('account.activateEmployee')
                          : t('account.deactivateEmployee')
                      }
                      w="48%"
                      display="block"
                      mt="30px"
                      onClick={() =>
                        data.deletedAt ? showActiveModal() : showDeleteModal()
                      }
                    />
                  </Flex>

                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    h="60px"
                    borderRadius="15px"
                    w="100%"
                    mt="24px"
                    textAlign="center"
                    color="text.1000"
                    fontWeight="bold"
                    cursor="pointer"
                    bg="gray.100"
                    onClick={() => setRead(false)}
                  >
                    <Text>{t('myProfile.cancel')}</Text>
                  </Flex>
                </Box>
              )
            ) : null}
          </Form>
        )}
      </Formik>

      <SimpleModal
        title={t('account.deactivateEmployee')}
        show={showDelete}
        setShow={setShowDelete}
      >
        <DeletePopup
          handleDelete={handleDelete.mutate}
          title={t('account.confirmDeactivate')}
        />
      </SimpleModal>

      <SimpleModal
        title={t('account.activateEmployee')}
        show={showActive}
        setShow={setShowActive}
      >
        <ActivePopup
          handleActivate={handleActivate.mutate}
          title={t('account.confirmActivate')}
        />
      </SimpleModal>
    </Box>
  );
};

export default Profile;
