import { Box, Grid, Text, Flex } from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { ImArrowDownRight2 } from 'react-icons/im';
import { MdLocationOn } from 'react-icons/md';
import { HiOutlineUser } from 'react-icons/hi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PresentRow = ({
  shift,
  i,
  checkIn,
  checkOut,
  checkInLocation,
  checkOutLocation,
  lateCheckIn,
  earlyCheckOut,
  outOfRangeCheckIn,
  outOfRangeCheckOut,
}) => {
  const { t } = useTranslation();

  const workingours = (checkIn, checkOut) => {
    const startTime = moment(checkIn, 'HH:mm a');
    const endTime = moment(checkOut, 'HH:mm a');
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    if (hours == 0) {
      return Math.abs(minutes) + 'minutes';
    } else if (minutes == 0) {
      return Math.abs(hours) + 'hours';
    } else {
      return Math.abs(hours) + 'h' + Math.abs(minutes) + 'm';
    }
  };

  return (
    <Grid
      gridTemplateColumns="2fr 2fr 2fr 2fr "
      borderTop={i != 0 && '1px solid #edf2f7'}
      py="22px"
      paddingInlineEnd="35px"
    >
      <Box>{shift}</Box>
      <Box>
        <Flex mb="8px" alignItems="center">
          <Box as={ImArrowDownRight2} me="4px" />
          <Text
            fontSize="14px"
            fontWeight="bold"
            color={lateCheckIn ? '#F43838' : 'text.1600'}
          >
            {checkIn}
          </Text>
        </Flex>

        <Flex alignItems="center">
          <Box as={MdLocationOn} me="4px" />
          <Text color={outOfRangeCheckIn && '#F43838'}>{checkInLocation}</Text>
        </Flex>
      </Box>

      <Box>
        <Flex mb="8px" alignItems="center">
          <Box as={ImArrowDownRight2} me="4px" />
          <Text
            fontSize="14px"
            fontWeight="bold"
            color={
              earlyCheckOut || checkOut == 'Checkout by system'
                ? '#F43838'
                : 'text.1600'
            }
          >
            {checkOut}
          </Text>
        </Flex>

        <Flex alignItems="center">
          <Box as={MdLocationOn} me="4px" />
          <Text color={outOfRangeCheckOut && '#F43838'}>
            {checkOutLocation}
          </Text>
        </Flex>
      </Box>

      <Box
        fontSize="14px"
        fontWeight="bold"
        color={checkOut == 'Checkout by system' ? '#F43838' : 'black'}
      >
        {checkOut == '--:--'
          ? '--:--'
          : checkOut == 'Checkout by system'
          ? 'Checkout by system'
          : workingours(checkIn, checkOut)}
      </Box>
    </Grid>
  );
};

export default PresentRow;
