import { Flex, Text, Button, Box } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import SearchInput from './search-input';
import { CustomButton } from 'components/shared';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import arabicDate from 'moment-arabic';

const Filter = ({
  title,
  buttonName,
  setShowAdd,
  setShowDelete,
  setValue,
  isCheck,
  titleDelete,
  btResponsiveWidth = '160px',
  defaultButtonWidth = '240px',
  isButtonExist,
  buttonText,
  children,
  searchWidth,
}) => {
  const { t } = useTranslation();

  arabicDate();

  return (
    <Flex justifyContent="space-between" alignItems="center" mb="12">
      <Box>
        <Text
          fontSize={['80%', '', '35px', '35px', '40px']}
          fontWeight="black"
          color="text.1800"
        >
          {title}
        </Text>
        <Text
          fontSize="14px"
          color="#4b4e50"
          // textAlign="center"
          whiteSpace="nowrap"
        >
          {moment(new Date())
            .locale(t('common.lang'))
            .format('hh:mm A | DD-MM-YYYY')}
          {/* .format('hh:mm A | MMM DD, yyyy')} */}
        </Text>
      </Box>

      <Flex w={['80%', '', '85%', '80%', '80%']} justifyContent="flex-end">
        {isCheck?.length > 0 ? (
          <Button
            bg="#f96969"
            color="white"
            h="40px"
            px="15px"
            borderRadius="10px"
            fontSize="15px"
            fontWeight="normal"
            _hover={{ bg: '#ef4444' }}
            _focus={{}}
            _active={{}}
            onClick={() => setShowDelete(true)}
          >
            {buttonText == 'delete' ? t('account.delete') : t('modals.delete')}{' '}
            {isCheck.length} {titleDelete}
          </Button>
        ) : null}
        <Flex>{children}</Flex>

        <SearchInput
          searchWidth={searchWidth}
          type="text"
          placeholder={t('filter.search')}
          setValue={setValue}
          me={isButtonExist ? ['4', '', '4', '4', '8'] : '0'}
          ms={['4', '', '3', '3', '4']}
        />

        {isButtonExist && (
          <CustomButton
            name={buttonName}
            icon={MdAdd}
            bg
            h="40px"
            maxW={[
              '240px',
              '',
              btResponsiveWidth,
              btResponsiveWidth,
              defaultButtonWidth,
            ]}
            fontSize={['16px', '', '14px', '14px', '16px']}
            borderRadius="10px"
            onClick={() => setShowAdd(true)}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default Filter;
