import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Grid, GridItem, useToast, Button } from '@chakra-ui/react';
import {
  Filter,
  DeletePopup,
  ActivePopup,
  Pagination,
  Loading,
  Error,
  Nodata,
} from 'components/authenticated/shared';
import { Card, EmployePopup } from 'components/authenticated/employees';
import { Select, MultiSelect, SimpleModal } from 'components/shared';
import { CustomModal } from 'components/shared';
import { getAllUsers } from 'apis/employee';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deleteUser, activateUser } from 'apis/employee';
import { useTranslation } from 'react-i18next';
import Permission from 'helpers/permission';
import { CustomToast } from 'components/authenticated/shared';

const Employees = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user, branchFilter, language } = useContext(userContext);
  const toast = useToast();

  //show modals
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState({ flag: false, data: {} });
  const [showDelete, setShowDelete] = useState({ flag: false, data: {} });
  const [showActive, setShowActive] = useState({ flag: false, data: {} });

  //filter and search data
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState({
    label: t('modals.status'),
    value: null,
  });
  const [branch, setBranch] = useState([branchFilter]);

  useEffect(() => {
    setBranch([branchFilter]);
  }, []);

  useEffect(() => {
    if (branchFilter.value == null) {
      setBranch([]);
    } else {
      setBranch([branchFilter]);
    }
  }, [branchFilter]);

  //handle pagination , status select and branch select
  const handlePageClick = async data => {
    setPageNumber(data.selected + 1);
  };

  const handleStatus = value => {
    setPageNumber(1);
    value === 'all' ? setStatus(null) : setStatus(value);
  };

  const handleBranch = (value, check) => {
    setPageNumber(1);
    if (!check) {
      let filteredBranches = branch.filter(elm => elm.value !== value.value);
      setBranch(filteredBranches);
    } else setBranch(prevState => [...prevState, value]);
  };

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getAllUsers', pageNumber, searchKey, branch, status.value],
    getAllUsers,
    {
      onSuccess: () => {
        // setPageNumber(1);
      },
    }
  );

  const handleDelete = useMutation(() => deleteUser([showDelete.data.id]), {
    onSuccess: async result => {
      await queryClient.invalidateQueries('getAllUsers');

      setShowDelete({ flag: false, data: {} });

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateEmployee')}
              description={t('toasts.deactivateEmployee')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateEmployee')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleActivate = useMutation(() => activateUser(showActive.data.id), {
    onSuccess: async result => {
      await queryClient.invalidateQueries('getAllUsers');

      setShowActive({ flag: false, data: {} });

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateEmployee')}
              description={t('toasts.activateEmployee')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateEmployee')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  let numberOfPages = data?.data.meta.totalPages;
  let itemsPerPage = data?.data.meta.itemsPerPage;
  let currentPage = data?.data.meta.currentPage;
  let itemCount = data?.data.meta.itemCount;
  let totalItems = data?.data.meta.totalItems;

  const [pageForce, setPageForce] = useState(null);
  useEffect(() => {
    setPageForce(currentPage - 1);
  }, [currentPage]);

  return (
    <Box>
      <Filter
        title={t('home.employees.title')}
        buttonName={t('home.employees.addNewEmployee')}
        setShowAdd={setShowAdd}
        setValue={setSearchKey}
        btResponsiveWidth="185px"
        isButtonExist={Permission('Employees') == 'Edit'}
      >
        <Select
          mx={['4', '', '2', '2', '4']}
          w={['150px', '', '115px', '115px', '150px']}
          h="40px"
          label={t('modals.status')}
          select={status}
          handleSelect={handleStatus}
          selections={[
            { label: t('common.all'), value: null },
            { label: t('common.activated'), value: 'activated' },
            { label: t('common.deactivated'), value: 'deactivated' },
          ]}
        />
        {/* <button onClick={nothing}>test error sentry</button> */}

        <MultiSelect
          mx={['4', '', '2', '2', '4']}
          w={['150px', '', '115px', '115px', '150px']}
          h="40px"
          label={t('filter.branch')}
          labelForMulti={t('project.branches')}
          select={branch}
          handleSelect={handleBranch}
          selections={user.user.branches?.map((item, index) => ({
            label: language == 'en' ? item.name.en : item.name.ar,
            value: item.id,
          }))}
        />
      </Filter>

      {isLoading && <Loading />}

      {isSuccess &&
        (totalItems > 0 ? (
          <>
            <Grid
              templateColumns={[
                'repeat(4, 1fr)',
                '',
                'repeat(3, 1fr)',
                'repeat(3, 1fr)',
                'repeat(4, 1fr)',
              ]}
              gap="8"
              mb="24px"
              minH="72vh"
            >
              {data?.data.items.map(e => {
                return (
                  <GridItem key={Math.random()}>
                    <Card
                      item={e}
                      setShowEdit={setShowEdit}
                      setShowDelete={setShowDelete}
                      setShowActive={setShowActive}
                    />
                  </GridItem>
                );
              })}
            </Grid>

            <SimpleModal
              title={t('home.employees.addEmployee')}
              show={showAdd}
              setShow={setShowAdd}
            >
              <EmployePopup setShow={setShowAdd} />
            </SimpleModal>

            <CustomModal
              title={t('modals.editEmployee')}
              show={showEdit}
              setShow={setShowEdit}
            >
              <EmployePopup setShow={setShowEdit} data={showEdit.data} />
            </CustomModal>

            <CustomModal
              title={t('account.deactivateEmployee')}
              show={showDelete}
              setShow={setShowDelete}
            >
              <DeletePopup
                handleDelete={handleDelete.mutate}
                title={t('account.confirmDeactivate')}
              />
            </CustomModal>

            <CustomModal
              title={t('account.activateEmployee')}
              show={showActive}
              setShow={setShowActive}
            >
              <ActivePopup
                handleActivate={handleActivate.mutate}
                title={t('account.confirmActivate')}
              />
            </CustomModal>

            <Pagination
              handlePageClick={handlePageClick}
              numberOfPages={numberOfPages}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              itemCount={itemCount}
              totalItems={totalItems}
              forcePage={pageForce}
            />
          </>
        ) : (
          <Nodata />
        ))}

      {isError && <Error />}
    </Box>
  );
};

export default Employees;
