import { useState, useEffect } from 'react';
import { Box, Image, Text, Flex, useToast } from '@chakra-ui/react';
import { CustomButton } from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { FormBody, Header } from 'components/unauthenticated/shared';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { sendVerification } from 'apis/iam/Signup';
import { useTranslation } from 'react-i18next';

const Success = ({ success }) => {
  const { t } = useTranslation();

  const toast = useToast();
  const { emailAddress } = success.data;

  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(10);

  const [reCount, setReCount] = useState(false);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, reCount]);

  useEffect(() => {
    setTimeLeft(20);
  }, [reCount]);

  const { mutate } = useMutation(sendVerification, {
    onSuccess: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.sendEmail')}
              description={t('toasts.sendEmail')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.sendEmail')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });
  return (
    <>
      <Header heading={t('signup.header')} isArrow />
      <Flex justifyContent="center" alignItems="center" mt="40px">
        <FormBody w="800px" p="80px" boxShadow="0 -20px 100px -60px #21B3E1">
          {/* <Text fontWeight="bold" textAlign="center" fontSize="25px">
            Signup
          </Text> */}
          <Box>
            <lottie-player
              src="https://assets7.lottiefiles.com/packages/lf20_9aa9jkxv.json"
              background="transparent"
              speed="1"
              style={{ width: '200px', height: '200px', margin: 'auto' }}
              loop
              autoplay
            ></lottie-player>

            {/* <lottie-player
              src="https://assets4.lottiefiles.com/packages/lf20_kltum0us.json"
              background="transparent"
              speed="1"
              style={{ width: '300px', height: '300px', margin: 'auto' }}
              loop
              autoplay
            ></lottie-player> */}
          </Box>
          <Text fontWeight="bold" textAlign="center" mb="40px">
            {t('signup.subscribedSuccessfully')}
          </Text>

          <Box mb="50px">
            <Text fontWeight="bold" fontSize="25px" mb="15px">
              {t('signup.thankYou')}
            </Text>
            <Text fontWeight="bold" fontSize="17px">
              {t('signup.verifyText')}
            </Text>
          </Box>

          <Text fontWeight="bold">
            {t('signup.verifyText1', { emailAddress: emailAddress })}
          </Text>

          <Text fontWeight="bold" fontSize="15px" mt="5px" color="gray">
            {t('signup.verifyText2')}{' '}
            <Text
              as="span"
              cursor="pointer"
              transition=".2s"
              opacity=".6"
              _hover={{ color: timeLeft > 0 ? 'gray' : 'black' }}
              onClick={() => {
                if (timeLeft == 0) {
                  mutate(emailAddress);
                  setReCount(!reCount);
                }
              }}
            >
              {t('signup.resendAgain')}
            </Text>{' '}
            {timeLeft == 0 ? null : timeLeft}
          </Text>

          <CustomButton
            bg
            name={t('login.header')}
            mt="50px"
            onClick={() => navigate('/signin')}
          />
        </FormBody>
      </Flex>
    </>
  );
};

export default Success;
