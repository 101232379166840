import { useState, useContext } from 'react';
import { useParams } from 'react-router';
import { Box, TabPanel } from '@chakra-ui/react';
import {
  Tabs,
  PageHeader,
  Loading,
  Error,
} from 'components/authenticated/shared';
import { Profile, Projects } from 'components/authenticated/branch';
import { useQuery } from 'react-query';
import { getBranchById } from 'apis/branch';
import { useTranslation } from 'react-i18next';
import { userContext } from 'contexts/userContext';

const Branch = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { language } = useContext(userContext);

  const [projectsCount, setProjectsCount] = useState(0);

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getBranchById', id],
    getBranchById
  );

  return (
    <Box mx="auto">
      <PageHeader
        text={language == 'en' ? data?.data.name.en : data?.data.name.ar}
        isArrow
      />
      <Tabs
        tabs={[
          t('home.branches.branchProfile'),
          `${t('account.projects')} (${projectsCount})`,
        ]}
      >
        <TabPanel outline="none" minH="600px">
          {isLoading && <Loading />}
          {isSuccess && <Profile data={data?.data} />}
          {isError && <Error />}
        </TabPanel>
        <TabPanel outline="none" minH="600px">
          <Projects setProjectsCount={setProjectsCount} />
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default Branch;
