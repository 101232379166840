import { useState, useContext } from 'react';
import { userContext } from 'contexts/userContext';
import {
  Box,
  Flex,
  Text,
  useToast,
  Tr,
  Td,
  Spinner,
  Grid,
} from '@chakra-ui/react';
import {
  CustomInput,
  CustomButton,
  MultiSelect,
  ErrorMsg,
} from 'components/shared';
import { Table, CustomToast } from 'components/authenticated/shared';
import { useMutation, useQueryClient } from 'react-query';
import { Formik, Form } from 'formik';
import { AddProductSchema } from 'schemas/catlog/AddProductSchema';
import { createProduct, updateProduct } from 'apis/catlog/Product';
import { updatePart } from 'apis/part';
import { useTranslation } from 'react-i18next';
import Permission from 'helpers/permission';

const PartPopup = ({ setShow, data }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const toast = useToast();

  const { user, language } = useContext(userContext);

  const [errors, setErrors] = useState([]);
  const [branchQuantity, setBranchQuantity] = useState(
    data
      ? data.totalBranches.map(elm => ({
          branchId: elm.branch.id,
          quantity: elm.quantity,
        }))
      : []
  );
  const [branch, setBranch] = useState(
    data
      ? data.totalBranches.map(elm => ({
          label: elm.branch.name.en,
          value: elm.branch.id,
        }))
      : []
  );

  const handleBranch = (value, check) => {
    if (!check) {
      let filteredBranches = branch.filter(elm => elm.value !== value.value);
      handleBranchQuantityChange(value.value, 0, false);
      setBranch(filteredBranches);
    } else {
      handleBranchQuantityChange(value.value, 0, true);
      setBranch(prevState => [...prevState, value]);
    }
  };

  const handleBranchQuantityChange = (branchId, quantity, check) => {
    let filteredBranches = branchQuantity.filter(
      elm => elm.branchId !== branchId
    );

    !check
      ? setBranchQuantity(filteredBranches)
      : setBranchQuantity([...filteredBranches, { branchId, quantity }]);
  };

  const handleSubmit = useMutation(
    ({ values, branchQuantity }) =>
      data
        ? updatePart(values, branchQuantity, data.id)
        : createProduct(values, branchQuantity),

    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllProducts');

        setErrors([]);

        setShow(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updatePart')
                    : t('toasts.titles.addPart')
                }
                description={
                  data ? t('toasts.updatePart') : t('toasts.addPart')
                }
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updatePart')
                    : t('toasts.titles.addPart')
                }
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );
  return (
    <Flex justifyContent="center">
      <Box w="full" maxW="70%">
        <Formik
          initialValues={{
            name: data ? data.name : '',
            nameAr: data ? data.nameAr : '',
            partNumber: data ? data.partNumber : '',
            price: 0,
            applicationPrice: 0,
          }}
          validationSchema={AddProductSchema}
          onSubmit={values => {
            if (branchQuantity.length <= 0) {
              setErrors(prevErrors => [...prevErrors, 'branch']);
              return;
            }

            handleSubmit.mutate({
              values,
              branchQuantity,
            });
          }}
        >
          {formik => (
            <Form>
              <Grid templateColumns="repeat(2,1fr)" gap="15px" mb="24px">
                <Box w="full">
                  <CustomInput
                    type="text"
                    label={t('modals.partName')}
                    placeholder={t('modals.enterPartName')}
                    value={formik.values.name}
                    onChange={formik.handleChange('name')}
                    onBlur={formik.handleBlur('name')}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <ErrorMsg text={t(formik.errors.name)} />
                  )}
                </Box>
                <Box w="full">
                  <CustomInput
                    type="text"
                    label={'nameAr'}
                    placeholder={'nameAr'}
                    value={formik.values.nameAr}
                    onChange={formik.handleChange('nameAr')}
                    onBlur={formik.handleBlur('nameAr')}
                  />
                  {formik.errors.nameAr && formik.touched.nameAr && (
                    <ErrorMsg text={t(formik.errors.nameAr)} />
                  )}
                </Box>
              </Grid>

              <Box w="full" mb="24px">
                <CustomInput
                  type="text"
                  label={t('modals.partNumber')}
                  placeholder={t('modals.enterPartNumber')}
                  value={formik.values.partNumber}
                  onChange={formik.handleChange('partNumber')}
                  onBlur={formik.handleBlur('partNumber')}
                />
                {formik.errors.partNumber && formik.touched.partNumber && (
                  <ErrorMsg text={t(formik.errors.partNumber)} />
                )}
              </Box>

              <Box w="full" mb="24px">
                <Text fontSize="14px" mb="5px" fontWeight="bold">
                  {t('sideBar.branches')}
                </Text>
                <MultiSelect
                  w="100%"
                  h="60px"
                  label={t('modals.branch')}
                  labelForMulti={t('sideBar.branches')}
                  select={branch}
                  handleSelect={handleBranch}
                  selections={user?.user?.branches?.map((item, index) => ({
                    label: language == 'en' ? item.name.en : item.name.ar,
                    value: item.id,
                  }))}
                />
                {errors.includes('branch') && (
                  <ErrorMsg text={t('signup.fieldRequired')} />
                )}
              </Box>

              {branch.length > 0 && (
                <Table
                  headers={[t('modals.branch'), t('modals.quantity')]}
                  theadHeight="0px"
                  borderRadius="0"
                  mb="24px"
                >
                  {branch.map((item, index) => (
                    <Tr key={index}>
                      <Td>{item.label}</Td>
                      <Td>
                        {
                          <CustomInput
                            onChange={e =>
                              handleBranchQuantityChange(
                                item.value,
                                parseInt(e.target.value),
                                true
                              )
                            }
                            type="number"
                            value={
                              branchQuantity.filter(
                                elm => elm.branchId === item.value
                              )[0].quantity
                            }
                            h="40px"
                            min="0"
                            max="9999"
                          />
                        }
                      </Td>
                    </Tr>
                  ))}
                </Table>
              )}

              {Permission('Products') == 'Edit' ||
              Permission('Products') == null ? (
                <CustomButton
                  bg
                  type="submit"
                  name={t('account.saveChanges')}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  loading={handleSubmit.isLoading && <Spinner ms="5px" />}
                  disabled={handleSubmit.isLoading}
                />
              ) : null}
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default PartPopup;
