import { Grid, Box, Flex, Text } from '@chakra-ui/react';

const SmallDevices = () => {
  return (
    <Grid placeItems="center" minH="100vh">
      <Flex flexDir="column" alignItems="center" justifyContent="center">
        <lottie-player
          src="https://assets2.lottiefiles.com/packages/lf20_gneejfiq.json"
          background="transparent"
          speed="1"
          style={{ width: '100%', height: '300px' }}
          loop
          autoplay
        ></lottie-player>
        <Text
          fontWeight="bold"
          fontSize={['19px', '20px', '', '']}
          mb="20px"
          textAlign="center"
        >
          This screen size is not supported yet.
        </Text>

        <Text w="280px" textAlign="center">
          Please switch to your desktop or labtop to use crm.
        </Text>
      </Flex>
    </Grid>
  );
};

export default SmallDevices;
