import { useState, useContext } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { SigninForm } from 'components/unauthenticated/signin';
import { login } from 'apis/iam/Login';
import { userContext } from 'contexts/userContext';
import { FaGlobe } from 'react-icons/fa';
import { Lang } from 'components/unauthenticated/shared';
import { useMutation } from 'react-query';

const Signin = () => {
  const { user, setUser } = useContext(userContext);

  const [errors, setErrors] = useState('');

  const { mutate, isLoading } = useMutation(login, {
    onSuccess: response => {
      setUser(response.data);
      setErrors('');
      console.log(response.data);
      let userData = JSON.stringify(response.data);
      localStorage.setItem('user', userData);
      localStorage.setItem(
        'defaultBranch',
        JSON.stringify({
          name: response.data.user.branches[0].name.en,
          id: response.data.user.branches[0].id,
        })
      );
    },
    onError: err => {
      setErrors(err.response.data.message);
      setUser({});
      localStorage.removeItem('user');
      localStorage.removeItem('defaultBranch');
    },
  });

  // const handleLogin = async (email, password) => {
  //   try {
  //     let response = await login(email, password);

  //     setUser(response.data);
  //     console.log(response.data);
  //     setErrors('');
  //     let userData = JSON.stringify(response.data);
  //     localStorage.setItem('user', userData);
  //   } catch (error) {
  //     console.log(error);
  //     setUser({});
  //     setErrors(error.response?.data?.message);
  //     localStorage.removeItem('user');
  //   }
  // };

  return (
    <Box pos="relative">
      <Lang />
      <SigninForm handleLogin={mutate} errors={errors} loading={isLoading} />
    </Box>
  );
};

export default Signin;
