import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { CardPopover, CustomPopover } from 'components/authenticated/shared';
import moment from 'moment';
import { BsArrowDownRight, BsArrowUpRight, BsPeople } from 'react-icons/bs';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';

const IconText = ({ icon, text, ...props }) => {
  return (
    <Flex alignItems="center" {...props}>
      <Box as={icon} me="5px" />
      <Text color="text.1400">{text}</Text>
    </Flex>
  );
};

const Card = ({ item, setShowEdit, isView, setShowDelete }) => {
  const { t } = useTranslation();

  return (
    <Box bg="bg.200" p="15px" borderRadius="10px">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb="15px"
        pos="relative"
      >
        <Heading as="h4" fontSize="18px" fontWeight="bold">
          {item.name}
        </Heading>
        {!isView ? (
          <CardPopover
            item={item}
            setShowEdit={setShowEdit}
            setShowDelete={setShowDelete}
            top="0"
            right="0"
            deleteText="delete"
          />
        ) : null}
      </Flex>

      <Flex justifyContent="space-between" mb="10px">
        <Flex alignItems="center">
          <Box as={BsArrowDownRight} me="5px" />
          <Text fontWeight="bold" color="text.1600">
            {moment(item.start, 'HH:mm:ss')
              .locale(t('common.lang'))
              .format('hh:mm A')}
          </Text>
        </Flex>

        <Flex alignItems="center">
          <IconText />
          <Box as={BsArrowUpRight} me="5px" />
          <Text fontWeight="bold" color="text.1700">
            {moment(item.end, 'HH:mm:ss')
              .locale(t('common.lang'))
              .format('hh:mm A')}
          </Text>
        </Flex>
      </Flex>

      <IconText
        icon={HiOutlineLocationMarker}
        text={item.locationName}
        mb="5px"
      />
      <IconText
        icon={BsPeople}
        text={`${item.numberOfEmployees} ${t('home.employees.title')}`}
      />
    </Box>
  );
};

export default Card;
