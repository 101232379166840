import ServiceItem from './service-item';

const ChosseBranchModal = ({
  itemId,
  items,
  branches,
  refetchServices,
  isPart,
}) => {
  return (
    <>
      {branches?.map(b => {
        return (
          <ServiceItem
            branchObject={b}
            refetchServices={refetchServices}
            itemId={itemId}
            items={items}
            isPart={isPart}
          />
        );
      })}
    </>
  );
};

export default ChosseBranchModal;
