import { useState, useContext } from 'react';
import { Text, Box, Flex, Grid, useToast, Spinner } from '@chakra-ui/react';
import { userContext } from 'contexts/userContext';
import {
  CustomInput,
  UploadImage,
  PhoneInput,
  CustomButton,
  SimpleModal,
  CustomUploadFile,
} from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { Formik, Form } from 'formik';
import { ProfileSchema } from 'schemas/employee/ProfileSchema.jsx';
import { ErrorMsg } from 'components/shared';

import { updateUser } from 'apis/employee';
import { useMutation, useQuery } from 'react-query';

import moment from 'moment';
import ChangePassowrdModal from './changePassowrdModal';
import { useTranslation } from 'react-i18next';

const Profile = ({ userData }) => {
  const { t } = useTranslation();
  const { user, setUser } = useContext(userContext);
  const { id } = userData;

  const toast = useToast();
  const [read, setRead] = useState(false);
  const [show, setShow] = useState(false);

  // const { data, isLoading, isSuccess, isError } = useQuery(
  //   ['getUserById', id],
  //   getUserById
  // );

  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess: async result => {
      setRead(false);
      localStorage.setItem(
        'user',
        JSON.stringify({
          accessToken: user.accessToken,
          user: result.data,
        })
      );
      setUser({
        accessToken: user.accessToken,
        user: result.data,
      });

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.updateProfile')}
              description={t('toasts.updateProfile')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },

    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.updateProfile')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Box py="8" ml="8" maxW="800px">
      <Formik
        initialValues={{
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.emailAddress,
          phone: userData.phoneNumber,
          image:
            userData.profileImageUrl?.replace(
              `${process.env.REACT_APP_API_BASE_URL}/uploads/image/`,
              ''
            ) || '',
        }}
        validationSchema={ProfileSchema}
        onSubmit={async values => {
          mutate({ values: values, userId: id });
        }}
        validateOnMount
      >
        {formik => (
          <Form>
            <Flex justifyContent="space-between" alignItems="start" mb="8">
              <Box>
                <Box>
                  {/* <UploadImage
                    textTitle={t('account.uploadPhoto')}
                    letter={userData.firstName[0]}
                    radius
                    defaultImage={userData.profileImageUrl}
                    formikChange={e => formik.setFieldValue('image', e)}
                    disabled={!read}
                    mb="0"
                  /> */}

                  <CustomUploadFile
                    textTitle={t('account.uploadPhoto')}
                    letter={userData.firstName[0]}
                    radius
                    defaultImage={userData.profileImageUrl}
                    formikChange={e => formik.setFieldValue('image', e)}
                    disabled={!read}
                    mb="0"
                  />
                </Box>
                <Text color="text.1200" fontSize="12">
                  {t('myProfile.joined')}{' '}
                  {moment(userData.createdAt)
                    .locale(t('common.lang'))
                    .format('DD-MM-YYYY')}
                  {/* .format('MMM D YYYY')} */}
                </Text>
              </Box>

              <Box
                bg="bg.600"
                borderRadius="5px"
                color="white"
                fontSize="12px"
                px="2"
                py="0.5"
              >
                <Text>{userData.searchId}</Text>
              </Box>
            </Flex>

            <Grid
              maxW="800px"
              gridTemplateColumns="repeat(2,1fr)"
              gap={8}
              mb="20px"
            >
              <Box w="full">
                <CustomInput
                  type="text"
                  label={t('signup.firstName')}
                  placeholder={t('signup.enterFirstName')}
                  value={formik.values.firstName}
                  onChange={formik.handleChange('firstName')}
                  onBlur={formik.handleBlur('firstName')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.firstName && formik.touched.firstName && (
                  <ErrorMsg text={t(formik.errors.firstName)} />
                )}
              </Box>

              <Box w="full">
                <CustomInput
                  type="text"
                  label={t('signup.lastName')}
                  placeholder={t('signup.enterLastName')}
                  value={formik.values.lastName}
                  onChange={formik.handleChange('lastName')}
                  onBlur={formik.handleBlur('lastName')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.lastName && formik.touched.lastName && (
                  <ErrorMsg text={t(formik.errors.lastName)} />
                )}
              </Box>
            </Grid>

            <Grid
              maxW="800px"
              gridTemplateColumns="repeat(2,1fr)"
              gap={8}
              mb="20px"
            >
              <Box w="full">
                <CustomInput
                  type="email"
                  label={t('signup.email')}
                  placeholder={t('signup.enterEmail')}
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.email && formik.touched.email && (
                  <ErrorMsg text={t(formik.errors.email)} />
                )}
              </Box>
              <Box w="full">
                <PhoneInput
                  label={t('signup.phone')}
                  value={formik.values.phone}
                  onChange={formik.handleChange('phone')}
                  onBlur={formik.handleBlur('phone')}
                  disabled={read ? false : true}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <ErrorMsg text={t(formik.errors.phone)} />
                )}
              </Box>
            </Grid>

            {!read ? (
              <CustomButton
                bg
                name={t('myProfile.update')}
                w="100%"
                display="block"
                m="auto"
                mt="30px"
                onClick={() => setRead(true)}
              />
            ) : (
              <Box>
                <Flex justifyContent="space-between" alignItems="center">
                  <CustomButton
                    bg
                    type="submit"
                    name={t('account.saveChanges')}
                    w="48%"
                    mt="30px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    loading={isLoading && <Spinner ms="5px" />}
                    disabled={isLoading}
                  />
                  <CustomButton
                    name={t('resetPassword.changePassword')}
                    w="48%"
                    display="block"
                    mt="30px"
                    onClick={() => setShow(true)}
                  />
                </Flex>

                <Flex
                  justifyContent="center"
                  alignItems="center"
                  h="60px"
                  borderRadius="15px"
                  w="100%"
                  mt="24px"
                  textAlign="center"
                  color="text.1000"
                  fontWeight="bold"
                  cursor="pointer"
                  bg="gray.100"
                  onClick={() => setRead(false)}
                >
                  <Text>{t('modals.cancel')}</Text>
                </Flex>
              </Box>
            )}
          </Form>
        )}
      </Formik>

      <SimpleModal
        title={t('resetPassword.changePassword')}
        show={show}
        setShow={setShow}
      >
        <ChangePassowrdModal />
      </SimpleModal>
    </Box>
  );
};

export default Profile;
