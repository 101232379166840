import { Box, Text, Image, Grid } from '@chakra-ui/react';

const CustomAvatar = ({
  image,
  letter,
  radius = true,
  width = '100px',
  letterSize = '48px',
  ...props
}) => {
  return (
    <>
      {image ? (
        <Image
          src={image}
          borderRadius={radius ? '50%' : '10px'}
          w={width}
          h="100px"
          m="auto"
          mb="16px"
          {...props}
        />
      ) : (
        <Grid
          m="auto"
          mb="16px"
          w="100px"
          h="100px"
          placeItems="center"
          borderRadius={radius ? '50%' : '10px'}
          bg="#EDFBFF"
          {...props}
        >
          <Text
            color="#21C6E1"
            fontWeight="bold"
            fontSize={letterSize}
            textTransform="uppercase"
          >
            {letter}
          </Text>
        </Grid>
      )}
    </>
  );
};

export default CustomAvatar;
