import { useContext } from 'react';
import { userContext } from 'contexts/userContext';

const Permission = roleName => {
  const { user } = useContext(userContext);

  // if(!roles) return;

  if (user.user.roles[0]?.roleName == 'SuperAdmin') {
    return 'Edit';
  }

  let userRoles = user.user.roles.find(role => role.roleName == roleName);

  return userRoles?.accessType;
};

export default Permission;
