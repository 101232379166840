import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Grid, Spinner, Text, useToast } from '@chakra-ui/react';
import {
  CustomInput,
  CustomButton,
  ErrorMsg,
  MultiSelect,
  Label,
} from 'components/shared';
import {
  CustomToast,
  Loading,
  SelectSearch,
} from 'components/authenticated/shared';
import { useMutation, useQueryClient } from 'react-query';
import { Formik, Form, FieldArray } from 'formik';
import { createService, updateService } from 'apis/service';
import { AddServiceSchema } from 'schemas/catlog/AddServiceSchema';
import { useTranslation } from 'react-i18next';
import Permission from 'helpers/permission';

function getHierarchicalStructure(data, id) {
  for (let i = 0; i < data?.length; i++) {
    const obj = data[i];
    if (obj.id === id) {
      return [obj];
    } else if (obj.children && obj.children.length > 0) {
      const result = getHierarchicalStructure(obj.children, id);
      if (result) {
        return [obj, ...result];
      }
    }
  }
  return null;
}

const ServicePopup = ({
  setShow,
  data,
  categoriesData,
  categoryId,
  refetchServices,
}) => {
  const { t } = useTranslation();

  const customCategoriesData = categoriesData?.data[0].children?.map(e => {
    return { label: e.name, value: e.id, children: e?.children };
  });

  const parents = getHierarchicalStructure(
    categoriesData?.data[0].children,
    categoryId
  );

  const customParents = parents?.map(e => {
    return { label: e.name, value: e.id, children: e?.children };
  });

  const queryClient = useQueryClient();
  const toast = useToast();

  const { user, language } = useContext(userContext);

  const [errors, setErrors] = useState([]);
  const [categorei, setCategorie] = useState(
    data
      ? customParents
        ? customParents[0]
        : null
      : {
          name: 'Select categorie',
          id: null,
        }
  );

  const [subCategoriesSelections, setSubCategoriesSelections] = useState([]);

  const [branch, setBranch] = useState(
    data
      ? data.totalBranches
          .filter(elm => {
            return elm.isActive == true;
          })
          .map(e => {
            return {
              label: e.branch.name.en,
              value: e.branch.id,
            };
          })
      : []
  );

  const currentBranches = data?.totalBranches.map(elm => elm.branch.id);
  // console.log(currentBranches);
  // console.log(branch);
  const addedBranches = [];
  const removedBranches = [];

  const handleBranch = (value, check) => {
    if (!check) {
      let filteredBranches = branch.filter(elm => elm.value !== value.value);
      setBranch(filteredBranches);
    } else setBranch(prevState => [...prevState, value]);
  };

  const handleCategorySelect = value => {
    setCategorie(value);
    setSubCategoriesSelections(value?.children);
  };

  const { mutate: create, isLoading: loadingCreate } = useMutation(
    createService,
    {
      onSuccess: async result => {
        setErrors([]);
        setShow(false);
        refetchServices();
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.addService')}
                description={t('toasts.addService')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.addService')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const { mutate: update, isLoading: loadingUpdate } = useMutation(
    updateService,
    {
      onSuccess: result => {
        setErrors([]);
        setShow(false);
        refetchServices();
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateService')}
                description={t('toasts.updateService')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateService')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const handleSubmit = useMutation(
    ({ values, branch }) =>
      data
        ? updateService(values, branch, data.id)
        : createService(values, branch),

    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllServices');

        setErrors([]);
        data ? setShow({ flag: false, data: {} }) : setShow(false);
        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updateService')
                    : t('toasts.titles.addService')
                }
                description={
                  data ? t('toasts.updateService') : t('toasts.addService')
                }
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updateService')
                    : t('toasts.titles.addService')
                }
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  if (data && !customParents) {
    return (
      <Box minH="300px" pos="relative" overflow="hidden">
        <Loading />
      </Box>
    );
  }

  return (
    <Flex justifyContent="center">
      <Box w="full" maxW="70%">
        <Formik
          initialValues={{
            name: data ? data.name : '',
            nameAr: data ? data.nameAr : '',
            price: data ? data.totalBranches[0]?.price : '',
            applicationPrice: data
              ? data.totalBranches[0]?.applicationPrice
              : '',
            categorei: data ? customParents[0] : {},
            subCategories: data ? customParents : [],
          }}
          validationSchema={AddServiceSchema}
          onSubmit={values => {
            if (branch.length <= 0) {
              setErrors(prevErrors => [...prevErrors, 'branch']);
              return;
            }

            if (data) {
              //check if new branch has added
              branch?.forEach(newItem => {
                const foundItem = currentBranches.find(
                  oldItem => oldItem === newItem.value
                );

                if (!foundItem) {
                  addedBranches.push(newItem.value); // Object is in the new array but not in the old array, so it's added
                }
              });

              //check if old branch has removed
              currentBranches?.forEach(oldItem => {
                const foundItem = branch.find(
                  newItem => newItem.value === oldItem
                );

                if (!foundItem) {
                  removedBranches.push(oldItem); // Object is in the old array but not in the new array, so it's removed
                }
              });

              const commonObjects = currentBranches.filter(obj1 => {
                return !removedBranches.some(obj2 => obj2 === obj1);
              });

              update({
                values,
                branch,
                productId: data.id,
                updatedBranches: [...commonObjects, ...addedBranches],
                deletedBranches: [...removedBranches],
                isDefault: data?.isDefault,
              });
            } else {
              create({
                values,
                branch,
              });
            }
          }}
        >
          {formik => (
            <Form>
              <Grid mb="24px" gridTemplateColumns="repeat(2,1fr)" gap="15px">
                <Box>
                  <CustomInput
                    type="text"
                    label={t('modals.serviceName')}
                    placeholder={t('modals.enterServiceName')}
                    value={formik.values.name}
                    onChange={formik.handleChange('name')}
                    onBlur={formik.handleBlur('name')}
                    disabled={data?.isDefault}
                  />
                  {formik.errors.name && formik.touched.name && (
                    <ErrorMsg text={t(formik.errors.name)} />
                  )}
                </Box>

                <Box>
                  <CustomInput
                    type="text"
                    label={'service name ar'}
                    placeholder={t('modals.enterServiceName')}
                    value={formik.values.nameAr}
                    onChange={formik.handleChange('nameAr')}
                    onBlur={formik.handleBlur('nameAr')}
                    disabled={data?.isDefault}
                  />
                  {formik.errors.nameAr && formik.touched.nameAr && (
                    <ErrorMsg text={t(formik.errors.nameAr)} />
                  )}
                </Box>
              </Grid>

              <Grid gridTemplateColumns="repeat(2,1fr)" mb="24px" gap="15px">
                <Box w="full">
                  <CustomInput
                    type="number"
                    label={'service cost'}
                    placeholder={'enter cost'}
                    value={formik.values.price}
                    onChange={formik.handleChange('price')}
                    onBlur={formik.handleBlur('price')}
                  />
                  {formik.errors.price && formik.touched.price && (
                    <ErrorMsg text={t(formik.errors.price)} />
                  )}
                </Box>

                <Box w="full">
                  <CustomInput
                    type="number"
                    label={'app service cost'}
                    placeholder={'enter cost'}
                    value={formik.values.applicationPrice}
                    onChange={formik.handleChange('applicationPrice')}
                    onBlur={formik.handleBlur('applicationPrice')}
                  />
                  {formik.errors.applicationPrice &&
                    formik.touched.applicationPrice && (
                      <ErrorMsg text={t(formik.errors.applicationPrice)} />
                    )}
                </Box>
              </Grid>

              <Box w="full" mb="24px">
                <Text fontSize="14px" mb="5px" fontWeight="bold">
                  {t('home.branches.title')}
                </Text>

                <MultiSelect
                  w="100%"
                  h="60px"
                  label={t('modals.branch')}
                  labelForMulti={t('sideBar.branches')}
                  select={branch}
                  handleSelect={handleBranch}
                  selections={user?.user?.branches?.map((item, index) => ({
                    label: language == 'en' ? item.name.en : item.name.ar,
                    value: item.id,
                  }))}
                />

                {errors.includes('branch') && (
                  <ErrorMsg text={t('signup.fieldRequired')} />
                )}
              </Box>

              <FieldArray name="subCategories">
                {({ push, remove }) => (
                  <>
                    <Box mb="24px">
                      <Label text="select categorie" />
                      <SelectSearch
                        h="60px"
                        label={'select Categorie'}
                        select={categorei}
                        handleSelect={value => {
                          handleCategorySelect(value);
                          formik.setFieldValue('subCategories', []);

                          if (value.children) {
                            push(value);
                          }

                          formik.setFieldValue('categorie', value);
                        }}
                        selections={customCategoriesData}
                        //isDisabled={item ? true : false}
                      />
                      {/* {formik.errors.employee && formik.touched.employee && (
                      <ErrorMsg text={t(formik.errors.employee)} />
                    )} */}
                    </Box>

                    {formik.values.subCategories.map((sub, index) => {
                      // sub is an array of items

                      const customSubCategoriesData = sub?.children?.map(e => {
                        return {
                          label: e.name,
                          value: e.id,
                          children: e?.children || [],
                        };
                      });

                      if (sub?.children?.length > 0) {
                        return (
                          <Box mb="24px">
                            <SelectSearch
                              h="60px"
                              label={'select subCategorie'}
                              select={{
                                label:
                                  formik.values.subCategories[index + 1]
                                    ?.label || 'subcategory',
                                value:
                                  formik.values.subCategories[index + 1]
                                    ?.value || '',
                              }}
                              handleSelect={value => {
                                if (formik.values.subCategories[index + 1]) {
                                  const newSub =
                                    formik.values.subCategories.slice(
                                      0,
                                      index + 1
                                    );

                                  formik.setFieldValue('subCategories', newSub);
                                  if (value.children) {
                                    push(value);
                                  }
                                } else {
                                  if (value.children) {
                                    push(value);
                                  }
                                }

                                //setSelectedSubCategories(value);
                                //formik.setFieldValue('employee', value.value);
                              }}
                              selections={customSubCategoriesData}
                              //isDisabled={item ? true : false}
                            />
                            {/* {formik.errors.employee &&
                                    formik.touched.employee && (
                                      <ErrorMsg text={t(formik.errors.employee)} />
                                    )} */}
                          </Box>
                        );
                      }
                    })}
                  </>
                )}
              </FieldArray>

              {/* {subCategoriesSelections.length > 0 ? (
                    <Box mb="24px">
                      <SelectSearch
                        h="60px"
                        label={'select subCategorie'}
                        select={selectedSubCategories}
                        handleSelect={value => {
                          setSelectedSubCategories(value);
                          //formik.setFieldValue('employee', value.value);
                        }}
                        selections={subCategoriesSelections}
                        //isDisabled={item ? true : false}
                      />
                      {formik.errors.employee && formik.touched.employee && (
                      <ErrorMsg text={t(formik.errors.employee)} />
                    )}
                    </Box>
                  ) : null} */}
              {Permission('Products') == 'Edit' ||
              Permission('Products') == null ? (
                <CustomButton
                  bg
                  type="submit"
                  name={t('account.saveChanges')}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  loading={
                    loadingCreate || (loadingUpdate && <Spinner ms="5px" />)
                  }
                  disabled={loadingCreate || loadingUpdate}
                />
              ) : null}
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default ServicePopup;
