import { Box, Flex, Text, Spinner } from '@chakra-ui/react';
import { Save } from 'components/authenticated/shared';
import { CustomButton } from 'components/shared';
import { useTranslation } from 'react-i18next';

const DeletePopup = ({ title, handleDelete, isLoading, buttonText }) => {
  const { t } = useTranslation();

  return (
    <Flex justifyContent="center">
      <Box maxW="70%">
        <Text>{title}</Text>

        <CustomButton
          name={
            buttonText == 'delete' ? t('account.delete') : t('modals.delete')
          }
          bg
          h="60px"
          mt="30px"
          loading={isLoading && <Spinner ms="5px" />}
          disabled={isLoading}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleDelete}
        />
        {/* <Save
          firstBtn={{ text: t('account.deactivate'), function: handleDelete }}
          isLoading={isLoading}
        /> */}
      </Box>
    </Flex>
  );
};

export default DeletePopup;
