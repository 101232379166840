import { Link as RouterLink } from 'react-router-dom';
import { Box, Image, Text, Link } from '@chakra-ui/react';
import curveLine from 'assets/images/curve.svg';
import googlePlay from 'assets/images/google-play.png';
import appleStore from 'assets/images/apple-store.png';
import qr from 'assets/images/qr.svg';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/logo2.png';

const imgLinks = [
  { img: googlePlay, link: '/google' },
  { img: appleStore, link: '/apple' },
  { img: qr, link: '/qr' },
];

const Side = () => {
  const { t } = useTranslation();

  return (
    <Box bg="bg.100" px="30px" py="8" minH="100vh" pos="relative">
      <Link to="/ali">
        <Image src={logo} alt="" w="300px" />
      </Link>

      <Box pos="relative" m="auto">
        <Text fontSize="48px" lineHeight="60px" mt="100px" color="text.400">
          Industry <br />
          solutions built
          <br /> for you
        </Text>
        <Image ms="65px" src={curveLine} alt="" />
      </Box>

      {/* <Box display="flex" pos="absolute" bottom="20px">
        {imgLinks.map(l => {
          return (
            <Link
              as={RouterLink}
              me="5px"
              _focus={{ outline: 'none' }}
              to={l.link}
              key={Math.random()}
            >
              <Image src={l.img} alt="" />
            </Link>
          );
        })}
      </Box> */}
    </Box>
  );
};

export default Side;
