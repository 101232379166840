import { useState, useEffect, useRef } from 'react';
import { Box, Flex, Text, Input, Grid } from '@chakra-ui/react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { useMutation } from 'react-query';
import { deleteProductInProject } from 'apis/projects';

const AddPart = ({
  id,
  searchId,
  name,
  quantity,
  changePrice,
  changeQuantity,
  inputQuantity,
  formik,
  index,
  arrayHelpers,
  setParts,
  parts,
  partsArray,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Flex p="10px" bg="#f9fafb" pos="relative" {...props}>
      <Box w="25%">{searchId}</Box>
      <Box w="30%">
        <Text
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          maxW="100px"
          title={name}
        >
          {name}
        </Text>
      </Box>
      <Box w="35%">
        <Input
          as={Field}
          type="number"
          h="30px"
          w="75px"
          p="10px"
          _focus={{}}
          name={changeQuantity}
        />
        {formik.errors &&
          formik.errors.parts &&
          formik.errors.parts[index] &&
          formik.errors.parts[index].quantity &&
          formik.touched &&
          formik.touched.parts &&
          formik.touched.parts[index] &&
          formik.touched.parts[index].quantity && (
            <Box fontSize="14px" color="red">
              {t('modals.enterQuantity')}
            </Box>
          )}
        {/* <Text fontSize="13px" color={isBig ? 'red' : 'initial'}>
          {quantity} {t('modals.isAvalible')}
        </Text> */}
      </Box>
      <Box w="30%">
        <Input
          as={Field}
          name={changePrice}
          type="number"
          h="30px"
          w="75px"
          p="10px"
          _focus={{}}
        />
        {formik.errors &&
          formik.errors.parts &&
          formik.errors.parts[index] &&
          formik.errors.parts[index].price &&
          formik.touched &&
          formik.touched.parts &&
          formik.touched.parts[index] &&
          formik.touched.parts[index].price && (
            <Box fontSize="14px" color="red">
              {t('modals.enterPrice')}
            </Box>
          )}
      </Box>
      <Grid
        w="30px"
        h="30px"
        bg="bg.300"
        cursor="pointer"
        placeItems="center"
        borderRadius="50%"
        _focus={{}}
        _hover={{ bg: '#30d2ed' }}
        _active={{ bg: 'bg.400' }}
        color="white"
        pos="absolute"
        right="10px"
        top="10px"
        onClick={() => {
          setParts(
            parts.filter(e => {
              return e.value != id;
            })
          );

          // mutate(productId);

          arrayHelpers.remove(index);
        }}
      >
        <Box as={AiOutlineClose} />
      </Grid>
    </Flex>
  );
};

export default AddPart;
