import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Flex } from '@chakra-ui/react';
import { FieldArray } from 'formik';
import { MultiSelect, SimpleModal } from 'components/shared';
import { MdAdd } from 'react-icons/md';
import { ServicePopup } from 'components/authenticated/services';
import AddService from './add-service';
import { MultiSelectSearch } from 'components/authenticated/shared';

const HandleServices = memo(
  ({
    options,
    loadingOptions,
    servicesArray,
    formik,
    services,
    setServices,
  }) => {
    const { t } = useTranslation();

    const [showAddServices, setShowAddServices] = useState(false);
    const [serData, setSerData] = useState([]);
    // const [services, setServices] = useState(
    //   servicesArray
    //     ? servicesArray.map(e => {
    //         return { label: e.product.name, value: e.product.id };
    //       })
    //     : []
    // );

    const handleServices = (value, check, index, e, data) => {
      // setServ(false);
      if (!check) {
        let filteredBranches = services.filter(
          elm => elm.value !== value.value
        );
        setServices(filteredBranches);

        let ind = data.findIndex(e => {
          return e.invoiceProductId == value.value;
        });

        e.remove(ind);
      } else {
        setServices(prevState => [...prevState, value]);

        e.insert(index, {
          type: 'service',
          invoiceProductId: value.value,
          name: value.label,
          quantity: 1,
          searchId: value.id,
          price: value.price,
        });
      }
    };

    return (
      <>
        <Box w="full">
          <Text fontSize="14px" mb="5px" fontWeight="bold">
            {t('project.services')}
          </Text>

          <FieldArray
            name="services"
            render={arrayHelpers => {
              // typeof errors.services === 'string' ? (
              //   <Text>{errors.services}</Text>
              // ) : null;

              return (
                <>
                  <Box mb="24px">
                    <Flex
                      w="full"
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Box w="82%" maxH="100px">
                        <MultiSelectSearch
                          labelForMulti={t('home.dashboard.services')}
                          h="60px"
                          label={t('modals.selectServices')}
                          select={services}
                          isLoadingSelections={loadingOptions}
                          handleSelect={(value, check, index) =>
                            handleServices(
                              value,
                              check,
                              index,
                              arrayHelpers,
                              formik.values.services
                            )
                          }
                          selections={options}
                        />
                      </Box>
                      <Flex
                        bg="bg.300"
                        color="white"
                        h="60px"
                        w="15%"
                        justifyContent="center"
                        alignItems="center"
                        fontSize="32px"
                        borderRadius="10px"
                        cursor="pointer"
                        onClick={() => setShowAddServices(true)}
                      >
                        <MdAdd />
                      </Flex>
                    </Flex>
                  </Box>

                  {formik.values.services?.length > 0 && (
                    <Box mb="20px">
                      <Flex
                        p="10px"
                        borderRadius="5px 5px 0 0"
                        borderBottom="1px solid #b7b8bb"
                        bg="#21C6E1"
                        color="white"
                      >
                        <Box w="30%">{t('home.dashboard.id')}</Box>
                        <Box w="35%">{t('home.dashboard.name')}</Box>
                        <Box w="20%">{t('home.dashboard.price')}</Box>
                      </Flex>
                      {formik.values.services.map((e, i) => {
                        return (
                          <AddService
                            arrayHelpers={arrayHelpers}
                            setServices={setServices}
                            services={services}
                            servicesArray={servicesArray}
                            index={i}
                            key={e.id}
                            setSerData={setSerData}
                            serData={serData}
                            id={e.invoiceProductId}
                            searchId={e.searchId}
                            text={e.name}
                            name={`services.${i}.price`}
                            border="1px solid #BFCFD6"
                            borderTop="0"
                            formik={formik}
                            borderRadius={
                              i == formik.values.services.length - 1
                                ? '0 0 5px 5px'
                                : '0'
                            }
                          />
                        );
                      })}
                    </Box>
                  )}
                </>
              );
            }}
          />
        </Box>

        <SimpleModal
          title={t('home.services.addNewService')}
          show={showAddServices}
          setShow={setShowAddServices}
        >
          <ServicePopup setShow={setShowAddServices} />
        </SimpleModal>
      </>
    );
  }
);

export default HandleServices;
