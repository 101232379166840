import * as Yup from 'yup';

export const AddClientSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'signup.firstName3Char')
    .max(100, 'signup.firstName100char')
    .required('signup.fieldRequired'),
  email: Yup.string()
    .email('signup.invalidEmail')
    .max(250, 'signup.email250Char'),
  street: Yup.string()
    .min(3, 'signup.street3Char')
    .max(250, 'signup.street250Char'),
  city: Yup.string().min(3, 'signup.city3Char').max(250, 'signup.city250Char'),
  zipCode: Yup.string()
    .min(3, 'signup.zipCode3Char')
    .max(250, 'signup.zipCode250Char'),
  notes: Yup.string().min(3, 'signup.minLength').max(255, 'signup.maxLength'),
  phone: Yup.string()
    .min(10, 'signup.phone8Char')
    .max(16, 'phone12Char')
    .required('signup.fieldRequired')
    .test('phoneStartsWithZero', 'startWithZero', phone =>
      phone && phone[3] === '0' ? false : true
    ),
  // taxNumber: Yup.string()
  // .test('numberOfDigits','taxNumber15Char',(cn) => (
  //     (!cn)?true:((cn.length === 15)?true:false)
  // ))
});
