import { useContext } from 'react';
import { userContext } from 'contexts/userContext';
import { Flex, Box, Heading, Text } from '@chakra-ui/react';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import { FaGlobe } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const PageHeader = ({ text, isArrow }) => {
  const navigate = useNavigate();
  const { language } = useContext(userContext);

  return (
    <Flex alignItems="center" mb="40px">
      {isArrow ? (
        <Box
          as={language == 'en' ? HiOutlineArrowLeft : HiOutlineArrowRight}
          fontSize="30px"
          color="text.500"
          cursor="pointer"
          onClick={() => navigate(-1)}
          me="16px"
        />
      ) : null}

      <Flex flexDir="column" alignItems="center">
        {text != null ? (
          <Text
            color="text.1800"
            textAlign="center"
            fontSize="40px"
            lineHeight="32px"
            fontWeight="bolder"
          >
            {text}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default PageHeader;
