import { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Image,
  Checkbox,
  Link,
  LinkBox,
  Spinner,
} from '@chakra-ui/react';
import { Header, ContinueBack } from 'components/unauthenticated/shared';
import { CustomButton } from 'components/shared';
import FormBody from '../../shared/form-body';
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';
import imageDefault from 'assets/images/defaultBranch.png';
// import imageDefault from 'assets/images/imageDefault.jpg';
import profileDefault from 'assets/images/avatar-user.png';
import { createWorkshop } from 'apis/iam/Signup';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const SignupConfirmForm = ({ paginate, personal, workshop, setSuccess }) => {
  const { t } = useTranslation();

  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    firstName,
    lastName,
    phone,
    email,
    image: profileImage,
    password,
  } = personal;

  const {
    workshopNameEN,
    workshopNameAR,
    city,
    street,
    countrySelected,
    commercialNumber,
    image: workshopImage,
    mapSelected,
    zipCode,
    buildingNumber,
    district,
    additionalNumber,
  } = workshop;

  const { mutate } = useMutation(createWorkshop, {
    onSuccess: res => {
      setSuccess(res);
      setLoading(false);
      paginate(1);
    },
    onError: err => {
      console.log(err.response);
    },
  });

  let formatedPhone = phone.indexOf('+') !== -1 ? phone : `+${phone}`;

  const data = {
    name: {
      ar: workshopNameAR,
      en: workshopNameEN,
    },
    lat: mapSelected.lat,
    long: mapSelected.lng,
    city: city,
    street: street,
    buildingNumber: buildingNumber.toString(),
    district: district.toString(),
    additionalNumber: additionalNumber.toString(),
    zipCode: zipCode.toString(),
    country: countrySelected,
    commercialNumber: commercialNumber.toString(),
    imagePath: workshopImage,
    logoPath: '',
    superAdmin: {
      phoneNumber: formatedPhone,
      password: password,
      firstName: firstName,
      lastName: lastName,
      emailAddress: email,
      roles: [],
      profileImagePath: profileImage,
    },
  };

  return (
    <Box>
      <Header
        heading={t('signup.header')}
        text={t('signup.confirmInformation')}
        isArrow
      />
      <Flex justifyContent="center" alignItems="center" mt="40px">
        <FormBody boxShadow="0 8px 160px -100px #21B3E1">
          <Flex justifyContent="start" alignItems="center">
            <Image
              src={
                profileImage == ''
                  ? profileDefault
                  : `${process.env.REACT_APP_API_BASE_URL}/uploads/image/${profileImage}`
              }
              w="100px"
              h="100px"
              borderRadius="50%"
              alt=""
              me="6"
            />
            <Box>
              <Flex>
                <Text fontWeight="bold" mb="2">
                  {firstName} {lastName}
                </Text>
              </Flex>

              <Box>
                <Flex alignItems="center">
                  <Text color="gray.400" me="1">
                    <HiOutlineMail />
                  </Text>
                  <Text>{email}</Text>
                </Flex>
                <Flex alignItems="center">
                  <Text color="gray.400" me="1">
                    <HiOutlinePhone />
                  </Text>
                  <Text>+{phone}</Text>
                </Flex>
              </Box>
            </Box>
          </Flex>
          {/* 
          <Image
            src={
              workshopImage == ''
                ? imageDefault
                : `${process.env.REACT_APP_API_BASE_URL}/uploads/image/${workshopImage}`
            }
            alt=""
            my="8"
            borderRadius="12px"
            h="180px"
            w="100%"
            objectFit="cover"
          /> */}

          <Flex
            my="25px"
            justifyContent="space-between"
            alignItems="center"
            dir="ltr"
          >
            <Text fontWeight="bold">{workshopNameEN}</Text>
            <Text fontWeight="bold">{workshopNameAR}</Text>
          </Flex>

          <Box mb="4" fontWeight="medium">
            <Text color="gray.400" mb="1" fontSize="sm">
              {t('signup.location')}
            </Text>
            <Text>
              {city}, {street}, {countrySelected.value}
            </Text>
          </Box>

          <Box mb="30px" fontWeight="medium">
            <Text color="gray.400" mb="1" fontSize="sm">
              {t('signup.commercialNumber')}
            </Text>
            <Text>{commercialNumber}</Text>
          </Box>

          <LinkBox>
            <Checkbox onChange={e => setCheck(e.target.checked)} me="5px">
              {t('signup.conditionText')}
            </Checkbox>
            <Link
              textDecoration="underline"
              href="https://policy.aerbag.com/terms/"
              target="_blank"
              _focus={{}}
            >
              {t('signup.conditionText1')}
            </Link>
          </LinkBox>

          <Flex justifyContent="space-between" mt="50px">
            <CustomButton
              name={t('signup.back')}
              w="40%"
              onClick={() => {
                paginate(-1);
              }}
            />
            <CustomButton
              disabled={!check || loading}
              name={t('signup.continue')}
              bg
              w="56%"
              loading={loading ? <Spinner ms="10px" /> : null}
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                mutate(data);
                setLoading(true);
              }}
            />
          </Flex>
        </FormBody>
      </Flex>
    </Box>
  );
};

export default SignupConfirmForm;
