import * as Yup from 'yup';

const editServiceByCategory = Yup.object({
  price: Yup.number(),
  applicationPrice: Yup.number().when('price', (price, schema) => {
    if (price !== undefined) {
      return schema.test(
        'isLessThan20Percent',
        'home.services.appPrice',
        function (applicationPrice) {
          const maxAppPrice = (price * 80) / 100; // Calculate 20% of the 'price'
          return (
            applicationPrice === undefined || applicationPrice <= maxAppPrice
          );
        }
      );
    } else {
      return schema; // If 'price' is undefined, no validation is applied to 'applicationPrice'
    }
  }),
});

export default editServiceByCategory;
