import { useState } from 'react';
import { Box, Grid, GridItem, Heading, useToast } from '@chakra-ui/react';
import Card from './card';
import ShiftPopup from './modal';
import AddNew from '../add-new';
import { CustomModal, SimpleModal } from 'components/shared';
import { DeletePopup, CustomToast } from 'components/authenticated/shared';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deleteShift } from 'apis/settings';
import { useTranslation } from 'react-i18next';
import Permission from 'helpers/permission';

const Shifts = ({ isView, shiftsSuccess, shifts }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const toast = useToast();

  //show modals
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState({ flag: false, data: {} });
  const [showDelete, setShowDelete] = useState({ flag: false, data: {} });

  const handleDelete = useMutation(() => deleteShift([showDelete.data.id]), {
    onSuccess: async result => {
      await queryClient.invalidateQueries('getAllShifts');

      setShowDelete({ flag: false, data: {} });

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deleteShift')}
              description={t('toasts.deleteShift')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deleteShift')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Box>
      <Heading
        as="h3"
        fontWeight="bold"
        fontSize="20px"
        mb="16px"
        textTransform="capitalize"
      >
        {t('account.shifts')}
      </Heading>
      {shiftsSuccess && (
        <>
          <Grid
            templateColumns={[
              'repeat(4, 1fr)',
              'repeat(4, 1fr)',
              'repeat(3, 1fr)',
              'repeat(3, 1fr)',
              'repeat(4, 1fr)',
            ]}
            gap={5}
          >
            {shifts?.data.map(item => {
              return (
                <GridItem>
                  <Card
                    item={item}
                    setShowEdit={setShowEdit}
                    setShowDelete={setShowDelete}
                    isView={isView}
                  />
                </GridItem>
              );
            })}

            <GridItem>
              {!isView && (
                <AddNew
                  h={shifts?.data.length == 0 ? '120px' : '100%'}
                  onClick={() => setShowAdd(true)}
                />
              )}
            </GridItem>
          </Grid>

          <SimpleModal
            title={t('account.addShift')}
            titleDir="left"
            show={showAdd}
            setShow={setShowAdd}
          >
            <ShiftPopup setShow={setShowAdd} />
          </SimpleModal>

          <CustomModal
            title={t('account.editShift')}
            titleDir="left"
            show={showEdit}
            setShow={setShowEdit}
          >
            <ShiftPopup setShow={setShowEdit} data={showEdit.data} />
          </CustomModal>

          <CustomModal
            title={t('account.deleteShift')}
            show={showDelete}
            setShow={setShowDelete}
          >
            <DeletePopup
              handleDelete={handleDelete.mutate}
              title={t('account.confirmDeleteShift')}
              isLoading={handleDelete.isLoading}
              buttonText="delete"
            />
          </CustomModal>
        </>
      )}
    </Box>
  );
};

export default Shifts;
