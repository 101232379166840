import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, useToast, Grid, Spinner } from '@chakra-ui/react';
import {
  CustomInput,
  ErrorMsg,
  CustomButton,
  Map,
  MultiSelect,
  Label,
} from 'components/shared';
import RangeCover from './slider';
import { PickTime, CustomToast } from 'components/authenticated/shared';
import moment from 'moment';
import { getAllPagesUsers, getAllUsersByBranch } from 'apis/employee';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { Formik, Form } from 'formik';
import { AddShiftSchema } from 'schemas/shift/AddShiftSchema';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { createShift, updateShift } from 'apis/settings';
import { useTranslation } from 'react-i18next';

const ShiftPopup = ({ setShow, data }) => {
  const { t } = useTranslation();
  const { branchFilter } = useContext(userContext);

  const queryClient = useQueryClient();
  const toast = useToast();
  const [errors, setErrors] = useState([]);

  const [branch, setBranch] = useState([branchFilter]);

  useEffect(() => {
    setBranch([branchFilter]);
  }, []);

  useEffect(() => {
    if (branchFilter.value == null) {
      setBranch([]);
    } else {
      setBranch([branchFilter]);
    }
  }, [branchFilter]);

  const [marker, setMarker] = useState(
    data
      ? {
          lat: data.location.lat,
          lng: data.location.long || data.location.lng,
        }
      : null
  );

  const [coverRange, setCoverRange] = useState(
    data ? data.allowedRadiusRange : 0
  );

  const weekends = [
    { label: t('days.sun'), value: 0 },
    { label: t('days.mon'), value: 1 },
    { label: t('days.thu'), value: 2 },
    { label: t('days.wed'), value: 3 },
    { label: t('days.thur'), value: 4 },
    { label: t('days.fri'), value: 5 },
    { label: t('days.sat'), value: 6 },
  ];

  const [weekend, setWeekend] = useState(
    data
      ? data.weekends.map(e => {
          return { label: weekends[e].label, value: e };
        })
      : []
  );

  const [employee, setEmployee] = useState(
    data
      ? data.numberOfEmployees == 0
        ? []
        : data.users?.map(e => {
            return { label: e.firstName + ' ' + e.lastName, value: e.id };
          })
      : []
  );

  const handleMarker = e => {
    let lat = e?.latLng?.lat();
    let lng = e?.latLng?.lng();

    setMarker(e);
    setErrors([]);
  };

  const handleCoverRange = val => {
    setCoverRange(val);
    setErrors([]);
  };

  function convertTo24HourTime(time12Hour) {
    const isAM = /AM/i.test(time12Hour);
    const isPM = /PM/i.test(time12Hour);

    if (isPM && !time12Hour.startsWith('12')) {
      return moment(time12Hour, 'hh:mm:ss a')
        .locale('en')
        .add('12', 'hours')
        .format('HH:mm:ss');
    } else if (isAM && !time12Hour.startsWith('12')) {
      return moment(time12Hour, 'hh:mm:ss a').locale('en').format('HH:mm:ss');
    } else if (isAM && time12Hour.startsWith('12')) {
      // Handle midnight (12:xx:xx AM)
      return '00' + time12Hour.slice(2);
    } else {
      return time12Hour;
    }
  }

  //requests

  const { data: employees } = useQuery(
    ['getAllUsersByBranch', branch[0]?.value],
    getAllUsersByBranch
  );

  const employeesConditionShifts = employees?.data?.items?.filter(e => {
    if (e.shifts == null) {
      return e;
    } else {
      return e?.shifts?.length < 3;
    }
  });

  const handleSubmit = useMutation(
    ({ values, marker, coverRange }) =>
      data
        ? updateShift(values, marker, coverRange, data.id)
        : createShift(values, marker, coverRange),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllShifts');

        setErrors([]);
        data ? setShow({ flag: false, data: {} }) : setShow(false);
        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updateShift')
                    : t('toasts.titles.addShift')
                }
                description={
                  data ? t('toasts.updateShift') : t('toasts.addShift')
                }
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updateShift')
                    : t('toasts.titles.addShift')
                }
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box>
      <Formik
        initialValues={{
          shiftName: data ? data.name : '',
          locationName: data ? data.locationName : '',
          checkinTime: data
            ? moment(data?.start, 'HH:mm:ss').locale('en').format('HH:mm:ss')
            : '',
          checkoutTime: data
            ? moment(data?.end, 'HH:mm:ss').locale('en').format('HH:mm:ss')
            : '',
          weekends: data ? data.weekends : [],
          usersIds: data
            ? data.users?.map(e => {
                return e.id;
              })
            : [],
        }}
        validationSchema={AddShiftSchema}
        onSubmit={values => {
          const checkIn24HourFormat = convertTo24HourTime(values.checkinTime);

          const checkOut24HourFormat = convertTo24HourTime(values.checkoutTime);

          const checkIn = moment(checkIn24HourFormat, 'HH:mm');
          const checkOut = moment(checkOut24HourFormat, 'HH:mm');

          // const duration = moment.duration(endTime.diff(startTime));
          // const hours = parseInt(duration.asHours());
          // const minutes = parseInt(duration.asMinutes()) % 60;

          if (!marker) {
            toast({
              render: props => {
                return (
                  <CustomToast
                    title={'map'}
                    description={'you should select in map'}
                    status="error"
                    onClose={props.onClose}
                  />
                );
              },
            });

            setErrors(prevErrors => [...prevErrors, 'marker']);
            return;
          } else if (!coverRange) {
            toast({
              render: props => {
                return (
                  <CustomToast
                    title={'map'}
                    description={'you should cover range in map'}
                    status="error"
                    onClose={props.onClose}
                  />
                );
              },
            });

            setErrors(prevErrors => [...prevErrors, 'coverRange']);
            return;
          } else {
            if (checkIn.isSame(checkOut)) {
              toast({
                render: props => {
                  return (
                    <CustomToast
                      title={t('toasts.titles.shiftTimeError')}
                      description={t('toasts.checkInSameCheckout')}
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else if (checkOut.isBefore(checkIn)) {
              toast({
                render: props => {
                  return (
                    <CustomToast
                      title={t('toasts.titles.shiftTimeError')}
                      description={t('toasts.checkInBiggetCheckout')}
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else if (checkOut.diff(checkIn, 'hours') < 1) {
              toast({
                render: props => {
                  return (
                    <CustomToast
                      title={t('toasts.titles.shiftTimeError')}
                      description={
                        'the diffrence between checkin and checkout must be 1 hour at least'
                      }
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else {
              handleSubmit.mutate({
                values,
                marker,
                coverRange,
              });
            }
          }
        }}
      >
        {formik => {
          return (
            <Form>
              <Box mb="24px">
                <CustomInput
                  type="text"
                  label={t('account.shiftName')}
                  placeholder={t('account.enterShiftName')}
                  value={formik.values.shiftName}
                  onChange={formik.handleChange('shiftName')}
                  onBlur={formik.handleBlur('shiftName')}
                />
                {formik.errors.shiftName && formik.touched.shiftName && (
                  <ErrorMsg text={t(formik.errors.shiftName)} />
                )}
              </Box>
              <Flex justifyContent="space-between" mb="24px">
                <Box w="48%">
                  <PickTime
                    defaultText={t('account.clockInTime')}
                    // maxTime={formik.values.checkoutTime}
                    // minTime={formik.values.checkinTime}
                    handleTime={value =>
                      formik.setFieldValue('checkinTime', value)
                    }
                    defaultTime={
                      data && new Date(moment(data?.start, 'HH:mm:ss'))
                    }
                  />
                  {/* {errors.includes('clockIn') && (
                    <ErrorMsg text={'This field is required'} />
                  )} */}
                  {formik.errors.checkinTime && formik.touched.checkinTime && (
                    <ErrorMsg text={t(formik.errors.checkinTime)} />
                  )}
                </Box>

                <Box w="48%">
                  <PickTime
                    handleTime={value =>
                      formik.setFieldValue('checkoutTime', value)
                    }
                    defaultText={t('account.clockOutTime')}
                    // minTime={formik.values.checkinTime}
                    // maxTime={formik.values.clockOut}
                    defaultTime={
                      data && new Date(moment(data?.end, 'HH:mm:ss'))
                    }
                  />
                  {formik.errors.checkoutTime &&
                    formik.touched.checkoutTime && (
                      <ErrorMsg text={t(formik.errors.checkoutTime)} />
                    )}
                </Box>
              </Flex>

              <Box mb="24px">
                <Box>
                  <Label text={t('account.locationName')} />
                  <Flex>
                    <Box w="85%" me="20px">
                      <CustomInput
                        type="text"
                        placeholder={t('account.enterLocationName')}
                        value={formik.values.locationName}
                        onChange={formik.handleChange('locationName')}
                        onBlur={formik.handleBlur('locationName')}
                      />
                      {formik.errors.locationName &&
                        formik.touched.locationName && (
                          <ErrorMsg text={t(formik.errors.locationName)} />
                        )}
                    </Box>

                    <Box w="10%" mt="5px">
                      <Map
                        marker={marker}
                        handleMarker={handleMarker}
                        coverRange={coverRange}
                        errMsg={errors.length > 0}
                        setMarker={setMarker}
                      >
                        <Box px="12px">
                          <RangeCover
                            value={coverRange}
                            handleValue={handleCoverRange}
                            mt="24px"
                          />
                        </Box>
                      </Map>
                    </Box>
                  </Flex>
                </Box>

                {/* {errors.includes('country') && (
        <ErrorMsg text={'This field is required'} />
      )}
      {errors.includes('marker') && (
        <ErrorMsg text={'Please select in map'} />
      )} */}
              </Box>
              <Grid gridTemplateColumns="repeat(2,1fr)" gap={6} mb="20px">
                <Box>
                  <Label text={t('account.selectWeekends')} />

                  <MultiSelect
                    h="60px"
                    label={t('account.selectWeekendLabel')}
                    labelForSingle={t('account.singleWeekend')}
                    labelForMulti={t('account.weekends')}
                    select={weekend}
                    handleSelect={(value, check) => {
                      // handleWeekend(value, check);

                      if (!check) {
                        let filterWeekends = weekend.filter(
                          elm => elm.value !== value.value
                        );

                        let days = filterWeekends.map(e => {
                          return e.value;
                        });
                        setWeekend(filterWeekends);
                        formik.setFieldValue('weekends', days);
                      } else {
                        let daysArr = [...weekend, value];
                        let days = daysArr.map(e => {
                          return e.value;
                        });

                        setWeekend(prevState => [...prevState, value]);

                        formik.setFieldValue('weekends', days);
                      }
                    }}
                    selections={weekends?.map((item, index) => ({
                      label: item.label,
                      value: item.value,
                    }))}
                  />
                  {formik.errors.weekends && formik.touched.weekends && (
                    <ErrorMsg text={t(formik.errors.weekends)} />
                  )}
                </Box>

                <Box>
                  <Label text={t('account.selectEmployees')} />

                  <MultiSelect
                    h="60px"
                    label={t('home.employees.selectEmployee')}
                    labelForMulti={t('home.employees.employees')}
                    labelForSingle={t('home.employees.employee')}
                    select={employee}
                    handleSelect={(value, check) => {
                      if (!check) {
                        let filterEmployees = employee.filter(
                          elm => elm.value !== value.value
                        );

                        let ids = filterEmployees.map(e => {
                          return e.value;
                        });

                        setEmployee(filterEmployees);
                        formik.setFieldValue('usersIds', ids);
                      } else {
                        let employeesArr = [...employee, value];
                        let employees = employeesArr.map(e => {
                          return e.value;
                        });
                        setEmployee(prevState => [...prevState, value]);

                        formik.setFieldValue('usersIds', employees);
                      }
                    }}
                    selections={employeesConditionShifts?.map(
                      (item, index) => ({
                        label: item.firstName + ' ' + item.lastName,
                        value: item.id,
                      })
                    )}
                  />
                  {/* {formik.errors.weekends && formik.touched.weekends && (
                    <ErrorMsg text={formik.errors.weekends} />
                  )} */}
                </Box>
              </Grid>

              <CustomButton
                bg
                type="submit"
                name={t('account.saveChanges')}
                w="100%"
                isDisabled={handleSubmit.isLoading}
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={handleSubmit.isLoading && <Spinner ms="5px" />}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ShiftPopup;
