import { Box, Text, Link, Image } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { getFileData } from 'apis/tickets';
import moment from 'moment';
import FileNameSize from '../file-name-size';
import png from 'assets/images/icons/png.svg';
import jpg from 'assets/images/icons/jpg.svg';
import pdf from 'assets/images/icons/pdf.svg';
import xlsx from 'assets/images/icons/xlsx.svg';
import docx from 'assets/images/icons/docx.svg';
import { useTranslation } from 'react-i18next';

const FileMessage = ({ date, file, from }) => {
  // const { data } = useQuery(['getFileData', file], getFileData);
  const { t } = useTranslation();

  return (
    <Box
      bg="white"
      p="10px"
      borderRadius="10px"
      w="325px"
      ms={from == 'staff' ? '0' : 'auto'}
      me={from == 'staff' ? 'auto' : '0'}
      pos="relative"
      mb="25px"
    >
      <Link
        color="white"
        lineH="17.4px"
        fontSize="14px"
        target="_blanck"
        href={`${process.env.REACT_APP_API_BASE_URL}/uploads/tickets/comments/${file}`}
        _active={{}}
        _focus={{}}
      >
        <FileNameSize
          image={
            file.split('.').pop() == 'jpg' || file.split('.').pop() == 'jpeg'
              ? jpg
              : file.split('.').pop() == 'png'
              ? png
              : file.split('.').pop() == 'pdf'
              ? pdf
              : ''
          }
          name={t('ticket.openFile')}
          isClose={false}

          // color="white"
        />
      </Link>
      {/* <Image
        src={`${process.env.REACT_APP_API_BASE_URL}/uploads/tickets/comments/${file}`}
      /> */}

      <Text
        fontSize="12px"
        color="#666668"
        pos="absolute"
        bottom="-20px"
        right="0"
      >
        {moment(date).format('DD MMMM, yyyy | hh:mm A')}
      </Text>
    </Box>
  );
};

export default FileMessage;
