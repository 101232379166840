import { useState } from 'react';
import { Box, Flex, Text, Grid } from '@chakra-ui/react';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { Header, ContinueBack } from 'components/unauthenticated/shared';
import {
  UploadImage,
  CustomInput,
  Map,
  Select,
  CustomButton,
  ErrorMsg,
  Countries,
  CustomUploadFile,
} from 'components/shared';
import { WorkshopSchema } from 'schemas/signup/WorkshopSchema';
import { FormBody } from 'components/unauthenticated/shared';
import { Formik, Form } from 'formik';
import defaultImage from 'assets/images/defaultBranch.png';
import { useTranslation } from 'react-i18next';

const SignupWorkshopForm = ({ paginate, workshop, setWorkshop }) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState(
    workshop.countrySelected || { label: 'select country', value: null }
  );

  const [countryName, setCountryName] = useState('حدد دولة');

  const [submit, setSubmit] = useState(false);

  // const [country, setCountry] = useState({
  //   label: data.country,
  //   value: data.country,
  // });

  // const [initMarker, setInitMarker] = useState({
  //   lat: data.location.lat,
  //   lng: data.location.long || data.location.lng,
  // });

  const [marker, setMarker] = useState(workshop.mapSelected);

  const handleMarker = e => {
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();

    setMarker({ lat, lng });
  };

  return (
    <Box>
      <Header
        heading={t('signup.header')}
        text={t('signup.workshopInformation')}
        isArrow
      />
      <Flex justifyContent="center" alignItems="center" mt="40px">
        <Formik
          initialValues={{
            image: '',
            workshopNameEN: '',
            workshopNameAR: '',
            commercialNumber: '',
            city: '',
            street: '',
            zipCode: '',
            buildingNumber: '',
            district: '',
            additionalNumber: '',
            countrySelected: null,
            mapSelected: null,
            ...workshop,
          }}
          validationSchema={WorkshopSchema}
          onSubmit={values => {
            setWorkshop({ ...workshop, ...values });
            paginate(1);
            setSubmit(true);
          }}
          validateOnMount
        >
          {formik => {
            return (
              <FormBody
                boxShadow="0 8px 160px -100px #21B3E1"
                onSubmit={formik.handleSubmit}
              >
                {/* <Box>
                  <UploadImage
                    defaultImage={defaultImage}
                    profileData={workshop}
                    setImageProfile={setWorkshop}
                    formikChange={e => formik.setFieldValue('image', e)}
                  /> 

                  <CustomUploadFile
                    radius={false}
                    defaultImage={defaultImage}
                    profileData={workshop}
                    setImageProfile={setWorkshop}
                    formikChange={e => formik.setFieldValue('image', e)}
                    width="196px"
                  />
                </Box> */}

                <Grid gridTemplateColumns="repeat(2,1fr)" gap={6} dir="ltr">
                  <Box mb="20px">
                    <CustomInput
                      dir="ltr"
                      type="text"
                      label="Workshop Name"
                      placeholder="Enter your workshop name"
                      value={formik.values.workshopNameEN}
                      onChange={formik.handleChange('workshopNameEN')}
                      onBlur={formik.handleBlur('workshopNameEN')}
                    />
                    {formik.errors.workshopNameEN &&
                      formik.touched.workshopNameEN && (
                        <ErrorMsg text={t(formik.errors.workshopNameEN)} />
                      )}
                  </Box>
                  <Box mb="20px" dir="rtl">
                    <CustomInput
                      type="text"
                      label="اسم الورشة"
                      placeholder="اسم الورشة بالعربي"
                      value={formik.values.workshopNameAR}
                      onChange={formik.handleChange('workshopNameAR')}
                      onBlur={formik.handleBlur('workshopNameAR')}
                    />
                    {formik.errors.workshopNameAR &&
                      formik.touched.workshopNameAR && (
                        <ErrorMsg text={formik.errors.workshopNameAR} />
                      )}
                  </Box>
                </Grid>

                <Box mb="20px">
                  <CustomInput
                    type="number"
                    label={t('signup.commercialNumber')}
                    placeholder={t('home.clients.enterCommercial')}
                    value={formik.values.commercialNumber}
                    onChange={formik.handleChange('commercialNumber')}
                    onBlur={formik.handleBlur('commercialNumber')}
                  />
                  {formik.errors.commercialNumber &&
                    formik.touched.commercialNumber && (
                      <ErrorMsg text={t(formik.errors.commercialNumber)} />
                    )}
                </Box>

                <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px" mb="24px">
                  <Box>
                    <CustomInput
                      type="number"
                      label={'Building Number'}
                      placeholder={'Building Number'}
                      value={formik.values.buildingNumber}
                      onChange={formik.handleChange('buildingNumber')}
                      onBlur={formik.handleBlur('buildingNumber')}
                    />
                    {formik.errors.buildingNumber &&
                      formik.touched.buildingNumber && (
                        <ErrorMsg text={t(formik.errors.buildingNumber)} />
                      )}
                  </Box>

                  <Box>
                    <CustomInput
                      type="number"
                      label={'Additional Number'}
                      placeholder={'Additional Number'}
                      value={formik.values.additionalNumber}
                      onChange={formik.handleChange('additionalNumber')}
                      onBlur={formik.handleBlur('additionalNumber')}
                    />
                    {formik.errors.additionalNumber &&
                      formik.touched.additionalNumber && (
                        <ErrorMsg text={t(formik.errors.additionalNumber)} />
                      )}
                  </Box>
                </Grid>

                <Box mb="24px">
                  <CustomInput
                    type="text"
                    label={'District Name'}
                    placeholder={'District Name'}
                    value={formik.values.district}
                    onChange={formik.handleChange('district')}
                    onBlur={formik.handleBlur('district')}
                  />
                  {formik.errors.district && formik.touched.district && (
                    <ErrorMsg text={t(formik.errors.district)} />
                  )}
                </Box>

                <Box mb="20px">
                  <Map
                    buttonName="choose location"
                    errMsg={
                      formik.errors.mapSelected && formik.touched.mapSelected
                        ? true
                        : false
                    }
                    marker={marker}
                    handleMarker={(values, country, city, street, zipcode) => {
                      setMarker(values);

                      formik.setFieldValue('mapSelected', values);

                      formik.setFieldValue('city', city);
                      formik.setFieldValue('countrySelected', country);
                      formik.setFieldValue('street', street);
                      formik.setFieldValue('zipCode', zipcode);
                    }}
                  />
                </Box>

                <Grid gridTemplateColumns="repeat(2,1fr)" gap={6} mb="24px">
                  <Box>
                    <CustomInput
                      type="text"
                      label={t('signup.country')}
                      placeholder={t('home.clients.enterCity')}
                      value={formik.values.countrySelected}
                      onChange={formik.handleChange('countrySelected')}
                      onBlur={formik.handleBlur('countrySelected')}
                      disabled
                      _disabled={{ opacity: '0.8', cursor: 'not-allowed' }}
                    />

                    {/* <Text
                      textTransform="capitalize"
                      color="text.600"
                      fontSize="14px"
                      mb="5px"
                      fontWeight="semibold"
                    >
                      {t('signup.country')}
                    </Text>

                    <Select
                      h="60px"
                      label={t('signup.country')}
                      select={country}
                      handleSelect={value => {
                        setCountry(value);
                        formik.setFieldValue('countrySelected', value);
                      }}
                      selections={Countries.map(elm => ({
                        label: elm.name,
                        value: elm.name,
                      }))}
                      isDisabled
                    />

                    {formik.errors.countrySelected &&
                      formik.touched.countrySelected && (
                        <ErrorMsg text={t(formik.errors.countrySelected)} />
                      )} */}
                  </Box>

                  <Box>
                    <CustomInput
                      type="text"
                      label={t('signup.city')}
                      placeholder={t('home.clients.enterCity')}
                      value={formik.values.city}
                      onChange={formik.handleChange('city')}
                      onBlur={formik.handleBlur('city')}
                      disabled
                      _disabled={{ opacity: '0.8', cursor: 'not-allowed' }}
                    />
                    {formik.errors.city && formik.touched.city && (
                      <ErrorMsg text={t(formik.errors.city)} />
                    )}
                  </Box>
                </Grid>

                <Grid gridTemplateColumns="repeat(2,1fr)" gap={6} mb="24px">
                  <Box>
                    <CustomInput
                      type="text"
                      label={t('signup.street')}
                      placeholder={t('home.clients.enterStreet')}
                      value={formik.values.street}
                      onChange={formik.handleChange('street')}
                      onBlur={formik.handleBlur('street')}
                    />
                    {formik.errors.street && formik.touched.street && (
                      <ErrorMsg text={t(formik.errors.street)} />
                    )}
                  </Box>

                  <Box>
                    <CustomInput
                      type="number"
                      label={t('signup.zipCode')}
                      placeholder={t('home.clients.enterZipcode')}
                      value={formik.values.zipCode}
                      onChange={formik.handleChange('zipCode')}
                      onBlur={formik.handleBlur('zipCode')}
                    />
                    {formik.errors.zipCode && formik.touched.zipCode && (
                      <ErrorMsg text={t(formik.errors.zipCode)} />
                    )}
                  </Box>
                </Grid>

                {/* <Flex color="text.1000" alignItems="center" cursor="pointer">
                  <Box
                    as={HiOutlineLocationMarker}
                    color={
                      formik.errors.mapSelected && formik.touched.mapSelected
                        ? 'red'
                        : 'text.1000'
                    }
                    me="5px"
                  />
                  <Map
                    errMsg={
                      formik.errors.mapSelected && formik.touched.mapSelected
                        ? true
                        : false
                    }
                    marker={marker}
                    handleMarker={e => {
                      let values = {
                        lat: e.latLng.lat(),
                        lng: e.latLng.lng(),
                      };
                      setMarker(values);
                      formik.setFieldValue('mapSelected', values);
                    }}
                  />
                </Flex> */}

                <Flex justifyContent="space-between" mt="50px">
                  <CustomButton
                    name={t('signup.back')}
                    w="40%"
                    onClick={() => {
                      setWorkshop({ ...workshop, ...formik.values });
                      paginate(-1);
                    }}
                  />
                  <CustomButton
                    type="submit"
                    // disabled={!formik.isValid}
                    name={t('signup.continue')}
                    bg
                    w="56%"
                  />
                </Flex>
              </FormBody>
            );
          }}
        </Formik>
      </Flex>
    </Box>
  );
};

export default SignupWorkshopForm;
