import request from 'axios-interceptors';
import moment from 'moment';

export const createBranch = (values, country, branchImagePath) => {
  return request.post('branches', {
    name: {
      en: values.enName,
      ar: values.arName,
    },
    startTime1: values.startTime1,
    endTime1: values.endTime1,
    startTime2: values.startTime2,
    endTime2: values.endTime2,
    street: values.street,
    city: values.city,
    zipCode: values.zipCode.toString(),
    country: values.countrySelected,
    commercialNumber: values.commercialNumber.toString(),
    lat: values.mapSelected.lat,
    long: values.mapSelected.lng,
    imagePath: values.image,
  });
};

export const getAllBranches = ({ queryKey }) => {
  return request.get(
    `branches?page=${queryKey[1]}&limit=${queryKey[4]}&searchKey=${queryKey[2]}&status=${queryKey[3]}`
  );
};

export const getBranchById = ({ queryKey }) => {
  return request.get(`branches/findById/${queryKey[1]}`);
};

export const getBranchTasks = ({ queryKey }) => {
  return request.get(`branches/projects/${queryKey[1]}`);
};

export const deleteBranch = branchId => {
  return request.post('branches/delete', {
    branchesIds: branchId,
  });
};

export const activateBranch = branchId => {
  return request.post(`branches/activate/${branchId}`);
};

export const updateBranch = (
  values,

  country,

  branchId
) => {
  return request.put(`branches/${branchId}`, {
    name: {
      en: values.enName,
      ar: values.arName,
    },
    startTime1: values.startTime1,
    endTime1: values.endTime1,
    startTime2: values.startTime2,
    endTime2: values.endTime2,
    lat: values?.mapSelected.lat,
    long: values?.mapSelected.lng,
    street: values.street,
    city: values.city,
    zipCode: values.zipCode.toString(),
    country: values.countrySelected,
    commercialNumber: values.commercialNumber.toString(),
    imagePath: values.image,
    // startTime1: moment(values.branchTimes[0].startTime, 'hh:mm:ss a').locale('en').format('HH:mm:ss'),
    // endTime1: moment(values.branchTimes[0].endTime, 'hh:mm:ss a').locale('en').format('HH:mm:ss'),
    // startTime2: values.branchTimes.length>1?moment(values.branchTimes[1].startTime, 'hh:mm:ss a').locale('en').format('HH:mm:ss'):null,
    // endTime2:  values.branchTimes.length>1?moment(values.branchTimes[1].endTime, 'hh:mm:ss a').locale('en').format('HH:mm:ss'):null,
  });
};
