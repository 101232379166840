import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import { MdLocationOn } from 'react-icons/md';
import { FormBody } from 'components/unauthenticated/shared';
import right from 'assets/images/right.png';
import { useTranslation } from 'react-i18next';

const IsRatingBefore = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Flex
      // w="400px"
      alignItems="center"
      justifyContent="center"
      mt="80px"
      // w="100vw"
      // h="100vh"
    >
      <FormBody boxShadow="0 8px 160px -100px #21B3E1">
        <Image src={right} mt="75px" mb="40px" mx="auto" />
        <Text textAlign="center" fontWeight="bold" color="#4A4A4A" mb="100px">
          {t('rating.ratedBefore')}
        </Text>
      </FormBody>
    </Flex>
  );
};

export default IsRatingBefore;
