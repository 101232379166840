import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const SimpleModal = ({
  title,
  titleDir = 'center',
  show,
  setShow,
  children,
  overflow = 'inside',
  ...props
}) => {
  return (
    <>
      <Modal
        isCentered
        onClose={() => setShow(false)}
        isOpen={show}
        motionPreset="slideInBottom"
        scrollBehavior={overflow}
        zIndex="111111"
      >
        <ModalOverlay />
        <ModalContent p="40px" maxW="720px" {...props}>
          <ModalHeader
            textAlign={titleDir}
            fontSize="32px"
            p="0"
            color="text.600"
            mb="40px"
            textTransform="capitalize"
          >
            {title}
          </ModalHeader>
          <ModalCloseButton
            top="20px"
            right="20px"
            fontSize="15px"
            bg="bg.300"
            color="white"
            borderRadius="50%"
            p="20px"
            _focus={{}}
            _hover={{ bg: '#30d2ed' }}
            _active={{ bg: 'bg.400' }}
          />
          <ModalBody p="0">{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default SimpleModal;
