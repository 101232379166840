import request from 'axios-interceptors';

export const createDocument = (values, branch, file) => {
  let formatedBranch = branch.length > 0 ? branch.map(elm => elm.value) : null;

  let form = new FormData();
  form.append('file', values.image);
  form.append('name', values.name);
  form.append('branches', JSON.stringify(formatedBranch));

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  };

  return request.post('documents/upload', form, config);
};

export const getAllDocuments = ({ queryKey }) => {
  // currentPage, searchKey, branch

  let formatedBranch =
    queryKey[3].length > 0 ? queryKey[3].map(elm => elm.value) : null;

  return request.get(
    `documents?page=${queryKey[1]}&limit=12&searchKey=${queryKey[2]}&branch=${formatedBranch}`
  );
};

export const deleteDocument = documentId => {
  return request.post('documents/delete', {
    documentsIds: documentId,
  });
};
