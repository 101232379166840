import { Box } from '@chakra-ui/react';

const StatusBox = ({ status, text, ...props }) => {
  return (
    <Box
      py="10px"
      w="125px"
      textAlign="center"
      textTransform="capitalize"
      bg={
        status == 'completed'
          ? 'bg.completed'
          : status == 'in_queue'
          ? 'bg.in_queue'
          : status == 'in_progress'
          ? 'bg.in_progress'
          : status == 'to_be_assigned'
          ? 'bg.unavailable'
          : status == 'available'
          ? 'bg.completed'
          : status == 'unavailable'
          ? 'bg.unavailable'
          : status == 'pending'
          ? 'bg.pending'
          : status == 'opened'
          ? 'bg.in_progress'
          : status == 'searchId'
          ? 'bg.searchId'
          : status == 'service'
          ? 'bg.service'
          : status == 'suggestions'
          ? 'bg.suggestions'
          : status == 'incident'
          ? 'bg.technical'
          : ''
      }
      color={
        status == 'completed'
          ? 'text.completed'
          : status == 'in_queue'
          ? 'text.in_queue'
          : status == 'in_progress'
          ? 'text.in_progress'
          : status == 'to_be_assigned'
          ? 'text.unavailable'
          : status == 'available'
          ? 'text.available'
          : status == 'unavailable'
          ? 'text.unavailable'
          : status == 'pending'
          ? 'text.pending'
          : status == 'opened'
          ? 'text.in_progress'
          : status == 'searchId'
          ? 'text.searchId'
          : status == 'service'
          ? 'white'
          : status == 'suggestions'
          ? 'white'
          : status == 'incident'
          ? 'white'
          : ''
      }
      borderRadius="10px"
      fontWeight="bold"
      fontSize="14px"
      {...props}
    >
      {text}
      {/* {item.workflowStatus == 'in_queue'
        ? t('status.inqueue')
        : item.workflowStatus == 'in_progress'
        ? t('status.ongoing')
        : item.workflowStatus == 'completed'
        ? t('status.completed')
        : t('status.toBeAssigned')} */}
    </Box>
  );
};

export default StatusBox;
