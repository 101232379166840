import request from 'axios-interceptors';

export const createTicket = ({
  type,
  title,
  userId,
  description,
  attachments,
}) => {
  return request.post('/iam/users/support/ticket', {
    type,
    title: 'test',
    userId,
    description,
    attachments: attachments,
  });
};

export const getTickets = () => {
  const user = localStorage.getItem('user');
  const userId = JSON.parse(user).user.id;

  return request.get(`/iam/users/support/ticket/${userId}`);
};

export const createComment = ({ ticketId, data }) => {
  return request.put(`/iam/users/support/ticket/comment/${ticketId}`, data);
};

export const deleteTicket = ticketId => {
  return request.delete(`/iam/users/support/ticket/${ticketId}`);
};

export const getFileData = ({ queryKey }) => {
  return request.get(`/uploads/tickets/comments/${queryKey[1]}`);
};
