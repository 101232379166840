import { useState } from 'react';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
} from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';

const SelectStatus = ({
  label,
  selections,
  isOpen,
  select,
  handleSelect,
  isDisabled,
  ...props
}) => {
  return (
    <Box {...props}>
      <Menu>
        <MenuButton
          as={Button}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          h="100%"
          borderRadius="10px"
          outline="none"
          bg={
            select.value == 'in_progress'
              ? 'bg.500'
              : select.value == 'completed'
              ? '#7686CA'
              : 'bg.2900'
          }
          color="white"
          fontWeight="normal"
          disabled={isDisabled}
          _focus={{}}
          _active={{}}
          _hover={{}}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text>{select?.label || label}</Text>
            <Box as={MdKeyboardArrowDown} color="white" fontSize="18px" />
          </Flex>
        </MenuButton>
        <MenuList minW="150px" maxH="200px" overflowY="auto">
          {selections?.map(e => {
            return (
              <MenuItem key={Math.random()} onClick={() => handleSelect(e)}>
                {e.label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SelectStatus;
