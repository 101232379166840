import {
  Box,
  Text,
  InputGroup,
  InputLeftAddon,
  Input,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { AiOutlineMail } from 'react-icons/ai';
import { CustomButton, ErrorMsg } from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { resetPasswordRequestValid } from 'schemas/login/reset-password-request';
import { useMutation } from 'react-query';
import { Formik } from 'formik';
import { resetPasswordRequest } from 'apis/iam/Login';
import { checkEmailValidity } from 'apis/iam/Signup';
import { useTranslation } from 'react-i18next';

const ResetPassword = ({ setShow }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const { mutate, isLoading } = useMutation(resetPasswordRequest, {
    onSuccess: () => {
      setShow(false);
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.sendEmail')}
              description={t('toasts.sendEmail')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.sendEmail')}
              description={t('toasts.emailNotExist')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Box>
      <Text textAlign="center" fontWeight="bold" fontSize="18px">
        {t('login.pleaseEnterEmail')}
      </Text>
      <Text textAlign="center">{t('login.receiveReset')}</Text>

      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={resetPasswordRequestValid}
        onSubmit={async values => {
          mutate(values.email);
        }}
        // validateOnMount
      >
        {formik => {
          return (
            <Box px="50px" as="form" onSubmit={formik.handleSubmit}>
              <Box my="30px">
                <InputGroup h="50px">
                  <InputLeftAddon
                    children={<Box as={AiOutlineMail} />}
                    h="100%"
                  />
                  <Input
                    type="email"
                    placeholder={t('signup.enterEmail')}
                    h="100%"
                    _focus={{}}
                    value={formik.values.email}
                    onChange={formik.handleChange('email')}
                    onBlur={formik.handleBlur('email')}
                  />
                </InputGroup>
                {formik.errors.email && formik.touched.email && (
                  <ErrorMsg text={t(formik.errors.email)} />
                )}
              </Box>

              <CustomButton
                name={t('login.confirm')}
                bg
                type="submit"
                loading={isLoading ? <Spinner ms="5px" /> : null}
                disabled={isLoading}
                display="flex"
                justifyContent="center"
                alignItem="cneter"
              />
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
};

export default ResetPassword;
