import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Text, Grid, useToast, Spinner } from '@chakra-ui/react';
import {
  CustomInput,
  Select,
  Label,
  SimpleModal,
  CustomButton,
} from 'components/shared';
import {
  PickTime,
  CustomDate,
  SelectSearch,
  CustomToast,
  HandleCar,
} from 'components/authenticated/shared';
import { MdAdd } from 'react-icons/md';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getAllPagesClients } from 'apis/client';
import {
  getAllCars,
  createAppointment,
  updateAppointment,
} from 'apis/appointment';
import { ClientPopup } from 'components/authenticated/clients';
import { Formik } from 'formik';
import { AddAppointmentSchema } from 'schemas/invoice/AddAppointmentSchema';
import { ErrorMsg } from 'components/shared';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TaskPopup = ({ setShow, itemInfo, refetchAllData, refetchList }) => {
  // const { customer, dueDate, carDetails, title, description, id } = itemInfo;

  const { t } = useTranslation();

  const toast = useToast();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  //user context
  const { user, branchFilter, language } = useContext(userContext);
  const branches = user.user.branches;

  const [showAdd, setShowAdd] = useState(false);

  //selections states
  const [client, setClient] = useState({
    label: itemInfo?.customer?.name || 'Select client',
    value: itemInfo?.customer?.name || null,
  });

  const [branch, setBranch] = useState(branchFilter);

  useEffect(() => {
    setBranch(branchFilter);
  }, []);

  useEffect(() => {
    if (branchFilter.value == null) {
      setBranch([]);
    } else {
      setBranch(branchFilter);
    }
  }, [branchFilter]);

  //handle selections

  const handleClientSelect = value => {
    setClient(value);
  };

  const handleBranch = value => {
    setBranch(value);
  };

  //handle requests
  const { data: clients, refetchClients } = useQuery(
    'getAllPagesClients',
    getAllPagesClients
  );

  const { mutate } = useMutation(createAppointment, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllWorkflows');
      setLoading(false);
      setShow(false);
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.addTask')}
              description={t('toasts.addTask')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      if (refetchAllData) {
        refetchAllData();
      }

      if (refetchList) {
        refetchList();
      }
    },
    onError: () => {
      setLoading(false);
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.addTask')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const { mutate: updateAppintment } = useMutation(updateAppointment, {
    onSuccess: async () => {
      setLoading(false);
      setShow(false);

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.updateTask')}
              description={t('toasts.updateTask')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      if (refetchAllData) {
        refetchAllData();
      }

      if (refetchList) {
        refetchList();
      }
    },
    onError: err => {
      setLoading(false);
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.updateTask')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  //get clientData,branchData
  const clientData = clients?.data.map(e => {
    return { label: e.name + e.mobileNumber, value: e.id };
  });

  const branchData = branches?.map(e => {
    return { label: language == 'en' ? e.name.en : e.name.ar, value: e.id };
  });

  return (
    <Flex justifyContent="center">
      <Formik
        initialValues={{
          client: itemInfo?.customer.name,
          macker: itemInfo?.carDetails.carManufactur || '',
          model: itemInfo?.carDetails.carModel || '',
          year: itemInfo?.carDetails.carYear || '',
          branch: branchFilter.value,
          date:
            moment(itemInfo?.dueDate).utc().locale('en').format('YYYY-MM-DD') ||
            '',
          time:
            moment(itemInfo?.dueDate).utc().locale('en').format('hh:mm:ss') ||
            '',
          // title: itemInfo?.title || '',
          subject: itemInfo?.description || '',
        }}
        validationSchema={AddAppointmentSchema}
        onSubmit={async values => {
          setLoading(true);
          if (itemInfo) {
            updateAppintment({
              appointmentId: itemInfo?.id,
              date: values.date,
              time: values.time,
            });
          } else {
            mutate(values);
          }
        }}
        // validateOnMount
      >
        {formik => {
          return (
            <Box as="form" w="full" maxW="85%" onSubmit={formik.handleSubmit}>
              <Box w="full">
                <Text
                  fontSize="14px"
                  mb="5px"
                  fontWeight="bold"
                  opacity={itemInfo ? 0.3 : 1}
                >
                  {t('project.client')}
                </Text>
                <Flex
                  w="full"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box w="82%" mb="24px">
                    <SelectSearch
                      h="60px"
                      label={t('modals.selectClient')}
                      select={client}
                      handleSelect={value => {
                        handleClientSelect(value);
                        formik.setFieldValue('client', value.value);
                      }}
                      selections={clientData}
                      isDisabled={itemInfo ? true : false}
                    />
                    {formik.errors.client && formik.touched.client && (
                      <ErrorMsg text={t(formik.errors.client)} />
                    )}
                  </Box>
                  <Flex
                    bg="bg.300"
                    color="white"
                    h="60px"
                    w="15%"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="32px"
                    borderRadius="10px"
                    cursor="pointer"
                    onClick={() => setShowAdd(true)}
                  >
                    <MdAdd />
                  </Flex>
                </Flex>
              </Box>

              <HandleCar item={itemInfo} formik={formik} />

              <Box w="full" mb="20px">
                <Text
                  fontSize="14px"
                  mb="5px"
                  fontWeight="bold"
                  opacity={itemInfo ? 0.3 : 1}
                >
                  {t('project.branches')}
                </Text>
                <SelectSearch
                  h="60px"
                  label="select branch"
                  select={branch}
                  handleSelect={value => {
                    handleBranch(value);
                    formik.setFieldValue('branch', value.value);
                  }}
                  selections={branchData}
                  isDisabled={itemInfo ? true : false}
                />
                {formik.errors.branch && formik.touched.branch && (
                  <ErrorMsg text={t(formik.errors.branch)} />
                )}
              </Box>
              <Grid gridTemplateColumns="repeat(2,1fr)" gap={6} mb="20px">
                <Box>
                  <Label text={t('modals.selectDate')} />
                  <CustomDate
                    h="60px"
                    bg="white"
                    border="1px solid #E2E8F0"
                    placeholder={t('modals.enterDate')}
                    defaultDate={itemInfo?.dueDate}
                    handleDate={value => formik.setFieldValue('date', value)}
                    minDate={new Date()}
                    dateFormat="dd MMMM yyyy"
                  />
                  {formik.errors.date && formik.touched.date && (
                    <ErrorMsg text={t(formik.errors.date)} />
                  )}
                </Box>
                <Box>
                  <Label text={t('modals.selectTime')} />

                  <PickTime
                    h="60px"
                    bg="white"
                    border="1px solid #E2E8F0"
                    handleTime={value => formik.setFieldValue('time', value)}
                    defaultTime={itemInfo?.dueDate}
                    defaultText={t('modals.enterTime')}
                    // placeholderText="Clock-out time"
                    // timeCaption="Clock-out"
                  />
                  {formik.errors.time && formik.touched.time && (
                    <ErrorMsg text={t(formik.errors.time)} />
                  )}
                </Box>
              </Grid>
              {/* <Box w="full" mb="20px">
                <CustomInput
                  type="text"
                  label={t('project.title')}
                  placeholder={t('project.enterTitle')}
                  value={formik.values.title}
                  onChange={formik.handleChange('title')}
                  onBlur={formik.handleBlur('title')}
                  isDisabled={years == null ? true : false}
                  textOpacity={itemInfo ? 0.3 : 1}
                />
                {formik.errors.title && formik.touched.title && (
                  <ErrorMsg text={t(formik.errors.title)} />
                )}
              </Box> */}
              <Box w="full" mb="20px">
                <CustomInput
                  type="text"
                  label={t('project.subject')}
                  placeholder={t('project.enterSubject')}
                  value={formik.values.subject}
                  onChange={formik.handleChange('subject')}
                  onBlur={formik.handleBlur('subject')}
                  // isDisabled={years == null ? true : false}
                  textOpacity={itemInfo ? 0.3 : 1}
                />
                {formik.errors.subject && formik.touched.subject && (
                  <ErrorMsg text={t(formik.errors.subject)} />
                )}
              </Box>
              <CustomButton
                name={t('account.save')}
                bg
                m="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={loading ? <Spinner ms="10px" /> : null}
                type="submit"
                isDisabled={!formik.dirty || loading}
              />
            </Box>
          );
        }}
      </Formik>

      <SimpleModal
        title={t('home.clients.addNewClient')}
        show={showAdd}
        setShow={setShowAdd}
      >
        <ClientPopup setShow={setShowAdd} refetch={refetchClients} />
      </SimpleModal>
    </Flex>
  );
};

export default TaskPopup;
