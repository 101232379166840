import request from 'axios-interceptors';

export const issueInvoice = projectId => {
  return request.post(`projects/issueInvoice/${projectId}`);
};

export const getInvoice = ({ queryKey }) => {
  const invoiceId = queryKey[1];

  return request.get(`invoice/print/${invoiceId}`);
};

export const getAllInvoices = ({ queryKey }) => {
  const status = queryKey[1];

  return request.get(
    `invoice/all?status=completed&customer=${queryKey[2]}&branch=${queryKey[3]}`
  );
};
export const createInvoice = ({
  formatedProducts,
  selectedClient,
  branchId,
}) => {
  return request.post(`invoice/create`, {
    products: formatedProducts,
    customerId: selectedClient,
    branchId,
  });
};

export const deleteInvoice = invoiceId => {
  return request.delete(`invoice/delete/${invoiceId}`);
};

export const exportInvoice = ({ from, to, customerId, branchId }) => {
  return request.post('invoice/export', {
    date: {
      from,
      to,
    },
    customer: customerId || null,
    branch: branchId || null,
  });
};
