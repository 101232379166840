import { useState } from 'react';
import {
  Tr,
  Td,
  Text,
  Tooltip,
  Button,
  Flex,
  Image,
  Box,
  Grid,
  PopoverBody,
  PopoverArrow,
  PopoverTrigger,
  Popover,
  PopoverContent,
} from '@chakra-ui/react';
import { IoMdArrowDropup } from 'react-icons/io';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { StatusBox } from 'components/authenticated/shared';
import TranslateDynamic from 'helpers/dynamic-translate';

const ProjectRow = ({
  searchId,
  title,
  type,
  description,
  createdAt,
  status,
  index,
  id,
  ...props
}) => {
  const [showComment, setShowComment] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const { t } = useTranslation();

  return (
    <Tr
      key={index}
      pos="relative"
      cursor="pointer"
      transition=".3s"
      bg={status == 'opened' && '#FEEBE8'}
      _hover={{ bg: '#ecf1f3' }}
      {...props}
    >
      <Td color="text.200">
        <Text
          as="span"
          cursor="pointer"
          textTransform="uppercase"
          fontWeight="bold"
        >
          {id}
        </Text>
      </Td>
      <Td textTransform="capitalize">
        <StatusBox
          status={type}
          text={
            type == 'incident'
              ? t('status.incident')
              : type == 'service'
              ? t('status.service')
              : t('status.suggestion')
          }
        />
        {/* <Text
          w="121px"
          fontSize="12px"
          fontWeight="bold"
          borderRadius="5px"
          textAlign="center"
          p="2px"
          bg={
            type == 'incident'
              ? '#7686CA'
              : type == 'suggestions'
              ? '#49BF8C'
              : '#FDBD72'
          }
          color="white"
        >
          {type == 'incident'
            ? t('status.incident')
            : type == 'service'
            ? t('status.service')
            : t('status.suggestion')}
        </Text> */}
      </Td>
      <Td fontSize="14px" color="#181F27">
        <Text
          maxW="210px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {description}
        </Text>
      </Td>
      <Td fontSize="14px" color="#181F27">
        {moment(createdAt).locale(t('common.lang')).format('D-M-yyyy')}
      </Td>

      <Td>
        <StatusBox
          status={status}
          text={
            status == 'pending'
              ? t('status.pending')
              : status == 'opened'
              ? t('status.ongoing')
              : t('status.closed')
          }
        />
        {/* <Text
          as="span"
          py="5px"
          px="15px"
          maxW="87px"
          textTransform="capitalize"
          borderRadius="10px"
          fontWeight="bold"
          fontSize="14px"
          bg={
            status == 'pending'
              ? '#FFF5EA'
              : status == 'opened'
              ? 'rgba(253,189,114,0.2)'
              : '#DBEDE6'
          }
          color={
            status == 'pending'
              ? '#FC9D2E'
              : status == 'opened'
              ? '#ED6262'
              : '#12885A'
          }
        >
          {status == 'pending'
            ? t('status.pending')
            : status == 'opened'
            ? t('status.ongoing')
            : t('status.closed')}
          {/* {status} 
        </Text> */}
      </Td>
    </Tr>
  );
};

export default ProjectRow;
