import { useRef, useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Flex,
  Text,
  Image,
  Grid,
  Textarea,
  Spinner,
} from '@chakra-ui/react';
import { FormBody, Header } from 'components/unauthenticated/shared';
import { CustomButton } from 'components/shared';
import { Loading } from 'components/authenticated/shared';
import ReactStars from 'react-rating-stars-component';
import { BsStarFill } from 'react-icons/bs';
import { MdLocationOn } from 'react-icons/md';
import { FaGlobe } from 'react-icons/fa';
import i18next from 'i18n';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { userContext } from 'contexts/userContext';
import { useParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { checkTokenValidity } from 'apis/iam/Login';
import {
  rateWorkflow,
  getRating,
  checkRateWorkflow,
} from 'apis/invoice/Workflow';
import IsRating from './is-rating';
import IsRatingBefore from './is-rated-before';
import defaultWorkshop from 'assets/images/defaultBranch.png';
import defaultEmployee from 'assets/images/avatar-user.png';

const Rating = () => {
  const { userId } = useParams();
  const [errors, setErrors] = useState();

  const navigate = useNavigate();

  const { setLanguage, language } = useContext(userContext);
  const { t } = useTranslation();

  const handleLang = () => {
    if (language == 'ar') {
      setLanguage('en');
      i18next.changeLanguage('en');
    } else {
      setLanguage('ar');
      i18next.changeLanguage('ar');
    }
  };

  const ref = useRef(null);

  let ratingValue = 0;

  const ratingChanged = newRating => {
    ratingValue = newRating;
  };

  //handle requests

  const {
    mutate,
    data: dataIsRated,
    isLoading: loadingForm,
    isError: isRatingBefore,
    isSuccess: isNotRatingBefore,
  } = useMutation(checkRateWorkflow, {
    onError: err => {
      setErrors(err.response.data.errors);

      // navigate('/');
    },
  });

  useEffect(() => {
    mutate({
      workflowId: userId,
    });
  }, []);

  const { data, isLoading: isLoadingData, isSuccess } = useQuery(
    ['getRating', userId],
    () => getRating(userId)
  );

  const {
    mutate: addRate,
    isLoading,
    isSuccess: isSuccessAddRating,
  } = useMutation(rateWorkflow);

  return (
    <Box minH="100vh" maxW="800px" m="auto" pt="45px" px="10px">
      <Header heading={t('rating.rateWorkshop')} />

      {loadingForm && <Loading />}

      {isSuccessAddRating && <IsRating data={data} />}

      {errors?.workflow?.isRated && <IsRatingBefore />}

      {isSuccess && !isSuccessAddRating && isNotRatingBefore && (
        <>
          {isLoadingData && <Loading />}
          <Flex justifyContent="center" alignItems="center" mt="40px">
            <FormBody
              boxShadow="0 8px 160px -100px #21B3E1"
              p="25px"
              w={['100%', '500px', '', '', '', '', '']}
            >
              <Flex justifyContent="space-between">
                <Box>
                  <Heading
                    as="h2"
                    fontWeight="bold"
                    fontSize={['17px', '', '20px', '20px']}
                    color="#151818"
                  >
                    {data?.data.tenant.name.en}
                  </Heading>
                  {/* <Flex mt="10px" alignItems="center">
                    <Box as={MdLocationOn} me="5px" />
                    <Text fontSize={['14px', '', '16px', '']}>
                      {data?.data.title}
                    </Text>
                  </Flex> */}
                </Box>

                <Box onClick={handleLang}>
                  <Flex alignItems="center" cursor="pointer">
                    <Text me="10px" fontSize="18px" color="text.700">
                      {t('login.lang')}
                    </Text>
                    <Box as={FaGlobe} color="text.100" />
                  </Flex>
                </Box>
              </Flex>
              <Image
                src={
                  data?.data.tenant.imagePath
                    ? `${process.env.REACT_APP_API_BASE_URL}/uploads/image/` +
                      data?.data.tenant.imagePath
                    : defaultWorkshop
                }
                w="100%"
                h="198px"
                objectFit="cover"
                borderRadius="10px"
                mt="13px"
                mb="20px"
              />
              <Heading
                as="h3"
                fontWeight="bold"
                fontSize={['20px', '', '23px', '23px']}
                color="#1F1F1F"
                textAlign="center"
              >
                {t('rating.howWasExperience')}
              </Heading>

              <Flex mt="20px" justifyContent="space-evenly">
                <Box>
                  <Text color="#181F27" mb="5px" textAlign="center">
                    {t('rating.serviceId')}
                  </Text>
                  <Grid
                    w="100px"
                    h="100px"
                    borderRadius="50%"
                    bg="rgba(33,198,255,0.2)"
                    placeItems="center"
                    color="#21C6E1"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    {data?.data.searchId}
                  </Grid>
                </Box>

                <Box>
                  <Text
                    color="#181F27"
                    mb="5px"
                    textAlign="center"
                    textTransform="capitalize"
                  >
                    {t('rating.technician')}
                  </Text>
                  <Image
                    w="100px"
                    h="100px"
                    borderRadius="50%"
                    src={
                      data?.data.user.profileImagePath
                        ? `${process.env.REACT_APP_API_BASE_URL}/uploads/image/` +
                          data?.data.user.profileImagePath
                        : defaultEmployee
                    }
                  />

                  <Text color="#181F27" textAlign="center">
                    {data?.data.user.firstName}
                  </Text>
                </Box>
              </Flex>

              <Flex
                bg="#EEF1F7"
                border="1px solid #E4E4E4"
                borderRadius="5px"
                py="10px"
                px="15px"
                justifyContent="center"
                mt="12px"
              >
                <ReactStars
                  classNames="rating-workshop"
                  count={5}
                  onChange={ratingChanged}
                  size={30}
                  char={<BsStarFill />}
                  activeColor="#FFB547"
                  color="#DFDFDF"
                />
              </Flex>
              <Textarea
                bg="#EEF1F7"
                border="1px solid #E4E4E4"
                mt="15px"
                placeholder={t('rating.tellUs')}
                p="15px"
                resize="none"
                h="150px"
                ref={ref}
                _focus={{}}
              />
              <CustomButton
                name={t('login.submit')}
                loading={isLoading && <Spinner ms="5px" />}
                textTransform="upperCase"
                mt="15px"
                bg
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                  addRate({
                    stars: ratingValue,
                    comment: ref.current.value,
                    workflowId: userId,
                    // slug: userId,
                  });
                }}
              />
            </FormBody>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Rating;
