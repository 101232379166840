import { Flex, Box, Heading, Text } from '@chakra-ui/react';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import { FaGlobe } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const Header = ({ heading, text, isArrow }) => {
  const navigate = useNavigate();

  return (
    <Flex justifyContent="center">
      {/* <Box
        as={HiOutlineArrowLeft}
        fontSize="24px"
        color="text.500"
        cursor="pointer"
        visibility={isArrow ? 'visible' : 'hidden'}
        onClick={() => navigate(-1)}
      /> */}

      <Flex flexDir="column" alignItems="center">
        <Heading
          as="h3"
          fontWeight="bold"
          fontSize="32px"
          lineHeight=" 40px"
          color="text.600"
        >
          {heading}
        </Heading>
        {text != null ? (
          <Text
            color="text.800"
            textAlign="center"
            fontSize="24px"
            lineHeight="32px"
            fontWeight="semibold"
            mt="16px"
          >
            {text}
          </Text>
        ) : null}
      </Flex>

      {/* <Box>
        <Flex alignItems="center" cursor="pointer">
          <Text me="10px" fontSize="18px" color="text.700">
            RU
          </Text>
          <Box as={FaGlobe} color="text.100" />
        </Flex>
      </Box> */}
    </Flex>
  );
};

export default Header;
