import { useState } from 'react';
import { Box, Text, Textarea, Flex, Image, Spinner } from '@chakra-ui/react';
import { CustomButton, SimpleModal } from 'components/shared';
import { useMutation } from 'react-query';
import { createComment } from 'apis/tickets';
import emojie from 'assets/images/icons/emojie.svg';
import link from 'assets/images/icons/link.svg';
import UploadFile from './upload-file';
import { useTranslation } from 'react-i18next';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

const TextEditor = ({ id, refetch }) => {
  const { t } = useTranslation();

  const [chatValue, setChatValue] = useState('');
  const [show, setShow] = useState(false);
  const [closeEmoji, setCloseEmoji] = useState(true);
  const [showUploadFile, setShowUploadFile] = useState(false);

  const onEmojiClick = emojiObject => {
    setChatValue(chatValue + emojiObject.native);
  };

  const handleChange = e => {
    setChatValue(e.target.value);
  };

  const { mutate, isLoading } = useMutation(createComment, {
    onSuccess: () => {
      refetch();
    },
  });

  return (
    <Box
      minH="188px"
      border="1px solid #D3D8DD"
      borderRadius="6px"
      bg="white"
      pos="relative"

      // mt="20px"
    >
      <Text
        fontSize="12px"
        color="#181F27"
        fontWeight="bold"
        p="11px"
        borderBottom="1px solid #D3D8DD"
        pos="relative"
        _after={{
          content: `""`,
          pos: 'absolute',
          left: '0',
          right: '0',
          bottom: '0',
          h: '2px',
          w: '110px',
          bg: 'black',
        }}
      >
        {t('ticket.ReplyToSupport')}
      </Text>
      <Textarea
        p="11px"
        borderRadius="0"
        h="100px"
        resize="none"
        outline="none"
        border="none"
        value={chatValue}
        onChange={e => handleChange(e)}
        onClick={() => setShow(false)}
        _focus={{}}
      />

      {show && (
        <>
          <Box
            pos="absolute"
            w="61vw"
            h="100vh"
            bg="transparent"
            top="-61vh"
            left="-100px"
            zIndex="11"
            onClick={() => {
              setShow(false);
            }}
          />
          <Box
            pos="absolute"
            bottom="40px"
            //transform="translateX(20px)"
            marginInline="20px"
            zIndex="11"
          >
            <Picker data={data} onEmojiSelect={onEmojiClick} />
          </Box>
        </>
      )}

      {/* <Picker
          onEmojiClick={onEmojiClick}
          groupNames={{
            smileys_people: 'yellow faces',
            animals_nature: 'cute dogs and also trees',
            food_drink: 'milkshakes and more',
            travel_places: 'I love trains',
            activities: 'lets play a game',
            objects: 'stuff',
            symbols: 'more stuff',
            flags: 'fun with flags',
            recently_used: 'did I really use those?!',
          }}
          disableSearchBar
          pickerStyle={{
            // width: '100%',
            position: 'absolute',
            bottom: '40px',
            left: '20px',
            height: show ? '400px' : '0',
            borderTop: show ? '1px solid #BFCFD6' : 'none',
            borderRadius: '0',
            overflowY: 'auto',
          }}
          preload
        /> */}

      <Flex justifyContent="space-between" p="11px">
        <Flex alignItems="center">
          <Image
            src={emojie}
            alt="emojie"
            me="17px"
            w="18px"
            h="18px"
            cursor="pointer"
            onClick={() => setShow(!show)}
          />

          <Image
            src={link}
            alt="link"
            me="17px"
            w="18px"
            h="18px"
            cursor="pointer"
            pos="relative"
            zIndex="222"
            onClick={() => {
              setShow(false);
              setShowUploadFile(true);
            }}
          />
        </Flex>
        <CustomButton
          name={t('ticket.send')}
          bg
          w="81px"
          h="35px"
          borderRadius="3px"
          pos="relative"
          zIndex="222"
          display="flex"
          alignItems="center"
          justifyContent="center"
          loading={isLoading && <Text> ...</Text>}
          onClick={() => {
            setShow(false);
            if (chatValue != '') {
              mutate({
                ticketId: id,
                data: { comment: chatValue, attachment: null },
              });
              setChatValue('');
            }
          }}
        />
      </Flex>
      <SimpleModal show={showUploadFile} setShow={setShowUploadFile}>
        <UploadFile id={id} refetch={refetch} setShow={setShowUploadFile} />
      </SimpleModal>
    </Box>
  );
};

export default TextEditor;
