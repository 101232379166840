import request from 'axios-interceptors';

// Shifts
export const getAllShifts = () => {
  return request.get(`shifts/all`);
};

export const createShift = (values, location, coverRange) => {
  return request.post('shifts', {
    name: values.shiftName,
    locationName: values.locationName,
    location: {
      lat: location.lat,
      long: location.lng,
    },
    start: values.checkinTime,
    end: values.checkoutTime,
    allowedRadius: coverRange,
    weekends: values.weekends,
    usersIds: values.usersIds,
  });
};

export const deleteShift = shiftId => {
  return request.post('shifts/delete', {
    ids: shiftId,
  });
};

export const updateShift = (
  values,

  location,
  coverRange,
  shiftId
) => {
  return request.put(`shifts/${shiftId}`, {
    name: values.shiftName,
    locationName: values.locationName,
    location: {
      lat: location.lat,
      long: location.lng,
    },
    start: values.checkinTime,
    end: values.checkoutTime,
    allowedRadius: coverRange,
    weekends: values.weekends,
    usersIds: values.usersIds,
  });
};

// Departments
export const getAllDepartments = () => {
  return request.get(`departments/all`);
};

export const createDepartment = values => {
  return request.post('departments', {
    name: values.name,
    usersIds: values.usersIds,
  });
};

export const deleteDepartment = departmentId => {
  return request.post('departments/delete', {
    ids: departmentId,
  });
};

export const updateDepartment = ({ values, departmentId }) => {
  return request.put(`departments/${departmentId}`, {
    name: values.name,
    usersIds: values.usersIds,
  });
};

// Attendance
export const updateAttendanceTime = time => {
  return request.put('settings/attendancePermit', {
    time: time,
  });
};

export const getAttendanceTime = () => {
  return request.get('settings');
};
