import { useContext, useState } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, TabPanel } from '@chakra-ui/react';
import {
  Tabs,
  PageHeader,
  Loading,
  Error,
} from 'components/authenticated/shared';
import {
  Profile,
  Permissions,
  Attendence,
  Projects,
} from 'components/authenticated/employee';
import { useParams } from 'react-router-dom';
import { getUserById } from 'apis/employee';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const Employee = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { user } = useContext(userContext);
  const [employeProjectsCount, setEmployeProjectsCount] = useState(0);

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getUserById', id],
    getUserById
  );

  const allTabs = [
    t('account.profile'),
    t('account.permissions'),
    t('account.attendance'),
    `${t('account.projects')} (${employeProjectsCount})`,
  ];
  const tabsWithoutPermissions = ['Profile', 'Attendence', 'Projects'];

  return (
    <>
      <Box mx="auto">
        <PageHeader
          text={
            isSuccess ? data?.data.firstName + ' ' + data?.data.lastName : null
          }
          isArrow
        />
        {isLoading && <Loading />}
        {isError && <Error />}
        {isSuccess && (
          <Tabs
            tabs={
              data?.data.roles[0]?.roleName != 'SuperAdmin'
                ? allTabs
                : tabsWithoutPermissions
            }
            mt="40px"
          >
            <TabPanel outline="none" minH="600px">
              <Profile data={data?.data} />
            </TabPanel>

            {data?.data.roles[0]?.roleName != 'SuperAdmin' ? (
              <TabPanel outline="none" minH="600px">
                <Permissions id={id} data={data} />
              </TabPanel>
            ) : null}

            <TabPanel outline="none" minH="600px">
              <Attendence id={id} shifts={data?.data.shifts} userData={data} />
            </TabPanel>

            <TabPanel outline="none" minH="600px">
              <Projects
                id={id}
                setEmployeProjectsCount={setEmployeProjectsCount}
              />
            </TabPanel>
          </Tabs>
        )}
      </Box>
    </>
  );
};

export default Employee;
