import { Tr, Td, Text, Flex } from '@chakra-ui/react';
import { Table } from 'components/authenticated/shared';
import { NoDataRow } from 'components/authenticated/shared';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const ProjectsTable = ({ getAllProjectss, noAccess }) => {
  const { t } = useTranslation();
  return (
    <Table
      headers={[
        t('home.dashboard.id'),
        t('home.dashboard.clientNumber'),
        t('home.dashboard.services'),
        t('home.dashboard.parts'),
        t('home.dashboard.totalCost'),
        t('home.dashboard.date'),
      ]}
      theadHeight="71px"
      borderRadius="0"
    >
      {noAccess ? (
        <NoDataRow colSpan="6" />
      ) : getAllProjectss?.data.items.length > 0 ? (
        getAllProjectss?.data.items.map((item, index) => (
          <Tr key={index}>
            <Td textTransform="uppercase">{item.searchId}</Td>
            <Td>{item.customer?.mobileNumber}</Td>
            <Td>
              <Flex
                justifyContent="center"
                alignItems="center"
                bg="bg.800"
                color="text.200"
                borderRadius="10px"
                w="32px"
                h="32px"
                fontWeight="bold"
                fontSize="14px"
              >
                <Text>
                  {
                    item.invoice.invoiceProducts.filter(
                      elm => elm.type === 'services'
                    ).length
                  }
                </Text>
              </Flex>
            </Td>
            <Td>
              <Flex
                justifyContent="center"
                alignItems="center"
                bg="bg.800"
                color="text.200"
                borderRadius="10px"
                w="32px"
                h="32px"
                fontWeight="bold"
                fontSize="14px"
              >
                <Text>
                  {
                    item.invoice.invoiceProducts.filter(
                      elm => elm.type === 'part'
                    ).length
                  }
                </Text>
              </Flex>
            </Td>
            <Td>
              <Flex>
                {item.invoice.total.toFixed(2)}
                <Text fontSize="12px" color="text.1100" p="0.5">
                  SR
                </Text>
              </Flex>
            </Td>
            <Td>
              {moment(item.endAt).locale(t('common.lang')).format('YYYY-MM-DD')}
            </Td>
          </Tr>
        ))
      ) : (
        <NoDataRow colSpan="6" />
      )}
    </Table>
  );
};

export default ProjectsTable;
