import { useState } from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Tooltip,
  Text,
  Box,
  Flex,
} from '@chakra-ui/react';

const RangeCover = ({ value, handleValue, ...props }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <Box {...props}>
      <Text mb="2px">Coverage range</Text>
      <Slider
        id="slider"
        value={value}
        min={0}
        max={1000}
        colorScheme="teal"
        onChange={handleValue}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        step={100}
      >
        <SliderTrack>
          <SliderFilledTrack bg="bg.3100" />
        </SliderTrack>
        <Tooltip
          hasArrow
          bg="bg.3100"
          color="white"
          placement="top"
          isOpen={showTooltip}
          label={`${value}`}
        >
          <SliderThumb
            border="1px solid black"
            boxShadow="0 0 0px 1px #3498ff"
          />
        </Tooltip>
      </Slider>

      <Flex alignItems="center" justifyContent="center">
        <Box
          me="5px"
          bg="bg.200"
          borderRadius="2px"
          px="5px"
          bg="bg.200"
          color="text.200"
        >
          {value}m
        </Box>
        <Text>is the coverage area for user attendance</Text>
      </Flex>
    </Box>
  );
};

export default RangeCover;
