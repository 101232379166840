import { Box, Text, Flex, Image } from '@chakra-ui/react';

const Card = ({ text, number, icon, bg }) => {
  return (
    <Box pos="relative">
      {/* <Box
        pos="absolute"
        top="0"
        left="0"
        bottom="0"
        w="100%"
        h="100%"
        bg="rgba(255, 255, 255, 0.4);"
        zIndex="111"
        borderRadius="10px"
        backdropFilter="blur(4px)"
      /> */}
      <Box py="15px" w="full" borderRadius="10px" bg="white">
        <Flex
          w="full"
          h="full"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box borderRadius="full" bg={bg} p="1.5" mb="15px">
            <Image w="36px" h="36px" src={icon} />
          </Box>
          <Text fontWeight="bold" color="text.2900" fontSize="16px">
            {text}
          </Text>
          <Text fontWeight="bold" color="text.2400" fontSize="18px">
            {number}
            {/* {number} */}
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default Card;
