import * as Yup from 'yup';

const regexAr = /^[\u0600-\u06FF0-9 ]*$/;

export const addCategoryValidation = Yup.object({
  subCategories: Yup.array().of(
    Yup.object({
      nameEn: Yup.string()
        .min(3, 'signup.minLength')
        .max(250, 'signup.maxLength')
        .required('signup.fieldRequired'),
      nameAr: Yup.string()
        .min(3, ' يجب أن يكون 3 حروف أو أكثر')
        .max(100, ' يجب أن يكون 100 حرف أو أقل')
        .required('هذا الحقل مطلوب')
        .test('onlyArabic', ' يجب أن يكون بالعربية', name =>
          regexAr.test(name) ? true : false
        ),
    })
  ),
});
