import { Flex, Grid, Text } from '@chakra-ui/react';

const AnalysisCard = ({ name, number, bg, color }) => {
  return (
    <Flex
      bg="white"
      borderRadius="10px"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      py="24px"
    >
      <Grid
        placeItems="center"
        bg={bg}
        w="50px"
        h="50px"
        borderRadius="50%"
        mb="16px"
        fontWeight="bold"
        fontSize="18px"
        color={color}
      >
        {number}
      </Grid>
      <Text color="text.2500">{name}</Text>
    </Flex>
  );
};

export default AnalysisCard;
