import { useContext } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Image, Text, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { CardPopover } from '../shared';
import Permission from 'helpers/permission';
import defaultImage from 'assets/images/defaultBranch.png';
import grayLoction from 'assets/images/icons/gray-location.svg';
import mapIcon from 'assets/images/icons/Map.svg';

const CardBranch = ({
  item,
  setShowEdit,
  setShowDelete,
  setShowActive,
  ...props
}) => {
  const { language } = useContext(userContext);

  return (
    <Box
      pos="relative"
      bg={!item.deletedAt ? 'white' : 'gray.200'}
      border={item.isDefault === 'true' ? '4px solid #21C6E1' : 'none'}
      p="20px"
      borderRadius="10px"
      _hover={{ bg: 'bg.100' }}
      {...props}
    >
      <Link
        to={item.id}
        // to={item.isDefault !== 'true' ? item.id : ''}
      >
        <Box>
          <Image
            src={item?.branchImageUrl || defaultImage}
            borderRadius="5px"
            w="100%"
            h="160px"
            mb="16px"
            objectFit="cover"
          />
          <Text
            color="text.2100"
            textTransform="capitalizes"
            mb="8px"
            fontSize="20px"
            fontWeight="bold"
          >
            {language == 'en' ? item.name.en : item.name.ar}
          </Text>
          <Flex fontSize="16" alignItems="center" mb="1">
            <Image src={mapIcon} me="12px" />

            <Text
              maxW="210px"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >{`${item.street}, ${item.city} ${item.zipCode}, ${item.country}`}</Text>
          </Flex>
        </Box>
      </Link>

      {Permission('Branches') == 'Edit' && item.isDefault === 'false' && (
        <CardPopover
          item={item}
          setShowDelete={setShowDelete}
          setShowEdit={setShowEdit}
          setShowActive={setShowActive}
          top="205px"
        />
      )}
    </Box>
  );
};

export default CardBranch;
