import {
  Box,
  Collapse,
  Image,
  useDisclosure,
  Tr,
  Td,
  Text,
  Grid,
  Flex,
  Table,
} from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { ImArrowDownRight2 } from 'react-icons/im';
import { MdLocationOn } from 'react-icons/md';
import { HiOutlineUser } from 'react-icons/hi';
import Weekend from './weekend-row';
import AbsentRow from './absent-row';
import LeaveRow from './leave-row';
import PresentRow from './present-row';
import { CustomAvatar } from 'components/shared';

const TableRow = ({
  firstName,
  lastName,
  department,
  shift,
  clockinHour,
  clokinLocation,
  clockoutHour,
  clokoutLocation,
  workingHours,
  profileImage,
  leave,
  weekend,
  absent,
  shifts,
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const padding = '25px';

  return (
    <Grid
      w="100%"
      gridTemplateColumns="2fr 8fr"
      bg="white"
      borderBottom="1px solid #edf2f7"
      ps="22px"
    >
      <Box pt="22px">
        <Box display="flex" alignItems="center">
          <Grid me="8px" placeItems="center">
            <CustomAvatar
              image={profileImage}
              w="40px"
              h="40px"
              letter={firstName[0]}
              mb="0"
              letterSize="16px"
            />
          </Grid>
          <Box>
            <Text fontSize="14px" fontWeight="bold" color="text.600">
              {firstName + ' ' + lastName}
            </Text>
            <Text fontSize="12px" color="text.600" opacity=".8">
              {department}
            </Text>
          </Box>
        </Box>
      </Box>
      {/* this we will pass all our nested rows */}
      <Box>
        {shifts?.map((e, i) => {
          if (e.type == 'weekend') {
            return <Weekend shift={e.shiftName} i={i} />;
          } else if (e.type == 'absent') {
            return <AbsentRow shift={e.shiftName} i={i} />;
          } else if (e.type == 'present') {
            return (
              <PresentRow
                shift={e.shiftName}
                i={i}
                checkIn={e.checkIn}
                checkOut={e.checkOut}
                checkInLocation={e.checkInLocation}
                checkOutLocation={e.checkOutLocation}
                lateCheckIn={e.lateCheckIn}
                earlyCheckOut={e.earlyCheckOut}
                outOfRangeCheckIn={e.outOfRangeCheckIn}
                outOfRangeCheckOut={e.outOfRangeCheckOut}
                // workingHours={e.workingHours}
              />
            );
          } else {
            return (
              <LeaveRow shift={e.shiftName} i={i} approvedBy={e.approvedBy} />
            );
          }
        })}
      </Box>
    </Grid>
    // <Box>
    //   <Grid w="100%" gridTemplateColumns="2fr 2fr 1fr 2fr 2fr">
    //     <Box>fdfff</Box>
    //     <Box>fdfff</Box>
    //     <Box>fdfff</Box>
    //     <Box>fdfff</Box>
    //     <Box>fdfff</Box>
    //   </Grid>
    // </Box>
    // <Tr>
    //   <Td>fsdfsf</Td>
    //   <Td colSpan="4" bg="red" p="0">
    //     <Table>
    //       <Tr bg="green">
    //         <Td bg="yellow" px="0">
    //           fjlksfjldks
    //         </Td>
    //         <Td bg="red" px="0">
    //           fjlksfjldks
    //         </Td>
    //         <Td bg="green" px="0">
    //           fjlksfjldks
    //         </Td>
    //         <Td bg="yellow" px="0">
    //           fjlksfjldks
    //         </Td>
    //       </Tr>
    //     </Table>
    //   </Td>
    // </Tr>
    // <>
    //   <Tr
    //     // onClick={onToggle}
    //     // cursor="pointer"
    //     pos="relative"
    //     fontSize="14px"
    //     borderBottom="1px solid #edf2f7"
    //   >
    //     <Td border="none">
    //       <Box display="flex" alignItems="center">
    //         <Grid me="8px" placeItems="center">
    //           <Image src={profileImage} borderRadius="50%" w="40px" h="40px" />
    //         </Grid>
    //         <Box>
    //           <Text fontSize="14px" fontWeight="bold" color="text.600">
    //             {name}
    //           </Text>
    //           <Text fontSize="12px" color="text.600" opacity=".8">
    //             {department}
    //           </Text>
    //         </Box>
    //       </Box>
    //     </Td>

    //     <Td color="text.600" paddingInline="0">
    //       <Text paddingInline={padding}>{shift || 'no shift name'}</Text>
    //       {/* <NextRow ms={padding}>
    //         <Text ms="-25px">{shift || 'no shift name'}</Text>
    //       </NextRow> */}
    //     </Td>

    //     {leave ? (
    //       <Td paddingInline="0">
    //         <Box paddingInline={padding}>
    //           <Box
    //             // as="span"
    //             fontSize="12px"
    //             bg="bg.1700"
    //             py="10px"
    //             px="15px"
    //             borderRadius="10px"
    //             color="text.200"
    //             display="inline-block"
    //           >
    //             On Leave
    //           </Box>
    //         </Box>
    //         {/*
    //         <NextRow>
    //           <Box
    //             fontSize="12px"
    //             bg="bg.1700"
    //             p="10px"
    //             px="15px"
    //             borderRadius="10px"
    //             color="text.200"
    //             display="inline-block"
    //           >
    //             On Leave
    //           </Box>
    //         </NextRow> */}
    //       </Td>
    //     ) : (
    //       <>
    //         <Td paddingInline="0">
    //           <Box paddingInline={padding}>
    //             <Flex mb="8px" alignItems="center">
    //               <Box as={ImArrowDownRight2} me="4px" />
    //               <Text fontSize="14px" fontWeight="bold" color="text.1600">
    //                 {clockinHour}
    //               </Text>
    //             </Flex>

    //             <Flex alignItems="center">
    //               <Box as={MdLocationOn} me="4px" />
    //               <Text>{clokinLocation}</Text>
    //             </Flex>
    //           </Box>

    //           {/* <NextRow>
    //             <Flex mb="8px" alignItems="center">
    //               <Box as={ImArrowDownRight2} me="4px" />
    //               <Text fontSize="14px" fontWeight="bold" color="text.1600">
    //                 {clockinHour}
    //               </Text>
    //             </Flex>

    //             <Flex alignItems="center">
    //               <Box as={MdLocationOn} me="4px" />
    //               <Text>{clokinLocation}</Text>
    //             </Flex>
    //           </NextRow> */}
    //         </Td>

    //         <Td paddingInline="0">
    //           <Box paddingInline={padding}>
    //             <Flex alignItems="center">
    //               <Box as={ImArrowDownRight2} me="4px" />
    //               <Text fontSize="14px" fontWeight="bold" color="text.1600">
    //                 {clockoutHour}
    //               </Text>
    //             </Flex>

    //             <Flex alignItems="center">
    //               <Box as={MdLocationOn} me="4px" />
    //               <Text>{clokoutLocation}</Text>
    //             </Flex>
    //           </Box>
    //           {/*
    //           <NextRow>
    //             <Flex alignItems="center">
    //               <Box as={ImArrowDownRight2} me="4px" />
    //               <Text fontSize="14px" fontWeight="bold" color="text.1600">
    //                 {clockoutHour}
    //               </Text>
    //             </Flex>

    //             <Flex alignItems="center">
    //               <Box as={MdLocationOn} me="4px" />
    //               <Text>{clokoutLocation}</Text>
    //             </Flex>
    //           </NextRow> */}
    //         </Td>

    //         <Td fontWeight="bold" paddingInline="0">
    //           <Text paddingInline={padding}>{workingHours}</Text>

    //           {/* <NextRow>
    //             <Text>{workingHours}</Text>
    //           </NextRow> */}
    //         </Td>
    //       </>
    //     )}

    //     {/* <Box
    //       as={MdKeyboardArrowDown}
    //       pos="absolute"
    //       right="15px"
    //       top="50%"
    //       transform="translateY(-50%)"
    //       transition=".3s"
    //       transform={
    //         isOpen
    //           ? 'translateY(-50%) rotate(180deg)'
    //           : 'translateY(-50%) rotate(0)'
    //       }
    //       fontSize="20px"
    //     /> */}
    //   </Tr>

    //   {/* <Tr p="0">
    //     <Td colSpan="5" p="0" borderBottom="0">
    //       <Collapse in={isOpen} animateOpacity w="100%">
    //         <Flex
    //           p="20px"
    //           pe="40px"
    //           bg="bg.2100"
    //           w="100%"
    //           justifyContent="space-between"
    //         >
    //           <Flex alignItems="center">
    //             <Text color="text.600">Clock In Time</Text>
    //           </Flex>

    //           <Text color="text.600" fontWeight="bold">
    //             12:10
    //           </Text>
    //           <Text color="text.600">Updated Clock In Time</Text>
    //           <Text color="text.600" fontWeight="bold">
    //             12:10
    //           </Text>
    //           <Text color="text.600">Clock </Text>
    //           <Text color="text.600">Approved by Ahmed</Text>
    //         </Flex>
    //       </Collapse>
    //     </Td>
    //   </Tr> */}
    // </>
  );
};

export default TableRow;
