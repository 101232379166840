import { useState, forwardRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Button } from '@chakra-ui/react';
import { GoCalendar } from 'react-icons/go';
import moment from 'moment';

const PickTime = ({
  handleTime,
  defaultTime,
  defaultText = 'Enter Time',
  filterTime,
  intervals = 15,
  ...props
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [change, setChange] = useState(false);

  useEffect(() => {
    if (defaultTime) {
      setChange(true);
      setStartDate(new Date(defaultTime));
    }
  }, []);

  const CalendarInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      _focus={{}}
      onClick={onClick}
      ref={ref}
      borderRadius="10px"
      color="text.1100"
      fontWeight="normal"
      {...props}
    >
      {change ? value : defaultText}

      <Box as={GoCalendar} pointerEvents="none" ms="10px" />
    </Button>
  ));

  return (
    <Box pos="relative">
      <Box
        as={DatePicker}
        selected={startDate}
        onChange={date => {
          setStartDate(date);
          setChange(true);

          if (handleTime) {
            handleTime(moment(date).utc().locale('en').format('hh:mm:ss a'));
          }
        }}
        bg="white"
        ps="20px"
        cursor="pointer"
        h="60px"
        border="1px solid #BFCFD6"
        borderColor="#E2E8F0"
        borderRadius="10px"
        outline="none"
        _focus={{ border: '1px solid #a5c6d5', boxShadow: 'none' }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={intervals}
        dateFormat="h:mm aa"
        customInput={<CalendarInput />}
        filterTime={filterTime}
        {...props}
      />
    </Box>
  );
};

export default PickTime;
