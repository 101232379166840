 

import request from 'axios-interceptors';

export const createUser = (values, branch, userImage, password) => {
  let formatedPhone =
    values.phone.indexOf('+') !== -1 ? values.phone : `+${values.phone}`;

  let formatedBranch = branch.length > 0 ? branch.map(elm => elm.value) : null;

  return request.post('iam/users', {
    firstName: values.firstName,
    lastName: values.lastName,
    emailAddress: values.email,
    phoneNumber: formatedPhone,
    password: password,
    department: values.department,
    profileImagePath: values.image ? values.image : '',
    branch: formatedBranch,
    departmentId: null,
    shiftsIds: null,
  });
};

export const getAllUsers = ({ queryKey }) => {
  // currentPage, searchKey, branch, status

  let formatedBranch =
    queryKey[3].length > 0 ? queryKey[3].map(elm => elm.value) : null;

  return request.get(
    `iam/users?page=${queryKey[1]}&limit=12&searchKey=${queryKey[2]}&branch=${formatedBranch}&status=${queryKey[4]}`
  );
};

export const getAllUsersByBranch = ({ queryKey }) => {
  // currentPage, searchKey, branch, status

  return request.get(`iam/users?branch=${queryKey[1]}`);
};

// export const getInfiniteScrollUsers=({pageParam})=>{
// return
// }

export const getUserById = ({ queryKey }) => {
  return request.get(`iam/users/findById/${queryKey[1]}`);
};

export const getUserTasks = ({ queryKey }) => {
  return request.get(`iam/users/tasks/${queryKey[1]}`);
};

export const getAllPagesUsers = () => {
  return request.get(`iam/users/all/tasks`);
};

export const updateUser = ({ values, userId }) => {
  let formatedPhone =
    values.phone.indexOf('+') !== -1 ? values.phone : `+${values.phone}`;

  return request.put(`iam/users/update/${userId}/profile`, {
    firstName: values.firstName,
    lastName: values.lastName,
    phoneNumber: formatedPhone,
    email: values.email,
    profileImagePath: values.image,
  });
};

export const updateUserPermission = ({ access, id }) => {
  return request.put(`iam/users/updatePermission/${id}`, {
    roles: access,
  });
};

export const updateEmployee = (
  values,
  branch,
  userImage,
  userId,
  department,
  shift
) => {
  let formatedPhone =
    values.phone.indexOf('+') !== -1 ? values.phone : `+${values.phone}`;

  let formatedBranch = branch.length > 0 ? branch.map(elm => elm.value) : null;

  let formatedShift = shift.length > 0 ? shift.map(elm => elm.value) : null;

  return request.put(`iam/users/update/${userId}/employee`, {
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phoneNumber: formatedPhone,
    branchesId: formatedBranch,
    profileImagePath: values.image,
    departmentId: department.value,
    shiftsIds: formatedShift,
  });
};

export const activateUser = userId => {
  return request.post(`iam/users/activate/${userId}`);
};

export const deleteUser = userId => {
  return request.post('iam/users/delete', {
    users: userId,
  });
};
