import { useState, useContext } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Grid, GridItem, Text, Flex, useToast } from '@chakra-ui/react';
import {
  Filter,
  DeletePopup,
  ActivePopup,
  Pagination,
  Loading,
  Error,
  CustomToast,
} from 'components/authenticated/shared';
import { Select, CustomModal, SimpleModal } from 'components/shared';
import { CardBranch, BranchPopup } from 'components/authenticated/branches';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  activateBranch,
  deleteBranch,
  updateBranch,
  getAllBranches,
} from 'apis/branch';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';

const Branches = () => {
  const { t } = useTranslation();
  const { setUser, user } = useContext(userContext);

  const queryClient = useQueryClient();
  const toast = useToast();

  //show modals
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState({ flag: false, data: {} });
  const [showDelete, setShowDelete] = useState({ flag: false, data: {} });
  const [showActive, setShowActive] = useState({ flag: false, data: {} });

  //filter and search data
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState({
    label: t('modals.status'),
    value: null,
  });

  //handle pagination , status select and branch select
  const handlePageClick = async data => {
    setPageNumber(data.selected + 1);
  };

  const handleStatus = value => {
    setPageNumber(1);
    value === 'all' ? setStatus(null) : setStatus(value);
  };

  const handleDelete = useMutation(() => deleteBranch([showDelete.data.id]), {
    onSuccess: async result => {
      await queryClient.invalidateQueries('getAllBranches');

      setShowDelete({ flag: false, data: {} });

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateBranch')}
              description={t('toasts.deactivateBranch')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      const user = JSON.parse(localStorage.getItem('user'));
      const branches = user.user.branches;

      const newBranchesData = branches.filter(e => {
        return e.id != showDelete.data.id;
      });

      localStorage.setItem(
        'user',
        JSON.stringify({
          user: { ...user.user, branches: newBranchesData },
          accessToken: user.accessToken,
        })
      );

      setUser(JSON.parse(localStorage.getItem('user')));
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateBranch')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleActivate = useMutation(() => activateBranch(showActive.data.id), {
    onSuccess: async result => {
      await queryClient.invalidateQueries('getAllBranches');

      setShowActive({ flag: false, data: {} });

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateBranch')}
              description={t('toasts.activateBranch')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      const user = JSON.parse(localStorage.getItem('user'));
      const branches = user.user.branches;

      const newBranchesData = [...branches, result.data];

      localStorage.setItem(
        'user',
        JSON.stringify({
          user: { ...user.user, branches: newBranchesData },
          accessToken: user.accessToken,
        })
      );

      setUser(JSON.parse(localStorage.getItem('user')));
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateBranch')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getAllBranches', 0, searchKey, status.value, 10],
    getAllBranches
  );

  let numberOfPages = data?.data.meta.totalPages;
  let itemsPerPage = data?.data.meta.itemsPerPage;
  let currentPage = data?.data.meta.currentPage;
  let itemCount = data?.data.meta.itemCount;
  let totalItems = data?.data.meta.totalItems;

  return (
    <Box>
      <Filter
        title={t('home.branches.title')}
        buttonName={t('home.branches.addNewBranch')}
        setShowAdd={setShowAdd}
        setValue={setSearchKey}
        btResponsiveWidth="190px"
        isButtonExist={Permission('Branches') == 'Edit'}
      >
        <Select
          mx="4"
          w="150px"
          h="40px"
          label={t('home.branches.status')}
          select={status}
          handleSelect={handleStatus}
          selections={[
            { label: t('common.all'), value: null },
            { label: t('common.activated'), value: 'activated' },
            { label: t('common.deactivated'), value: 'deactivated' },
          ]}
        />
      </Filter>

      {isLoading && <Loading />}

      {isSuccess && (
        <>
          <Box minH="72vh">
            <Grid
              templateColumns={[
                'repeat(4, 1fr)',
                '',
                'repeat(3, 1fr)',
                'repeat(3, 1fr)',
                'repeat(4, 1fr)',
              ]}
              gap="8"
              mb="24px"
            >
              {data?.data.items.map(e => {
                return (
                  <GridItem key={Math.random()}>
                    <CardBranch
                      setShowEdit={setShowEdit}
                      setShowDelete={setShowDelete}
                      setShowActive={setShowActive}
                      item={e}
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </Box>

          <SimpleModal
            title={t('home.branches.addNewBranch')}
            show={showAdd}
            setShow={setShowAdd}
          >
            <BranchPopup setShow={setShowAdd} />
          </SimpleModal>

          <CustomModal
            title={t('modals.editBranch')}
            show={showEdit}
            setShow={setShowEdit}
          >
            <BranchPopup setShow={setShowEdit} data={showEdit.data} />
          </CustomModal>

          <CustomModal
            title={t('modals.deleteBranch')}
            show={showDelete}
            setShow={setShowDelete}
          >
            <DeletePopup
              handleDelete={handleDelete.mutate}
              title={t('modals.deleteBranchText')}
              isLoading={handleDelete.isLoading}
            />
          </CustomModal>

          <CustomModal
            title={t('modals.activateBranch')}
            show={showActive}
            setShow={setShowActive}
          >
            <ActivePopup
              handleActivate={handleActivate.mutate}
              title={t('modals.confirmationActivateBranch')}
              isLoading={handleActivate.isLoading}
            />
          </CustomModal>

          <Pagination
            handlePageClick={handlePageClick}
            numberOfPages={numberOfPages}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            itemCount={itemCount}
            totalItems={totalItems}
            forcePage={currentPage - 1}
          />
        </>
      )}

      {isError && <Error />}
    </Box>
  );
};

export default Branches;
