import { useState, useContext, useEffect } from 'react';
import {
  Box,
  Text,
  Tr,
  Td,
  Checkbox,
  Flex,
  useToast,
  Tooltip,
} from '@chakra-ui/react';

import {
  Filter,
  Table,
  DeletePopup,
  ActivePopup,
  CustomSwitch,
  Pagination,
  Loading,
  Nodata,
  Error,
  CustomToast,
  StatusBox,
} from 'components/authenticated/shared';
import {
  Select,
  CustomModal,
  MultiSelect,
  SimpleModal,
} from 'components/shared';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getAllProducts,
  deleteProduct,
  activateProduct,
} from 'apis/catlog/Product';
import { deactivatePart, activatePart } from 'apis/part';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';
import PartPopup from './modal';
import PartSwitch from './part-switch';

const PartRow = ({
  item,
  isCheck,
  setIsCheck,
  refetchParts,

  setShowDeleteAll,
  selectedBranch,
  branchId,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showChosseBranchModal, setShowChosseBranchModal] = useState(false);

  const [showActive, setShowActive] = useState(false);

  const { mutate: deactivate, isLoading: isLoadingDeactivate } = useMutation(
    deactivatePart,
    {
      onSuccess: () => {
        refetchParts();
        setShowDelete(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deactivatePart')}
                description={t('toasts.deactivatePart')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deactivatePart')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const { mutate: activate, isLoading: isLoadingactivate } = useMutation(
    activatePart,
    {
      onSuccess: () => {
        refetchParts();

        setShowActive(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.activatePart')}
                description={t('toasts.activatePart')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.activatePart')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const handleClickCheck = e => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  const branches = item?.totalBranches;

  const selectedBranchesIds = selectedBranch?.map(e => {
    return e.value;
  });

  // const isActive = branches?.find(e => {
  //   return selectedBranchesIds.find(b => {
  //     return b == e?.branch.id;
  //   });
  // });

  const isBranchActive = branches?.some(b => b.isActive === true);

  return (
    <>
      <Tr
        bg={item.deletedAt ? 'gray.200' : 'white'}
        _hover={{ bg: '#f8f8f8' }}
        cursor="pointer"
        onClick={e => {
          if (
            e.target.localName != 'input' &&
            e.target.localName != 'span' &&
            e.target.localName != 'svg' &&
            e.target.localName != 'polyline'
          ) {
            setShowEdit(true);
          }
        }}
      >
        {Permission('Products') == 'Edit' && (
          <Td>
            {item.deletedAt == null && (
              <Checkbox
                id={item.id}
                onChange={e => handleClickCheck(e)}
                isChecked={isCheck.includes(item.id)}
              ></Checkbox>
            )}
          </Td>
        )}

        <Td>{item.sku}</Td>
        <Td>
          <Text
            maxW="190px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {item.name}
          </Text>
        </Td>
        <Td>
          <Text
            maxW="190px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {item.partNumber}
          </Text>
        </Td>
        <Td>{item.totalQuantity}</Td>
        <Td>
          <StatusBox
            status={item.totalQuantity > 0 ? 'available' : 'unavailable'}
            text={
              item.totalQuantity > 0
                ? t('home.storage.available')
                : t('home.storage.unavailable')
            }
          />
          {/* <Flex
      justifyContent="center"
      alignItems="center"
      bg={item.totalQuantity > 0 ? 'bg.1000' : 'bg.900'}
      color={
        item.totalQuantity > 0 ? 'text.2000' : 'text.1900'
      }
      borderRadius="10px"
      w="82px"
      h="32px"
      fontWeight="bold"
      fontSize="14px"
    >
      <Text>
        {item.totalQuantity > 0
          ? t('home.storage.available')
          : t('home.storage.unavailable')}
      </Text>
    </Flex> */}
        </Td>
        <Td>
          <Tooltip
            hasArrow
            borderRadius="5px"
            label={
              <Box>
                <Text mb="2" textAlign="center">
                  {t('modals.branchList')}
                </Text>

                {item.totalBranches.map((elm, ind) => (
                  <Text>
                    {elm.branch.name.en}: {elm.quantity}
                  </Text>
                ))}
              </Box>
            }
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              bg="bg.800"
              color="text.200"
              borderRadius="10px"
              w="32px"
              h="32px"
              fontWeight="bold"
              fontSize="14px"
              cursor="default"
            >
              <Text>{item.totalBranches.length}</Text>
            </Flex>
          </Tooltip>
        </Td>
        {Permission('Products') == 'Edit' && (
          <Td>
            <PartSwitch
              isActive={isBranchActive}
              item={item}
              setShowDelete={setShowDelete}
              setShowActive={setShowActive}
              setShowChosseBranchModal={setShowChosseBranchModal}
              showChosseBranchModal={showChosseBranchModal}
              branchSelected={selectedBranch}
              refetchParts={refetchParts}
              position="relative"
              top="0"
            />
          </Td>
        )}
      </Tr>

      <SimpleModal
        title={t('modals.editPart')}
        show={showEdit}
        setShow={setShowEdit}
      >
        <PartPopup setShow={setShowEdit} data={item} />
      </SimpleModal>

      <SimpleModal
        title={t('modals.deletePart')}
        show={showDelete}
        setShow={setShowDelete}
      >
        <DeletePopup
          handleDelete={() => deactivate({ products: [item.id], branchId })}
          title={t('modals.deletePartText')}
          isLoading={isLoadingDeactivate}
        />
      </SimpleModal>

      <SimpleModal
        title={t('modals.activatePart')}
        show={showActive}
        setShow={setShowActive}
      >
        <ActivePopup
          handleActivate={() =>
            activate({
              price: item?.price,
              applicationPrice: item?.applicationPrice,
              productId: item?.id,
              branchId: selectedBranchesIds[0],
            })
          }
          isLoading={isLoadingactivate}
          title={t('modals.confirmActivatePart')}
        />
      </SimpleModal>
    </>
  );
};

export default PartRow;
