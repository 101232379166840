import { Flex, Box } from '@chakra-ui/react';
import Side from './side';

const Authenticated = ({ children }) => {
  return (
    <Flex bg="bg.200">
      <Side />
      <Box
        pb="55px"
        pt="37px"
        px={['55px', '', '20px', '20px', '40px']}
        w={[
          'calc(100% - 308px)',
          'calc(100% - 308px)',
          '100%',
          'calc(100% - 308px)',
        ]}
        pos="relative"
        ms={['0', '', '160px', '0']}
        minH="100vh"
        overflow="hidden"
      >
        {children}
      </Box>
    </Flex>
  );
};

export default Authenticated;
