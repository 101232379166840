import { Box, Text } from '@chakra-ui/react';
import { Card } from 'components/authenticated/appointments';

const List = ({ data, refetchList, refetchAllData, ...props }) => {
  return (
    <Box {...props}>
      <Box overflowY="auto" maxH="550px">
        {data?.data.map((item, index) => (
          <Card
            itemInfo={item}
            refetchAllData={refetchAllData}
            refetchList={refetchList}
          />
        ))}
      </Box>
    </Box>
  );
};

export default List;
