import request from 'axios-interceptors';

export const getAllNotifications = ({ queryKey }) => {
  const userId = JSON.parse(localStorage.getItem('user')).user.id;

  return request.get(
    `iam/users/notifications/all/${queryKey[1]}?page=${queryKey[2]}`
  );
};

export const deleteNotification = notificationId => {
  return request.delete(`iam/users/notifications/${notificationId}`);
};

export const deviceToken = deviceToken => {
  const userId = JSON.parse(localStorage.getItem('user')).user.id;

  return request.put(`iam/users/device/tokens/${userId}`, {
    webDeviceToken: deviceToken,
    mobileDeviceToken: null,
  });
};
