const Countries = [
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Oman', code: 'OM' },
  { name: 'Qatar', code: 'QA' },
  { name: 'United Arab Emirates', code: 'AE' },
];

export default Countries;
