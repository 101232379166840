import { Button, Box } from '@chakra-ui/react';

const CustomButton = ({
  name,
  icon,
  bg,
  hoverBg = 'bg.300',
  activeBg = 'bg.400',
  loading,
  ...props
}) => {
  return (
    <Button
      h="60px"
      bg={bg ? 'bg.300' : 'transparent'}
      border={bg ? 'none' : '2px solid #21C6E1'}
      borderRadius="15px"
      w="100%"
      color={bg ? 'white' : 'text.1000'}
      _hover={{ bg: hoverBg, color: 'white', border: 'none' }}
      _focus={{ outline: 'none' }}
      _active={{ bg: activeBg }}
      display={icon ? 'flex' : 'initial'}
      alignItems="center"
      {...props}
    >
      {icon ? <Box as={icon} me="5px" fontSize="22px" /> : null}
      {name}
      {loading ? loading : null}
    </Button>
  );
};

export default CustomButton;
