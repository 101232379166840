import { Box, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';

const TableFooter = () => {
  const user = localStorage.getItem('user');
  const userFirstName = JSON.parse(user).user.firstName;
  const userLastName = JSON.parse(user).user.lastName;

  return (
    <Flex mt="39px" alignItems="center">
      <Box me="180px">
        <Text color="#181F27" fontSize="14px">
          Printed by:{' '}
          <Text as="span" fontWeight="bold">
            {userFirstName + ' ' + userLastName}
          </Text>
        </Text>

        <Text color="#181F27" fontSize="14px">
          Printed on:{' '}
          <Text as="span" fontWeight="bold">
            {moment(new Date()).format('DD/MM/YYYY')}
          </Text>
        </Text>
      </Box>

      <Flex>
        <Box
          fontWeight="bold"
          fontSize="12px"
          borderInlineStart="8px solid #FFDA87"
          p="5px"
          me="10px"
        >
          Weekend/Day off{' '}
        </Box>

        <Box
          fontWeight="bold"
          fontSize="12px"
          borderInlineStart="8px solid #12885A"
          p="5px"
          me="10px"
        >
          Present
        </Box>
        <Box
          fontWeight="bold"
          fontSize="12px"
          borderInlineStart="8px solid #6147FF"
          p="5px"
          me="10px"
        >
          Absent
        </Box>
        <Box
          fontWeight="bold"
          fontSize="12px"
          borderInlineStart="8px solid #21C6E1"
          p="5px"
          me="10px"
        >
          Leave Request
        </Box>
        <Box
          fontWeight="bold"
          fontSize="12px"
          borderInlineStart="8px solid #F43838"
          p="5px"
        >
          Late
        </Box>
      </Flex>
    </Flex>
  );
};

export default TableFooter;
