import { Box, Text, Flex, Image } from '@chakra-ui/react';
import { IoMdDocument } from 'react-icons/io';
import { MdCreateNewFolder } from 'react-icons/md';
import { ViewPopover } from 'components/authenticated/shared';
import moment from 'moment';
import Permission from 'helpers/permission';
import png from 'assets/images/icons/png.svg';
import jpg from 'assets/images/icons/jpg.svg';
import pdf from 'assets/images/icons/pdf.svg';
import xlsx from 'assets/images/icons/xlsx.svg';
import docx from 'assets/images/icons/docx.svg';
import { useTranslation } from 'react-i18next';

const Card = ({ item, setShowDelete }) => {
  const { t } = useTranslation();

  return (
    <Box position="relative" bg="white" borderRadius="10px" p="4">
      <ViewPopover
        item={item}
        setShowDelete={setShowDelete}
        viewLink={item.url}
        isDeleteExist={Permission('Documents') == 'Edit'}
      />

      <Flex
        mt="8"
        mb="6"
        fontWeight="bold"
        bg="#F4F9FF"
        color={item?.type === 'jpg' ? 'text.200' : 'text.3000'}
        fontSize="42px"
        justifyContent="center"
        alignItems="center"
        borderRadius="full"
        w="72px"
        h="72px"
        mx="auto"
      >
        <Image
          w="35px"
          src={
            item.url.split('.').pop() == 'jpg' ||
            item.url.split('.').pop() == 'jpeg'
              ? jpg
              : item.url.split('.').pop() == 'png'
              ? png
              : item.url.split('.').pop() == 'pdf'
              ? pdf
              : item.url.split('.').pop() == 'xlsx'
              ? xlsx
              : item.url.split('.').pop() == 'docx'
              ? docx
              : ''
          }
        />
      </Flex>

      <Box>
        <Text
          fontWeight="bold"
          color="text.2100"
          textAlign="center"
          fontSize="18px"
        >
          {item.name}
        </Text>
      </Box>

      <Flex
        justifyContent="center"
        alignItems="center"
        color="gray.400"
        fontSize="14px"
      >
        <MdCreateNewFolder />
        <Text ms="1">
          {moment(item.createdAt).locale(t('common.lang')).format('DD-MM-YYYY')}
        </Text>
      </Flex>
    </Box>
  );
};

export default Card;
