import { Box } from '@chakra-ui/react';

const FormBody = ({ children, ...props }) => {
  return (
    <Box
      as="form"
      bg="white"
      p="40px"
      borderRadius="15px"
      w="500px"
      boxShadow="0 0 160px -60px #21B3E1"
      {...props}
    >
      {children}
    </Box>
  );
};

export default FormBody;
