import { Flex, Text, Image, Heading } from '@chakra-ui/react';
import wrong from 'assets/images/wrong.png';

const Error = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pos="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%,-50%)"
    >
      <Image src={wrong} w="340px" />
      <Heading as="h3" fontWeight="bold" mb="10px">
        Something went wrong.
      </Heading>
      <Text>sorry, something went wrong there.Try again.</Text>
    </Flex>
  );
};

export default Error;
