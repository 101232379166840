import { useRef } from 'react';
import {
  Box,
  Grid,
  Flex,
  Button,
  Image,
  Spinner,
  useToast,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
} from '@chakra-ui/react';
import { CustomButton, CustomInput, CustomUploadFile } from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import subCatIcon from 'assets/images/sub-cat.svg';
import arrowDirection from 'assets/images/dir-arrow.svg';
import { Formik, Form, FieldArray } from 'formik';
import { addCategoryValidation } from 'schemas/add-category';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import defaultUploadIcon from 'assets/images/default-upload-icon.png';
import { createCategorie, uploadCategoryIcon } from 'apis/categories';

function groupObjectsByParent(subCategories) {
  return new Promise(resolve => {
    const parentMap = {};
    const result = [];

    // Create a map of parents to their corresponding objects
    subCategories.forEach(category => {
      const parent =
        category.parent && category.parent[category.parent.length - 1];
      if (!parent) {
        result.push({
          name: category.nameEn,
          nameAr: category.nameAr,

          children: category.children,
        });
      } else {
        if (!parentMap[parent]) {
          parentMap[parent] = [];
        }

        parentMap[parent].push({
          name: category.nameEn,
          nameAr: category.nameAr,
          children: category.children,
        });
      }
    });

    // Assign children to their parent objects recursively
    const assignChildren = obj => {
      const children = parentMap[obj.name] || [];

      // Exclude objects with an empty children array
      if (children.length > 0) {
        obj.children = children.map(child => assignChildren(child));
      }

      return obj;
    };

    const resolvedResult = result.map(obj => assignChildren(obj));
    resolve(resolvedResult);
  });
}

const AddCatgeoryModal = ({ refetchCategories, setShowModal }) => {
  const toast = useToast();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(createCategorie, {
    onSuccess: () => {
      refetchCategories();
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('categories.addCategory')}
              description={t('categories.addSuccess')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
      setShowModal();
    },
    onError: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('categories.addCategory')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const B = ({ parents }) => {
    return (
      <Breadcrumb spacing="8px" separator={'>>'} fontSize="10px">
        <BreadcrumbItem>
          <BreadcrumbLink href="#">{t('common.from')}</BreadcrumbLink>
        </BreadcrumbItem>

        {parents?.map(e => {
          return (
            <BreadcrumbItem>
              <BreadcrumbLink href="#">{e}</BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    );
  };

  //add object to children
  //if multi items on array then check the parent object go to children then search ont
  const responseUrl = `${process.env.REACT_APP_API_BASE_URL}/uploads/categories/`;

  return (
    <>
      <Box>
        <Formik
          initialValues={{
            fileName: null,
            subCategories: [
              {
                nameEn: '',
                nameAr: '',

                // children: [],
              },
            ],
          }}
          validationSchema={addCategoryValidation}
          onSubmit={values => {
            groupObjectsByParent(values.subCategories)
              .then(result => {
                mutate({ fileName: values.fileName, data: result[0] });
              })
              .catch(
                error => {
                  console.error(error);
                }

                //groupObjectsByParent(formik.values.subCategories)
              );
          }}
        >
          {formik => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <CustomUploadFile
                  uploadEndPoint={uploadCategoryIcon}
                  textTitle={t('categories.uploadIcon')}
                  mb="0"
                  defaultImage={defaultUploadIcon}
                  responseUrl={responseUrl}
                  radius
                  formikChange={value =>
                    formik.setFieldValue('fileName', value)
                  }
                />
                <>
                  {/* <Flex justifyContent="space-between" alignItems="flex-end">
                    <Grid
                      gridTemplateColumns="repeat(2,1fr)"
                      gap="15px"
                      w="88%"
                    >
                      <CustomInput label="category name en" />
                      <CustomInput label="category name ar" />
                    </Grid>
                    <Button
                      w="10%"
                      h="60px"
                      bg="bg.300"
                      border="none"
                      borderRadius="15px"
                      color="white"
                      _hover={{ bg: 'bg.300', color: 'white', border: 'none' }}
                      _focus={{ outline: 'none' }}
                      _active={{ bg: 'bg.400' }}
                      alignItems="center"
                    >
                      <Image w="30px" src={subCatIcon} />
                    </Button>
                  </Flex> */}

                  <FieldArray name="subCategories">
                    {({ push, remove }) => (
                      <>
                        {formik.values.subCategories.map((p, index) => {
                          const nameEn = `subCategories[${index}].nameEn`;

                          const nameAr = `subCategories[${index}].nameAr`;

                          const parents = p.parent || [];

                          return (
                            <>
                              <Flex
                                justifyContent="space-between"
                                alignItems="flex-end"
                              >
                                {/* {index != 0 ? (
                                <Box position="relative" w="5%">
                                  <Image
                                    pos="absolute"
                                    src={arrowDirection}
                                    me="5px"
                                    top="-109px"
                                  />
                                </Box>
                              ) : null} */}

                                <Box w="88%" pos="relative">
                                  {index == 0 ? null : (
                                    <Grid
                                      placeItems="center"
                                      w="33px"
                                      h="33px"
                                      borderRadius="50%"
                                      bg="#cf3434"
                                      cursor="pointer"
                                      margin="auto"
                                      pos="absolute"
                                      top="39%"
                                      left="50%"
                                      zIndex="11"
                                      transform="translateX(-50%)"
                                      color="white"
                                      onClick={() => remove(index)}
                                    >
                                      x
                                    </Grid>
                                  )}
                                  <Grid
                                    gridTemplateColumns="repeat(2,1fr)"
                                    gap="15px"
                                  >
                                    <Box>
                                      <CustomInput
                                        label={
                                          index == 0 ? (
                                            t('categories.categoryEn')
                                          ) : (
                                            <>
                                              {t('categories.categoryEn')}{' '}
                                              <B parents={parents} />
                                            </>
                                          )
                                        }
                                        name={nameEn}
                                        value={p.nameEn}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </Box>

                                    <Box>
                                      <CustomInput
                                        label={
                                          index == 0 ? (
                                            t('categories.categoryAr')
                                          ) : (
                                            <>
                                              {t('categories.categoryAr')}{' '}
                                              <B parents={parents} />
                                            </>
                                          )
                                        }
                                        name={nameAr}
                                        value={p.nameAr}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                      />
                                    </Box>
                                  </Grid>
                                </Box>
                                <Button
                                  w="10%"
                                  h="60px"
                                  bg="bg.300"
                                  border="none"
                                  borderRadius="15px"
                                  color="white"
                                  title={t('categories.addSubCategory')}
                                  _hover={{
                                    bg: 'bg.300',
                                    color: 'white',
                                    border: 'none',
                                  }}
                                  _focus={{ outline: 'none' }}
                                  _active={{ bg: 'bg.400' }}
                                  alignItems="center"
                                  onClick={() => {
                                    if (parents.length < 2) {
                                      push({
                                        nameEn: '',
                                        nameAr: '',
                                        parent: [...parents, p.nameEn],
                                      });
                                    }
                                  }}
                                  disabled={
                                    !p.nameEn ||
                                    !p.nameAr ||
                                    parents.length >= 2
                                  }
                                >
                                  <Image w="30px" src={subCatIcon} />
                                </Button>
                              </Flex>
                              <Grid
                                gridTemplateColumns="repeat(2,1fr)"
                                gap="15px"
                                w="88%"
                                mb="20px"
                              >
                                <Box>
                                  {formik.errors &&
                                    formik.errors.subCategories &&
                                    formik.errors.subCategories[index] &&
                                    formik.errors.subCategories[index].nameEn &&
                                    formik.touched &&
                                    formik.touched.subCategories &&
                                    formik.touched.subCategories[index] &&
                                    formik.touched.subCategories[index]
                                      .nameEn && (
                                      <Box fontSize="14px" color="red">
                                        {t(
                                          formik.errors.subCategories[index]
                                            .nameEn
                                        )}
                                      </Box>
                                    )}
                                </Box>

                                <Box>
                                  {formik.errors &&
                                    formik.errors.subCategories &&
                                    formik.errors.subCategories[index] &&
                                    formik.errors.subCategories[index].nameAr &&
                                    formik.touched &&
                                    formik.touched.subCategories &&
                                    formik.touched.subCategories[index] &&
                                    formik.touched.subCategories[index]
                                      .nameAr && (
                                      <Box fontSize="14px" color="red">
                                        {t(
                                          formik.errors.subCategories[index]
                                            .nameAr
                                        )}
                                      </Box>
                                    )}
                                </Box>
                              </Grid>
                            </>

                            // <Box key={p.id}>

                            //   <Button
                            //     margin="normal"
                            //     type="button"
                            //     color="secondary"
                            //     variant="outlined"
                            //     onClick={() => remove(index)}
                            //   >
                            //     x
                            //   </Button>
                            //   <Button
                            //     type="button"
                            //     variant="outlined"
                            //     onClick={() =>
                            //       push({
                            //         id: Math.random(),
                            //         nameEn: '',
                            //         nameAr: '',
                            //       })
                            //     }
                            //   >
                            //     Add
                            //   </Button>
                            // </Box>
                          );
                        })}
                      </>
                    )}
                  </FieldArray>
                </>
                <CustomButton
                  name={t('account.save')}
                  bg
                  mt="30px"
                  type="submit"
                  loading={isLoading && <Spinner me="5px" />}
                  disabled={isLoading}
                  display="flex"
                  alignItems="center"
                />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default AddCatgeoryModal;
