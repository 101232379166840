import request from 'axios-interceptors';

export const getTenantInfo = () => {
  return request.get(`iam/tenants`);
};

export const updateTenantInfo = (values, country) => {
  return request.put(`iam/tenants`, {
    name: {
      ar: values.workshopNameAR,
      en: values.workshopNameEN,
    },
    lat: values.mapSelected.lat,
    long: values.mapSelected.lng,
    city: values.city,
    street: values.street,
    country: country.value,
    zipCode: values.zipCode,
    buildingNumber: values?.buildingNumber.toString(),
    district: values.districtName,
    additionalNumber: values?.additionalNumber.toString(),
    commercialNumber: values.commercialNumber,
    taxNumber: !!values.taxNumber ? values.taxNumber : null,
    isTaxable: !!values.isTaxable ? values.isTaxable : false,
    imagePath: values.workshopImage ? values.workshopImage : '',
    logoPath: values.workshopLogo ? values.workshopLogo : '',
  });
};
