import { useState, memo } from 'react';
import { Box, Flex, useToast, Spinner } from '@chakra-ui/react';
import { MultiSelect, CustomButton } from 'components/shared';
import {
  CustomToast,
  MultiSelectSearch,
} from 'components/authenticated/shared';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getAllPagesProducts } from 'apis/catlog/Product';
import { updateWorkflowProducts } from 'apis/projects';
import AddPart from './add-part';
import { Formik, Field, FieldArray } from 'formik';
import addPart from 'schemas/tasks/add-part';
import { useTranslation } from 'react-i18next';

const AddParts = memo(({ invoiceProducts, id, branch, setShow, refetch }) => {
  const toast = useToast();
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const servicesArray = invoiceProducts.filter(e => {
    return e.type == 'service';
  });

  const partsArray = invoiceProducts.filter(e => {
    return e.type == 'part';
  });

  const customFormatServicesArray = servicesArray?.map(e => {
    return {
      type: 'service',
      invoiceProductId: e.product.id,
      price: 0,
      quantity: e.quantity,
    };
  });

  const [parts, setParts] = useState(
    partsArray
      ? partsArray.map(e => {
          return { label: e.product.name, value: e.product.id };
        })
      : []
  );

  const { data } = useQuery(
    ['getAllPagesProducts', branch.value],
    getAllPagesProducts
  );

  const { mutate, isLoading } = useMutation(updateWorkflowProducts, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllUsersTasks');
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.updateParts')}
              description={t('toasts.updateParts')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      refetch();
      setShow(false);
    },
    onError: err => {
      const notAvaliablePart =
        err.response?.data?.errors?.products?.lessThanAvailable.split(',');

      if (err.response?.data?.errors?.products?.lessThanAvailable) {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateParts')}
                description={`${notAvaliablePart[0]}  ${t(
                  'toasts.noAvailableQuantity'
                )} ${notAvaliablePart[1]}`}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      } else
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateParts')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
    },
  });

  const filterPartsAvalibel = data?.data.filter(e => {
    return e.totalQuantity > 0;
  });

  const partsData = filterPartsAvalibel?.map(e => {
    return {
      quantity: e.totalQuantity,
      id: e.sku,
      label: e.name,
      value: e.id,
    };
  });

  // const partsData = data?.data.map(e => {
  //   return { quantity: e.totalQuantity, id: e.sku, label: e.name, value: e.id };
  // });

  const handleParts = (value, check, index, e, data) => {
    if (!check) {
      let filteredBranches = parts.filter(elm => elm.value !== value.value);
      setParts(filteredBranches);

      let ind = data.findIndex(e => {
        return e.invoiceProductId == value.value;
      });
      e.remove(ind);
    } else {
      setParts(prevState => [...prevState, value]);
      e.insert(index, {
        type: 'part',
        invoiceProductId: value.value,
        searchId: value.id,
        name: value.label,
        price: 0,
        quantity: '',
      });
    }
  };

  return (
    <Formik
      initialValues={{
        parts: partsArray
          ? partsArray.map(e => {
              return {
                type: 'part',
                invoiceProductId: e.product.id,
                name: e.product.name,
                quantity: e.quantity,
                searchId: e.product.sku,
                price: e.price,
              };
            })
          : [],
      }}
      validationSchema={addPart}
      onSubmit={async values => {
        mutate({
          invoiceId: id,
          products: [...values.parts, ...customFormatServicesArray],
        });
      }}
      // validateOnMount
    >
      {formik => {
        return (
          <Box as="form" p="40px" onSubmit={formik.handleSubmit}>
            <FieldArray
              name="parts"
              render={arrayHelpers => (
                <>
                  <Box>
                    <MultiSelectSearch
                      labelForMulti={t('home.dashboard.parts')}
                      mb="24px"
                      h="60px"
                      label={t('modals.selectParts')}
                      select={parts}
                      handleSelect={(value, check, index) =>
                        handleParts(
                          value,
                          check,
                          index,
                          arrayHelpers,
                          formik.values.parts
                        )
                      }
                      selections={partsData}
                    />
                  </Box>
                  {formik.values.parts.length > 0 && (
                    <Box mb="20px">
                      <Flex
                        bg="#21C6E1"
                        color="white"
                        p="10px"
                        borderRadius="5px 5px 0 0"
                        borderBottom="1px solid #b7b8bb"
                      >
                        <Box w="25%">{t('modals.id')}</Box>
                        <Box w="30%">{t('modals.name')}</Box>
                        <Box w="35%">{t('modals.quantity')}</Box>
                      </Flex>
                      {formik.values.parts.map((e, i) => {
                        return (
                          <AddPart
                            searchId={e.searchId}
                            id={e.invoiceProductId}
                            index={i}
                            name={e.name}
                            formik={formik}
                            inputQuantity={e.quantity}
                            quantity={
                              data?.data?.find(i => {
                                return i.id == e.invoiceProductId;
                              })?.totalQuantity
                            }
                            changeQuantity={`parts.${i}.quantity`}
                            border="1px solid #BFCFD6"
                            borderTop="0"
                            borderRadius={
                              i == formik.values.parts.length - 1
                                ? '0 0 5px 5px'
                                : '0'
                            }
                            arrayHelpers={arrayHelpers}
                            parts={parts}
                            setParts={setParts}
                          />
                        );
                      })}
                    </Box>
                  )}
                </>
              )}
            />

            <CustomButton
              bg
              name={t('signup.confirm')}
              type="submit"
              display="flex"
              alignItems="center"
              justifyContent="center"
              loading={isLoading && <Spinner ms="5px" />}
              disabled={isLoading}
            />
          </Box>
        );
      }}
    </Formik>
  );
});

export default AddParts;
