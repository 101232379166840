import { useState, memo } from 'react';
import { Box, Flex, useToast, Spinner } from '@chakra-ui/react';
import { MultiSelect, CustomButton } from 'components/shared';
import {
  CustomToast,
  MultiSelectSearch,
} from 'components/authenticated/shared';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getAllPagesServices } from 'apis/service';
import { updateWorkflowProducts } from 'apis/projects';
import AddService from './add-service';
import { Formik, Field, FieldArray } from 'formik';
import addPart from 'schemas/tasks/add-part';
import { useTranslation } from 'react-i18next';

const AddServices = memo(
  ({ invoiceProducts, id, branch, setShow, refetch }) => {
    const { t } = useTranslation();

    const toast = useToast();

    const queryClient = useQueryClient();

    const servicesArray = invoiceProducts.filter(e => {
      return e.type == 'service';
    });

    const partsArray = invoiceProducts.filter(e => {
      return e.type == 'part';
    });

    const customFormatPartsArray = partsArray?.map(e => {
      return {
        type: 'service',
        invoiceProductId: e.product.id,
        price: 0,
        quantity: e.quantity,
      };
    });

    const [services, setServices] = useState(
      servicesArray
        ? servicesArray.map(e => {
            return { label: e.product.name, value: e.product.id };
          })
        : []
    );

    const { data } = useQuery(
      ['getAllPagesServices', branch.value],
      getAllPagesServices
    );

    const { mutate, isLoading } = useMutation(updateWorkflowProducts, {
      onSuccess: async () => {
        await queryClient.invalidateQueries('getAllUsersTasks');
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateServices')}
                description={t('toasts.updateServices')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });

        refetch();
        setShow(false);
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateServices')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    });

    const servicesData = data?.data.map(e => {
      return {
        quantity: e.totalQuantity,
        id: e.sku,
        label: e.name,
        value: e.id,
      };
    });

    const handleServices = (value, check, index, e, data) => {
      if (!check) {
        let filteredBranches = services.filter(
          elm => elm.value !== value.value
        );
        setServices(filteredBranches);
        let ind = data.findIndex(e => {
          return e.invoiceProductId == value.value;
        });
        e.remove(ind);
      } else {
        setServices(prevState => [...prevState, value]);
        e.insert(index, {
          type: 'service',
          invoiceProductId: value.value,
          name: value.label,
          quantity: 1,
          searchId: value.id,
          price: 0,
        });
      }
    };

    return (
      <Formik
        initialValues={{
          services: servicesArray
            ? servicesArray.map(e => {
                return {
                  type: 'service',
                  invoiceProductId: e.product.id,
                  name: e.product.name,
                  quantity: 1,
                  searchId: e.product.sku,
                  price: e.price,
                };
              })
            : [],
        }}
        onSubmit={async values => {
          if (servicesArray.length > 0) {
            mutate({
              invoiceId: id,
              products: [...values.services, ...customFormatPartsArray],
            });
          } else {
            if (values.services.length == 0) {
              toast({
                render: props => {
                  return (
                    <CustomToast
                      title={t('toasts.titles.addService')}
                      description={t('toasts.addService')}
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else
              mutate({
                invoiceId: id,
                products: [...values.services, ...customFormatPartsArray],
              });
          }
        }}
        // validateOnMount
      >
        {formik => {
          return (
            <Box as="form" p="40px" onSubmit={formik.handleSubmit}>
              <FieldArray
                name="services"
                render={arrayHelpers => (
                  <>
                    <Box>
                      <MultiSelectSearch
                        labelForMulti={t('home.dashboard.services')}
                        mb="24px"
                        h="60px"
                        label={t('modals.selectServices')}
                        select={services}
                        handleSelect={(value, check, index) =>
                          handleServices(
                            value,
                            check,
                            index,
                            arrayHelpers,
                            formik.values.services
                          )
                        }
                        selections={servicesData}
                      />
                    </Box>
                    {formik.values.services.length > 0 && (
                      <Box mb="20px">
                        <Flex
                          bg="#21C6E1"
                          color="white"
                          p="10px"
                          borderRadius="5px 5px 0 0"
                          borderBottom="1px solid #b7b8bb"
                        >
                          <Box w="30%">{t('modals.id')}</Box>
                          <Box w="35%">{t('modals.name')}</Box>
                        </Flex>
                        {formik.values.services.map((e, i) => {
                          return (
                            <AddService
                              searchId={e.searchId}
                              id={e.invoiceProductId}
                              services={services}
                              setServices={setServices}
                              arrayHelpers={arrayHelpers}
                              text={e.name}
                              name={`services.${i}.price`}
                              border="1px solid #BFCFD6"
                              borderTop="0"
                              borderRadius={
                                i == formik.values.services.length - 1
                                  ? '0 0 5px 5px'
                                  : '0'
                              }
                            />
                          );
                        })}
                      </Box>
                    )}
                  </>
                )}
              />

              <CustomButton
                bg
                name={t('signup.confirm')}
                type="submit"
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={isLoading && <Spinner ms="5px" />}
                disabled={isLoading}
              />
            </Box>
          );
        }}
      </Formik>
    );
  }
);

export default AddServices;
