import { Box, Flex, Text, Image, Grid } from '@chakra-ui/react';
import { RiCloseFill } from 'react-icons/ri';

const FileNameSize = ({
  image,
  name,
  size,
  color = '#A3AEB4',
  onClose,
  isClose = true,
  iconSize,
  textSize = '12px',
  buttonW = '17px',
  ...props
}) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" {...props}>
      <Flex alignItems="center">
        <Image src={image} me="12px" w={iconSize} />
        <Text
          fontSize={textSize}
          color={color}
          overflow="hidden"
          maxW="120px"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {name}
        </Text>
      </Flex>

      <Flex alignItems="center">
        <Text me="20px" fontSize={textSize} color="#A3AEB4">
          {size}
        </Text>
        {isClose && (
          <Grid
            w={buttonW}
            h={buttonW}
            borderRadius="50%"
            bg="rgba(240,78,117,20%)"
            placeItems="center"
            fontSize="12px"
            fontWeight="bold"
            cursor="pointer"
          >
            <Box as={RiCloseFill} color="#F04E4E" onClick={onClose} />
          </Grid>
        )}
      </Flex>
    </Flex>
  );
};

export default FileNameSize;
