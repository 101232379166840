import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { GMap as M } from 'components/shared';
import { useTranslation } from 'react-i18next';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { CustomButton } from 'components/shared';

const Map = ({
  setMarker,
  marker,
  handleMarker,
  coverRange,
  errMsg,
  buttonName,
  children,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { t } = useTranslation();

  return (
    <>
      <Button
        onClick={onOpen}
        color={errMsg ? 'red' : 'green'}
        p="0"
        _hover={{ bg: 'bg.300', color: 'white', border: 'none' }}
        _focus={{ outline: 'none' }}
        _active={{ bg: 'bg.400' }}
        fontWeight="semibold"
        display="grid"
        placeItems="center"
        height="60px"
        w="100%"
        bg={errMsg ? 'red' : 'bg.300'}
        border="none"
      >
        <Flex alignItems="center">
          {buttonName ? (
            <Text me="5px" color="white">
              {buttonName}
            </Text>
          ) : null}
          <Box as={HiOutlineLocationMarker} color="white" fontSize="25px" />
        </Flex>
      </Button>
      <Modal
        isCentered
        onClose={onClose}
        isOpen={isOpen}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent maxW="900px" minH="600px">
          <ModalHeader>Location</ModalHeader>
          <ModalCloseButton _focus={{ boxShadow: 'none' }} />
          <ModalBody h="500px">
            <M
              marker={marker}
              handleMarker={handleMarker}
              coverRange={coverRange}
              setMarker={setMarker}
            />
            {children}
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              m="0"
              colorScheme="blue"
              mr={3}
              letterSpacing="1px"
              h="60px"
              w="100%"
              bg="bg.300"
              onClick={onClose}
              _hover={{ bg: '#2cbad1' }}
              _focus={{}}
              _active={{}}
            >
              {t('modals.confirm')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Map;
