// src/components/rtl-provider.js

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtl from 'stylis-plugin-rtl';
import { userContext } from 'contexts/userContext';
import { useContext } from 'react';
// NB: A unique `key` is important for it to work!
const options = {
  rtl: { key: 'css-ar', stylisPlugins: [rtl] },
  ltr: { key: 'css-en' },
};

const RtlProvider = ({ children }) => {
  const { language } = useContext(userContext);
  const direction = localStorage.getItem('i18nextLng');
  //   const { locale } = useRouter();
  const dir = language == 'ar' ? 'rtl' : 'ltr';

  const cache = createCache(options[dir]);
  return <CacheProvider value={cache} children={children} />;
};

export default RtlProvider;
