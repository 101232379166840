import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Text, Grid, Image } from '@chakra-ui/react';
import CustomDate from '../../shared/date-picker';
import ChecktimeTable from './checktime-table';
import {
  CustomButton,
  CustomModal,
  SimpleModal,
  Select,
} from 'components/shared';
import { HiOutlineUpload } from 'react-icons/hi';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Loading, Nodata, Error } from 'components/authenticated/shared';
import { getUserAttendance } from 'apis/clockin/Attendance';
import { ExportPopup } from './exportAttendance';
import { getAllShifts } from 'apis/settings';
import noShift from 'assets/images/noShift.png';
import moment from 'moment';

const Attendence = ({ id, shifts, userData }) => {
  const { t } = useTranslation();
  const { user, branchFilter, language } = useContext(userContext);
  const [date, setDate] = useState(new Date());
  const [showAdd, setShowAdd] = useState(false);
  const [shift, setShift] = useState({
    label: t('modals.selectShift'),
    value: null,
  });
  const [branch, setBranch] = useState(branchFilter);
  const dateSelected = moment(date).locale('en').format('YYYY-MM');

  console.log(shift);
  const userBranches = userData?.data?.branches?.map(e => {
    return { label: e.name, value: e.id };
  });

  const userShifts = userData?.data?.shifts?.map(e => {
    return { label: e.name, value: e.id, weekends: e.weekends };
  });

  useEffect(() => {
    setShift({
      label: userShifts[0]?.label,
      value: userShifts[0]?.value,
      weekends: userShifts[0]?.weekends,
    });
    setBranch({
      label:
        language == 'en'
          ? userBranches[0]?.label.en
          : userBranches[0]?.label.ar,
      value: userBranches[0]?.value,
    });
  }, [userData?.data]);

  //change shift select
  const handleShift = value => {
    setShift(value);
  };

  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    ['getUserAttendance', id, dateSelected, shift?.value, branch?.value],
    getUserAttendance,
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (shift?.value != undefined) {
      refetch();
    }
  }, [shift?.value, dateSelected, branch?.value]);

  //handle branch
  const handleBranch = value => {
    setBranch(value);
  };

  return (
    <Box pos="relative" minH={data?.data.length > 0 ? 'initial' : '600px'}>
      {isLoading && <Loading />}
      {isError && <Error />}
      <Flex
        pos="relative"
        mb="40px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <Box h="40px" w="180px">
            <CustomDate showMonthYearPicker setDate={setDate} />
          </Box>

          <Select
            selectTitle={t('modals.selectBranch')}
            mx={['4', '', '2', '2', '4']}
            w="180px"
            h="40px"
            label="Branch"
            select={branch}
            handleSelect={handleBranch}
            selections={userBranches}
          />

          <Select
            selectTitle={t('modals.selectShift')}
            w="180px"
            h="40px"
            label={t('modals.selectShift')}
            select={shift}
            handleSelect={handleShift}
            selections={userShifts}
          />
        </Flex>

        <Box>
          <CustomButton
            name={t('status.exportFile')}
            h="40px"
            fontSize="14px"
            bg
            icon={HiOutlineUpload}
            onClick={() => setShowAdd(true)}
            disabled={shift?.value == undefined}
          />
        </Box>
      </Flex>

      {shift?.value == undefined && (
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDir="column"
          minH="400px"
        >
          <Image src={noShift} w="350px" />
          <Text fontWeight="bold" fontSize="25px">
            {t('account.employeeNoShift')}
          </Text>
        </Flex>
      )}

      {isSuccess && (
        <>
          <ChecktimeTable
            data={data}
            datePicker={date}
            shifts={[shift]}
            shift={shift}
            createdAt={userData?.data.createdAt}
          />

          <SimpleModal
            title={t('home.employees.exportAttendanceList')}
            show={showAdd}
            setShow={setShowAdd}
            overflow="outside"
          >
            <ExportPopup
              data={userData?.data}
              shifts={shifts}
              shift={shift}
              branch={branch}
              createdAt={userData?.data.createdAt}
            />
          </SimpleModal>
        </>
      )}
    </Box>
  );
};

export default Attendence;
