import TableRow from './table-row';
import moment from 'moment';
import CustomTable from 'components/authenticated/shared/custom-table';
import { useTranslation } from 'react-i18next';
import { Nodata } from 'components/authenticated/shared';

const ChecktimeTable = ({ data, date }) => {
  const { t } = useTranslation();

  const tableData = () => {
    if (date > new Date()) {
      return null;
    } else {
      return data?.data.map(e => {
        let arrShifts = [];

        e.shifts?.map(s => {
          if (
            s?.weekends?.includes(
              new Date(moment(e).locale('en').format()).getDay()
            )
          ) {
            const weekend = {
              type: 'weekend',
              shiftName: s.name,
            };
            arrShifts.push(weekend);
          } else {
            const presentObject =
              e.attendances.length != 0 ? e.attendances[0] : false;

            if (presentObject) {
              if (presentObject.leave) {
                const leave = {
                  type: 'leave',
                  shiftName: s.name,
                  approvedBy: presentObject.approvedBy,
                };
                arrShifts.push(leave);
              } else {
                let startTime = moment(presentObject.checkIn, 'HH:mm:ss')
                  .locale('en')
                  .format('hh:mm A');

                let endTime = moment(presentObject.checkOut, 'HH:mm:ss')
                  .locale('en')
                  .format('hh:mm A');

                const start = s?.start;
                const end = s?.end;

                //diff between now and the start of shift
                const d1 = moment(new Date(presentObject?.day + ' ' + start));
                const d2 = moment(new Date());
                const diffDates = d2.diff(d1, 'minutes');

                //diff between start and end hours of shift
                const dStart = moment(
                  new Date(presentObject?.day + ' ' + start)
                );
                const dEnd = moment(new Date(presentObject?.day + ' ' + end));
                const diffMinutes = dEnd.diff(dStart, 'minutes');

                let checkoutBySystem = '--:--';

                var duration = '--:--';

                if (diffDates > diffMinutes + 30) {
                  checkoutBySystem =
                    // moment(checkoutTimeForce._d, 'HH:mm:ss').format(
                    //   'hh:mm A'
                    // ) +
                    'Checkout by system';

                  duration = moment.duration(
                    moment(s?.end, 'hh:mm:ss').diff(startTime)
                  );
                } else {
                  duration = moment.duration(
                    moment(presentObject.checkOut, 'hh:mm:ss').diff(startTime)
                  );
                }

                var workingHours = duration.asHours();

                const present = {
                  type: 'present',
                  shiftName: s.name,
                  checkIn: startTime,
                  checkOut:
                    presentObject.checkOut != null ? endTime : checkoutBySystem,
                  checkInLocation: s.name,
                  checkOutLocation: s.name,
                  lateCheckIn: presentObject.lateCheckIn,
                  earlyCheckOut: presentObject.lateCheckIn,
                  outOfRangeCheckIn: presentObject.outOfRangeCheckIn,
                  outOfRangeCheckOut: presentObject.outOfRangeCheckOut,
                  // workingHours:
                  //   presentObject.checkOut != null
                  //     ? workingHours
                  //     : checkoutBySystem,
                };
                arrShifts.push(present);
              }
            } else {
              const absent = {
                type: 'absent',
                shiftName: s.name,
              };
              arrShifts.push(absent);
            }
          }
        });

        const tableRow = (
          <TableRow
            shifts={arrShifts}
            firstName={e.firstName}
            lastName={e.lastName}
            profileImage={e.profileImageUrl}
            department={e.phoneNumber}
          />
        );
        arrShifts = [];

        return tableRow;
      });
    }
  };

  if (data?.data.length > 0) {
    return (
      <CustomTable
        headers={[
          t('modals.name'),
          t('common.shift'),
          t('common.clockin'),
          t('common.clockout'),
          t('home.workflow.workingHours'),
        ]}
      >
        {tableData()}
      </CustomTable>
    );
  }
  return <Nodata />;
};

export default ChecktimeTable;
