import { useState } from 'react';
import { Box, Flex, Text, Grid, GridItem, useToast } from '@chakra-ui/react';
import {
  Filter,
  DeletePopup,
  ActivePopup,
  Pagination,
  Loading,
  Nodata,
  Error,
  CustomToast,
} from 'components/authenticated/shared';
import { Card } from 'components/authenticated/clients';
import { Select, CustomModal, SimpleModal } from 'components/shared';
import { ClientPopup } from 'components/authenticated/clients';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getAllClients } from 'apis/client';
import { deleteClient, activateClient } from 'apis/client';
import { useTranslation } from 'react-i18next';
import Permission from 'helpers/permission';

const Clients = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const toast = useToast();
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState({
    label: t('filter.status'),
    value: null,
  });

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getAllClients', pageNumber, searchKey, status.value],
    getAllClients
  );

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState({ flag: false, data: {} });
  const [showDelete, setShowDelete] = useState({ flag: false, data: {} });
  const [showActive, setShowActive] = useState({ flag: false, data: {} });

  const handleStatus = value => {
    setPageNumber(1);
    setStatus(value);
  };

  const handleDelete = useMutation(() => deleteClient([showDelete.data.id]), {
    onSuccess: async result => {
      await queryClient.invalidateQueries('getAllClients');

      setShowDelete({ flag: false, data: {} });

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateClient')}
              description={t('toasts.deactivateClient')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateClient')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleActivate = useMutation(() => activateClient(showActive.data.id), {
    onSuccess: async result => {
      await queryClient.invalidateQueries('getAllClients');

      setShowActive({ flag: false, data: {} });

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateClient')}
              description={t('toasts.activateClient')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateClient')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handlePageClick = async data => {
    setPageNumber(data.selected + 1);
  };

  let numberOfPages = data?.data.meta.totalPages;
  let itemsPerPage = data?.data.meta.itemsPerPage;
  let currentPage = data?.data.meta.currentPage;
  let itemCount = data?.data.meta.itemCount;
  let totalItems = data?.data.meta.totalItems;

  return (
    <Box>
      <Filter
        title={t('home.clients.title')}
        buttonName={t('home.clients.addNewClient')}
        setShowAdd={setShowAdd}
        setValue={setSearchKey}
        isButtonExist={
          Permission('Clients') == 'Edit' || Permission('Clients') == null
        }
      >
        <Select
          mx="4"
          w="150px"
          h="40px"
          label="Status"
          select={status}
          handleSelect={handleStatus}
          selections={[
            { label: t('common.all'), value: null },
            { label: t('common.activated'), value: 'activated' },
            { label: t('common.deactivated'), value: 'deactivated' },
          ]}
        />
      </Filter>

      {isLoading && <Loading />}

      {isSuccess &&
        (totalItems > 0 ? (
          <>
            <Box minH="72vh">
              <Grid
                templateColumns={[
                  'repeat(4, 1fr)',
                  'repeat(4, 1fr)',
                  'repeat(3, 1fr)',
                  'repeat(3, 1fr)',
                  'repeat(4, 1fr)',
                ]}
                gap="8"
                mb="24px"
              >
                {data?.data.items.map((item, index) => (
                  <GridItem key={index}>
                    <Card
                      item={item}
                      setShowEdit={setShowEdit}
                      setShowDelete={setShowDelete}
                      setShowActive={setShowActive}
                    />
                  </GridItem>
                ))}
              </Grid>
            </Box>

            <CustomModal
              title={t('modals.editClient')}
              show={showEdit}
              setShow={setShowEdit}
            >
              <ClientPopup setShow={setShowEdit} data={showEdit.data} />
            </CustomModal>

            <CustomModal
              title={t('home.clients.deactivateClient')}
              show={showDelete}
              setShow={setShowDelete}
            >
              <DeletePopup
                handleDelete={handleDelete.mutate}
                title={t('home.clients.confirmDeactivate')}
                isLoading={handleDelete.isLoading}
              />
            </CustomModal>

            <CustomModal
              title={t('home.clients.activateClient')}
              show={showActive}
              setShow={setShowActive}
            >
              <ActivePopup
                handleActivate={handleActivate.mutate}
                title={t('home.clients.confirmActivate')}
                isLoading={handleActivate.isLoading}
              />
            </CustomModal>

            <Pagination
              handlePageClick={handlePageClick}
              numberOfPages={numberOfPages}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              itemCount={itemCount}
              totalItems={totalItems}
              forcePage={currentPage - 1}
            />
          </>
        ) : (
          <Nodata />
        ))}

      <SimpleModal
        title={t('home.clients.addNewClient')}
        show={showAdd}
        setShow={setShowAdd}
      >
        <ClientPopup setShow={setShowAdd} />
      </SimpleModal>
      {isError && <Error />}
    </Box>
  );
};

export default Clients;
