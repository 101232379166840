import * as Yup from 'yup';

const regexEn = /^[A-Za-z0-9 ]*$/;
const regexAr = /^[\u0600-\u06FF0-9 ]*$/;

export const AddBranchSchema = Yup.object().shape({
  enName: Yup.string()
    .min(3, 'signup.workshopName3Char')
    .max(100, 'signup.workshopName100Char')
    .required('signup.fieldRequired')
    .test('onlyEnglish', 'signup.onlyEnglish', name =>
      regexEn.test(name) ? true : false
    ),
  arName: Yup.string()
    .min(3, 'signup.workshopName3Char')
    .max(100, 'signup.workshopName100Char')
    .required('signup.fieldRequired')
    .test('onlyArabic', 'اسم الورشة يجب أن يكون بالعربية', name =>
      regexAr.test(name) ? true : false
    ),
  street: Yup.string()
    .min(3, 'signup.street3Char')
    .max(250, 'signup.street250Char')
    .required('signup.fieldRequired'),
  city: Yup.string()
    .min(3, 'signup.city3Char')
    .max(250, 'signup.city250Char')
    .required('signup.fieldRequired'),
    startTime1: Yup.string()
 
    .required('signup.fieldRequired'),
    endTime1: Yup.string()
 
    .required('signup.fieldRequired'),
  zipCode: Yup.string()
    .min(3, 'signup.zipCode3Char')
    .max(250, 'signup.zipCode250Char')
    .required('signup.fieldRequired'),
  commercialNumber: Yup.string()
    .required('signup.fieldRequired')
    .test('numberOfDigits', 'signup.commercial10Char', cn =>
      cn && cn.length === 10 ? true : false
    ),

    branchTimes:Yup.array().of(
      Yup.object({
        startTime: Yup.string().required('First Name is required'),
        endTime: Yup.string().required('Last Name is required'),
      })
    ),
});
