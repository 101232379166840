import { Box, Flex, Table, Tr, Td, Text } from '@chakra-ui/react';

const TableData = ({ value1, value2, notCetner, w1, bg, color1, ...props }) => {
  return (
    <Td
      px="0"
      py="2px"
      border="1px solid black"
      bg={bg ? '#e3e3e3' : 'transparent'}
      minH="50px"
      {...props}
    >
      <Text
        textAlign={notCetner ? 'initial' : 'center'}
        fontSize="12px"
        lineHeight="1.2"
        color={color1}
        w={w1}
      >
        {value1}
      </Text>
      {value2 && (
        <Text textAlign="center" fontSize="12px" lineHeight="1.2">
          {value2}
        </Text>
      )}
    </Td>
  );
};

const RowData = ({ item, number, ...props }) => {
  return (
    <Tr {...props}>
      <TableData value1={number} px="5px" h="45px" />
      <TableData notCetner w1="200px" value1={item?.name} px="5px" />
      <TableData value1={item?.price} px="5px" />
      <TableData value1={item?.quantity} px="5px" />
      <TableData value1={item?.taxableAmount} px="5px" />
      <TableData value1={item?.taxAmount} px="5px" />
      <TableData value1={item?.totalInc} px="5px" />
    </Tr>
  );
};

const InvoiceInfo = ({ data, ...props }) => {
  return (
    <Table border="1px solid black" {...props}>
      <Box
        as="caption"
        textAlign="center"
        py="5px"
        fontWeight="semibold"
        border="1px solid black"
        borderBottom="none"
        bg="#e3e3e3"
      >
        <Text textAlign="center" fontSize="12px" lineHeight="1.2">
          تفاصيل الفاتورة
        </Text>
        <Text textAlign="center" fontSize="12px" lineHeight="1.2">
          Invoice Details
        </Text>
      </Box>

      <Tr>
        <TableData value1="#" />
        <TableData value1=" المنتجات" value2="items" />
        <TableData value1="سعر الوحدة" value2="unit cost" />
        <TableData value1="الكمية" value2="quantity" />
        <TableData value1="المبلغ الخاضع للضريبة" value2="Taxable Amount" />
        <TableData value1="قيمة الضريبة" value2="Tax Amount" />
        <TableData value1="الإجمالي شامل الضريبة" value2="Total incl. TAX" />
      </Tr>
      {data?.map((item, i) => {
        return <RowData number={i + 1} item={item} />;
      })}

      {/* <RowData number="2" />
      <RowData number="3" />
      <RowData number="4" />
      <RowData number="5" />
      <RowData number="6" />
      <RowData number="7" /> */}
      {/* <RowData number="8" /> */}
      {/* <RowData number="9" />
      <RowData number="10" /> */}

      {/* <RowData />
      <RowData bg="#e8e8e8" /> */}
    </Table>
  );
};

export default InvoiceInfo;
