import { useState } from 'react';
import { Box, Grid, Text, Flex, Textarea, Spinner } from '@chakra-ui/react';
import { Select, CustomInput, Label, CustomButton } from 'components/shared';
import UploadAttachments from './upload-attachments';
import { AiOutlineUpload } from 'react-icons/ai';
import { createTicket } from 'apis/tickets';
import { useMutation } from 'react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const CreateTicket = ({ setShow, refetch }) => {
  const { t } = useTranslation();

  const [select, setSelect] = useState({
    label: t('ticket.selectIssue'),
    value: '',
  });
  const [subject, setSubject] = useState(null);
  const [uploadFiles, setUploadFiles] = useState(null);
  // const [error, setEerror] = useState(select != null && false);

  const selections = [
    { label: t('status.incident'), value: 'incident' },
    { label: t('status.service'), value: 'service' },
    { label: t('status.suggestion'), value: 'suggestions' },
  ];

  const { mutate, isLoading } = useMutation(createTicket, {
    onSuccess: () => {
      setShow(false);
      refetch();
    },
  });

  const user = localStorage.getItem('user');
  const userId = JSON.parse(user).user.id;

  return (
    <Box>
      <Grid gridTemplateColumns="repeat(2,1fr)" gap="24px">
        <Box>
          <Label text={t('ticket.ticketType')} />
          <Select
            h="60px"
            selections={selections}
            select={select}
            handleSelect={e => setSelect(e)}
            label={t('ticket.selectIssue')}
          />
        </Box>
        <CustomInput
          placeholder={moment(new Date())
            .locale(t('common.lang'))
            .format('DD MMM yyyy')}
          h="60px"
          label={t('home.clockin.requestedAt')}
          isReadOnly
        />
      </Grid>

      <Grid templateColumns="repeat(2,1fr)" gap="24px" mt="35px">
        <Box>
          <Label text={t('project.subject')} />
          <Textarea
            minH="124px"
            border="1px solid #BFCFD6"
            borderRadius="10px"
            p="10px"
            placeholder={t('ticket.writeProblemHere')}
            onChange={e => setSubject(e.target.value)}
            resize="none"
            _focus={{}}

            //   value={formik.values.comment}
            //   onChange={formik.handleChange('comment')}
            //   onBlur={formik.handleBlur('comment')}
          ></Textarea>
        </Box>

        <Box>
          <Label text={t('ticket.uploadAttachmens')} />
          <Box border="1px solid #BFCFD6" p="10px" borderRadius="8px" h="124px">
            <UploadAttachments setUploadFiles={setUploadFiles} />
          </Box>
        </Box>
      </Grid>

      <Flex justifyContent="flex-end" mt="53px">
        <CustomButton
          name={t('myProfile.cancel')}
          h="48px"
          mt="20px"
          w="165px"
          me="10px"
          borderColor="#F43838"
          color="#F43838"
          hoverBg="#F43838"
          activeBg="#F43838"
          textTransform="uppercase"
          onClick={() => setShow(false)}
        />
        <CustomButton
          name={t('account.save')}
          bg
          mt="20px"
          h="48px"
          w="165px"
          textTransform="uppercase"
          type="submit"
          loading={isLoading && <Spinner ms="5px" />}
          display="flex"
          alignItems="center"
          justifyContent="center"
          isDisabled={
            select == null || subject == null || subject == '' || isLoading
          }
          onClick={() => {
            mutate({
              type: select.value,
              description: subject,
              userId: userId,
              attachments: uploadFiles,
            });
          }}
        />
      </Flex>
    </Box>
  );
};

export default CreateTicket;
