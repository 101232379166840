import { Box, Flex, Text, Spinner } from '@chakra-ui/react';
import { CustomButton } from 'components/shared';
import { Save } from 'components/authenticated/shared';
import { useTranslation } from 'react-i18next';

const ActivePopup = ({ title, handleActivate, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Flex justifyContent="center">
      <Box maxW="70%">
        <Text>{title}</Text>

        <CustomButton
          name={t('account.activate')}
          bg
          h="60px"
          mt="30px"
          loading={isLoading && <Spinner ms="5px" />}
          disabled={isLoading}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleActivate}
        />
      </Box>
    </Flex>
  );
};

export default ActivePopup;
