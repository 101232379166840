import { Box, Grid, Tr, Td, Text, Flex } from '@chakra-ui/react';

import { ImArrowDownRight2 } from 'react-icons/im';
import { MdLocationOn } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { StatusBox } from 'components/authenticated/shared';

const Weekend = ({ shift, i }) => {
  const { t } = useTranslation();

  return (
    <Grid
      gridTemplateColumns="2fr 2fr 2fr 2fr"
      borderTop={i != 0 && '1px solid #edf2f7'}
      py="22px"
    >
      <Box>{shift}</Box>
      <StatusBox text={t('status.weekend')} status="searchId" />
      {/* <Box
        as="span"
        fontSize="12px"
        bg="bg.1700"
        py="10px"
        borderRadius="10px"
        color="text.200"
        display="Grid"
        placeItems="center"
        w="100px"
        fontWeight="bold"
      >
        {t('status.weekend')}
      </Box> */}
    </Grid>
  );
};

export default Weekend;
