import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Text, Grid, useToast, Input } from '@chakra-ui/react';
import {
  CustomInput,
  Label,
  CustomButton,
  MultiSelect,
  SimpleModal,
} from 'components/shared';
import {
  PickTime,
  CustomDate,
  SelectSearch,
  ViewService,
} from 'components/authenticated/shared';

import AddService from 'components/authenticated/projects/add-service';
import AddPart from 'components/authenticated/projects/add-part';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

const ProjectView = ({ item }) => {
  const { t } = useTranslation();
  const { language } = useContext(userContext);

  const servicesArray = item?.invoice.invoiceProducts.filter(e => {
    return e.type == 'service';
  });

  const partsArray = item?.invoice.invoiceProducts.filter(e => {
    return e.type == 'part';
  });

  return (
    <Flex justifyContent="center">
      <Box maxW="75%">
        <CustomInput
          label={t('project.client')}
          value={item.customer.name}
          mb="20px"
          isReadOnly
        />

        <Grid gridTemplateColumns="repeat(3,1fr)" gap={5} mb="20px">
          <CustomInput
            label={t('project.maker')}
            value={item.carDetails.carManufactur}
            isReadOnly
          />
          <CustomInput
            label={t('project.model')}
            value={item.carDetails.carModel}
            isReadOnly
          />
          <CustomInput
            label={t('project.year')}
            value={item.carDetails.carYear}
            isReadOnly
          />
        </Grid>

        <Box mb="20px">
          <CustomInput
            label={t('project.branches')}
            value={item.branch.branchName.en}
            isReadOnly
          />
        </Box>

        {/* <Grid gridTemplateColumns="repeat(2,1fr)" gap={6} mb="20px">
          <Box>
            <Label text="select date" />
            <CustomDate
              h="60px"
              bg="white"
              border="1px solid #E2E8F0"
              handleDate={value => formik.setFieldValue('date', value)}
              placeholder="Enter Date"
              defaultDate={item?.dueDate}
              maxDate={new Date()}
              dateFormat="dd MMMM yyyy"
            />
            {formik.errors.date && formik.touched.date && (
              <ErrorMessage errorMessage={formik.errors.date} />
            )}
          </Box>
          <Box>
            <Label text="select time" />

            <PickTime
              h="60px"
              bg="white"
              border="1px solid #E2E8F0"
              handleTime={value => formik.setFieldValue('time', value)}
              defaultTime={item?.dueDate}
            />
            {formik.errors.time && formik.touched.time && (
              <ErrorMessage errorMessage={formik.errors.time} />
            )}
          </Box>
        </Grid> */}

        <Box w="full" mb="20px">
          <CustomInput
            type="text"
            label={t('project.subject')}
            value={item.description}
            isReadOnly
          />
        </Box>

        <Box w="full">
          {servicesArray.length > 0 && (
            <Text fontSize="14px" mb="5px" fontWeight="bold">
              {t('project.services')}
            </Text>
          )}

          <Box mb="24px">
            <Box mb="20px">
              {servicesArray.length > 0 ? (
                <ViewService services={servicesArray} />
              ) : null}
            </Box>
          </Box>
        </Box>

        <Box w="full">
          {partsArray.length > 0 && (
            <Text fontSize="14px" mb="5px" fontWeight="bold">
              {t('home.storage.title')}
            </Text>
          )}

          <Box mb="24px">
            <Box mb="20px">
              {partsArray.length > 0 && (
                <Flex
                  bg="#21C6E1"
                  color="white"
                  p="10px"
                  borderRadius="5px 5px 0 0"
                  borderBottom="1px solid #b7b8bb"
                >
                  <Box w="25%">{t('home.storage.id')}</Box>
                  <Box w="30%">{t('home.storage.name')}</Box>
                  <Box w="35%">{t('home.storage.quantity')}</Box>
                  <Box w="30%">{t('home.projects.price')}</Box>
                </Flex>
              )}

              {partsArray.map(e => {
                return (
                  <Flex p="10px" bg="#f9fafb">
                    <Box w="25%">{e.product.sku}</Box>
                    <Box w="30%">
                      <Text
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        maxW="100px"
                      >
                        {language == 'en' ? e.product.name : e.product.nameAr}
                      </Text>
                    </Box>
                    <Box w="35%">
                      <Input
                        type="number"
                        h="30px"
                        w="75px"
                        p="10px"
                        _focus={{}}
                        value={e.quantity}
                        isReadOnly
                      />
                      {/* <Text fontSize="13px" color={isBig ? 'red' : 'initial'}>
                        {quantity} is the available
                      </Text> */}
                    </Box>
                    <Box w="30%">
                      <Input
                        type="number"
                        h="30px"
                        w="75px"
                        p="10px"
                        value={e.price}
                        _focus={{}}
                        isReadOnly
                      />
                    </Box>
                  </Flex>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default ProjectView;
