import { useState } from 'react';
import { Box, Flex, Grid } from '@chakra-ui/react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Card from './card';
import { BiPlus } from 'react-icons/bi';
import { SimpleModal } from 'components/shared';
import { TaskPopup } from 'components/authenticated/tasks';
import { useTranslation } from 'react-i18next';
import Permission from 'helpers/permission';

const UnassignedTasks = ({
  items,
  column,
  tasks,
  refetch,
  isDropDisabled,
  works,
  setWorks,
  isDragDisabled,
  branch,
}) => {
  const [showAdd, setShowAdd] = useState(false);

  const { t } = useTranslation();

  const isEditable =
    Permission('Workflow') == 'Edit' || Permission('Workflow') == null;

  return (
    <Box
      mb="70px"
      pos="relative"
      _after={{
        content: `""`,
        pos: 'absolute',
        w: '100%',
        h: '1px',
        left: '0',
        bottom: '-40px',
        bg: '#CCD5ED',
      }}
    >
      <Droppable
        // key="characters"
        direction="horizontal"
        droppableId={column?.id}
        isDropDisabled={isDropDisabled}
      >
        {(provided, snapshot) => {
          return (
            <Flex
              {...provided.droppableProps}
              ref={provided.innerRef}
              justifyContent="flex-start"
              alignItems="center"
              maxW="100%"
              overflowX="auto"
              minH="345px"

              // maxH="250px"
              // overflowY="auto"
              // gridTemplateColumns="repeat(3,400px)"
              // mb="20px"
              // gap="20px"
            >
              {items?.length == 0 && (
                <Grid
                  bg="white"
                  minH="230px"
                  maxH="100%"
                  minW="400px"
                  // ms="15px"
                  borderRadius="10px"
                  placeItems="center"
                  cursor={isEditable ? 'pointer' : 'not-allowed'}
                  onClick={() => {
                    if (isEditable) {
                      setShowAdd(true);
                    }
                  }}
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    w="60px"
                    h="60px"
                    borderRadius="50%"
                    bg="rgba(33,198,255,.2)"
                  >
                    <Box as={BiPlus} color="text.1000" fontSize="30px" />
                  </Flex>
                </Grid>
              )}

              {items?.map((item, index) => {
                return (
                  <Draggable
                    draggableId={item?.id}
                    key={item?.id}
                    index={index}
                    isDragDisabled={isDragDisabled}
                  >
                    {(provided, snapshot) => {
                      return (
                        <Box
                          // key={items.id}

                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            userSelect: 'none',
                            // padding: '4px 16px',
                            paddingInlineEnd: '16px',
                            minHeight: '50px',
                            ...provided.draggableProps.style,
                          }}
                        >
                          <Card
                            data={item}
                            works={works}
                            setWorks={setWorks}
                            branch={branch}
                            refetch={refetch}
                          />
                        </Box>
                      );
                    }}
                  </Draggable>
                );
              })}

              {provided.placeholder}
            </Flex>
          );
        }}
      </Droppable>

      <SimpleModal
        title={t('home.workflow.addNewTask')}
        show={showAdd}
        setShow={setShowAdd}
      >
        <TaskPopup setShow={setShowAdd} />
      </SimpleModal>
    </Box>
  );
};

export default UnassignedTasks;
