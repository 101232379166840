import * as Yup from 'yup';

export const createInvoiceValidation = Yup.object().shape({
  client: Yup.string().required('signup.fieldRequired'),

  // subject: Yup.string().required('signup.fieldRequired'),
  services: Yup.array().of(
    Yup.object().shape({
      price: Yup.string().required('signup.fieldRequired'), // these constraints take precedence
    })
  ),
  // .min(3, 'Minimum of 3 friends'),
  parts: Yup.array().of(
    Yup.object().shape({
      price: Yup.string().required('signup.fieldRequired'), // these constraints take precedence
      quantity: Yup.string().required('signup.fieldRequired'), // these constraints take precedence
    })
  ),

  // .min(3, 'Minimum of 3 friends'),
});
