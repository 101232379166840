import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Grid, Text, Flex } from '@chakra-ui/react';
import {
  AddCatgeoryModal,
  ServiceCard,
} from 'components/authenticated/main-categories';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import arabicDate from 'moment-arabic';
import { CustomButton, SimpleModal, Select } from 'components/shared';
import { useQuery } from 'react-query';
import { getAllCategories } from 'apis/categories';
import { Loading, Nodata } from 'components/authenticated/shared';
import { AiOutlinePlus } from 'react-icons/ai';

const MainCategories = () => {
  const { user, branchFilter } = useContext(userContext);
  const { t } = useTranslation();
  const [showAddCat, setShowAddCat] = useState(false);
  const [showLoad, setShowLoad] = useState(false);

  const [branch, setBranch] = useState(branchFilter);

  useEffect(() => {
    setBranch(branchFilter);
  }, [branchFilter]);

  arabicDate();

  const { data, isLoading, isFetching, isError, refetch } = useQuery(
    ['getAllCategories', branch?.value],
    getAllCategories
  );

  function checkNestedChildren(child) {
    if (child.services && child.services.length > 0) {
      for (let product of child.services) {
        if (product.branchProduct && product.branchProduct.length > 0) {
          return true; // Found non-empty branchProduct in child object
        }
      }
    }

    if (child.children && child.children.length > 0) {
      for (let nestedChild of child.children) {
        if (checkNestedChildren(nestedChild)) {
          return true; // Recursively check nested children
        }
      }
    }

    return false; // No non-empty branchProduct found
  }

  function isParentActive(data) {
    // Check if any of the services in the current level are active
    if (
      data.services &&
      data.services.some(
        service =>
          service.branchProduct &&
          service.branchProduct.some(branch => branch.isActive)
      )
    ) {
      return true;
    }

    // Check if any of the children have an active service
    if (data.children && data.children.some(child => isParentActive(child))) {
      return true;
    }

    return false;
  }

  function hasServices(obj) {
    if (obj.services && obj.services.length > 0) {
      return true; // Services found in current object
    }

    if (obj.children && obj.children.length > 0) {
      // Check children recursively
      for (let i = 0; i < obj.children.length; i++) {
        if (hasServices(obj.children[i])) {
          return true; // Services found in any child
        }
      }
    }

    return false; // No services found in current object or its children
  }

  //handle branch
  const handleBranch = value => {
    setBranch(value);
  };

  return (
    <Box>
      <Flex justifyContent="space-between">
        <Box>
          <Text
            fontSize={['80%', '', '35px', '35px', '40px']}
            fontWeight="black"
            color="text.1800"
          >
            {t('categories.mainCategories')}
          </Text>
          <Text
            fontSize="14px"
            color="#4b4e50"
            // textAlign="center"
            whiteSpace="nowrap"
          >
            {moment(new Date())
              .locale(t('common.lang'))
              .format('hh:mm A | DD-MM-YYYY')}
            {/* .format('hh:mm A | MMM DD, yyyy')} */}
          </Text>
        </Box>

        <Flex alignItems="center">
          <Select
            selectTitle={t('modals.selectBranch')}
            mx={['4', '', '2', '2', '4']}
            w="170px"
            h="60px"
            label="Branch"
            select={branch}
            handleSelect={handleBranch}
            selections={user.user.branches?.map((item, index) => ({
              label: item.name,
              value: item.id,
            }))}
          />
          <CustomButton
            name={t('categories.addCategory')}
            icon={AiOutlinePlus}
            bg
            w="260px"
            onClick={() => setShowAddCat(true)}
          />
        </Flex>
      </Flex>

      {isLoading && <Loading />}

      {!isLoading ? (
        data?.data[0]?.children.length > 0 ? (
          <Grid mt="30px" gridTemplateColumns="repeat(3,1fr)" gap="20px">
            {data?.data[0]?.children.map(c => {
              const isActive = c.services ? isParentActive(c) : false;
              const isThereServices = hasServices(c);

              return (
                <ServiceCard
                  isCustom={c.custom}
                  id={c.id}
                  name={c.name}
                  nameAr={c?.nameAr}
                  iconPath={c?.iconPath}
                  childrens={c.children}
                  services={c?.services}
                  isActive={isActive}
                  refetchCategories={refetch}
                  setShowLoad={setShowLoad}
                  isThereServices={isThereServices}
                  branch={branch?.value}
                />
              );
            })}
          </Grid>
        ) : (
          <Nodata />
        )
      ) : null}

      {/* {showLoad ? (
        <Box
          w="100%"
          h="100%"
          pos="fixed"
          bg="rgba(255,255,255,0.8)"
          top="0"
          left="0"
          zIndex="11"
        >
          <Loading />
        </Box>
      ) : null} */}

      <SimpleModal
        title={t('categories.addCategory')}
        show={showAddCat}
        setShow={setShowAddCat}
        maxW="800px"
      >
        <AddCatgeoryModal
          refetchCategories={refetch}
          setShowModal={setShowAddCat}
        />
      </SimpleModal>
    </Box>
  );
};

export default MainCategories;
