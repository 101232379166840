import { useState, useRef, useContext } from 'react';
import {
  Box,
  Text,
  Tr,
  Td,
  Checkbox,
  Flex,
  useToast,
  Tooltip,
  Button,
} from '@chakra-ui/react';

import {
  Filter,
  Table,
  DeletePopup,
  ActivePopup,
  CardPopover,
  Pagination,
  Loading,
  Nodata,
  Error,
  CustomToast,
  CustomSwitch,
} from 'components/authenticated/shared';
import {
  Select,
  CustomModal,
  MultiSelect,
  SimpleModal,
} from 'components/shared';
import ServiceSwitch from './service-switch';
import { ServicePopup } from 'components/authenticated/services';
import { useMutation } from 'react-query';
import { activateService, deactivateService } from 'apis/service';
import { deleteProduct } from 'apis/catlog/Product';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';
import { userContext } from 'contexts/userContext';

const ServiceRow = ({
  item,
  refetchServices,
  isCheck,
  setIsCheck,
  branchId,
  categoriesData,
  selectedBranch,
}) => {
  const { t } = useTranslation();
  const { language } = useContext(userContext);

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [showChosseBranchModal, setShowChosseBranchModal] = useState(false);

  const toast = useToast();

  const selectRef = useRef();
  const switchRef = useRef();

  const branches = item?.totalBranches;

  const selectedBranchesIds = selectedBranch?.map(e => {
    return e.value;
  });

  const isActive = branches?.find(e => {
    return selectedBranchesIds.find(b => {
      return b == e?.branch.id;
    });
  });

  const isBranchActive = branches?.some(b => b.isActive === true);

  const { mutate: deactivate, isLoading: loadingDeactivateService } =
    useMutation(deactivateService, {
      onSuccess: () => {
        refetchServices();

        setShowDelete(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deactivateService')}
                description={t('toasts.deactivateService')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deactivateService')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    });

  const { mutate: activate, isLoading: loadingActivateService } = useMutation(
    activateService,
    {
      onSuccess: () => {
        refetchServices();

        setShowActive(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.activateService')}
                description={t('toasts.activateService')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.activateService')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const handleClickCheck = e => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  return (
    <Tr
      bg={item.deletedAt ? 'gray.200' : 'white'}
      transition="0.3s"
      _hover={{ bg: '#f8f8f8' }}
      cursor="pointer"
      onClick={e => {
        if (
          e.target.localName != 'input' &&
          e.target.localName != 'span' &&
          e.target.localName != 'svg' &&
          e.target.localName != 'polyline'
        ) {
          setShowEdit(true);
        }
      }}
    >
      {Permission('Products') == 'Edit' && (
        <Td>
          {item.deletedAt == null && (
            <Checkbox
              id={item.id}
              onChange={e => handleClickCheck(e)}
              isChecked={isCheck.includes(item.id)}
              ref={selectRef}
            ></Checkbox>
          )}
        </Td>
      )}

      <Td>{item.sku}</Td>
      <Td>
        <Text
          maxW="190px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {language == 'en' ? item?.name : item?.nameAr}
        </Text>
      </Td>
      <Td>
        <Tooltip
          hasArrow
          borderRadius="5px"
          label={
            <Box>
              <Text mb="2" textAlign="center">
                {t('modals.branchList')}
              </Text>

              {item.totalBranches.map((elm, ind) => (
                <Text>
                  {t('modals.branch')} {ind + 1}:{' '}
                  {language == 'en' ? elm.branch.name.en : elm.branch.name.ar}
                </Text>
              ))}
            </Box>
          }
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            bg="bg.800"
            color="text.200"
            borderRadius="10px"
            w="32px"
            h="32px"
            fontWeight="bold"
            fontSize="14px"
            cursor="default"
          >
            <Text>{item.totalBranches.length}</Text>
          </Flex>
        </Tooltip>
      </Td>
      <Td>
        <Text
          maxW="190px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {language == 'en' ? item?.category?.name : item?.category?.nameAr}
        </Text>
      </Td>
      {Permission('Products') == 'Edit' && (
        <Td>
          <ServiceSwitch
            isActive={isBranchActive}
            item={item}
            setShowDelete={setShowDelete}
            setShowActive={setShowActive}
            setShowChosseBranchModal={setShowChosseBranchModal}
            showChosseBranchModal={showChosseBranchModal}
            position="relative"
            top="0"
            branchSelected={selectedBranch}
            refetchServices={refetchServices}
          />
          {/* <CardPopover
        item={item}
        setShowDelete={setShowDelete}
        setShowEdit={setShowEdit}
        setShowActive={setShowActive}
        pos="relative"
        inset="0"
      /> */}
        </Td>
      )}

      <SimpleModal
        title={t('modals.deleteService')}
        show={showDelete}
        setShow={setShowDelete}
      >
        <DeletePopup
          handleDelete={() => deactivate({ products: [item.id], branchId })}
          title={t('modals.deleteServiceText')}
          isLoading={loadingDeactivateService}
        />
      </SimpleModal>

      <SimpleModal
        title={t('modals.activateService')}
        show={showActive}
        setShow={setShowActive}
      >
        <ActivePopup
          handleActivate={() =>
            activate({
              price: item?.price,
              applicationPrice: item?.applicationPrice,
              productId: item?.id,
              branchId,
            })
          }
          title={t('modals.confirmActivateService')}
          isLoading={loadingActivateService}
        />
      </SimpleModal>

      <SimpleModal
        title={t('modals.editService')}
        show={showEdit}
        setShow={setShowEdit}
      >
        <ServicePopup
          setShow={setShowEdit}
          data={item}
          categoriesData={categoriesData}
          categoryId={item?.category?.id}
          refetchServices={refetchServices}
        />
      </SimpleModal>
    </Tr>
  );
};

export default ServiceRow;
