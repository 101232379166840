import { useState, useRef } from 'react';
import { Box, Grid, Text, Button } from '@chakra-ui/react';
import { CustomDate, ExportAttendance } from 'components/authenticated/shared';
import { CustomButton } from 'components/shared';
import ExcelTable from './excelTable';
import { useQuery } from 'react-query';
import { getUserAttendance } from 'apis/clockin/Attendance';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

const ExportPopup = ({ data, shifts, shift, branch, createdAt }) => {
  const { t } = useTranslation();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: 'Users Attendance',
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);

  return (
    <>
      <Box>
        <Grid templateColumns="repeat(2,1fr)" gap={6}>
          <Box>
            <Text
              textTransform="capitalize"
              color="text.600"
              fontSize="14px"
              mb="5px"
              fontWeight="semibold"
            >
              {t('common.from')}
            </Text>
            <CustomDate
              selectsStart
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/dd/yyyy"
              setDate={setStartDate}
              maxDate={new Date()}
            />
          </Box>
          <Box>
            <Text
              textTransform="capitalize"
              color="text.600"
              fontSize="14px"
              mb="5px"
              fontWeight="semibold"
            >
              {t('common.to')}
            </Text>
            <CustomDate
              selectsEnd
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              dateFormat="MM/dd/yyyy"
              setDate={setEndDate}
            />
          </Box>
        </Grid>

        <Button
          h="60px"
          bg="bg.300"
          borderRadius="15px"
          w="100%"
          color="white"
          mt="50px"
          _hover={{}}
          _focus={{ outline: 'none' }}
          _active={{}}
          onClick={handlePrint}
        >
          {t('common.export')}
        </Button>
      </Box>

      <Box display="none">
        <ExcelTable
          shift={shift}
          branch={branch}
          data={data}
          datePicker={startDate}
          endDate={endDate}
          ref={componentRef}
        />
      </Box>
    </>
  );
};

export default ExportPopup;
