import { Box, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const UserMessage = ({ message, date }) => {
  const { t } = useTranslation();

  return (
    <Box
      bg="#21C6E1"
      p="10px"
      borderRadius="10px"
      w="325px"
      ms="auto"
      pos="relative"
      mb="25px"
    >
      <Text color="white" lineH="17.4px" fontSize="14px">
        {message}
      </Text>

      <Text
        fontSize="12px"
        color="#666668"
        pos="absolute"
        bottom="-20px"
        right="0"
      >
        {moment(date)
          .locale(t('common.lang'))
          .format('DD MMMM, yyyy | hh:mm A')}
      </Text>
    </Box>
  );
};

export default UserMessage;
