import * as Yup from 'yup';

export const NewPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(6, 'signup.password6Char')
    .max(100, 'signup.password100Char')
    .required('signup.fieldRequired'),
  newPassword: Yup.string()
    .min(6, 'signup.password6Char')
    .max(100, 'signup.password100Char')
    .required('signup.fieldRequired'),
  confirmPassword: Yup.string()
    .min(6, 'signup.password6Char')
    .max(100, 'signup.password100Char')
    .required('signup.fieldRequired')
    .oneOf([Yup.ref('newPassword'), null], 'signup.passwordsMatched'),
});
