import { useState, useRef } from 'react';
import {
  Tr,
  Td,
  Checkbox,
  Flex,
  Text,
  useToast,
  Box,
  Link,
  Spinner,
} from '@chakra-ui/react';
import {
  DeletePopup,
  CustomPopover,
  CustomToast,
} from 'components/authenticated/shared';
import { SimpleModal, Invoice } from 'components/shared';
import { AiFillSetting, AiFillPrinter } from 'react-icons/ai';
import { getInvoice } from 'apis/invoice/invoice';
// import { ProjectPopup } from 'components/authenticated/projects';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { motion } from 'framer-motion';
//import EditSalary from './edit-modal';

const RowInvoiceTemplate = ({
  data,
  indexStart,
  item,
  index,
  refetch,
  ...props
}) => {

  const { t } = useTranslation();

  // const { data } = useQuery(['getInvoice', item?.invoiceId], getInvoice);

  const CellData = ({ value, ...props }) => {
    return (
      <Td
        textTransform="uppercase"
        textAlign="center"
        border="1px solid black"
        p="5px"
      >
        <Text {...props}>{value}</Text>
      </Td>
    );
  };

  return (
    <>
      {data?.map((v, i) => {
        return (
          <Tr border="1px solid black" p="0" {...props}>
            <CellData value={indexStart + i + 1} />
            <CellData
              value={moment(v.date).locale('en').format('DD-MM-YYYY')}
            />
            <CellData value={v.invoiceNumber} />
            <CellData
              value={v.customer}
              maxW="130px"
              whiteSpace="normal"
              textAlign="center"
              m="auto"
            />
            <CellData
              value={v.net.toFixed(2) + ' ' + 'SR'}
            />
            <CellData
              value={v.branch}
              maxW="130px"
              whiteSpace="normal"
              textAlign="center"
              m="auto"
            />
          </Tr>
        );
      })}
    </>
  );
};

export default RowInvoiceTemplate;
