import { Box, Flex } from '@chakra-ui/react';
import { BiPlus } from 'react-icons/bi';

const AddNew = ({ ...props }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bg="bg.200"
      h="100%"
      borderRadius="10px"
      cursor="pointer"
      transition=".3s"
      _hover={{ bg: '#e4e6e9' }}
      {...props}
    >
      <Flex
        alignItems="center"
        justifyContent="center"
        w="60px"
        h="60px"
        borderRadius="50%"
        bg="rgba(33,198,255,.2)"
      >
        <Box as={BiPlus} color="text.1000" fontSize="30px" />
      </Flex>
    </Flex>
  );
};

export default AddNew;
