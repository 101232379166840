import { Box, Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TableHeader = ({ shiftName, branchName, from, to }) => {
  const { t } = useTranslation();

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Box>
        <Text fontSize="20px" fontWeight="bold">
          Autoshop Name
        </Text>
        <Box border="1px solid #EAF4FF" mb="3px" borderRadius="5px" p="5px">
          <Text color="#181F27" fontSize="14px">
            Branch :{' '}
            <Text as="span" fontWeight="bold">
              {branchName}
            </Text>
          </Text>
        </Box>

        <Box border="1px solid #EAF4FF" borderRadius="5px" p="5px">
          <Text color="#181F27" fontSize="14px">
            Shift:{' '}
            <Text as="span" fontWeight="bold">
              {shiftName}
            </Text>
          </Text>
        </Box>
      </Box>

      <Box>
        <Text fontWeight="bold" color="#181F27" fontSize="25px">
          Attendance Report
        </Text>
      </Box>

      <Flex alignItems="center">
        <Box
          bg="#EAF4FF"
          color="#1BA3EF"
          px="30px"
          py="9px"
          fontWeight="bold"
          textAlign="center"
          borderRadius="10px"
          h="41px"
        >
          {moment(from).format('MMMM D yyyy')}
        </Box>

        <Text fontWeight="bold" color="#616871" fontSize="15px" mx="8px">
          {t('common.to')}
        </Text>

        <Box
          bg="#EAF4FF"
          color="#1BA3EF"
          px="30px"
          py="9px"
          fontWeight="bold"
          textAlign="center"
          borderRadius="10px"
          h="41px"
        >
          {moment(to).format('MMMM D yyyy')}
        </Box>
      </Flex>
    </Flex>
  );
};

export default TableHeader;
