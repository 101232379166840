import ExcelJS from 'exceljs';
import { Button, Spinner, useToast } from '@chakra-ui/react';
import { CustomToast } from 'components/authenticated/shared';
import { useMutation } from 'react-query';
import { exportInvoice } from 'apis/invoice/invoice';
import moment from 'moment';
import { CustomButton } from 'components/shared';
import { useTranslation } from 'react-i18next';

const InvoiceToExcel = ({
  data,
  companyName,
  from,
  to,
  branch,
  customerName,
}) => {
  const toast = useToast();
  const { t } = useTranslation();
  // const { user } = JSON.parse(localStorage.getItem('user'));
  // const branches = user?.branches;

  // const mainCompany = branches?.find(e => {
  //   return e.isDefault == 'true';
  // });

  const branchName = () => {
    if (typeof branch?.label == 'object') {
      return branch?.label?.en;
    }
    return branch?.label;
  };

  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('My Sheet');

  /*TITLE*/
  sheet.mergeCells('A1', 'F3');
  const titleCell = sheet.getCell('A1');
  titleCell.value = {
    richText: [
      {
        text: `Sales Transactions: ${companyName}\n`,

        font: { size: 16, bold: true, name: 'Arial' },
        // alignment: { wrapText: true },
      },
      {
        text: `Branch:${branchName()}`,
        font: { size: 11, bold: true, name: 'Arial' },
        // alignment: { wrapText: true },
      },
    ],
  };

  sheet.mergeCells('A4', 'B5');
  const titleCell2 = sheet.getCell('A4');
  titleCell2.value = {
    richText: [
      {
        text: 'Type\n',

        font: { size: 10, bold: true, name: 'Arial' },
        // alignment: { wrapText: true },
      },
      {
        text: 'Sales transactions',
        font: { size: 8, bold: true, name: 'Arial' },
        // alignment: { wrapText: true },
      },
    ],
  };

  sheet.mergeCells('C4', 'D5');
  const titleCell3 = sheet.getCell('C4');
  titleCell3.value = {
    richText: [
      {
        text: 'Date Period\n',

        font: { size: 10, bold: true, name: 'Arial' },
        // alignment: { wrapText: true },
      },
      {
        text: `${moment(from).locale('en').format('DD-MM-YYYY')} - ${moment(to)
          .locale('en')
          .format('DD-MM-YYYY')}`,
        font: { size: 8, bold: true, name: 'Arial' },
        // alignment: { wrapText: true },
      },
    ],
  };

  sheet.mergeCells('E4', 'F5');
  const titleCell4 = sheet.getCell('E4');
  titleCell4.value = {
    richText: [
      {
        text: 'Customer\n',

        font: { size: 10, bold: true, name: 'Arial' },
        // alignment: { wrapText: true },
      },
      {
        text: customerName,
        font: { size: 8, bold: true, name: 'Arial' },
        // alignment: { wrapText: true },
      },
    ],
  };

  //   const titleCell2 = sheet.getCell('A1');

  // Make the title text bold
  //   titleCell.font = { bold: true, size: 24 };

  titleCell.alignment = {
    vertical: 'top',
    // horizontal: 'center',
    wrapText: true,
  };

  titleCell2.alignment = {
    vertical: 'top',
    // horizontal: 'center',
    wrapText: true,
  };

  titleCell3.alignment = {
    vertical: 'top',
    // horizontal: 'center',
    wrapText: true,
  };

  titleCell4.alignment = {
    vertical: 'top',
    // horizontal: 'center',
    wrapText: true,
  };

  /*Column headers*/
  sheet.getRow(6).values = [
    '#',
    'Date',
    'Invoice No.',
    'Customer',
    'Amount',
    'Branch',
  ];

  const values = ['A', 'B', 'C', 'D', 'E', 'F'];

  sheet.getRow(6)._cells.map((e, i) => {
    sheet.mergeCells(`${values[i]}6`, `${values[i]}7`);
    e.border = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    };

    e.font = {
      bold: true,
    };
  });
  /*Define your column keys because this is what you use to insert your data
according to your columns, they're column A, B, C, D respectively being idClient, Name, Tel, and Adresse.
So, it's pretty straight forward */

  const columnStyle = {
    font: { bold: true },
    alignment: { vertical: 'middle', horizontal: 'center' },
  };

  sheet.columns = [
    { key: 'id', width: 5, ...columnStyle },
    { key: 'date', width: 15, ...columnStyle },
    { key: 'invoiceNumber', width: 18, ...columnStyle },
    { key: 'customer', width: 26, ...columnStyle },
    { key: 'amount', width: 26, ...columnStyle },
    { key: 'branch', width: 26, ...columnStyle },
  ];

  sheet.getRow(6).alignment = { vertical: 'middle', horizontal: 'center' };

  /* Now we use the keys we defined earlier to insert your data by iterating through arrData
  and calling worksheet.addRow()
  */

  const download = () => {
    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = 'download.xlsx';
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const invoicesData = data?.data?.data;
  const salesAmount = data?.data?.salesTotalWithTax.toFixed(2);

  invoicesData.forEach(function (item, index) {
    sheet.addRow({
      id: index + 1,
      date: new Date(item.date),
      invoiceNumber: item.invoiceNumber,
      customer: item.customer,
      amount: (item.amount + item.amount * 0.15).toFixed(2),
      branch: item.branch,
    });

    sheet.getRow(index + 1 + 7).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    };

    sheet.getRow(index + 1 + 7)._cells.map(e => {
      e.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } },
      };
    });

    sheet.getRow(index + 1 + 7)._cells[4].numFmt = '#,##0.00 "SR"';
  });

  sheet.addRow({
    customer: 'Sales Total',
    amount: salesAmount,
  });

  sheet.getRow(invoicesData.length + 8)._cells[3].alignment = {
    vertical: 'middle',
    horizontal: 'right',
  };

  sheet.getRow(invoicesData.length + 8)._cells[4].alignment = {
    vertical: 'middle',
    horizontal: 'center',
  };

  sheet.getRow(invoicesData.length + 8)._cells[4].numFmt = '#,##0.00 "SR"';

  // const { mutate, isLoading } = useMutation(exportInvoice, {
  //   onSuccess: res => {
  //     const invoicesData = res?.data?.data;
  //     const salesAmount = res?.data?.salesTotal;

  //     if (invoicesData?.length > 0) {
  //       invoicesData.forEach(function (item, index) {
  //         sheet.addRow({
  //           id: item.id,
  //           date: new Date(item.date),
  //           invoiceNumber: item.invoiceNumber,
  //           customer: item.customer,
  //           amount: item.amount,
  //           branch: item.branch,
  //         });

  //         sheet.getRow(index + 1 + 7).alignment = {
  //           vertical: 'middle',
  //           horizontal: 'center',
  //         };

  //         sheet.getRow(index + 1 + 7)._cells.map(e => {
  //           e.border = {
  //             top: { style: 'thin', color: { argb: '000000' } },
  //             left: { style: 'thin', color: { argb: '000000' } },
  //             bottom: { style: 'thin', color: { argb: '000000' } },
  //             right: { style: 'thin', color: { argb: '000000' } },
  //           };
  //         });

  //         sheet.getRow(index + 1 + 7)._cells[4].numFmt = '#,##0.00 "SR"';
  //       });

  //       sheet.addRow({
  //         customer: 'Sales Total',
  //         amount: salesAmount,
  //       });

  //       sheet.getRow(invoicesData.length + 8)._cells[3].alignment = {
  //         vertical: 'middle',
  //         horizontal: 'right',
  //       };

  //       sheet.getRow(invoicesData.length + 8)._cells[4].alignment = {
  //         vertical: 'middle',
  //         horizontal: 'center',
  //       };

  //       sheet.getRow(invoicesData.length + 8)._cells[4].numFmt =
  //         '#,##0.00 "SR"';

  //       download();
  //     } else {
  //       toast({
  //         render: props => {
  //           return (
  //             <CustomToast
  //               title={'export invoices'}
  //               description={'There is no data to export'}
  //               status="success"
  //               onClose={props.onClose}
  //             />
  //           );
  //         },
  //       });
  //     }
  //   },
  //   onError: () => {
  //     toast({
  //       render: props => {
  //         return (
  //           <CustomToast
  //             title={'export invoices'}
  //             description={'Something went wrong'}
  //             status="error"
  //             onClose={props.onClose}
  //           />
  //         );
  //       },
  //     });
  //   },
  // });

  return (
    <CustomButton
      bg
      name={t('accounting.downloadFile')}
      onClick={download}
      mt="20px"
      // display="flex"
      // alignItems="center"
      // loading={isLoading ? <Spinner ms="5px" /> : null}
      // disabled={isLoading}
      // cursor={isLoading ? 'not-allowed' : 'pointer'}
    />

    // <Button
    //   h="60px"
    //   bg="bg.300"
    //   borderRadius="15px"
    //   w="100%"
    //   color="white"
    //   mt="50px"
    //   _hover={{}}
    //   _focus={{ outline: 'none' }}
    //   _active={{}}

    //   textTransform="capitalize"
    //   disabled={isLoading}
    //   cursor={isLoading ? 'not-allowed' : 'pointer'}
    // >
    //   export excel
    //   {isLoading ? <Spinner ms="5px" /> : null}
    // </Button>
  );
};

export default InvoiceToExcel;
