import request from 'axios-interceptors';

export const getAllWorkflows = ({ queryKey }) => {
  let formatedBranch = !!queryKey[3] ? [queryKey[3]] : null;

  return request.get(
    `workflow/appointments/day/${queryKey[1]}?searchKey=${queryKey[2]}&branch=${formatedBranch}`
  );
};

export const getWorkflowById = workflowId => {
  return request.get(`workflow/appointments/${workflowId}`);
};

export const assignTaskToUser = ({ workflowId, userId, workingHours }) => {
  return request.put(`workflow/assignUser/${workflowId}`, {
    userId: userId,
    expectedTime: workingHours,
  });
};

export const unassignTaskToUser = workflowId => {
  return request.put(`workflow/unassignUser/${workflowId}`);
};

export const updateWorkflowStatus = ({ workflowId, workflowStatus }) => {
  return request.put(`workflow/updateStatus/${workflowId}`, {
    workflowState: workflowStatus,
  });
};

export const getAllUsersTasks = ({ queryKey }) => {
  let formatedBranch = !!queryKey[1] ? [queryKey[1]] : null;

  return request.get(`iam/users/tasks?branch=${formatedBranch}`);
};

export const deleteWorkflow = workflowId => {
  return request.post(`workflow/appointments/delete`, {
    workflowIds: workflowId,
  });
};

export const checkRateWorkflow = ({ workflowId }) => {
  return request.post(`workflow/isRated/${workflowId}`);
};

export const rateWorkflow = ({ stars, comment, workflowId, slug }) => {
  return request.post(`workflow/rate/${workflowId}`, {
    rating: stars,
    notes: comment,
  });
};

export const getRating = userId => {
  return request.get(`workflow/rate/data/${userId}`);
};
