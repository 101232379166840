import { useEffect } from 'react';
import {
  ListItem,
  Box,
  UnorderedList,
  Link,
  useDisclosure,
  Collapse,
  Flex,
} from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import { IoIosArrowDown } from 'react-icons/io';

const MenuItem = ({
  name,
  label,
  subMenus,
  icon,
  sideIsOpen,
  to,
  openSide,
  changeId,
  activeLink,
}) => {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!sideIsOpen) {
      onClose();
    }

    if (activeLink != name) {
      onClose();
    }
  }, [sideIsOpen, activeLink]);

  useEffect(() => {
    if (pathname.includes(name.toLowerCase()) && sideIsOpen) {
      onOpen();
    } else {
    }
  }, []);

  return (
    <ListItem
      listStyleType="none"
      mb={subMenus ? '5px' : '0'}
      whiteSpace="nowrap"
    >
      <NavLink
        to={to}
        className={({ isActive }) => (isActive ? 'activeClassName' : undefined)}
      >
        <Link
          end
          transition=".3s"
          display="flex"
          alignItems="center"
          justifyContent={sideIsOpen ? 'space-between' : 'center'}
          borderRadius={subMenus && isOpen ? '15px 15px 0 0' : '15px'}
          p="15px"
          color={isOpen ? 'text.200' : 'text.100'}
          bg={isOpen ? 'bg.100' : 'initial'}
          mb={subMenus ? '0' : '5px'}
          // _activeLink={{ bg: 'bg.100', color: 'text.200' }}
          _focus={{ boxShadow: 'none' }}
          _hover={{ textDecor: 'none' }}
          onClick={() => {
            if (subMenus) {
              onToggle();
              openSide();
            }
            changeId(name);
          }}
        >
          <Flex alignItems="center">
            <Box
              fontSize=" 20px"
              m={sideIsOpen ? '0' : 'auto'}
              me={sideIsOpen ? '15px' : 'auto'}
            >
              {icon}
            </Box>
            <Box
              as="span"
              textTransform="capitalize"
              fontSize="18px"
              lineHeight="20px"
              display={!sideIsOpen ? 'none' : 'inline'}
            >
              {label}
            </Box>
          </Flex>
          {subMenus && subMenus.length > 0 ? (
            <Box
              as={IoIosArrowDown}
              colort="text.500"
              transition=".3s"
              display={sideIsOpen ? 'auto' : 'none'}
              transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
            />
          ) : (
            false
          )}
        </Link>
      </NavLink>

      {subMenus && subMenus.length > 0 ? (
        <Collapse in={isOpen} animateOpacity>
          <UnorderedList
            transition=".3s"
            overflow="hidden"
            background="bg.100"
            ps="50px"
            borderRadius="0 0 15px 15px"
            m="0"
            pb="10px"
          >
            {subMenus.map((menu, index) => (
              <ListItem listStyleType="none" pt="5px" key={index}>
                <NavLink to={menu.to}>
                  <Link
                    as={NavLink}
                    color="text.100"
                    fontSize="16px"
                    lineHeight="30px"
                    to={menu.to}
                    _activeLink={{ fontWeight: 'bold', color: 'text.300' }}
                    _focus={{ boxShadow: 'none' }}
                    _hover={{ textDecor: 'none' }}
                  >
                    {menu.name}
                  </Link>
                </NavLink>
              </ListItem>
            ))}
          </UnorderedList>
        </Collapse>
      ) : null}
    </ListItem>
  );
};

export default MenuItem;
