import { useState } from 'react';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  Checkbox,
} from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Select } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

const MultiSelect = ({
  label,
  selections,
  isOpen,
  select,
  handleSelect,
  isDisabled,
  isLoadingSelections,
  disapledCheck,
  labelForMulti,
  labelForSingle,
  ...props
}) => {
  // const data = selections.map(e => {
  //   return { label: e.label, value: e.value };
  // });

  //selections is array of objects each object contain label and value
  //select is object
  //handle select store object in select

  const { t } = useTranslation();

  return (
    <Box {...props}>
      <Menu>
        <MenuButton
          as={Button}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          h="full"
          border="1px solid #BFCFD6"
          borderColor="#BFCFD6"
          borderRadius="10px"
          outline="none"
          bg="transparent"
          color="text.1100"
          fontWeight="normal"
          _focus={{
            border: '1px solid #a5c6d5',
            boxShadow: 'none',
            bg: 'transparent',
          }}
          _active={{
            bg: 'transparent',
          }}
          _hover={{
            bg: 'transparent',
          }}
          _disabled={{
            opacity: 1,
            cursor: 'not-allowed',
          }}
          isDisabled={isDisabled}
        >
          <Flex alignItems="center" justifyContent="space-between">
            {select?.length > 0 ? (
              <Text>
                {select.length > 1
                  ? select.length + ' ' + labelForMulti
                  : select.length +
                    ' ' +
                    (labelForSingle != undefined ? labelForSingle : label)}
              </Text>
            ) : (
              <Text>{label}</Text>
            )}

            <Box as={MdKeyboardArrowDown} color="black" />
          </Flex>
        </MenuButton>

        <MenuList maxH="200px" overflowY="auto">
          {isLoadingSelections && (
            <MenuItem
              display="block"
              textAlign="center"
              isDisabled={true}
              _disabled={{ opacity: '0.7', cursor: 'default' }}
              _active={{}}
            >
              {t('common.loading')}...
            </MenuItem>
          )}

          {selections?.map((e, index) => {
            return (
              <MenuItem key={Math.random()} closeOnSelect={false} p="0">
                <Box width="full" height="full">
                  <Checkbox
                    width="full"
                    height="full"
                    p="10px"
                    isChecked={
                      select?.filter(elm => elm.value === e.value).length > 0
                    }
                    onChange={event =>
                      handleSelect(e, event.target.checked, index)
                    }
                    isDisabled={disapledCheck}
                  >
                    <Text
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      maxW="200px"
                    >
                      {e.label}
                    </Text>
                  </Checkbox>
                </Box>
              </MenuItem>
            );
          })}
          {selections?.length == 0 && (
            <MenuItem
              display="block"
              textAlign="center"
              isDisabled={true}
              _disabled={{ opacity: '0.7', cursor: 'default' }}
              _active={{}}
            >
              {t('account.noData')}
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      {/* <Select
        isMulti
        options={data}
        placeholder="Select some colors..."
        closeMenuOnSelect={false}
        hasStickyGroupHeaders
      /> */}
    </Box>
  );
};

export default MultiSelect;
