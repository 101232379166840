import request from 'axios-interceptors';

export const checkEmailValidity = email => {
  return request.put('iam/users/isValidEmail', {
    emailAddress: email,
  });
};

export const checkPhoneValidity = phone => {
  return request.put('iam/users/isValidPhoneNumber', {
    phoneNumber: '+' + phone,
  });
};

export const sendVerification = email => {
  return request.put('iam/auth/sendEmailAddressConfirmationUrl', {
    emailAddress: email,
  });
};

// export const createWorkshop = (
//   personalForm,
//   personalImage,
//   workshopForm,
//   workshopImage,
//   marker,
//   country
// ) => {
//   let formatedPhone =
//     personalForm.phone.indexOf('+') !== -1
//       ? personalForm.phone
//       : `+${personalForm.phone}`;

//   return request.post('iam/tenants/provision', {
//     name: {
//       ar: workshopForm.workshopNameAR,
//       en: workshopForm.workshopNameEN,
//     },
//     location: {
//       lat: marker.lat,
//       long: marker.lng,
//     },
//     city: workshopForm.city,
//     street: workshopForm.street,
//     zipCode: workshopForm.zipCode,
//     country: country,
//     commercialNumber: workshopForm.commercialNumber,
//     imagePath: workshopImage ? workshopImage : '',
//     superAdmin: {
//       phoneNumber: formatedPhone,
//       password: personalForm.password,
//       firstName: personalForm.firstName,
//       lastName: personalForm.lastName,
//       emailAddress: personalForm.email,
//       roles: [],
//       profileImagePath: personalImage ? personalImage : '',
//     },
//   });
// };

export const createWorkshop = data => {
  return request.post('iam/tenants/provision', data);
};
