import { Box, Input, Text } from '@chakra-ui/react';

const CustomInput = ({ type, label, textOpacity, placeholder, ...props }) => {
  return (
    <Box>
      <Text
        textTransform="capitalize"
        color="text.600"
        fontSize="14px"
        mb="5px"
        fontWeight="semibold"
        opacity={textOpacity}
      >
        {label}
      </Text>
      <Input
        type={type}
        placeholder={placeholder}
        w="100%"
        h="60px"
        border="1px solid #BFCFD6"
        borderColor="#BFCFD6"
        borderRadius="10px"
        outline="none"
        _focus={{ border: '1px solid #a5c6d5', boxShadow: 'none' }}
        {...props}
      />
    </Box>
  );
};

export default CustomInput;
