import { useState, useContext, useEffect, useRef } from 'react';
import { userContext } from 'contexts/userContext';
import {
  Box,
  Text,
  Tr,
  Td,
  Checkbox,
  Flex,
  useToast,
  Tooltip,
  Button,
} from '@chakra-ui/react';
import {
  Filter,
  Table,
  DeletePopup,
  ActivePopup,
  CardPopover,
  Pagination,
  Loading,
  Nodata,
  Error,
  CustomToast,
  CustomSwitch,
} from 'components/authenticated/shared';
import {
  Select,
  CustomModal,
  MultiSelect,
  SimpleModal,
} from 'components/shared';
import {
  ServicePopup,
  ServiceRow,
  ChosseBranchModal,
} from 'components/authenticated/services';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getAllServices,
  getAllPagesServices,
  activateService,
} from 'apis/service';
import { deactivateService } from 'apis/service';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';
import { getAllCategories } from 'apis/categories';

const Services = () => {
  const { t } = useTranslation();
  const { user, branchFilter, language } = useContext(userContext);
  const toast = useToast();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState({ label: '', value: null });

  const [branch, setBranch] = useState([branchFilter]);

  useEffect(() => {
    if (typeof branchFilter?.label == 'object') {
      setBranch([{ label: branchFilter.label.en, value: branchFilter.value }]);
    } else {
      setBranch([{ label: branchFilter.label, value: branchFilter.value }]);
    }

    //this logic was used with multi select
    // if (branchFilter.value == null) {
    //   setBranch([]);
    // } else {
    //   setBranch([{ label: branchFilter.label.en, value: branchFilter.value }]);
    // }
  }, [branchFilter]);

  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    ['getAllServices', pageNumber, searchKey, branch, status.value],
    getAllServices
  );

  const [showAdd, setShowAdd] = useState(false);

  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showChosseBranchModal, setShowChosseBranchModal] = useState(false);
  const [cat, setCat] = useState({ label: 'select cat', value: null });
  const [subSelected, setSubSelected] = useState({
    name: 'select sub',
    value: null,
  });

  const [sub, setSub] = useState(null);

  const {
    data: categoriesD,
    isLoading: isLoadCat,
    isError: isErrorCat,
  } = useQuery(['getAllCategories', branch[0]?.value], getAllCategories);

  const catData = categoriesD?.data[0]?.children;

  const handleCat = value => {
    setCat(value);
    setSubSelected({
      name: 'subcatgory',
      value: null,
    });
    if (value.children.length > 0) {
      setSub(value.children);
    } else {
      setSub(null);
    }
  };

  const handleSub = value => {
    setSubSelected(value);
  };

  const handleStatus = value => {
    setPageNumber(1);
    setStatus(value);
  };

  const handleBranch = (value, check) => {
    setPageNumber(1);
    setBranch([value]);

    // this logic for multi select
    // if (!check) {
    //   let filteredBranches = branch.filter(elm => elm.value !== value.value);
    //   if (branch.length == 1) {
    //     return false;
    //   } else {
    //     setBranch(filteredBranches);
    //   }
    // } else setBranch(prevState => [...prevState, value]);
  };

  const { mutate, isLoading: loading } = useMutation(deactivateService, {
    onSuccess: async result => {
      refetch();
      setIsCheck([]);
      setShowDeleteAll(false);

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.removeMultiServices')}
              description={t('toasts.removeMultiServices')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.removeMultiServices')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handlePageClick = async data => {
    setPageNumber(data.selected + 1);
  };

  let numberOfPages = data?.data.meta.totalPages;
  let itemsPerPage = data?.data.meta.itemsPerPage;
  let currentPage = data?.data.meta.currentPage;
  let itemCount = data?.data.meta.itemCount;
  let totalItems = data?.data.meta.totalItems;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(
      data?.data.items.filter(e =>
        e.totalBranches.some(b => b.isActive === true)
      )
      // data?.data.items.filter(e => {
      //   return e.deletedAt == null;
      // })
    );
  }, [data]);

  const headers = [
    'check',
    t('modals.id'),
    t('modals.name'),
    t('modals.branch'),
    t('categories.category'),
    'option',
  ];
  const headersWithoutChech = [
    t('modals.id'),
    t('modals.name'),
    t('modals.branch'),
    t('categories.category'),
  ];

  return (
    <>
      <Filter
        title={t('home.services.title')}
        buttonName={t('home.services.addNewService')}
        setShowAdd={setShowAdd}
        setValue={setSearchKey}
        isCheck={isCheck}
        setShowDelete={
          branch?.length > 1 ? setShowChosseBranchModal : setShowDeleteAll
        }
        titleDelete={t('home.services.title')}
        btResponsiveWidth="170px"
        isButtonExist={Permission('Products') == 'Edit'}
        searchWidth="180px"
      >
        {/* <Select
          mx={['4', '', '1', '2']}
          w={['150px', '', '115px', '150px']}
          h="40px"
          select={status}
          selectTitle="Categories"
          label={t('modals.status')}
          handleSelect={handleStatus}
          selections={[
            { label: t('common.all'), value: null },
            { label: t('common.activated'), value: 'activated' },
            { label: t('common.deactivated'), value: 'deactivated' },
          ]}
        /> */}

        {/* {sub !== null ? (
          <Select
            mx={['4', '', '5px', '']}
            w={['150px', '', '110px', '115px']}
            h="40px"
            select={subSelected}
            label={'subcategorie'}
            labelVariable="name"
            handleSelect={handleSub}
            selections={sub}
          />
        ) : null}

        <Select
          mx={['4', '', '5px', '']}
          w={['150px', '', '110px', '115px']}
          h="40px"
          select={cat}
          label={'categorie'}
          labelVariable="name"
          handleSelect={handleCat}
          selections={catData}
        /> */}

        <Select
          mx={['4', '', '5px', '']}
          w={['150px', '', '110px', '115px']}
          h="40px"
          select={status}
          label={t('modals.status')}
          handleSelect={handleStatus}
          selections={[
            { label: t('common.all'), value: null },
            { label: t('common.activated'), value: 'activated' },
            { label: t('common.deactivated'), value: 'deactivated' },
          ]}
        />

        <Select
          mx={['4', '', '5px', '']}
          w={['150px', '', '110px', '115px']}
          h="40px"
          label={t('modals.branch')}
          select={branch[0]}
          handleSelect={handleBranch}
          selections={user.user.branches?.map((item, index) => ({
            label: language == 'en' ? item.name.en : item.name.ar,
            value: item.id,
          }))}
        />
      </Filter>

      {isLoading && <Loading />}

      {isSuccess &&
        (totalItems > 0 ? (
          <>
            <Box minH="72vh">
              <Table
                headers={
                  Permission('Products') == 'Edit'
                    ? headers
                    : headersWithoutChech
                }
                theadHeight="71px"
                mb="24px"
                setIsCheckAll={setIsCheckAll}
                setIsCheck={setIsCheck}
                isCheckAll={isCheckAll}
                list={list}
                length={data?.data.items.length}
                isCheck={isCheck}
              >
                {data?.data.items.map((item, index) => (
                  <ServiceRow
                    item={item}
                    key={index}
                    refetchServices={refetch}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    branchId={branch[0]?.value}
                    categoriesData={categoriesD}
                    selectedBranch={branch}
                  />
                ))}
              </Table>
            </Box>

            <SimpleModal
              title={t('modals.deleteServices')}
              show={showDeleteAll}
              setShow={setShowDeleteAll}
            >
              <DeletePopup
                handleDelete={() =>
                  mutate({ products: isCheck, branchId: branch[0].value })
                }
                title={t('modals.confirmDeleteServices')}
                isLoading={loading}
              />
            </SimpleModal>

            <Pagination
              handlePageClick={handlePageClick}
              numberOfPages={numberOfPages}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              itemCount={itemCount}
              totalItems={totalItems}
              forcePage={currentPage - 1}
            />
          </>
        ) : (
          <Nodata />
        ))}

      <SimpleModal
        title={t('home.services.addNewService')}
        show={showAdd}
        setShow={setShowAdd}
      >
        <ServicePopup
          setShow={setShowAdd}
          categoriesData={categoriesD}
          refetchServices={refetch}
        
        />
      </SimpleModal>

      <SimpleModal
        title={'which branch do you want to activate/deactivate this service?'}
        show={showChosseBranchModal}
        setShow={setShowChosseBranchModal}
      >
        <ChosseBranchModal
          branches={branch}
          items={isCheck}
          refetchServices={refetch}
        />
      </SimpleModal>

      {isError && <Error />}
    </>
  );
};

export default Services;
