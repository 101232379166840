import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email('signup.invalidEmail')
    .max(250, 'signup.email250Char')
    .required('signup.fieldRequired'),
  password: Yup.string()
    .min(6, 'signup.password6Char')
    .max(100, 'signup.password100Char')
    .required('signup.fieldRequired'),
});
