import React from 'react';
import { Table as T, Tr, Td, Text, Flex, Box } from '@chakra-ui/react';
import { Table, ExportAttendance } from 'components/authenticated/shared';
import TableRow from '../checktime-table/table-row';
// import Weekend from '../checktime-table/weekend';
import moment from 'moment';
import { CustomTable } from 'components/authenticated/shared';

// import Absent from '../checktime-table/absent';

const pageStyle = `
@media all {
  .page-break {
    display: none;
    overflow:scroll;
    page-break-before: always
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .pagebreak {    break-inside: avoid; } 
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
   
  }
}

@page {
  size: auto;
  margin: 10mm;

}
`;

class ExcelTable extends React.Component {
  render() {
    var getDaysBetweenDates = function (startDate, endDate) {
      var now = startDate.clone(),
        dates = [];

      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('MM/DD/YYYY'));
        now.add(1, 'days');
      }
      return dates;
    };

    let s = moment(this.props.datePicker);
    let e = moment(this.props.endDate);
    let dateRange = getDaysBetweenDates(s, e);

    return (
      <Box {...this.props}>
        <style type="text/css" media="print">
          {pageStyle}
        </style>
        <ExportAttendance
          arrDays={dateRange}
          arrUsers={[this.props.data]}
          shift={this.props.shift}
          branch={this.props.branch}
          from={this.props.datePicker}
          to={this.props.endDate}
        />
        {/* <CustomTable
          headers={['Name', 'Shift', 'Clock In', 'Clock Out', 'Working H’rs']}
        >
          {tableData()}
        </CustomTable> */}
      </Box>
    );
  }
}

// const ExcelTable = ({ data, shift, datePicker, endDate, branch, ...props }) => {
//   var getDaysBetweenDates = function (startDate, endDate) {
//     var now = startDate.clone(),
//       dates = [];

//     while (now.isSameOrBefore(endDate)) {
//       dates.push(now.format('MM/DD/YYYY'));
//       now.add(1, 'days');
//     }
//     return dates;
//   };

//   var s = moment(datePicker);
//   var e = moment(endDate);

//   var dateRange = getDaysBetweenDates(s, e);

//   return (
//     <Box {...props}>
//       <ExportAttendance
//         arrDays={dateRange}
//         arrUsers={data.data}
//         shift={shift}
//         branch={branch}
//         from={datePicker}
//         to={endDate}
//       />
//       {/* <CustomTable
//         headers={['Name', 'Shift', 'Clock In', 'Clock Out', 'Working H’rs']}
//       >
//         {tableData()}
//       </CustomTable> */}
//     </Box>
//   );
// };

export default ExcelTable;
