import { useState } from 'react';
import {
  Text,
  Box,
  Flex,
  Grid,
  useToast,
  Spinner,
  Checkbox,
} from '@chakra-ui/react';
import {
  CustomInput,
  UploadImage,
  Select,
  Map,
  ErrorMsg,
  CustomButton,
  Countries,
  CustomUploadFile,
} from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { useMutation, useQueryClient } from 'react-query';
import { Formik, Form } from 'formik';
import { WorkshopSchema } from 'schemas/employee/WorkshopSchema';
import { updateTenantInfo } from 'apis/iam/Tenant';
import { useTranslation } from 'react-i18next';
import defaultLogo from 'assets/images/defaultLogo.png';
import defaultBranch from 'assets/images/defaultBranch.png';

//import Styles
import './style.css';

const Workshop = ({ data }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [read, setRead] = useState(false);

  const toast = useToast();

  const [errors, setErrors] = useState([]);
  const [marker, setMarker] = useState('');
  const [country, setCountry] = useState({
    label: data ? data.country : null,
    value: data ? data.country : null,
  });

  // const [marker, setMarker] = useState(
  //   data
  //     ? {
  //         lat: data?.location?.lat,
  //         lng: data?.location?.long || data?.location?.lng,
  //       }
  //     : null
  // );

  // const handleMarker = e => {
  //   let lat = e.latLng.lat();
  //   let lng = e.latLng.lng();

  //   setMarker({ lat, lng });
  // };

  const handleCountry = e => {
    setCountry(e);
  };

  const handleSubmit = useMutation(
    ({ values, country, marker, workshopImage }) =>
      updateTenantInfo(values, country, marker, workshopImage),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getTenantInfo');

        setErrors([]);
        setRead(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateWorkshop')}
                description={t('toasts.updateWorkshop')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateWorkshop')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box py="8" ml="8" maxW="800px">
      <Formik
        initialValues={{
          workshopNameEN: data.name.en,
          workshopNameAR: data.name.ar,
          street: data.street,
          countrySelected: data.country,
          city: data.city,
          zipCode: data.zipCode,
          buildingNumber: data?.buildingNumber || '',
          districtName: data?.district || '',
          additionalNumber: data?.additionalNumber || '',
          commercialNumber: data.commercialNumber,
          taxNumber: data.taxNumber || '',
          isTaxable: data.isTaxable || false,
          mapSelected: data.mapSelected || { lat: data.location.x, lng: data.location.y },
          workshopImage:
            data?.imagePath != null
              ? (data?.imagePath).replace(
                  `${process.env.REACT_APP_API_BASE_URL}/uploads/image/`,
                  ''
                )
              : null,
          workshopLogo:
            data?.logoPath != null
              ? data?.logoPath?.replace(
                  `${process.env.REACT_APP_API_BASE_URL}/uploads/image/`,
                  ''
                )
              : null,
        }}
        validationSchema={WorkshopSchema}
        onSubmit={values => {
          handleSubmit.mutate({
            values,
            country,
          });
        }}
      >
        {formik => {
          return (
            <Form>
              <Grid gridTemplateColumns="repeat(2,1fr)">
                {/* <UploadImage
            
              textTitle={t('account.uploadPhoto')}
              helperText={t('account.workshopPicture')}
              defaultImage={data?.imagePath}
              formikChange={e => formik.setFieldValue('workshopImage', e)}
              disabled={!read}
            /> */}

                <CustomUploadFile
                  radius={false}
                  textTitle={t('account.uploadPhoto')}
                  helperText={t('account.workshopPicture')}
                  defaultImage={data?.imagePath || defaultBranch}
                  formikChange={e => formik.setFieldValue('workshopImage', e)}
                  disabled={!read}
                  width="196px"
                />

                <CustomUploadFile
                  radius={false}
                  helperText={t('account.workshopLogo')}
                  textTitle={t('account.uploadLogo')}
                  defaultImage={data?.logoPath || defaultLogo}
                  formikChange={e => formik.setFieldValue('workshopLogo', e)}
                  disabled={!read}
                />

                {/* <UploadImage
              helperText={t('account.workshopLogo')}
              textTitle={t('account.uploadLogo')}
              defaultImage={data?.logoPath || defaultLogo}
              formikChange={e => formik.setFieldValue('workshopLogo', e)}
              disabled={!read}
            /> */}
                {/* <UploadFile src={data?.imagePath} handleFile={handleFile} />
            <UploadFile src={data?.imagePath} handleFile={handleFile} /> */}
              </Grid>

              <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px" mb="24px">
                <Box>
                  <CustomInput
                    type="text"
                    label={`${t('account.workshopName')} (EN)`}
                    placeholder="Enter your workshop name"
                    value={formik.values.workshopNameEN}
                    onChange={formik.handleChange('workshopNameEN')}
                    onBlur={formik.handleBlur('workshopNameEN')}
                    isReadOnly={read ? false : true}
                  />
                  {formik.errors.workshopNameEN &&
                    formik.touched.workshopNameEN && (
                      <ErrorMsg text={t(formik.errors.workshopNameEN)} />
                    )}
                </Box>

                <Box>
                  <CustomInput
                    type="text"
                    label={`${t('account.workshopName')} (AR)`}
                    placeholder="Enter your workshop name"
                    value={formik.values.workshopNameAR}
                    onChange={formik.handleChange('workshopNameAR')}
                    onBlur={formik.handleBlur('workshopNameAR')}
                    isReadOnly={read ? false : true}
                  />
                  {formik.errors.workshopNameAR &&
                    formik.touched.workshopNameAR && (
                      <ErrorMsg text={t(formik.errors.workshopNameAR)} />
                    )}
                </Box>
              </Grid>

              <Box mb="24px">
                <Map
                  buttonName="choose location"
                  errMsg={
                    formik.errors.mapSelected && formik.touched.mapSelected
                      ? true
                      : false
                  }
                  marker={marker}
                  handleMarker={(values, country, city, street, zipcode) => {
                    setMarker(values);
                    formik.setFieldValue('mapSelected', values);
                    formik.setFieldValue('city', city);
                    formik.setFieldValue('countrySelected', country);
                    formik.setFieldValue('street', street);
                    formik.setFieldValue('zipCode', zipcode);
                  }}
                />
              </Box>

              <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px" mb="24px">
                <Box>
                  <CustomInput
                    type="text"
                    label={t('signup.country')}
                    placeholder={t('home.clients.enterCity')}
                    value={formik.values.countrySelected}
                    onChange={formik.handleChange('countrySelected')}
                    onBlur={formik.handleBlur('countrySelected')}
                    disabled
                    _disabled={{ opacity: '0.8', cursor: 'not-allowed' }}
                  />
                  {/* <Box>
                    <Text fontSize="14px" mb="5px" fontWeight="bold">
                      {t('signup.country')}
                    </Text>
                    <Select
                      h="60px"
                      mb="24px"
                      label="Country"
                      select={country}
                      handleSelect={handleCountry}
                      selections={Countries.map(elm => ({
                        label: elm.name,
                        value: elm.name,
                      }))}
                    />
                  </Box> */}
                </Box>

                <Box>
                  <CustomInput
                    type="text"
                    label={t('signup.city')}
                    placeholder={t('home.clients.enterCity')}
                    value={formik.values.city}
                    onChange={formik.handleChange('city')}
                    onBlur={formik.handleBlur('city')}
                    isReadOnly={read ? false : true}
                  />
                  {formik.errors.city && formik.touched.city && (
                    <ErrorMsg text={t(formik.errors.city)} />
                  )}
                </Box>
              </Grid>

              <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px" mb="24px">
                <Box>
                  <CustomInput
                    type="text"
                    label={t('signup.street')}
                    placeholder={t('home.clients.enterStreet')}
                    value={formik.values.street}
                    onChange={formik.handleChange('street')}
                    onBlur={formik.handleBlur('street')}
                    isReadOnly={read ? false : true}
                  />
                  {formik.errors.street && formik.touched.street && (
                    <ErrorMsg text={t(formik.errors.street)} />
                  )}
                </Box>
                <Box>
                  <CustomInput
                    type="number"
                    label={t('signup.zipCode')}
                    placeholder={t('home.clients.enterZipcode')}
                    value={formik.values.zipCode}
                    onChange={formik.handleChange('zipCode')}
                    onBlur={formik.handleBlur('zipCode')}
                    isReadOnly={read ? false : true}
                  />
                  {formik.errors.zipCode && formik.touched.zipCode && (
                    <ErrorMsg text={t(formik.errors.zipCode)} />
                  )}
                </Box>
              </Grid>

              <Grid gridTemplateColumns="repeat(3,1fr)" gap="25px" mb="24px">
                <Box>
                  <CustomInput
                    type="number"
                    label={'Building Number'}
                    placeholder={'Building Number'}
                    value={formik.values.buildingNumber}
                    onChange={formik.handleChange('buildingNumber')}
                    onBlur={formik.handleBlur('buildingNumber')}
                    isReadOnly={read ? false : true}
                  />
                  {formik.errors.buildingNumber &&
                    formik.touched.buildingNumber && (
                      <ErrorMsg text={t(formik.errors.buildingNumber)} />
                    )}
                </Box>
                <Box>
                  <CustomInput
                    type="text"
                    label={'District Name'}
                    placeholder={'District Name'}
                    value={formik.values.districtName}
                    onChange={formik.handleChange('districtName')}
                    onBlur={formik.handleBlur('districtName')}
                    isReadOnly={read ? false : true}
                  />
                  {formik.errors.districtName &&
                    formik.touched.districtName && (
                      <ErrorMsg text={t(formik.errors.districtName)} />
                    )}
                </Box>

                <Box>
                  <CustomInput
                    type="number"
                    label={'Additional Number'}
                    placeholder={'Additional Number'}
                    value={formik.values.additionalNumber}
                    onChange={formik.handleChange('additionalNumber')}
                    onBlur={formik.handleBlur('additionalNumber')}
                    isReadOnly={read ? false : true}
                  />
                  {formik.errors.additionalNumber &&
                    formik.touched.additionalNumber && (
                      <ErrorMsg text={t(formik.errors.additionalNumber)} />
                    )}
                </Box>
              </Grid>

              <Box w="full" mb="24px">
                <CustomInput
                  type="text"
                  label={t('account.commercialNumber')}
                  placeholder={t('home.clients.enterCommercial')}
                  value={formik.values.commercialNumber}
                  onChange={formik.handleChange('commercialNumber')}
                  onBlur={formik.handleBlur('commercialNumber')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.commercialNumber &&
                  formik.touched.commercialNumber && (
                    <ErrorMsg text={t(formik.errors.commercialNumber)} />
                  )}
              </Box>

              <Grid gridTemplateColumns="repeat(1,1fr)" gap="25px" mb="24px">
                {formik.values.isTaxable ? (
                  <Box>
                    <CustomInput
                      type="text"
                      label={t('account.taxNumber')}
                      placeholder={t('account.enterTaxNumber')}
                      value={formik.values.taxNumber}
                      onChange={formik.handleChange('taxNumber')}
                      onBlur={formik.handleBlur('taxNumber')}
                      isReadOnly={read ? false : true}
                    />
                    {formik.errors.taxNumber && formik.touched.taxNumber && (
                      <ErrorMsg text={t(formik.errors.taxNumber)} />
                    )}
                  </Box>
                ) : null}

                <Checkbox
                  className="hasVat-checkbox"
                  isChecked={formik.values.isTaxable}
                  onChange={formik.handleChange('isTaxable')}
                  disabled={!read}
                >
                  {t('account.hasVat')}
                </Checkbox>
                {formik.errors.isTaxable && formik.touched.isTaxable && (
                  <ErrorMsg text={t(formik.errors.isTaxable)} />
                )}
              </Grid>

              {!read ? (
                <CustomButton
                  bg
                  name={t('myProfile.update')}
                  w="100%"
                  display="block"
                  m="auto"
                  mt="30px"
                  onClick={() => setRead(true)}
                />
              ) : (
                <Box>
                  <Flex justifyContent="space-between" alignItems="center">
                    <CustomButton
                      bg
                      type="submit"
                      name={t('account.saveChanges')}
                      w="48%"
                      mt="30px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      loading={handleSubmit.isLoading && <Spinner ms="5px" />}
                      disabled={handleSubmit.isLoading}
                    />
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      h="60px"
                      borderRadius="15px"
                      w="48%"
                      mt="30px"
                      textAlign="center"
                      color="text.1000"
                      fontWeight="bold"
                      cursor="pointer"
                      bg="gray.100"
                      onClick={() => setRead(false)}
                    >
                      <Text>{t('modals.cancel')}</Text>
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Form>
          );
        }}
      </Formik>{' '}
    </Box>
  );
};

export default Workshop;
