import { useState } from 'react';
import { Box, Heading, Grid, GridItem, useToast } from '@chakra-ui/react';
import Card from './card';
import DepartmentPopup from './modal';
import AddNew from '../add-new';
import { CustomModal, SimpleModal } from 'components/shared';
import { DeletePopup, CustomToast } from 'components/authenticated/shared';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { deleteDepartment } from 'apis/settings';
import { useTranslation } from 'react-i18next';

const Departments = ({ isView, departments, departmentsSuccess }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const toast = useToast();

  //show modals
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState({ flag: false, data: {} });
  const [showDelete, setShowDelete] = useState({ flag: false, data: {} });

  const handleDelete = useMutation(
    () => deleteDepartment([showDelete.data.id]),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllDepartments');

        setShowDelete({ flag: false, data: {} });

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deleteDepartment')}
                description={t('toasts.deleteDepartment')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deleteDepartment')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box mt="36px">
      <Heading
        as="h3"
        fontWeight="bold"
        fontSize="20px"
        mb="16px"
        textTransform="capitalize"
      >
        {t('account.departments')}
      </Heading>
      {departmentsSuccess && (
        <>
          <Grid
            templateColumns={[
              'repeat(4, 1fr)',
              'repeat(4, 1fr)',
              'repeat(3, 1fr)',
              'repeat(3, 1fr)',
              'repeat(4, 1fr)',
            ]}
            gap={5}
          >
            {departments?.data.map(item => {
              return (
                <GridItem>
                  <Card
                    item={item}
                    setShowEdit={setShowEdit}
                    setShowDelete={setShowDelete}
                    isView={isView}
                  />
                </GridItem>
              );
            })}
            <GridItem>
              {!isView ? (
                <AddNew
                  h={departments?.data.length == 0 ? '120px' : '100%'}
                  onClick={() => setShowAdd(true)}
                  py="12px"
                />
              ) : null}
            </GridItem>
          </Grid>
          <SimpleModal
            title={t('account.addDepartment')}
            titleDir="left"
            show={showAdd}
            setShow={setShowAdd}
          >
            <DepartmentPopup setShow={setShowAdd} />
          </SimpleModal>
          <CustomModal
            title={t('account.editDepartment')}
            titleDir="left"
            show={showEdit}
            setShow={setShowEdit}
          >
            <DepartmentPopup setShow={setShowEdit} data={showEdit.data} />
          </CustomModal>
          <CustomModal
            title={t('account.deleteDepartment')}
            show={showDelete}
            setShow={setShowDelete}
          >
            <DeletePopup
              handleDelete={handleDelete.mutate}
              title={t('account.confirmDeleteDepartment')}
              isLoading={handleDelete.isLoading}
              buttonText="delete"
            />
          </CustomModal>
        </>
      )}
    </Box>
  );
};

export default Departments;
