import { Box, Flex, Text } from '@chakra-ui/react';
import { CustomInput } from 'components/shared';
import { StatusBox } from 'components/authenticated/shared';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const TicketInfo = ({ status, createdAt, searchId }) => {
  const { t } = useTranslation();

  return (
    <Box bg="white" borderRadius="10px" p="20px" mb="20px">
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="#232728" fontSize="15px" fontWeight="bold" me="30px">
          {t('ticket.ticketId')}
        </Text>
        <StatusBox
          text={searchId}
          status="searchId"
          textTransform="uppercase"
        />
        {/* <Box bg="bg.3000" borderRadius="5px" px="3" py="1" mt="auto">
       
          <Text
            textTransform="uppercase"
            fontSize="14px"
            color="text.1000"
            fontWeight="bold"
            w="121px"
            textAlign="center"
          >
            {searchId}
          </Text>
        </Box> */}
      </Flex>

      <Box mb="11px" mt="11px">
        <CustomInput
          label={t('ticket.createdOn')}
          placeholder={moment(createdAt).format('DD MMMM yyyy')}
          h="35px"
          borderRadius="5px"
          isReadOnly
        />
      </Box>

      <Box mb="11px">
        <CustomInput
          label={t('ticket.closedOn')}
          h="35px"
          borderRadius="5px"
          isReadOnly
        />
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="14px" fontWeight="bold" color="#181F27">
          {t('common.status')}:
        </Text>

        <StatusBox
          text={
            status == 'pending'
              ? t('status.pending')
              : status == 'opened'
              ? t('status.ongoing')
              : t('status.closed')
          }
          status={status}
        />

        {/* <Box
          bg="#FFE4E4"
          color="#ED6262"
          fontWeight="bold"
          fontSize="12px"
          w="121px"
          textAlign="center"
          py="5px"
          borderRadius="5px"
        >
          {status == 'pending'
            ? t('status.pending')
            : status == 'opened'
            ? t('status.ongoing')
            : t('status.closed')}
        </Box> */}
      </Flex>
    </Box>
  );
};

export default TicketInfo;
