import React from 'react';
import { Box } from '@chakra-ui/react';

// import Weekend from '../checktime-table/weekend';
import moment from 'moment';

import InvoiceTemplate from './invoice-template';
// import Absent from '../checktime-table/absent';

const pageStyle = `
@media all {
  .page-break {
 
    page-break-before: always
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .pagebreak {    break-inside: avoid; } 
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    overflow:hidden;
   
  }
}

@page {
  size: auto;
  margin: 10mm;
}
`;

class PrintInvoice extends React.Component {
  render() {
    return (
      <Box {...this.props}>
        <style type="text/css" media="print">
          {pageStyle}
        </style>
        <InvoiceTemplate
          data={this.props.data}
          companyName={this.props.companyName}
          branch={this.props.branch}
          from={this.props.from}
          to={this.props.to}
          salesAmount={this.props.salesAmount}
          customerName={this.props.customerName}
        />
        {/* <CustomTable
          headers={['Name', 'Shift', 'Clock In', 'Clock Out', 'Working H’rs']}
        >
          {tableData()}
        </CustomTable> */}
      </Box>
    );
  }
}

export default PrintInvoice;
