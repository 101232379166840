import request from 'axios-interceptors';

export const getAllCategories = ({ queryKey }) => {
  return request.get(`/categories/all?branch=${queryKey[1]}`);
};

// export const getCategorieById = queryKeys => {
//   const q = queryKeys;
//   console.log(q);
//   return request.get(`/categories/${id}`);
// };

export const createCategorie = ({ fileName, data }) => {
  return request.post('/categories', { ...data, fileName });
};

export const editCategorie = ({ id, name, nameAr, fileName }) => {
  return request.put(`/categories/${id}`, {
    name,
    nameAr,
    fileName,
  });
};

export const uploadCategoryIcon = data => {
  return request.post('uploads/categories', data);
};

export const activateCategory = ({ id, branch }) => {
  return request.post(
    `categories/activateServiceByCategory/${id}?branch=${branch}`
  );
};

export const deactivateCategory = ({ id, branch }) => {
  return request.post(
    `categories/deactivateServiceByCategory/${id}?branch=${branch}`
  );
};
