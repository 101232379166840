import { useState } from 'react';

import { Box, Text, Flex, Grid, Image } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { AiOutlineCar, AiOutlinePlus } from 'react-icons/ai';

import { SimpleModal } from 'components/shared';
import TimeModal from './time-modal';
import moment from 'moment';
import Addparts from '../assigned-tasks/modal-parts';
import AddServices from '../assigned-tasks/modal-services';
import { useTranslation } from 'react-i18next';
import blueCar from 'assets/images/icons/blue-car.svg';
import bluePhone from 'assets/images/icons/blue-phone.svg';
import { StatusBox } from 'components/authenticated/shared';

const Card = ({ data, works, setWorks, branch, refetch }) => {
  const { t } = useTranslation();

  const [timeShow, setTimeShow] = useState(false);
  const [time, setTime] = useState(0);
  const [showParts, setShowParts] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showDescribtion, setShowDescribtion] = useState(false);

  let servicesNumber;
  let partsNumber;

  if (data.invoice.invoiceProducts.length == 0) {
    servicesNumber = 0;
    partsNumber = 0;
  } else {
    servicesNumber = data.invoice.invoiceProducts.filter(
      elm => elm.type === 'service'
    ).length;

    partsNumber = data.invoice.invoiceProducts.filter(
      elm => elm.type === 'part'
    ).length;
  }

  const formatedTime = () => {
    var totalMinutes;
    if (data?.expectedTime) {
      totalMinutes = data?.expectedTime;
    } else {
      totalMinutes = works[data?.id]?.expectedTime || 0;
    }

    var hourss = Math.floor(totalMinutes / 60);
    var minutess = totalMinutes % 60;

    return hourss === 0
      ? `${minutess}min`
      : `${hourss}h ${minutess == 0 ? '' : `${minutess}min`}`;
  };

  return (
    <Box
      bg="white"
      borderRadius="10px"
      padding="15px"
      w="400px"
      border={data?.isFromAerbag ? '3px solid rgb(27, 163, 239)' : 'none'}
    >
      <Flex justifyContent="space-between" alignItems="flex-start" mb="12px">
        {data?.isFromAerbag ? (
          <Text
            color="GrayText.2100"
            fontWeight="bold"
            fontSize="24px"
            overflow="hidden"
            maxW="230px"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            AerBag App
          </Text>
        ) : (
          <Text
            color="GrayText.2100"
            fontWeight="bold"
            fontSize="24px"
            overflow="hidden"
            maxW="230px"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {data?.customer.name}
          </Text>
        )}

        <StatusBox
          text={t('home.workflow.to_be_assigned')}
          status="to_be_assigned"
        />
        {/* 
        <Box
          bg="#F04E4E"
          color="white"
          borderRadius="5px"
          py="8px"
          px="8px"
          fontSize="14px"
          borderRadius="10px"
          minW="111px"
        >

          <Text textAlign="center">
            {/* {data?.workflowStatus.replaceAll('_', ' ')} 
            {t('home.workflow.to_be_assigned')}
          </Text>
        </Box> */}
      </Flex>

      <Box color="text.600" mb="11px">
        <Flex justifyContent="flex-start" alignItems="center" mb="8px">
          <Image src={blueCar} />
          <Flex justifyContent="flex-start" alignItems="center" ms="10px">
            <Text>{data?.carDetails?.carManufactur}</Text>
            <Box h="15px" w="1.5px" bg="text.1100" mx="5px"></Box>
            <Text>{data?.carDetails?.carModel}</Text>
            <Box h="15px" w="1.5px" bg="text.1100" mx="5px"></Box>
            <Text>{data?.carDetails?.carYear}</Text>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-start" alignItems="center">
          <Image src={bluePhone} />
          <Text ms="10px">{data?.customer.mobileNumber}</Text>
        </Flex>
      </Box>

      <Grid
        gridTemplateColumns="1fr 1fr 2fr"
        justifyContent="space-between"
        alignItems="center"
        fontSize="14px"
        mb="11px"
      >
        <Box
          justifyContent="space-between"
          alignItems="center"
          fontSize="12px"
          color="text.1100"
          h="100%"
        >
          <Text me="20px">{t('home.workflow.services')}</Text>
          <Grid
            w="27px"
            h="27px"
            borderRadius="50%"
            bg="rgba(33,198,225,15%)"
            color="#21C6E1"
            ms="8px"
            placeItems="center"
            fontSize="14px"
            cursor="pointer"
            onClick={() => setShowServices(true)}
          >
            {servicesNumber ? servicesNumber : <Box as={AiOutlinePlus} />}
          </Grid>
        </Box>

        <Box
          justifyContent="space-between"
          alignItems="center"
          fontSize="12px"
          color="text.1100"
          h="100%"
        >
          <Text me="20px">{t('home.workflow.parts')}</Text>
          <Grid
            w="27px"
            h="27px"
            borderRadius="50%"
            bg="rgba(33,198,225,15%)"
            color="#21C6E1"
            placeItems="center"
            ms="2px"
            fontSize="14px"
            cursor="pointer"
            onClick={() => setShowParts(true)}
          >
            {partsNumber ? partsNumber : <Box as={AiOutlinePlus} />}
          </Grid>
        </Box>

        <Box color="text.600" pos="relative">
          <Text fontSize="12px" color="text.1100">
            {t('home.workflow.description')} :
          </Text>
          <Box
            h="46px"
            overflow="hidden"
            border="1px solid #DEF6FB"
            borderRadius="5px"
            px="3px"
            onMouseOver={() => setShowDescribtion(true)}
            onMouseOut={() => setShowDescribtion(false)}
          >
            {data?.isFromAerbag ? (
              <>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Client Name:
                  </Text>{' '}
                  {data.appClient
                    ? data?.appClient?.name
                    : data?.customer?.name}
                </Text>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Mobile:
                  </Text>{' '}
                  {data.appClient
                    ? data?.appClient?.mobileNumber
                    : data?.customer?.mobileNumber}
                </Text>
              </>
            ) : (
              <Text>{data?.description}</Text>
            )}
          </Box>

          <Box
            pos="absolute"
            border="1px solid #DEF6FB"
            borderRadius="5px"
            bg="white"
            px="3px"
            minW="185px"
            minH="46px"
            top="18px"
            opacity={showDescribtion ? '1' : '0'}
            pointerEvents="none"
            transition=".3s"
          >
            {data?.isFromAerbag ? (
              <>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Client Name:
                  </Text>{' '}
                  {data.appClient
                    ? data?.appClient?.name
                    : data?.customer?.name}
                </Text>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Mobile:
                  </Text>{' '}
                  {data.appClient
                    ? data?.appClient?.mobileNumber
                    : data?.customer?.mobileNumber}
                </Text>
              </>
            ) : (
              <Text>{data?.description}</Text>
            )}
          </Box>
        </Box>
      </Grid>

      <Grid
        gridTemplateColumns="1fr 1fr 1fr"
        // justifyContent="space-between"

        w="full"
        fontSize="14px"
      >
        <Box>
          <Text fontSize="12px" color="text.1100">
            {t('home.workflow.duration')}
          </Text>
          {formatedTime() == '0min' ? (
            <Grid
              w="27px"
              h="27px"
              borderRadius="50%"
              bg="rgba(33,198,225,15%)"
              color="#21C6E1"
              placeItems="center"
              ms="10px"
              fontSize="14px"
              cursor="pointer"
              onClick={() => setTimeShow(true)}
            >
              <Box as={AiOutlinePlus} />
            </Grid>
          ) : (
            <Text cursor="pointer" onClick={() => setTimeShow(true)}>
              {formatedTime()}
            </Text>
          )}
        </Box>

        <Box
          bg="bg.3000"
          borderRadius="5px"
          px="3px"
          py="1"
          mt="auto"
          minW="121px"
          ms="20px"
        >
          <Text
            textAlign="center"
            textTransform="uppercase"
            fontSize="14px"
            color="text.1000"
            fontWeight="semibold"
          >
            {data?.searchId}
          </Text>
        </Box>

        {/* <Box>
          <Text fontSize="12px" color="text.1100">
            Timer
          </Text>
          <Text>
            {data?.timer}
             1h 16m 
          </Text>
        </Box> */}
      </Grid>
      <SimpleModal
        title={t('modals.time')}
        show={timeShow}
        setShow={setTimeShow}
      >
        <TimeModal
          id={data?.id}
          works={works}
          setWorks={setWorks}
          setTime={setTime}
          time={works[data?.id]?.expectedTime || 0}
          setTimeShow={setTimeShow}
        />
      </SimpleModal>

      <SimpleModal
        title={t('home.storage.addPart')}
        show={showParts}
        setShow={setShowParts}
      >
        <Addparts
          invoiceProducts={data?.invoice?.invoiceProducts}
          id={data?.invoice?.id}
          setShow={setShowParts}
          branch={branch}
          refetch={refetch}
        />
      </SimpleModal>

      <SimpleModal
        title={t('home.services.addServices')}
        show={showServices}
        setShow={setShowServices}
      >
        <AddServices
          invoiceProducts={data?.invoice?.invoiceProducts}
          id={data?.invoice?.id}
          setShow={setShowServices}
          branch={branch}
          refetch={refetch}
        />
      </SimpleModal>
    </Box>
  );
};

export default Card;
