import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { CustomButton, SimpleModal, Select } from 'components/shared';
import { SearchInput, CustomDate } from 'components/authenticated/shared';
import { ExportPopup } from 'components/authenticated/attendance/exportAttendance';
import {
  AnalysisCard,
  ChecktimeTable,
} from 'components/authenticated/attendance';
import { HiOutlineUpload } from 'react-icons/hi';
import { useQuery } from 'react-query';
import {
  Loading,
  Nodata,
  Error,
  NoShift,
} from 'components/authenticated/shared';
import {
  getAllAttendance,
  getAllPagesAttendance,
} from 'apis/clockin/Attendance';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getAllShifts } from 'apis/settings';

const Attendance = () => {
  const { user, branchFilter } = useContext(userContext);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const [showAdd, setShowAdd] = useState(false);
  const [shift, setShift] = useState({ label: 'select shifts', value: null });
  const [date, setDate] = useState();
  const [searchKey, setSearchkey] = useState('');
  const [branch, setBranch] = useState(branchFilter);

  //this effect to set branch select to values in main branesh that store in context
  useEffect(() => {
    setBranch(branchFilter);
  }, [branchFilter]);

  //get all shifts
  const {
    data: shifts,
    isLoading: loadingShifts,
    isSuccess: shiftsSuccess,
  } = useQuery('getAllShifts', getAllShifts, {
    onSuccess: result => {},
  });

  const allShifts = shifts?.data.map(e => {
    return { label: e.name, value: e.id, weekends: e.weekends };
  });

  useEffect(() => {
    setShift(
      shifts?.data.length == 0
        ? false
        : {
            label: shifts?.data[0].name,
            value: shifts?.data[0].id,
            weekends: shifts?.data[0].weekends,
          }
    );
    setLoading(false);
  }, [loadingShifts]);

  //get all attendance [] of users
  const { data: allData, refetch: refetchAllPagesAttendance } = useQuery(
    ['getAllPagesAttendance', shift?.value || null, branch?.value],
    getAllPagesAttendance,
    {
      enabled: false,
    }
  );

  //get all attendane of users in by filtering
  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    [
      'getAllAttendance',
      searchKey,
      moment(date).locale('en').format('YYYY-MM-DD'),
      shift?.value,
      branch?.value,
    ],
    getAllAttendance,
    {
      enabled: false,
    }
  );

  //refetch all attendance when change date, branch,shift
  useEffect(() => {
    if (shift?.value != undefined) {
      refetch();
      refetchAllPagesAttendance();
    }
  }, [shift?.value, date, branch?.value, searchKey]);

  //change shift select
  const handleShift = value => {
    setShift(value);
  };

  //handle branch
  const handleBranch = value => {
    setBranch(value);
  };

  //get data numbers of employee status
  const presentEmployees = () => {
    let presents = 0;
    let apsents = 0;
    let leave = 0;

    if (date) {
      if (date < new Date()) {
        data?.data.map(e => {
          if (e.attendances?.length > 0) {
            if (e.attendances[0].leave) {
              leave++;
            } else presents++;
          } else {
            apsents++;
          }
        });
      }
    } else {
      data?.data.map(e => {
        if (e.attendances?.length > 0) {
          if (e.attendances[0].leave) {
            leave++;
          } else presents++;
        } else {
          apsents++;
        }
      });
    }

    return { presents, apsents, leave };
  };

  const { presents, apsents, leave } = presentEmployees();

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb="40px">
        <Box>
          <Text fontSize="36px" fontWeight="bold" color="text.1800">
            {t('account.attendance')}
          </Text>
          <Text
            fontSize="14px"
            color="#4b4e50"
            // textAlign="center"
            whiteSpace="nowrap"
            dir="ltr"
          >
            {moment(new Date())
              .locale(t('common.lang'))
              .format('hh:mm A | DD-MM-YYYY')}
            {/* .format('hh:mm A | MMM DD, yyyy')} */}
          </Text>
        </Box>

        <Flex alignItems="center" w="80%" justifyContent="flex-end">
          <CustomDate
            border="1px solid #BFCFD6"
            buttonH="50px"
            setDate={setDate}
            dateFormat="dd MMMM yyyy"
          />

          <Select
            selectTitle={t('modals.selectShift')}
            ms={['4', '', '2', '2', '4']}
            w={['150px', '', '115px', '115px', '150px']}
            h="50px"
            label={t('modals.selectShift')}
            select={shift}
            handleSelect={handleShift}
            selections={allShifts}
          />

          <Select
            selectTitle={t('modals.selectBranch')}
            mx={['4', '', '2', '2', '4']}
            w="150px"
            h="50px"
            label="Branch"
            select={branch}
            handleSelect={handleBranch}
            selections={user.user.branches?.map((item, index) => ({
              label: item.name,
              value: item.id,
            }))}
          />
          <SearchInput
            placeholder={t('filter.search')}
            h="50px"
            me={['4', '', '2', '2', '4']}
            setValue={setSearchkey}
          />
          <CustomButton
            name={t('status.exportFile')}
            h="50px"
            w="150px"
            fontSize="14px"
            bg
            icon={HiOutlineUpload}
            disabled={
              isLoading || shift?.value == undefined || data?.data.length == 0
            }
            onClick={() => setShowAdd(true)}
          />
        </Flex>
      </Flex>
      <Grid templateColumns="repeat(3,1fr)" gap={5} mb="24px">
        <AnalysisCard
          name={t('home.clockin.presentEmployees')}
          bg="rgba(18,136,90,0.1)"
          color="text.2700"
          number={presents}
        />
        <AnalysisCard
          name={t('home.clockin.absentEmployees')}
          bg="rgba(237,98,98,0.1)"
          color="text.2800"
          number={apsents}
        />
        <AnalysisCard
          name={t('home.clockin.onLeave')}
          bg="rgba(27,163,239,0.1)"
          color="text.200"
          number={leave}
        />
      </Grid>

      <Box mx="auto">
        {loading ? <Loading /> : !shift && <Nodata />}

        {(isLoading || loadingShifts) && <Loading />}

        {isError && <Error />}

        {isSuccess && shiftsSuccess && (
          <>
            <ChecktimeTable data={data} date={date} />
          </>
        )}
      </Box>

      <SimpleModal
        title={t('home.employees.exportAttendanceList')}
        show={showAdd}
        setShow={setShowAdd}
        overflow="outside"
        // maxW="1300px"
      >
        <ExportPopup data={allData} shift={shift} branch={branch} />
      </SimpleModal>
    </>
  );
};

export default Attendance;
