import { useContext } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Select } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

const SelectSearch = ({
  label,
  selections,
  isOpen,
  select,
  handleSelect,
  loading,
  ...props
}) => {
  const { language } = useContext(userContext);

  const { t } = useTranslation();

  return (
    <Box>
      <FormControl>
        <Select
          noOptionsMessage={() => {
            if (loading) {
              return 'loading...';
            } else {
              return t('account.noData');
            }
          }}
          onChange={e => {
            handleSelect(e);
          }}
          value={
            select?.value == null
              ? ''
              : typeof select.label == 'object'
              ? {
                  label: language == 'en' ? select.label.en : select.label.ar,
                  value: select.value,
                }
              : select
          }
          name="colors"
          options={selections}
          placeholder={label}
          closeMenuOnSelect={true}
          chakraStyles={{
            dropdownIndicator: provided => ({
              ...provided,
              bg: 'transparent',
              px: 2,
              cursor: 'inherit',
              border: 'none',
              //   display: 'none',
            }),
            menuList: provided => ({
              ...provided,
              maxW: 'auto',
              minW: 'auto',
            }),
            control: provided => ({
              ...provided,
              cursor: 'pointer',
              _focus: {},
            }),

            input: provided => ({
              ...provided,
              h: '50px',
            }),
            indicatorSeparator: provided => ({
              ...provided,
              display: 'none',
            }),
          }}
          {...props}
        />
      </FormControl>
    </Box>
  );
};

export default SelectSearch;
