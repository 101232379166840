import React from 'react';
import { Text, Box, Flex, Heading } from '@chakra-ui/react';
import StatusCard from './status-card';
import StatusCardNumber from './status-card-number';
import DayCard from './day-card';
import TableHeader from './table-header';
import TableFooter from './table-footer';
import moment from 'moment';
// import Absent from '../checktime-table/absent';

const ExportAttendance = ({
  arrDays,
  arrUsers,
  shift,
  branch,
  from,
  to,
  ...props
}) => {
  const days = [];
  for (let i = 0; i < 31; i++) {
    days.push(i);
  }
  const repeatedMonths = arrDays.map(e => {
    return moment(e).format('MM yyyy');
  });

  const months = [...new Set([...repeatedMonths])];

  const arrMonths = months.map(e => {
    return arrDays.filter(i => {
      return (
        moment(i).format('MM yyyy') == moment(e, 'MM yyyy').format('MM yyyy')
      );
    });
  });

  //check if parent height + box that we want to add is less than

  return (
    <Box minH="925px">
      <TableHeader
        shiftName={shift?.label}
        branchName={branch?.label}
        from={from}
        to={to}
      />
      {arrMonths.map(e => {
        //months
        //e is refer to days in the array

        return (
          <Box className="pagebreak">
            {/* header of month */}

            <Flex mt="13px" className="pagebreak">
              <Flex
                border="1px solid #EAF4FF"
                borderRadius="5px"
                p="5px"
                me="1px"
                minW="180px"
                alignItems="center"
              >
                <Text color="#181F27" fontSize="14px" fontWeight="bold" m="0">
                  Employees
                </Text>
              </Flex>

              {e.map((e, i) => {
                return (
                  <DayCard
                    month={moment(e).format('MMM')}
                    day={moment(e).format('D')}
                    index={i}
                    length={days.length}
                    bg={
                      shift?.weekends.includes(parseInt(moment(e).format('d')))
                        ? '#FFDB87'
                        : '#EAF4FF'
                    }
                  />
                );
              })}
            </Flex>

            {/* user attendance */}
            {arrUsers.map(user => {
              let allPresents = 0;
              let allAbsent = 0;
              let allLaters = 0;
              let allLeave = 0;
              // let allWeekends = user.shifts[0].weekends.length;

              e.map(e => {
                const weekends = user.shifts[0].weekends;
                const attendances = user.attendances;

                if (weekends.includes(parseInt(moment(e).format('d')))) {
                } else {
                  let IsattendanceExist = attendances.find(a => {
                    return a.day == moment(e).format('YYYY-MM-DD');
                  });
                  if (IsattendanceExist) {
                    if (IsattendanceExist.leave) {
                      allLeave++;
                    } else if (IsattendanceExist.lateCheckIn) {
                      allLaters++;
                    }
                    allPresents++;
                  } else {
                    allAbsent++;
                  }
                }
              });

              return (
                <Flex mt="5px">
                  <Box
                    border="1px solid #EAF4FF"
                    borderRadius="5px"
                    p="5px"
                    me="1px"
                    minW="180px"
                  >
                    <Text fontWeight="bold" color="#323232" fontSize="12px">
                      {user.firstName}
                    </Text>
                    <Flex justifyContent="space-evenly">
                      <StatusCardNumber
                        name="P"
                        bgLine="#12885A"
                        number={allPresents}
                      />
                      <StatusCardNumber
                        name="A"
                        bgLine="#6147FF"
                        number={allAbsent}
                      />
                      <StatusCardNumber
                        name="LR"
                        bgLine="#21C6E1"
                        number={allLeave}
                      />
                      <StatusCardNumber
                        name="L"
                        bgLine="#F43838"
                        number={allLaters}
                      />
                    </Flex>
                  </Box>
                  {e.map((e, i) => {
                    const weekends = user.shifts[0].weekends;
                    const attendances = user.attendances;

                    if (
                      weekends.includes(
                        parseInt(moment(e).locale('en').format('d'))
                      )
                    ) {
                      return (
                        <StatusCard
                          index={i}
                          length={days.length}
                          lineColor="#FFDA87"
                          name="W"
                          bg="#fff8e8"
                        />
                      );
                    } else {
                      let IsattendanceExist = attendances.find(a => {
                        return (
                          a.day == moment(e).locale('en').format('YYYY-MM-DD')
                        );
                      });
                      if (IsattendanceExist) {
                        if (IsattendanceExist.leave) {
                          return (
                            <StatusCard
                              index={i}
                              length={days.length}
                              lineColor="#21C6E1"
                              name="LR"
                              bg="white"
                            />
                          );
                        } else if (IsattendanceExist.lateCheckIn) {
                          return (
                            <StatusCard
                              index={i}
                              length={days.length}
                              lineColor="#F43838"
                              name="L"
                              bg="white"
                            />
                          );
                        }

                        return (
                          <StatusCard
                            index={i}
                            length={days.length}
                            lineColor="#12885A"
                            name="P"
                            bg="white"
                          />
                        );
                      } else {
                        return (
                          <StatusCard
                            index={i}
                            length={days.length}
                            lineColor="#6147FF"
                            name="A"
                            bg={'white'}
                          />
                        );
                      }
                    }
                  })}
                </Flex>
              );
            })}
          </Box>
        );
      })}

      <TableFooter />
    </Box>
  );
};

export default ExportAttendance;
