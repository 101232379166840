import { Box, Text, Flex, Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ViewService = ({ services }) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex
        p="10px"
        borderRadius="5px 5px 0 0"
        borderBottom="1px solid #b7b8bb"
        bg="#21C6E1"
        color="white"
        justifyContent="space-between"
      >
        <Box w="30%">{t('common.id')}</Box>
        <Box w="35%">{t('home.services.name')}</Box>
        <Box w="20%">{t('home.projects.price')}</Box>
      </Flex>

      {services?.map((e, i) => {
        return (
          <Flex p="10px" bg="#f9fafb" justifyContent="space-between">
            <Box w="30%">{e?.product?.sku}</Box>
            <Box w="35%">
              <Text maxW="150px">{e?.product?.name}</Text>
            </Box>
            <Box w="20%">
              <Input
                type="number"
                h="30px"
                w="75px"
                p="10px"
                _focus={{}}
                value={e?.price}
                isReadOnly
              />
            </Box>
          </Flex>
        );
      })}
    </>
  );
};

export default ViewService;
