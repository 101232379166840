import { useState } from 'react';
import { Switch } from '@chakra-ui/react';
import ChosseBranchModal from './chosse-branch-modal';
import { SimpleModal } from 'components/shared';

const ServiceSwitch = ({
  setShowDelete,
  setShowActive,
  item,
  isActive,
  setShowChosseBranchModal,
  showChosseBranchModal,
  refetchServices,
  ...props
}) => {
  const checkIsActive = () => {
    if (isActive) {
      if (isActive?.isActive) {
        return true;
      } else {
        return false;
      }
    } else {
      if (item?.deletedAt == null) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <Switch
        position="absolute"
        top="20px"
        right="15px"
        size="lg"
        // colorScheme="cyan"
        onChange={e => {
          if (!e.target.checked) {
            setShowDelete(true);
          } else {
            setShowActive(true);
          }

          {
            /* this logic was used with multi branches selected */
          }
          // if (item?.totalBranches.length > 1) {
          //   setShowChosseBranchModal(true);
          // } else {
          //   if (!e.target.checked) {
          //     setShowDelete(true);
          //   } else {
          //     setShowActive(true);
          //   }
          // }
        }}
        isChecked={isActive}
        background="none"
        _focus={{ background: 'none' }}
        _active={{}}
        {...props}
      />

      {/* this logic was used with multi branches selected */}
      {/* <SimpleModal
        title={'which branch do you want to activate/deactivate this service?'}
        show={showChosseBranchModal}
        setShow={setShowChosseBranchModal}
      >
        <ChosseBranchModal
          branches={item?.totalBranches}
          itemId={item?.id}
          refetchServices={refetchServices}
        />
      </SimpleModal> */}
    </>
  );
};

export default ServiceSwitch;
