import { useState, forwardRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { Box, Button } from '@chakra-ui/react';
import { GoCalendar } from 'react-icons/go';
import moment from 'moment';
import 'styles/date-picker.scss';

const CustomDate = ({
  setDate,
  start,
  w = '100%',
  dateFormat = 'MMMM yyyy',
  border,
  buttonH,
  showMonthYearPicker,
  handleDate,
  placeholder,
  defaultDate,
  minDate,
  maxDate,
  ...props
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [change, setChange] = useState(false);
  //we use this to enable prev and next with calendar ==> go to calendar file
  useEffect(() => {
    if (start) {
      setStartDate(start);
    }
  }, [start]);

  useEffect(() => {
    if (defaultDate) {
      setChange(true);
      setStartDate(new Date(defaultDate));
    }
  }, []);

  const CalendarInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      _focus={{}}
      onClick={onClick}
      ref={ref}
      border={border}
      h={buttonH}
      borderRadius="10px"
      color="text.1100"
      fontWeight="normal"
      {...props}
    >
      {placeholder ? (change ? value : placeholder) : value}

      {/* {value} */}

      <Box as={GoCalendar} pointerEvents="none" ms="10px" />
    </Button>
  ));

  return (
    <Box pos="relative">
      <Box
        as={DatePicker}
        className="calen"
        selected={startDate}
        onChange={date => {
          setStartDate(date);
          setChange(true);

          if (setDate) {
            setDate(date);
          }

          if (handleDate) {
            handleDate(moment(date).locale('en').format('YYYY-MM-DD'));
          }
        }}
        bg="white"
        boxShadow="0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06)"
        h="40px"
        ps="20px"
        borderRadius="5px"
        cursor="pointer"
        w={w}
        _focus={{ outline: 'none' }}
        dateFormat={dateFormat}
        calendarClassName="calen"
        dayClassName={() => {
          return 'day';
        }}
        todayButton="today"
        customInput={<CalendarInput />}
        showMonthYearPicker={showMonthYearPicker ? true : false}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Box>
  );
};

export default CustomDate;
