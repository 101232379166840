import { useState } from 'react';
import { Box, Flex, Link as L, Text, Button, Spinner } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  CustomInput,
  CustomButton,
  SimpleModal,
  ErrorMsg,
} from 'components/shared';
import { Header } from 'components/unauthenticated/shared';
import FormBody from 'components/unauthenticated/shared/form-body';
import { Formik } from 'formik';
import { LoginSchema } from 'schemas/login/LoginSchema';
import { useTranslation } from 'react-i18next';
import ResetPassword from './reset-password';

const SigninForm = ({ handleLogin, errors, loading }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <Box>
      <Header heading={t('login.header')} />
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        mt="150px"
      >
        {errors == 'Unauthorized' ? (
          <ErrorMsg text={t('login.Unauthorized')} />
        ) : (
          ''
        )}

        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={values => {
            handleLogin({ email: values.email, password: values.password });
          }}
        >
          {formik => (
            <FormBody onSubmit={formik.handleSubmit}>
              <Box mb="20px">
                <CustomInput
                  type="email"
                  label={t('login.email')}
                  placeholder={t('signup.enterEmail')}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email && (
                  <ErrorMsg text={t(formik.errors.email)} />
                )}
              </Box>

              <CustomInput
                type="password"
                label={t('login.password')}
                placeholder={t('signup.enterPassword')}
                onChange={formik.handleChange('password')}
                onBlur={formik.handleBlur('password')}
                value={formik.values.password}
              />
              {formik.errors.password && formik.touched.password && (
                <ErrorMsg text={t(formik.errors.password)} />
              )}
              <Text
                mt="12px"
                display="flex"
                alignItems="center"
                justifyContent="end"
                color="text.800"
              >
                {t('login.forgetPassword')}
                <L
                  fontWeight="bold"
                  textDecoration="underline"
                  ms="5px"
                  onClick={() => setShow(true)}
                  _focus={{ outline: 'none' }}
                >
                  {t('login.resetPassword')}
                </L>
              </Text>

              <CustomButton
                name={t('login.header')}
                bg
                type="submit"
                mt="50px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={loading && <Spinner ms="5px" />}
                disabled={loading}
              />

              <Text mt="12px" textAlign="center">
                {t('login.dontHaveAnAccount')}
                <L
                  as={Link}
                  to="/signup"
                  fontWeight="bold"
                  textDecoration="underline"
                  ms="5px"
                  _focus={{ outline: 'none' }}
                >
                  {t('login.signUp')}
                </L>
              </Text>
            </FormBody>
          )}
        </Formik>
      </Flex>

      <SimpleModal
        show={show}
        setShow={setShow}
        title={t('login.resetThePassword')}
      >
        <ResetPassword setShow={setShow} />
      </SimpleModal>
    </Box>
  );
};

export default SigninForm;
