import request from 'axios-interceptors';

export const getBranchesCount = () => {
  return request.get(`branches/count`);
};

export const getEmployeesCount = () => {
  return request.get(`iam/users/count`);
};

export const getClientsCount = () => {
  return request.get(`crm/customers/count`);
};

export const getProjectsCount = () => {
  return request.get(`projects/count`);
};

export const getLastProjects = () => {
  return request.get(`projects/lastWeek`);
};
