import { Flex, Image, Heading, Text, CircularProgress } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const Loading = () => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      pos="absolute"
      top="50%"
      left="50%"
      transform="translate(-50%,-50%)"
    >
      <Flex>
        <Heading as="h3" fontWeight="bold" mb="10px" me="10px">
          {t('common.loading')}
        </Heading>
        <CircularProgress isIndeterminate color="bg.300" />
      </Flex>
    </Flex>
  );
};

export default Loading;
