import {
  Box,
  Image,
  Tr,
  Td,
  Text,
  Grid,
  Flex,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';

const LeaveRow = ({ shift, i, approvedBy }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <>
      <Grid
        gridTemplateColumns="2fr 2fr 2fr 2fr "
        borderTop={i != 0 && '1px solid #edf2f7'}
        py="22px"
        onClick={onToggle}
        cursor={'pointer'}
        pos="relative"
      >
        <Box>{shift}</Box>
        <Box
          as="span"
          fontSize="12px"
          bg="bg.1700"
          py="10px"
          borderRadius="10px"
          color="text.200"
          display="Grid"
          placeItems="center"
          w="100px"
          fontWeight="bold"
        >
          OnLeave
        </Box>
        <Box
          as={MdKeyboardArrowDown}
          pos="absolute"
          right="15px"
          top="50%"
          transform="translateY(-50%)"
          transition=".3s"
          transform={
            isOpen
              ? 'translateY(-50%) rotate(180deg)'
              : 'translateY(-50%) rotate(0)'
          }
          fontSize="20px"
        />
      </Grid>
      <Collapse in={isOpen} animateOpacity w="100%">
        <Flex
          p="20px"
          pe="40px"
          bg="bg.2100"
          w="100%"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <Text color="text.600">Clock In Time</Text>
          </Flex>

          <Text color="text.600" fontWeight="bold">
            12:10
          </Text>
          <Text color="text.600">Updated Clock In Time</Text>
          <Text color="text.600" fontWeight="bold">
            12:10
          </Text>
          <Text color="text.600">Clock </Text>
          <Text color="text.600">
            Approved by {approvedBy?.firstName + ' ' + approvedBy?.lastName}
          </Text>
        </Flex>
      </Collapse>
    </>
  );
};

export default LeaveRow;
