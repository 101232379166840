import { Box, Button, Link, Spinner, Text } from '@chakra-ui/react';
import { CustomButton } from 'components/shared';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { getRedirectUrl } from 'apis/quickbooks';

const QuickbooksSignin = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useQuery('get redirect url', getRedirectUrl);

  const url = data?.data?.redirect_url;

  return (
    <Box
      boxShadow="0 0 160px -60px #21B3E1"
      bg="white"
      p="40px"
      borderRadius="15px"
      mt="150px"
      w="600px"
      mx="auto"
    >
      <Text fontWeight="bold" textAlign="center" mb="20px" fontSize="20px">
        {t('quickbooks.title')}
      </Text>
      <Text textAlign="center" mb="40px">
        {t('quickbooks.desc')}
      </Text>
      <CustomButton
        loading={isLoading ? <Spinner ms="5px" /> : null}
        disabled={isLoading}
        as={Link}
        href={url}
        name={t('quickbooks.buttonName')}
        w="50%"
        mx="auto"
        display="flex"
        bg
        _hover={{
          textDecoration: 'none',
        }}
        _active={{
          boxShadow: 'none',
        }}
      />
    </Box>
  );
};

export default QuickbooksSignin;
