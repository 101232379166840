import { Box } from '@chakra-ui/react';
import Shifts from './shifts';
import Departments from './departments';
import Attendence from './attendence';
import Permission from 'helpers/permission';
import { useQuery } from 'react-query';
import {
  getAllDepartments,
  getAllShifts,
  getAttendanceTime,
} from 'apis/settings';
import { Loading, Error } from 'components/authenticated/shared';

const Settings = () => {
  const {
    data: shifts,
    isLoading: loadingShifts,
    isSuccess: shiftsSuccess,
    isError: shiftsError,
  } = useQuery('getAllShifts', getAllShifts);

  const {
    data: departments,
    isLoading: loadingDepartments,
    isSuccess: departmentsSuccess,
    isError: departmentsError,
  } = useQuery('getAllDepartments', getAllDepartments);

  const {
    data: attendanceTime,
    isLoading: loadingAttendanceTime,
    isSuccess: attendanceTimeSuccess,
    isError: attendanceTimeError,
  } = useQuery('getAttendanceTime', getAttendanceTime);

  return (
    <Box p="40px">
      {(loadingShifts || loadingDepartments || loadingAttendanceTime) && (
        <Loading />
      )}

      {(shiftsError || departmentsError || attendanceTimeError) && <Error />}

      {shiftsSuccess && departmentsSuccess && attendanceTimeSuccess && (
        <>
          <Shifts
            isView={Permission('Setting') == 'View'}
            shifts={shifts}
            shiftsSuccess={shiftsSuccess}
          />
          <Departments
            isView={Permission('Setting') == 'View'}
            departments={departments}
            departmentsSuccess={departmentsSuccess}
          />
          <Attendence
            isView={Permission('Setting') == 'View'}
            attendanceTime={attendanceTime}
            attendanceTimeSuccess={attendanceTimeSuccess}
          />
        </>
      )}
    </Box>
  );
};

export default Settings;
