import request from 'axios-interceptors';

export const getRedirectUrl = () => {
  let userData = JSON.parse(localStorage.getItem('user'));

  return request.get(`/accounting/redirect_uri`, {
    headers: {
      Authorization: `Bearer  ${userData?.accessToken}`,
    },
  });
};
