import { useState } from 'react';
import { Box, Flex, Grid, Text, Link, Textarea } from '@chakra-ui/react';
import { CustomInput, CustomButton, Label, Select } from 'components/shared';
import { CustomDate } from 'components/authenticated/shared';
import { Formik } from 'formik';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const PopupRequest = ({
  setShow,
  data,
  mutate,
  editRequest,
  id,
  status,
  editing,
}) => {
  const { t } = useTranslation();
  const { type, from, to, createdAt, comment, attachmentUrl } = data;

  //edit request buttons
  const [edit, setEdit] = useState(false);
  // const [cancel, setCancel] = useState(false);

  const [leaveType, setLeaveType] = useState({
    label: type,
    value: type,
  });

  const handleLeaveType = value => {
    setLeaveType(value);
  };

  return (
    <Formik
      initialValues={{
        leaveType: leaveType.value,
        requestedAt: '',
        editedAt: '',
        from: from,
        to: to,
        comment: comment,
      }}
      // validationSchema={addProject}
      onSubmit={async values => {
        editRequest(
          {
            id: id,
            from: moment(values.from).format(),
            to: moment(values.to).format(),
          },
          {
            onSuccess: () => {
              setShow(false);
            },
          }
        );
      }}
      // validateOnMount
    >
      {formik => {
        return (
          <Box as="form" onSubmit={formik.handleSubmit}>
            <Grid templateColumns="repeat(2,1fr)" gap={4} mb="20px">
              <Box>
                <Label text={t('home.clockin.leaveType')} />
                <Select
                  // mx={['4', '', '2', '2', '4']}
                  // w={['150px', '', '115px', '115px', '150px']}
                  h="60px"
                  select={leaveType}
                  handleSelect={value => {
                    handleLeaveType(value);
                    formik.setFieldValue('leaveType', value.value);
                  }}
                  selections={[
                    { label: 'sick leave', value: 'sick leave' },
                    { label: 'casual leave', value: 'casual leave' },
                    { label: 'annual leave', value: 'annual leave' },
                  ]}
                  isDisabled={!edit && !editing}
                />
                {/* <CustomInput value={type}  /> */}
              </Box>
              <Grid
                gridTemplateColumns={editing ? 'repeat(2,1fr)' : '1fr'}
                gap={editing ? '15px' : '0'}
              >
                <CustomInput
                  label={t('home.clockin.requestedAt')}
                  value={moment(createdAt).format('DD-MM-YYYY')}
                  isReadOnly={true}
                />

                {editing && (
                  <CustomInput
                    label="Edited On"
                    value={moment(new Date()).format('DD-MM-YYYY')}
                    isReadOnly={true}
                  />
                )}
              </Grid>
            </Grid>
            <Grid templateColumns="repeat(2,1fr)" gap={4} mb="20px">
              {/* <CustomInput
                label="From"
                value={}
                isReadOnly={!edit}
              /> */}
              <Box>
                <Label text={t('common.from')} />
                <CustomDate
                  h="60px"
                  bg="white"
                  border="1px solid #BFCFD6"
                  handleDate={value => formik.setFieldValue('from', value)}
                  placeholder="Enter Date"
                  defaultDate={moment(from, 'MM/DD/yyyy , hh:mm:ss A').format()}
                  dateFormat="dd MMMM yyyy"
                  isDisabled={!edit && !editing}
                />
              </Box>
              <Box>
                <Label text={t('common.to')} />
                <CustomDate
                  h="60px"
                  bg="white"
                  border="1px solid #BFCFD6"
                  handleDate={value => formik.setFieldValue('to', value)}
                  placeholder="Enter Date"
                  defaultDate={moment(to, 'MM/DD/yyyy , hh:mm:ss A').format()}
                  dateFormat="dd MMMM yyyy"
                  isDisabled={!edit && !editing}
                />
              </Box>
            </Grid>

            <Grid templateColumns="repeat(2,1fr)" gap={4}>
              {attachmentUrl != null ? (
                <Link
                  href={attachmentUrl}
                  download
                  target="_blank"
                  _hover={{}}
                  _focus={{}}
                >
                  <Text
                    textTransform="capitalize"
                    color="text.600"
                    fontSize="14px"
                    mb="5px"
                    fontWeight="semibold"
                  >
                    Download the file
                  </Text>
                  <Flex
                    border="2px dashed #788596"
                    minH="124px"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="5px"
                    bg="bg.2200"
                    cursor="pointer"
                  >
                    <Box as={FaDownload} fontSize="25px" color="text.2600" />
                  </Flex>
                </Link>
              ) : (
                <Box>
                  <Text
                    textTransform="capitalize"
                    color="text.600"
                    fontSize="14px"
                    mb="5px"
                    fontWeight="semibold"
                  >
                    {t('home.clockin.downloadFile')}
                  </Text>
                  <Flex
                    border="2px dashed #788596"
                    minH="124px"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="5px"
                    bg="bg.2200"
                  >
                    <Text> {t('home.clockin.noFileProvided')}</Text>
                  </Flex>
                </Box>
              )}

              <Box>
                <Label text={t('home.clockin.comment')} />
                <Textarea
                  minH="124px"
                  border="1px solid #BFCFD6"
                  borderRadius="10px"
                  p="10px"
                  resize="none"
                  _focus={{}}
                  isReadOnly={!edit && !editing}
                  value={formik.values.comment}
                  onChange={formik.handleChange('comment')}
                  onBlur={formik.handleBlur('comment')}
                ></Textarea>
              </Box>
            </Grid>
            <Flex></Flex>
            {status == 'pending' ? (
              <Flex justifyContent="flex-end" mt="48px">
                <CustomButton
                  name={t('home.clockin.rejectRequest')}
                  w="165px"
                  h="48px"
                  me="12px"
                  onClick={() => {
                    mutate({ status: 'rejected', id });
                    setShow(false);
                  }}
                />
                <CustomButton
                  bg
                  name={t('home.clockin.acceptRequest')}
                  w="165px"
                  h="48px"
                  onClick={() => {
                    mutate({ status: 'accepted', id });
                    setShow(false);
                  }}
                />
              </Flex>
            ) : null}

            {status == 'accepted' && editing ? (
              <>
                <Flex justifyContent="flex-end">
                  <CustomButton
                    name={t('modals.cancel')}
                    mt="20px"
                    w="165px"
                    me="10px"
                    borderColor="#F43838"
                    color="#F43838"
                    hoverBg="#F43838"
                    activeBg="#F43838"
                    onClick={() => setShow(false)}
                  />
                  <CustomButton
                    name={t('account.save')}
                    bg
                    mt="20px"
                    w="165px"
                    type="submit"
                    isDisabled={!formik.dirty}
                  />
                </Flex>
              </>
            ) : null}

            {status == 'accepted' && !editing ? (
              <>
                {edit && (
                  <Flex justifyContent="flex-end">
                    <CustomButton
                      name={t('modals.cancel')}
                      mt="20px"
                      w="165px"
                      me="10px"
                      borderColor="#F43838"
                      color="#F43838"
                      hoverBg="#F43838"
                      activeBg="#F43838"
                      onClick={() => setShow(false)}
                    />
                    <CustomButton
                      name={t('account.save')}
                      bg
                      mt="20px"
                      w="165px"
                      type="submit"
                      isDisabled={!formik.dirty}
                    />
                  </Flex>
                )}

                {!edit && (
                  <CustomButton
                    name={t('home.clockin.editRequest')}
                    bg
                    mt="20px"
                    onClick={() => setEdit(true)}
                  />
                )}
              </>
            ) : null}
          </Box>
        );
      }}
    </Formik>
  );
};

export default PopupRequest;
