import {
  Box,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const ViewPopover = ({
  item,
  setShowDelete,
  viewLink,
  isDeleteExist = true,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <Button
          position="absolute"
          top="25px"
          right="15px"
          color="text.1100"
          fontSize="18px"
          h="18px"
          justifyContent="flex-end"
          bg="transparent"
          p="0"
          minW="5px"
          _focus={{}}
          _hover={{}}
          _active={{}}
          {...props}
        >
          <Box as={BsThreeDotsVertical} />
        </Button>
      </PopoverTrigger>
      <PopoverContent w="120px" _focus={{}}>
        <PopoverArrow />
        <PopoverBody>
          <Box
            cursor="pointer"
            textAlign="center"
            my="10px"
            textTransform="capitalize"
          >
            <a href={viewLink} target="_blank">
              {t('modals.view')}
            </a>
          </Box>
          {isDeleteExist && (
            <Box
              onClick={() => setShowDelete({ flag: true, data: item })}
              cursor="pointer"
              textAlign="center"
              my="10px"
              color="text.1900"
              textTransform="capitalize"
            >
              {t('account.delete')}
            </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ViewPopover;
