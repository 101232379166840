import request from 'axios-interceptors';

export const createAppointment = ({
  client,
  macker,
  model,
  year,
  branch,
  date,
  time,
  title,
  subject,
}) =>
  // title,
  // selectedClient,
  // maker,
  // model,
  // year,
  // formatedDate,
  // formatedTime,
  // branch
  {
    return request.post('workflow/appointments', {
      customerId: client,
      title: title,
      description: subject,
      date: date,
      time: time,
      branch: branch,
      carDetails: {
        carManufactur: macker,
        carModel: model,
        carYear: year,
      },
    });
  };

export const updateAppointment = ({ appointmentId, date, time }) => {
  return request.put(`workflow/appointments/update/${appointmentId}`, {
    date: date,
    time: time,
  });
};

export const getAllAppointments = ({ queryKey }) => {
  let formatedBranch = !!queryKey[1] ? [queryKey[1]] : null;

  return request.get(
    `workflow/appointments?searchKey=${queryKey[2]}&branch=${queryKey[1]}&day=${queryKey[3]}`
  );
};
