import request from 'axios-interceptors';

export const createService = ({ values, branch }) => {
  let formatedBranch =
    branch.length > 0
      ? branch.map(elm => {
          return { branchId: elm.value };
        })
      : null;

  return request.post('cat-log/products', {
    name: values.name,
    nameAr: values.nameAr,
    price: values.price,
    applicationPrice: values.applicationPrice,
    categoryId:
      values.subCategories.length == 0
        ? values.categorie?.value
        : values.subCategories[values.subCategories.length - 1]?.value,
    type: 'service',
    branches: formatedBranch,
  });
};

export const updateService = ({
  values,
  branch,
  updatedBranches,
  deletedBranches,
  productId,
  isDefault,
}) => {
  // let formatedBranch =
  //   branch.length > 0
  //     ? branch.map(elm => {
  //         return { branchId: elm.value };
  //       })
  //     : null;

  if (isDefault) {
    return request.put(`cat-log/products/updateService/${productId}`, {
      price: values.price,
      applicationPrice: values.applicationPrice,
      categoryId: values?.categorei?.value,
      type: 'service',
      toBeUpdatedBranchesIds: [...updatedBranches],
      toBeRemovedBranchesIds: [...deletedBranches],
    });
  }
  return request.put(`cat-log/products/updateService/${productId}`, {
    name: values.name,
    nameAr: values.nameAr,
    price: values.price,
    applicationPrice: values.applicationPrice,
    categoryId: values?.categorei?.value,
    type: 'service',
    toBeUpdatedBranchesIds: [...updatedBranches],
    toBeRemovedBranchesIds: [...deletedBranches],
  });
};

export const activateService = ({
  price,
  applicationPrice,
  productId,
  branchId,
}) => {
  return request.post('cat-log/products/activateService', {
    isActive: true,
    price,
    applicationPrice,
    productId,
    branchId,
  });
};

export const deactivateService = ({ products, branchId }) => {
  return request.post('cat-log/products/deleteService', {
    products,
    branchId,
  });
};

export const getAllServices = ({ queryKey }) => {
  // currentPage, searchKey, branch, status

  let formatedBranch =
    queryKey[3].length > 0 ? queryKey[3].map(elm => elm.value) : null;

  return request.get(
    `cat-log/products?type=service&page=${queryKey[1]}&limit=10&searchKey=${queryKey[2]}&branch=${formatedBranch}&status=${queryKey[4]}`
  );
};

export const getAllPagesServices = ({ queryKey }) => {
  return request.get(`cat-log/products/all?type=service&branch=${queryKey[1]}`);
};
