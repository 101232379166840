import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const DayCard = ({ month, day, index, length, ...props }) => {
  return (
    <Box
      overflow="hidden"
      w="31px"
      p="5px"
      fontSize="8px"
      borderRadius="5px"
      me={index == length - 1 ? '0' : '1px'}
      {...props}
    >
      <Text textAlign="center" textTransform="lowercase">
        {month}
      </Text>
      <Text textAlign="center" fontWeight="bold">
        {day}
      </Text>
    </Box>
  );
};

export default DayCard;
