import * as Yup from 'yup';

export const AddUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'signup.firstName3Char')
    .max(100, 'signup.firstName100char')
    .required('signup.fieldRequired'),
  lastName: Yup.string()
    .min(3, 'signup.lastName3Char')
    .max(100, 'signup.lastName100Char')
    .required('signup.fieldRequired'),
  email: Yup.string()
    .email('signup.invalidEmail')
    .max(250, 'signup.email250Char')
    .required('signup.fieldRequired'),
  phone: Yup.string()
    .min(10, 'signup.phone8Char')
    .max(16, 'phone12Char')
    .required('signup.fieldRequired')
    .test('phoneStartsWithZero', 'startWithZero', phone =>
      phone && phone[3] === '0' ? false : true
    ),
});
