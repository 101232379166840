import { useState } from 'react';
import {
  Box,
  Flex,
  Link as L,
  Text,
  Button,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import {
  CustomInput,
  CustomButton,
  SimpleModal,
  ErrorMsg,
} from 'components/shared';
import { Header } from 'components/unauthenticated/shared';
import FormBody from 'components/unauthenticated/shared/form-body';
import { CustomToast } from 'components/authenticated/shared';
import { Formik } from 'formik';
import { ResetPasswordSchema } from 'schemas/login/ResetPasswordSchema';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { resetPassword } from 'apis/iam/Login';
import { useNavigate } from 'react-router-dom';

const ResetPassword = ({ handleLogin, errors, token }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const toast = useToast();

  const { mutate, isLoading } = useMutation(resetPassword, {
    onSuccess: res => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.changePassword')}
              description={t('toasts.changePassword')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      navigate('/');
    },
    onError: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.changePassword')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Box>
      <Header heading={t('login.resetPassword')} />
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        mt="150px"
      >
        {/* <Text color="red">{errors}</Text> */}
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={ResetPasswordSchema}
          onSubmit={values => {
            mutate({ password: values.password, token: token });
          }}
        >
          {formik => (
            <FormBody onSubmit={formik.handleSubmit}>
              <Box mb="20px">
                <CustomInput
                  type="password"
                  label={t('signup.createPassword')}
                  placeholder={t('signup.createPassword')}
                  onChange={formik.handleChange('password')}
                  onBlur={formik.handleBlur('password')}
                  value={formik.values.password}
                />
                {formik.errors.password && formik.touched.password && (
                  <ErrorMsg text={t(formik.errors.password)} />
                )}
              </Box>

              <CustomInput
                type="password"
                label={t('signup.confirmPassword')}
                placeholder={t('signup.reEnterPassword')}
                onChange={formik.handleChange('confirmPassword')}
                onBlur={formik.handleBlur('confirmPassword')}
                value={formik.values.confirmPassword}
              />
              {formik.errors.confirmPassword &&
                formik.touched.confirmPassword && (
                  <ErrorMsg text={t(formik.errors.confirmPassword)} />
                )}

              <CustomButton
                name="Confirm "
                bg
                type="submit"
                mt="50px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={isLoading && <Spinner me="5px" />}
                disabled={isLoading}
              />
            </FormBody>
          )}
        </Formik>
      </Flex>
    </Box>
  );
};

export default ResetPassword;
