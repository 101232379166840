import { useState } from 'react';
import { Table } from 'components/authenticated/shared';
import { SimpleModal } from 'components/shared';
import { Text, Box, Tr, Td } from '@chakra-ui/react';
import { getUserTasks } from 'apis/employee';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Loading, Error, Nodata } from 'components/authenticated/shared';
import ProjectRow from './project-row';

const Projects = ({ id, setEmployeProjectsCount }) => {
  const { t } = useTranslation();

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getUserTasks', id],
    getUserTasks,
    {
      onSuccess: res => {
        setEmployeProjectsCount(res?.data?.tasks.length);
      },
    }
  );

  return (
    <Box p="40px" minH={data?.data.tasks.length > 0 ? 'initial' : '600px'}>
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess &&
        (data?.data.tasks.length > 0 ? (
          <>
            <Table
              headers={[
                t('common.id'),
                t('common.client'),
                t('common.completedOn'),
                t('common.status'),
                t('common.rating'),
              ]}
              bg="bg.1200"
              theadHeight="71px"
              overflowContainer="initial"
            >
              {data?.data.tasks.map((item, index) => {
                return <ProjectRow item={item} index={index} />;
              })}
            </Table>
          </>
        ) : (
          <Nodata />
        ))}
    </Box>
  );
};

export default Projects;
