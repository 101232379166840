import request from 'axios-interceptors';

export const getAllRequests = ({ queryKey }) => {
  return request.get(
    `iam/users/attendance/all/requests?searchKey=${queryKey[1]}&status=${queryKey[2]}`
  );
};

export const changeRequestStatus = ({ id, status }) => {
  return request.put(`iam/users/attendance/requests/result/${id}`, {
    requestStatus: status,
  });
};

export const editRequest = ({ id, from, to }) => {
  return request.put(`iam/users/attendance/requests/update/${id}`, {
    from: from,
    to: to,
  });
};
