import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@chakra-ui/react';
import { BiCurrentLocation } from 'react-icons/bi';
import {
  GoogleMap,
  LoadScript,
  Marker,
  Circle,
  Autocomplete,
} from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import { Loading } from 'components/authenticated/shared';

const id = ['749119187108fc04'];

const containerStyle = {
  width: '100%',
  height: '500px',
};
const center = {
  lat: 24.713552,
  lng: 46.675297,
};
const Goptions = {
  disableDefaultUI: true,
  zoomControl: true,
  rotateControl: true,

  mapTypeControl: true,
  mapId: id,
};

const options = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  zIndex: 1,
};
const libraries = ['places'];

const LoadingComponent = () => {
  return (
    <Box pos="relative" minH="400px">
      <Loading />
    </Box>
  );
};

const direction = localStorage.getItem('i18nextLng');

const Map = ({ setMarker, marker, handleMarker, coverRange }) => {
  const [zoomMap, setZoomMap] = useState(12);

  function handleZoomChanged() {
    setZoomMap(this.getZoom()); //this refers to Google Map instance
  }

  const focusZoom = () => {
    navigator.geolocation.getCurrentPosition(res => {
      let lat = res.coords.latitude;
      let lng = res.coords.longitude;

      // setMarker({ lat, lng });
      marker ? setLocation(marker) : setLocation({ lat, lng });
    });
    setZoomMap(15);
  };

  const getComponentByTypes = (results, types) => {
    for (let i = 0; i < results.length; i++) {
      const components = results[i].address_components;
      for (let j = 0; j < components.length; j++) {
        const componentTypes = components[j].types;
        if (componentTypes.some(type => types.includes(type))) {
          return components[j];
        }
      }
    }
    return null;
  };

  const getLocName = (my_location, handleMarker) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ latLng: my_location }, function (results, status) {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const country = getComponentByTypes(results, ['country']);
        const city = getComponentByTypes(results, [
          'locality',
          'administrative_area_level_1',
        ]);
        const street = getComponentByTypes(results, ['route']);
        const zipCode = getComponentByTypes(results, ['postal_code']);

        handleMarker(
          my_location,
          country?.long_name,
          city?.long_name,
          street?.long_name,
          zipCode?.long_name
        );
      }
    });
  };

  const [location, setLocation] = useState(null);
  const [autoComplete, setAutoComplete] = useState(null);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    getCurrentLocation();
  }, []);

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(res => {
      let lat = res.coords.latitude;
      let lng = res.coords.longitude;

      marker ? setLocation(marker) : setLocation({ lat, lng });
    });
  };

  const onLoad = autocomplete => {
    setAutoComplete(autocomplete);
  };

  // const onPlaceChanged = () => {
  //   if (autoComplete !== null) {
  //     console.log(autoComplete.getPlace().geometry.location.lat());
  //   } else {
  //     console.log("Autocomplete is not loaded yet!");
  //   }
  // };

  return (
    <div className="relative" style={{ width: '100%', direction: 'rtl' }}>
      <LoadScript
        googleMapsApiKey="AIzaSyBSiidQGPxByUoQzAz1UHJhqyJR80uO16g"
        libraries={libraries}
        language="ar"
        mapIds={id}
        loadingElement={<LoadingComponent />}
      >
        <Box pos="relative">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={location || center}
            zoom={zoomMap}
            // zoomControl={true}
            onClick={e => {
              let values = {
                lat: e.latLng.lat(),
                lng: e.latLng.lng(),
              };

              getLocName(values, handleMarker);
            }}
            options={Goptions}
            onZoomChanged={handleZoomChanged}
          >
            {/* Child components, such as markers, info windows, etc. */}

            {marker && <Marker position={marker} />}

            {marker && coverRange && (
              <Circle
                center={marker}
                options={{ ...options, radius: coverRange }}
              />
            )}
            {/* onPlaceChanged={onPlaceChanged} */}
            {/* <Autocomplete onLoad={onLoad}>
            <input
              type="text"
              placeholder={t('signup.workshopLocation')}
              style={{
                textAlign: i18n.language === 'ar' ? 'right' : 'left',
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: 'absolute',
                left: '65%',
                marginLeft: '-120px',
              }}
            />
          </Autocomplete> */}

            <Grid
              placeItems="center"
              borderRadius="5px"
              w="40px"
              h="40px"
              bg="white"
              pos="absolute"
              bottom="115px"
              // right={direction == 'ar' ? '10px' : 'initial'}
              // left={direction == 'en' ? '94%' : 'initial'}
              ms="10px"
              direction="rtl"
              zIndex="11"
              cursor="pointer"
              onClick={focusZoom}
            >
              <BiCurrentLocation />
            </Grid>
          </GoogleMap>
        </Box>
      </LoadScript>
    </div>
  );
};

export default Map;
