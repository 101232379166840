import { useState, useContext } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Grid, Spinner, Text, useToast } from '@chakra-ui/react';
import { CustomInput, CustomButton, ErrorMsg } from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { useMutation } from 'react-query';
import { Formik, Form, FieldArray } from 'formik';
import { activateService } from 'apis/service';
import editServiceByCategory from 'schemas/add-category/edit-service';
import { useTranslation } from 'react-i18next';

const EditService = ({
  setShow,
  id,
  refetchCategories,
  isDefault,
  branchId,
  name,
  nameAr,
}) => {
  const toast = useToast();
  const { t } = useTranslation();

  const { mutate: activeServ, isLoading } = useMutation(
    activateService,

    {
      onSuccess: () => {
        setShow(false);
        refetchCategories();
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateService')}
                description={t('toasts.updateService')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateService')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Flex justifyContent="center">
      <Box w="full" maxW="70%">
        <Formik
          initialValues={{
            // name:  '',
            // nameAr:  '',
            price: 0,
            applicationPrice: 0,
          }}
          validationSchema={editServiceByCategory}
          onSubmit={values => {
            activeServ({
              price: values.price,
              applicationPrice: values?.applicationPrice,
              productId: id,
              branchId,
            });
            // handleSubmit.mutate({
            //   values,
            //   branch,
            // });
          }}
        >
          {formik => (
            <Form>
              <Grid mb="24px" gridTemplateColumns="repeat(2,1fr)" gap="15px">
                <Box>
                  <CustomInput
                    type="text"
                    label={t('modals.serviceName')}
                    placeholder={t('modals.enterServiceName')}
                    value={name}
                    disabled
                    // onChange={formik.handleChange('name')}
                    // onBlur={formik.handleBlur('name')}
                  />
                  {/* {formik.errors.name && formik.touched.name && (
                    <ErrorMsg text={t(formik.errors.name)} />
                  )} */}
                </Box>

                <Box>
                  <CustomInput
                    type="text"
                    label={'service name ar'}
                    placeholder={t('modals.enterServiceName')}
                    value={nameAr}
                    disabled
                    // onChange={formik.handleChange('nameAr')}
                    // onBlur={formik.handleBlur('nameAr')}
                  />
                  {/* {formik.errors.nameAr && formik.touched.nameAr && (
                    <ErrorMsg text={t(formik.errors.nameAr)} />
                  )} */}
                </Box>
              </Grid>

              <Grid gridTemplateColumns="repeat(2,1fr)" mb="24px" gap="15px">
                <Box w="full">
                  <CustomInput
                    type="number"
                    label={'service cost'}
                    placeholder={'enter cost'}
                    value={formik.values.price}
                    onChange={formik.handleChange('price')}
                    onBlur={formik.handleBlur('price')}
                  />
                  {formik.errors.price && formik.touched.price && (
                    <ErrorMsg text={t(formik.errors.price)} />
                  )}
                </Box>

                <Box w="full">
                  <CustomInput
                    type="number"
                    label={'app service cost'}
                    placeholder={'enter cost'}
                    value={formik.values.applicationPrice}
                    onChange={formik.handleChange('applicationPrice')}
                    onBlur={formik.handleBlur('applicationPrice')}
                  />
                  {formik.errors.applicationPrice &&
                    formik.touched.applicationPrice && (
                      <ErrorMsg text={t(formik.errors.applicationPrice)} />
                    )}
                </Box>
              </Grid>

              <CustomButton
                bg
                type="submit"
                name={'Edit & Activate'}
                w="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={isLoading && <Spinner ms="5px" />}
                disabled={isLoading}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default EditService;
