import { useState, useEffect, useContext } from 'react';
import { ImStack } from 'react-icons/im';
import { TbUsers, TbGitBranch } from 'react-icons/tb';

import { HiOutlineUserGroup, HiOutlineCollection } from 'react-icons/hi';
import { IoIosArrowBack } from 'react-icons/io';
import { IoBusinessOutline } from 'react-icons/io5';
import { VscFiles } from 'react-icons/vsc';
import { FiUsers } from 'react-icons/fi';
import { RiGitBranchLine } from 'react-icons/ri';
import { AiOutlineAppstoreAdd, AiOutlineFieldTime } from 'react-icons/ai';
import { GiTakeMyMoney } from 'react-icons/gi';
import { FaFileInvoice } from 'react-icons/fa';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuItem from './menu-item';
import User from './user';
import { Box, Image, UnorderedList, Button } from '@chakra-ui/react';
import bigLogo from 'assets/images/logo2.png';
import smallLogo from 'assets/images/small-logo.svg';
import AccessPage from 'helpers/AccessPage';
import { userContext } from 'contexts/userContext';

const Side = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(window.innerWidth < 1280 ? false : true);
  const [activeLink, setActiveLink] = useState(null);

  const { user } = useContext(userContext);

  const openSide = () => {
    setOpen(true);
  };

  const changeId = e => {
    setActiveLink(e);
  };

  const menuItems = [
    {
      name: t('sideBar.dashboard'),
      label: t('sideBar.dashboard'),
      role: 'Dashboard',
      exact: true,
      to: '/',
      icon: <ImStack />,
    },
    {
      name: t('sideBar.employees'),
      label: t('sideBar.employees'),
      role: 'Employees',
      exact: true,
      to: '/employees',
      icon: <TbUsers />,
    },
    // {
    //   name: 'salaries',
    //   label: 'salaries',
    //   role: 'Salaries',
    //   exact: true,
    //   to: '/salaries',
    //   icon: <GiTakeMyMoney />,
    // },
    // {
    //   name: 'invoices',
    //   label: 'invoices',
    //   role: 'Invoices',
    //   exact: true,
    //   to: '/invoices',
    //   icon: <FaFileInvoice />,
    // },

    // {
    //   name: t('sideBar.projects'),
    //   label: t('sideBar.projects'),
    //   role: 'Projects',
    //   to: `/projects`,
    //   icon: <HiOutlineCollection />,
    // },

    {
      name: t('sideBar.clients'),
      label: t('sideBar.clients'),
      role: 'Clients',
      exact: true,
      to: `/clients`,
      icon: <HiOutlineUserGroup />,
    },
    {
      name: 'workflow',
      label: t('sideBar.workflow'),
      role: 'Workflow',
      to: `/workflow/tasks`,
      icon: <TbGitBranch />,
      subMenus: [
        { name: t('home.workflow.tasks'), to: '/workflow/tasks' },
        { name: t('home.workflow.appointments'), to: '/workflow/appointments' },
      ],
    },
    {
      name: 'products',
      label: t('sideBar.products'),
      role: 'Products',
      exact: true,
      to: `/products/main-categories`,
      icon: <AiOutlineAppstoreAdd />,
      subMenus: [
        {
          name: t('categories.mainCategories'),
          to: '/products/main-categories',
        },
        { name: t('sideBar.storage'), to: '/products/parts' },
        { name: t('sideBar.services'), to: '/products/services' },
      ],
    },
    {
      name: 'clockin',
      label: t('sideBar.clockin'),
      role: 'ClockIn',
      exact: true,
      to: `/clockin/attendance`,
      icon: <AiOutlineFieldTime />,
      subMenus: [
        { name: t('home.clockin.attendence'), to: '/clockin/attendance' },
        { name: t('home.clockin.requests'), to: '/clockin/requests' },
      ],
    },

    {
      name: t('accounting.accounting'),
      label: t('accounting.accounting'),
      role: 'accounting',
      to: `/projects`,
      icon: <GiTakeMyMoney />,
      subMenus: [
        { name: t('sideBar.projects'), to: '/projects' },
        { name: t('accounting.invoices'), to: '/invoices' },
      ],
    },

    {
      name: t('sideBar.documents'),
      label: t('sideBar.documents'),
      role: 'Documents',
      to: `/documents`,
      icon: <VscFiles />,
    },
    {
      name: t('sideBar.branches'),
      label: t('sideBar.branches'),
      role: 'Branches',
      to: `/branches`,
      icon: <IoBusinessOutline />,
    },
  ];

  return (
    <Box
      as="nav"
      w="full"
      minH="100vh"
      pos={['relative', 'relative', 'absolute', 'relative']}
      transition=".3s"
      maxW={open ? '308px  ' : '157px '}
      bg="white"
      py="37px"
      zIndex="1111"
    >
      <Box
        w="full"
        h="full"
        pos="fixed"
        top="0"
        left="0"
        transition=".3s"
        maxW={open ? '308px' : '157px'}
        bg="white"
        py="37px"
      >
        <Box w="full" h="full" overflowY="auto" overflowX="hidden">
          <NavLink to="/" onClick={() => setActiveLink('dashboard')}>
            {open ? (
              <Box px={open ? '30px' : '50px'}>
                <Image mx="auto" src={bigLogo} alt="" w="100%" />
              </Box>
            ) : (
              <Image w="130px" mx="auto" src={smallLogo} alt="" />
            )}
          </NavLink>

          <UnorderedList m="0" mt="45px" px={open ? '30px' : '50px'}>
            {menuItems.map((e, i) => {
              if (i > 0) {
                if (AccessPage(user.user.roles, e.role)) {
                  return (
                    <MenuItem
                      key={i}
                      name={e.name}
                      label={e.label}
                      to={e.to}
                      icon={e.icon}
                      subMenus={e.subMenus}
                      sideIsOpen={open}
                      openSide={openSide}
                      changeId={changeId}
                      activeLink={activeLink}
                    />
                  );
                }
              } else {
                return (
                  <MenuItem
                    key={i}
                    name={e.name}
                    label={e.label}
                    to={e.to}
                    icon={e.icon}
                    subMenus={e.subMenus}
                    sideIsOpen={open}
                    openSide={openSide}
                    changeId={changeId}
                    activeLink={activeLink}
                  />
                );
              }
            })}
          </UnorderedList>

          <Box px="30px">
            <Box as="hr" my="30px" />
          </Box>

          <User openSide={openSide} sideIsOpen={open} changeId={changeId} />
        </Box>

        <Button
          minW="34px"
          h="34px"
          p="0"
          cursor="pointer"
          display="grid"
          placeItems="center"
          color="black"
          pos="absolute"
          top="150px"
          right="calc(-34px / 2)"
          bg="white"
          border="2px solid #BFCFD6"
          borderRadius="5px"
          _hover={{ bg: '#fbfbfb', color: 'white' }}
          _active={{ bg: '#fbfbfb' }}
          _focus={{ boxShadow: 'none' }}
          onClick={() => setOpen(!open)}
        >
          <Box
            as={IoIosArrowBack}
            color="text.500"
            transition=".3s"
            transform={open ? 'rotate(0)' : 'rotate(180deg)'}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default Side;
