import * as Yup from 'yup';

export const AddShiftSchema = Yup.object().shape({
  shiftName: Yup.string()
    .min(3, 'signup.minLength')
    .max(250, 'signup.maxLength')
    .required('signup.fieldRequired'),
  checkinTime: Yup.string().required('signup.fieldRequired'),
  checkoutTime: Yup.string().required('signup.fieldRequired'),
  locationName: Yup.string()
    .min(3, 'signup.minLength')
    .max(250, 'signup.maxLength')
    .required('signup.fieldRequired'),
  weekends: Yup.array()
    .min(1, 'modals.selectOneAtLeast')
    .required('modals.selectOneAtLeast'),
});
