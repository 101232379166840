import request from 'axios-interceptors';

export const updateWorkflowProducts = ({ invoiceId, products }) => {
  return request.put(`invoice/products/update/${invoiceId}`, {
    products: products,
  });
};

export const deleteWorkflowProducts = productId => {
  return request.put(`invoice/delete/product/${productId}`);
};

export const getAllProjects = ({ queryKey }) => {
  //   currentPage, searchKey, branch, branchRequired

  let formatedBranch = !!queryKey[3] ? [queryKey[3]] : null;

  return request.get(
    `projects?page=${queryKey[1]}&limit=10&searchKey=${queryKey[2]}&branch=${formatedBranch}&branchRequired=${queryKey[4]}`
  );
};

export const createProject = ({
  // title,
  selectedClient,
  maker,
  model,
  year,
  formatedDate,
  formatedTime,
  branch,
  selectedUser,
  formatedProducts,
}) => {
  return request.post('projects', {
    products: formatedProducts,
    employeeId: selectedUser,
    customerId: selectedClient,
    // title: title,
    date: formatedDate,
    time: formatedTime,
    branch: branch,
    carDetails: {
      carManufactur: maker,
      carModel: model,
      carYear: year,
    },
  });
};

export const updateProject = ({ projectId, date, time, formatedProducts }) => {
  return request.put(`projects/update/${projectId}`, {
    products: formatedProducts,
    date: date,
    time: time,
  });
};


export const deleteProject = projectId => {
  return request.post('projects/delete', {
    workflowIds: projectId,
  });
};

export const deleteProductInProject = productId => {
  return request.post(`projects/delete/product/${productId}`);
};
