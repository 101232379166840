import { useState } from 'react';
import {
  Tr,
  Td,
  Text,
  Tooltip,
  Button,
  Flex,
  Image,
  Box,
  Grid,
} from '@chakra-ui/react';
import { IoMdArrowDropup } from 'react-icons/io';
import moment from 'moment';
import ProjectView from './project-view-modal';
import { SimpleModal } from 'components/shared';
import { StatusBox } from 'components/authenticated/shared';
import { BsStarFill } from 'react-icons/bs';
import emptyStar from 'assets/images/empty-star.png';
import fillStar from 'assets/images/fill-star.png';
import { useTranslation } from 'react-i18next';

const ProjectRow = ({ item, index }) => {
  const [showComment, setShowComment] = useState(false);
  const { t } = useTranslation();

  const stars = value => {
    let arrStars = [];

    for (let i = 0; i < 5; i++) {
      if (i < value) {
        arrStars.push(fillStar);
      } else {
        arrStars.push(emptyStar);
      }
    }

    return arrStars;
  };

  const [show, setShow] = useState(false);

  return (
    <Tr key={index}>
      <Td color="text.200">
        <Text
          as="span"
          cursor="pointer"
          onClick={() => setShow(true)}
          textTransform="uppercase"
        >
          {item.searchId}
        </Text>
      </Td>
      <Td>
        {item?.user
          ? item?.user?.userFirstName + ' ' + item?.user?.userLastName
          : ''}
      </Td>
      <Td>
        {item.workflowStatus === 'completed' &&
          moment(item.endAt).locale(t('common.lang')).format('DD-MM-YYYY')}
      </Td>
      <Td>
        <StatusBox
          status={item.workflowStatus}
          text={
            item.workflowStatus == 'in_queue'
              ? t('status.inqueue')
              : item.workflowStatus == 'in_progress'
              ? t('status.ongoing')
              : item.workflowStatus == 'completed'
              ? t('status.completed')
              : t('status.toBeAssigned')
          }
        />

        {/* <Box
          py="10px"
          w="125px"
          textAlign="center"
          textTransform="capitalize"
          bg={
            item.workflowStatus == 'completed'
              ? 'bg.1000'
              : item.workflowStatus == 'in_queue'
              ? 'rgba(253,189,114,0.2)'
              : 'rgba(237,98,98,0.2)'
          }
          color={
            item.workflowStatus == 'completed'
              ? 'text.2000'
              : item.workflowStatus == 'in_queue'
              ? 'rgba(253,189,114,1)'
              : '#ED6262'
          }
          borderRadius="10px"
          fontWeight="bold"
          fontSize="14px"
        >
          {item.workflowStatus == 'in_queue'
            ? t('status.inqueue')
            : item.workflowStatus == 'in_progress'
            ? t('status.ongoing')
            : item.workflowStatus == 'completed'
            ? t('status.completed')
            : t('status.toBeAssigned')}
        </Box> */}
      </Td>

      <Td>
        {item?.rating?.rate || item?.rating?.rate == 0 ? (
          item?.rating?.comments ? (
            <>
              <Flex
                cursor="default"
                pos="relative"
                onMouseOver={() => setShowComment(true)}
                onMouseOut={() => setShowComment(false)}
              >
                {stars(item?.rating?.rate).map(e => (
                  <Image w="20px" me="5px" src={e} />
                ))}
                <Box
                  bg="white"
                  boxShadow="1px 4px 5px 0 rgba(0,0,0,0.1)"
                  pos="absolute"
                  w="251px"
                  top="32px"
                  p="10px"
                  borderRadius="5px"
                  left="-51px"
                  opacity={showComment ? '1' : '0'}
                  visibility={showComment ? 'visible' : 'hidden'}
                  transition=".3s"
                  zIndex="111"
                >
                  <Box
                    as={IoMdArrowDropup}
                    fontSize="40px"
                    color="white"
                    pos="absolute"
                    top="-24px"
                    left="45%"
                    transform="translateX(-50%)"
                  />
                  <Flex alignItems="center" mb="10px">
                    <Grid
                      placeItems="center"
                      bg="rgba(8,192,93,0.2)"
                      w="25px"
                      h="25px"
                      borderRadius="50%"
                      me="8px"
                      color="#08C05D"
                    >
                      !
                    </Grid>
                    <Text fontSize="15px" fontWeight="bold" color="#181F27">
                      {t('common.clientReview')}
                    </Text>
                  </Flex>
                  <Text whiteSpace="normal" color="#616871" fontSize="14px">
                    {item?.rating?.comments}
                  </Text>
                </Box>
              </Flex>
            </>
          ) : (
            <Flex cursor="default">
              {stars(item?.rating?.rate).map(e => (
                <Image w="20px" me="5px" src={e} />
              ))}
            </Flex>
          )
        ) : item.endAt == null ? (
          ''
        ) : (
          <Text>{t('status.noRating')}</Text>
        )}
      </Td>

      <SimpleModal
        title={t('project.viewProject')}
        show={show}
        setShow={setShow}
      >
        <ProjectView item={item} />
      </SimpleModal>
    </Tr>
  );
};

export default ProjectRow;
