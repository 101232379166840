import { useState } from 'react';
import {
  Box,
  Tr,
  Td,
  Text,
  Grid,
  Flex,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Button,
} from '@chakra-ui/react';
import PopupRequest from '../popup-request';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { changeRequestStatus } from 'apis/clockin/Request';
import { useMutation, queryClient } from 'react-query';
import {
  Filter,
  DeletePopup,
  Pagination,
  Loading,
  Error,
  Nodata,
  CardPopover,
} from 'components/authenticated/shared';
import { CustomAvatar, SimpleModal } from 'components/shared';
import Permission from 'helpers/permission';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const ActionButton = ({ name, ...props }) => {
  return (
    <Text
      as="span"
      p="10px"
      px="15px"
      borderRadius="10px"
      fontSize="12px"
      mr="10px"
      {...props}
    >
      {name}
    </Text>
  );
};

const TableRow = ({ data, mutate, editRequest }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const { status, type, user, id } = data;

  // const { firstName, lastName, phoneNumber, profileImageUrl } = user;

  // const { mutate } = useMutation(changeRequestStatus, {
  //   onSuccess: async result => {
  //     await queryClient.invalidateQueries('getAllRequests');
  //   },
  // });

  return (
    <>
      <Tr
        pos="relative"
        fontSize="14px"
        borderBottom="1px solid #edf2f7"
        bg={status == 'pending' ? '#FEEBE8' : 'initial'}
      >
        <Td display="flex" alignItems="center" border="none">
          <Grid me="8px" placeItems="center">
            <CustomAvatar
              image={user?.profileImageUrl}
              letter={user?.firstName[0]}
              h="40px"
              w="40px"
              mb="0"
              letterSize="16px"
            />
            {/* <Image src={profileImageUrl} w="40px" h="40px" borderRadius="50%" /> */}
          </Grid>
          <Box>
            <Text fontSize="14px" fontWeight="bold" color="text.600">
              {user?.firstName} {user?.lastName}
            </Text>
            <Text fontSize="12px" color="text.600" opacity=".8">
              {user?.phoneNumber}
            </Text>
          </Box>
        </Td>
        <Td color="text.600">
          {type == 'casual leave'
            ? t('home.clockin.casualLeave')
            : type == 'sick leave'
            ? t('home.clockin.sickLeave')
            : t('home.clockin.annualLeave')}
        </Td>
        <Td>
          {Permission('Clockin') == 'Edit' && (
            <Flex mb="8px" alignItems="center">
              {status == 'pending' ? (
                <>
                  <ActionButton
                    name="Approve"
                    bg="bg.2300"
                    color="white"
                    cursor="pointer"
                    onClick={() => mutate({ status: 'accepted', id })}
                  />
                  <ActionButton
                    name="Reject"
                    bg="bg.2400"
                    color="white"
                    cursor="pointer"
                    onClick={() => mutate({ status: 'rejected', id })}
                  />
                  <ActionButton
                    name="Details"
                    bg="bg.1000"
                    color="text.2000"
                    cursor="pointer"
                    onClick={() => setShow(true)}
                  />
                </>
              ) : (
                <Flex justifyContent="space-between" w="100%">
                  <ActionButton
                    name={
                      status == 'rejected'
                        ? t('home.clockin.rejected')
                        : t('home.clockin.accepted')
                    }
                    bg="bg.2500"
                    color="white"
                    textTransform="capitalize"
                    bg={status == 'rejected' ? 'bg.2400' : 'bg.2300'}
                    minW="70px"
                    textAlign="center"
                  />

                  <Popover placement="bottom">
                    <PopoverTrigger>
                      <Button
                        position="absolute"
                        top="23px"
                        right="15px"
                        color="text.1100"
                        fontSize="18px"
                        h="18px"
                        justifyContent="flex-end"
                        bg="transparent"
                        p="0"
                        minW="5px"
                        _focus={{}}
                        _hover={{}}
                        _active={{}}
                      >
                        <Box
                          as={BiDotsVerticalRounded}
                          fontSize="30px"
                          cursor="pointer"
                          color="gray"
                        />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent w="100px" _focus={{}}>
                      <PopoverArrow />
                      <PopoverBody>
                        {status != 'rejected' && (
                          <Box
                            cursor="pointer"
                            textAlign="center"
                            my="10px"
                            onClick={() => setShowEdit(true)}
                          >
                            {t('modals.edit')}
                          </Box>
                        )}

                        <Box
                          cursor="pointer"
                          textAlign="center"
                          my="10px"
                          color={'text.2000'}
                          onClick={() => setShow(true)}
                        >
                          {t('modals.view')}
                        </Box>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Flex>
              )}
            </Flex>
          )}
        </Td>
      </Tr>

      <SimpleModal
        show={show}
        setShow={setShow}
        title={t('home.clockin.requestDetails')}
        titleDir="left"
      >
        <PopupRequest
          setShow={setShow}
          data={data}
          id={id}
          mutate={mutate}
          status={status}
          overflow="outside"
        />
      </SimpleModal>

      <SimpleModal
        show={showEdit}
        setShow={setShowEdit}
        title="Request Details"
        titleDir="left"
        overflow="outside"
      >
        <PopupRequest
          setShow={setShowEdit}
          data={data}
          id={id}
          mutate={mutate}
          status={status}
          editRequest={editRequest}
          editing
        />
      </SimpleModal>
    </>
  );
};

export default TableRow;
