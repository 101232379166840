import { useState } from 'react';
import { Box, Flex, Grid, Text, useToast } from '@chakra-ui/react';
import { SearchInput, CustomToast } from 'components/authenticated/shared';
import { Select } from 'components/shared';
import { AnalysisCard } from 'components/authenticated/attendance';
import { RequestsTable } from 'components/authenticated/requests';
import { getAllRequests } from 'apis/clockin/Request';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { changeRequestStatus, editRequest } from 'apis/clockin/Request';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Requests = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({ label: '', value: null });
  const [searchKey, setSearchkey] = useState('');

  const handleStatus = value => {
    value === 'all' ? setStatus(null) : setStatus(value);
  };

  const toast = useToast();
  const queryClient = useQueryClient();

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getAllRequests', searchKey, status.value],
    getAllRequests
  );

  const { mutate, isLoading: loadingResponse } = useMutation(
    changeRequestStatus,
    {
      onSuccess: async (res, variables) => {
        await queryClient.invalidateQueries('getAllRequests');

        toast({
          render: props => {
            return (
              <CustomToast
                title={`Request ${variables.status}`}
                description={`You ${variables.status} request of this client`}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const { mutate: editRequestById, isLoading: loadingEdit } = useMutation(
    editRequest,
    {
      onSuccess: async (res, variables) => {
        await queryClient.invalidateQueries('getAllRequests');

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.editRequest')}
                description={t('toasts.editRequest')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const numberOfStatus = () => {
    let pending = 0;
    let rejected = 0;
    let approved = 0;

    data?.data.map(e => {
      if (e.status == 'pending') {
        pending++;
      } else if (e.status == 'rejected') {
        rejected++;
      } else {
        approved++;
      }
    });

    return { pending, rejected, approved };
  };

  const { pending, rejected, approved } = numberOfStatus();

  return (
    <Box mx="auto">
      <Flex justifyContent="space-between" alignItems="center" mb="40px">
        <Box>
          <Text fontSize="36px" fontWeight="bold" color="text.1800">
            {t('home.clockin.requests')}
          </Text>
          <Text
            fontSize="14px"
            color="#4b4e50"
            // textAlign="center"
            whiteSpace="nowrap"
            dir="ltr"
          >
            {moment(new Date())
              .locale(t('common.lang'))
              .format('hh:mm A | DD-MM-YYYY')}
            {/* .format('hh:mm A | MMM DD, yyyy')} */}
          </Text>
        </Box>

        <Flex alignItems="center" w="80%" justifyContent="flex-end">
          <Select
            mx="4"
            w="150px"
            h="50px"
            label={t('filter.status')}
            select={status}
            handleSelect={handleStatus}
            selections={[
              { label: t('common.all'), value: null },
              { label: t('home.clockin.pending'), value: 'pending' },
              { label: t('home.clockin.rejected'), value: 'rejected' },
              { label: t('home.clockin.accepted'), value: 'accepted' },
            ]}
          />
          <SearchInput
            placeholder={t('filter.search')}
            h="50px"
            ms="10px"
            setValue={setSearchkey}
          />
        </Flex>
      </Flex>

      <Grid templateColumns="repeat(3,1fr)" gap={5} mb="24px">
        <AnalysisCard
          name={t('home.clockin.pending')}
          number={pending}
          bg="rgba(18,136,90,0.1)"
          color="text.2700"
        />
        <AnalysisCard
          name={t('home.clockin.accepted')}
          number={approved}
          bg="rgba(27,163,239,0.1)"
          color="text.200"
        />
        <AnalysisCard
          name={t('home.clockin.rejected')}
          number={rejected}
          bg="rgba(237,98,98,0.1)"
          color="text.2800"
        />
      </Grid>

      <RequestsTable
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        mutate={mutate}
        editRequest={editRequestById}
      />
    </Box>
  );
};

export default Requests;
