import { useState, useRef } from 'react';
import {
  Tr,
  Td,
  Checkbox,
  Flex,
  Text,
  useToast,
  Box,
  Link,
  Spinner,
} from '@chakra-ui/react';
import {
  DeletePopup,
  CustomPopover,
  CustomToast,
} from 'components/authenticated/shared';
import { SimpleModal, Invoice } from 'components/shared';
import { AiFillSetting, AiFillPrinter } from 'react-icons/ai';
import { getInvoice } from 'apis/invoice/invoice';
// import { ProjectPopup } from 'components/authenticated/projects';
import { useQuery } from 'react-query';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { motion } from 'framer-motion';
//import EditSalary from './edit-modal';

const RowInvoice = ({ item, index, refetch, ...props }) => {
  const { t } = useTranslation();

  const toast = useToast();
  const componentRef = useRef();
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'invoice',
  });

  const LoadingAnimation = () => {
    const dotVariants = {
      hidden: { opacity: 0, scale: 0 },
      visible: { opacity: 1, scale: 0.8 },
    };

    const trasnitions = [
      {
        duration: 0.5,
        repeat: Infinity,
        repeatType: 'reverse',
      },
      {
        duration: 0.5,
        repeat: Infinity,
        repeatType: 'reverse',
        delay: 0.2,
      },
      {
        duration: 0.5,
        repeat: Infinity,
        repeatType: 'reverse',
        delay: 0.4,
      },
    ];

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginInlineStart: '5px',
        }}
      >
        {trasnitions.map(e => {
          return (
            <motion.div
              style={{
                width: '5px',
                height: '5px',
                borderRadius: '50%',
                background: 'black',
              }}
              variants={dotVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={e}
            ></motion.div>
          );
        })}
      </div>
    );
  };

  // const { data } = useQuery(['getInvoice', item?.invoiceId], getInvoice);

  return (
    <Tr {...props}>
      <Td textTransform="uppercase">{item.id}</Td>
      {/* <Td>{item?.name}</Td> */}
      <Td>{item?.number}</Td>

      {/* <Td>{item?.salary} SR</Td>
      <Td>{item?.salary} SR</Td> */}
      <Td>{item?.netCost?.toFixed(2)} SR</Td>

      <Td>
        {item?.date
          ? moment(item?.date).locale(t('common.lang')).format('DD-MM-YYYY')
          : 'not issued yet'}
      </Td>
      {/* <Td>{item?.status}</Td> */}

      {item?.date ? (
        <Td>
          {item?.invoiceUrl ? (
            <Flex>
              <Link
                ms="15px"
                href={item?.invoiceUrl ? item?.invoiceUrl : null}
                target="_blank"
                download="file-1"
                _active={{}}
                _focus={{}}
              >
                <Box
                  as={AiFillPrinter}
                  cursor="pointer"
                  fontSize="20px"
                  color="text.200"
                  // onClick={handlePrint}
                />
              </Link>
            </Flex>
          ) : (
            <Flex>
              <Text>{t('accounting.processing')} </Text>
              <LoadingAnimation />
              {/* <Spinner ms="5px" /> */}
            </Flex>
          )}

          {/* <Box
          as={AiFillPrinter}
          ms="15px"
          cursor="pointer"
          fontSize="20px"
          color="text.200"
          onClick={handlePrint}
        />
        <Box display="none">
          <Invoice data={data?.data} ref={componentRef} />
        </Box> */}
        </Td>
      ) : null}

      {/* <SimpleModal title="Edit Salary" show={showEdit} setShow={setShowEdit}>
        <EditSalary />
      </SimpleModal> */}
      {/* 
      <SimpleModal
        title={t('modals.deleteProject')}
        show={showDelete}
        setShow={setShowDelete}
      >
        <DeletePopup
          title={t('modals.deleteProjectText')}
          id={item.id}
          setShow={setShowDelete}
          handleDelete={() => mutate([item.id])}
          isLoading={loadingDelete}
          buttonText="delete"
        />
      </SimpleModal> */}
    </Tr>
  );
};

export default RowInvoice;
