import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

const CustomModal = ({
  title,
  titleDir = 'center',
  show,
  setShow,
  children,
  overflow = 'inside',
}) => {
  return (
    <>
      <Modal
        isCentered
        onClose={() => setShow({ flag: false, data: {} })}
        isOpen={show.flag}
        motionPreset="slideInBottom"
        scrollBehavior={overflow}
      >
        <ModalOverlay />
        <ModalContent p="40px" maxW="720px">
          <ModalHeader
            textAlign={titleDir}
            fontSize="32px"
            p="0"
            color="text.600"
            mb="40px"
          >
            {title}
          </ModalHeader>
          <ModalCloseButton
            top="20px"
            right="20px"
            fontSize="15px"
            bg="bg.300"
            color="white"
            borderRadius="50%"
            p="20px"
            _focus={{}}
            _hover={{ bg: '#30d2ed' }}
            _active={{ bg: 'bg.400' }}
          />
          <ModalBody p="0">{children}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CustomModal;
