import { Box, Grid, Text, Flex } from '@chakra-ui/react';
import { ImArrowDownRight2 } from 'react-icons/im';
import { MdLocationOn } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const PresentRow = ({
  shift,
  i,
  checkIn,
  checkOut,
  checkInLocation,
  checkOutLocation,
  workingHours,
  lateCheckIn,
  earlyCheckOut,
  outOfRangeCheckIn,
  outOfRangeCheckOut,
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      gridTemplateColumns="2fr 2fr 2fr 2fr "
      borderTop={i !== 0 && '1px solid #edf2f7'}
      py="22px"
      paddingInlineEnd="45px"
    >
      <Box>{shift}</Box>
      <Box>
        <Flex mb="8px" alignItems="center">
          <Box as={ImArrowDownRight2} me="4px" />
          <Text
            fontSize="14px"
            fontWeight="bold"
            color={lateCheckIn ? '#F43838' : 'text.1600'}
          >
            {checkIn}
          </Text>
        </Flex>

        <Flex alignItems="center">
          <Box as={MdLocationOn} me="4px" />
          <Text color={outOfRangeCheckIn && '#F43838'}>{checkInLocation}</Text>
        </Flex>
      </Box>

      <Box>
        <Flex mb="8px" alignItems="center">
          <Box as={ImArrowDownRight2} me="4px" />
          <Text
            fontSize="14px"
            fontWeight="bold"
            color={
              earlyCheckOut || checkOut === 'Checkout by system'
                ? '#F43838'
                : 'text.1600'
            }
          >
            {checkOut}
          </Text>
        </Flex>

        <Flex alignItems="center">
          <Box as={MdLocationOn} me="4px" />
          <Text color={outOfRangeCheckOut && '#F43838'}>
            {checkOutLocation}
          </Text>
        </Flex>
      </Box>

      <Box>{workingHours}</Box>
    </Grid>
  );
};

export default PresentRow;
