import { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  Button,
  Image,
  Heading,
  Grid,
  Spinner,
} from '@chakra-ui/react';
import { AiOutlineUpload } from 'react-icons/ai';
import { RiCloseFill } from 'react-icons/ri';
import axios from 'axios';
import ImageUploading from 'react-images-uploading';
import FileNameSize from '../file-name-size';
import png from 'assets/images/icons/png.svg';
import jpg from 'assets/images/icons/jpg.svg';
import pdf from 'assets/images/icons/pdf.svg';
import xlsx from 'assets/images/icons/xlsx.svg';
import docx from 'assets/images/icons/docx.svg';
import { CustomButton } from 'components/shared';
import { useMutation } from 'react-query';
import { createComment } from 'apis/tickets';
import { ErrorMsg } from 'components/shared';
import { useTranslation } from 'react-i18next';

const UploadFile = ({ setUploadFiles, id, refetch, setShow }) => {
  const [images, setImages] = useState([]);
  const { t } = useTranslation();

  const maxNumber = 1;

  const { mutate, isLoading } = useMutation(createComment, {
    onSuccess: () => {
      setShow(false);
      refetch();
    },
  });

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    setImages(imageList);
  };

  const sendMessage = () => {
    const fd = new FormData();
    fd.append('attachment', images[0].file);
    fd.append('comment', 'send text with attachment');
    mutate({ ticketId: id, data: fd });
  };

  return (
    <Box>
      <Text textAlign="center" fontWeight="bold" fontSize="20px" mb="20px">
        {t('ticket.uploadFiles')}
      </Text>

      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        allowNonImageType
        acceptType={['jpg', 'gif', 'png', 'pdf', 'xlsx', 'docx']}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          errors,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <>
            {/* <Flex flexDir="column" h="200px">
              {imageList.map((image, index) => (
                <FileNameSize
                  image={
                    image.file.type == 'image/jpg' ||
                    image.file.type == 'image/jpeg'
                      ? jpg
                      : image.file.type == 'image/png'
                      ? png
                      : image.file.type == 'application/pdf'
                      ? pdf
                      : ''
                  }
                  name={image.file.name}
                  size={(image.file.size / 1000).toFixed(2) + ' ' + 'kb'}
                  h="calc(100% / 3)"
                  iconSize="30px"
                  textSize="14px"
                  buttonW="20px"
                  onClose={() => onImageRemove(index)}
                />
              ))}

              {imageList.length == 3 ? null : (
                <Flex
                  border="2px dashed #788596"
                  h={
                    imageList.length == 1
                      ? 'calc(100% - (100% / 3))'
                      : imageList.length == 2
                      ? 'calc(100% / 3)'
                      : '100%'
                  }
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="5px"
                  bg="bg.2200"
                  cursor="pointer"
                  opacity={isDragging ? 0.5 : 1}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <Box
                    as={AiOutlineUpload}
                    fontSize={imageList.length > 0 ? '30px' : '45px'}
                  />
                </Flex>
              )}
            </Flex> */}

            {imageList.map((image, index) => (
              <Box h="200px">
                <Image
                  w="100px"
                  m="auto"
                  src={
                    image.file.type == 'image/jpg' ||
                    image.file.type == 'image/jpeg'
                      ? jpg
                      : image.file.type == 'image/png'
                      ? png
                      : image.file.type == 'application/pdf'
                      ? pdf
                      : image.file.type ==
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                      ? docx
                      : image.file.type ==
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      ? xlsx
                      : ''
                  }
                />
                <Flex justifyContent="center" alignItems="center" dir="ltr">
                  <Text textAlign="center" me="20px">
                    {image.file.name}
                  </Text>

                  <Grid
                    w="20px"
                    h="20px"
                    borderRadius="50%"
                    bg="rgba(240,78,117,20%)"
                    placeItems="center"
                    fontSize="12px"
                    fontWeight="bold"
                    cursor="pointer"
                    onClick={() => onImageRemove(index)}
                  >
                    <Box as={RiCloseFill} color="#F04E4E" />
                  </Grid>
                </Flex>

                <Text
                  textAlign="center"
                  fontSize="15px"
                  color="#A3AEB4"
                  dir="ltr"
                >
                  {(image.file.size / 1000).toFixed(2) + ' ' + 'kb'}
                </Text>

                {/* <FileNameSize
                  image={
                    image.file.type == 'image/jpg' ||
                    image.file.type == 'image/jpeg'
                      ? jpg
                      : image.file.type == 'image/png'
                      ? png
                      : image.file.type == 'application/pdf'
                      ? pdf
                      : ''
                  }
                  name={image.file.name}
                  size={(image.file.size / 1000).toFixed(2) + ' ' + 'kb'}
                  h="calc(100% / 3)"
                  iconSize="30px"
                  textSize="14px"
                  buttonW="20px"
                  onClose={() => onImageRemove(index)}
                /> */}
              </Box>
            ))}

            {imageList.length == 1 ? null : (
              <Flex
                h="200px"
                border="2px dashed #788596"
                justifyContent="center"
                alignItems="center"
                borderRadius="5px"
                bg="bg.2200"
                cursor="pointer"
                opacity={isDragging ? 0.5 : 1}
                onClick={onImageUpload}
                {...dragProps}
              >
                <Box
                  as={AiOutlineUpload}
                  fontSize={imageList.length > 0 ? '30px' : '45px'}
                />
              </Flex>
            )}

            {errors && (
              <Box>
                {errors.maxNumber && (
                  <ErrorMsg text="max number allowed to upload images is just one file" />
                )}
                {errors.acceptType && (
                  <span>Your selected file type is not allow</span>
                )}
                {errors.maxFileSize && (
                  <span>Selected file size exceed maxFileSize</span>
                )}
                {errors.resolution && (
                  <span>
                    Selected file is not match your desired resolution
                  </span>
                )}
              </Box>
            )}

            {imageList.length > 0 && (
              <CustomButton
                name="send"
                loading={isLoading && <Spinner ms="5px" />}
                disabled={isLoading}
                display="flex"
                alignItems="center"
                justifyContent="center"
                mt="15px"
                h="50px"
                bg
                onClick={() => {
                  sendMessage();
                }}
              />
            )}
          </>
        )}
      </ImageUploading>
    </Box>
  );
};

export default UploadFile;
