import { Box, Grid } from '@chakra-ui/react';

const CustomTable = ({ headers, children, thBg, ...props }) => {
  return (
    <Box borderRadius="15px" bg="white" {...props}>
      <Grid
        w="100%"
        gridTemplateColumns="2fr 2fr 2fr 2fr 2fr"
        p="22px"
        borderBottom="1px solid #edf2f7"
        bg={thBg}
      >
        {headers?.map(e => {
          return (
            <Box color="#181F27" fontWeight="bold">
              {e}
            </Box>
          );
        })}
      </Grid>
      {children}
    </Box>
  );
};

export default CustomTable;
