import { Box, Text, Flex, Grid, GridItem } from '@chakra-ui/react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import { getLastProjects } from 'apis/dashboard';
import { getProjectsCount } from 'apis/dashboard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Chart = ({ isView, data, data1, noAccess, ...props }) => {
  const { t } = useTranslation();

  const getProjectsByDate = date => {
    let num = data?.data.filter(elm => date.isSame(elm.endAt, 'date')).length;
    return num;
  };

  const getEarningsByDate = date => {
    let sum = 0;

    data?.data.map(elm => {
      if (!date.isSame(elm.endAt, 'date')) return;

      sum += elm.invoice.totalCost;
    });

    return sum;
  };

  const getServicesByDate = date => {
    let sum = 0;

    let filteredProjects = data?.data.filter(elm =>
      date.isSame(elm.endAt, 'date')
    );
    let filteredServices = filteredProjects.map(elm => {
      sum += elm.invoice.invoiceProducts.filter(
        e => e.type === 'service'
      ).length;
    });
    return sum;
  };

  const getPartsByDate = date => {
    let sum = 0;

    let filteredProjects = data?.data.filter(elm =>
      date.isSame(elm.endAt, 'date')
    );
    let filteredServices = filteredProjects.map(elm => {
      sum += elm.invoice.invoiceProducts.filter(e => e.type === 'part').length;
    });
    return sum;
  };

  const emptyArray = [0, 0, 0, 0, 0, 0, 0];

  return (
    <Flex
      flexWrap="wrap"
      // h="400px"
      templateColumns={[
        'repeat(3, 1fr)',
        'repeat(3, 1fr)',
        'repeat(1, 1fr)',
        'repeat(1, 1fr)',
        'repeat(3, 1fr)',
      ]}
      // gap="20px"
      {...props}
    >
      <Box
        w={['58%', '58%', '100%', '100%', '58%']}
        bg="white"
        borderRadius="10px"
        me={['2%', '2%', '0', '0', '2%']}
        mb={['20px', '20px', '20px', '20px', '0px']}
      >
        <Box pos="relative">
          {/* {!isView && (
            <Box
              pos="absolute"
              top="0"
              left="0"
              bottom="0"
              w="100%"
              h="100%"
              bg="rgba(255, 255, 255, 0.4);"
              zIndex="111"
              borderRadius="10px"
              backdropFilter="blur(4px)"
            />
          )} */}

          <Bar
            data={{
              labels: [
                moment()
                  .locale(t('common.lang'))
                  .subtract(6, 'days')
                  .format('DD-MM-YYYY'),
                moment()
                  .locale(t('common.lang'))
                  .subtract(5, 'days')
                  .format('DD-MM-YYYY'),
                moment()
                  .locale(t('common.lang'))
                  .subtract(4, 'days')
                  .format('DD-MM-YYYY'),
                moment()
                  .locale(t('common.lang'))
                  .subtract(3, 'days')
                  .format('DD-MM-YYYY'),
                moment()
                  .locale(t('common.lang'))
                  .subtract(2, 'days')
                  .format('DD-MM-YYYY'),
                t('home.dashboard.yesterday'),
                t('home.dashboard.today'),
              ],
              datasets: [
                {
                  label: t('home.dashboard.projects'),
                  data: noAccess
                    ? emptyArray
                    : [
                        getProjectsByDate(moment().subtract(6, 'days')),
                        getProjectsByDate(moment().subtract(5, 'days')),
                        getProjectsByDate(moment().subtract(4, 'days')),
                        getProjectsByDate(moment().subtract(3, 'days')),
                        getProjectsByDate(moment().subtract(2, 'days')),
                        getProjectsByDate(moment().subtract(1, 'days')),
                        getProjectsByDate(moment().subtract(0, 'days')),
                      ],
                  backgroundColor: ['#B8C2EB'],
                  borderRadius: 999,
                  barThickness: 10,
                },
                {
                  label: t('home.dashboard.earnings'),
                  data: noAccess
                    ? emptyArray
                    : [
                        getEarningsByDate(moment().subtract(6, 'days')),
                        getEarningsByDate(moment().subtract(5, 'days')),
                        getEarningsByDate(moment().subtract(4, 'days')),
                        getEarningsByDate(moment().subtract(3, 'days')),
                        getEarningsByDate(moment().subtract(2, 'days')),
                        getEarningsByDate(moment().subtract(1, 'days')),
                        getEarningsByDate(moment().subtract(0, 'days')),
                      ],
                  backgroundColor: ['#7686CA'],
                  borderRadius: 999,
                  barThickness: 10,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  labels: {
                    font: {
                      style: 'normal',
                      weight: '600',
                    },
                    boxHeight: 7.5,
                    boxWidth: 7.5,
                    borderRadius: 7.5,
                    usePointStyle: true,
                    pointSytle: 'circle',
                    padding: 20,
                  },
                  position: 'top',
                  align: 'end',
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                },
              },
            }}
            style={{ width: '100%', height: '400px' }}
          />
        </Box>
      </Box>

      <Flex
        w={['40%', '40%', '100%', '100%', '40%']}
        flexDirection={['column', 'column', 'row', 'row', 'column']}
        justifyContent={[
          'space-between',
          'space-between',
          'space-between',
          'space-between',
          'initial',
        ]}
      >
        <Box
          bg="white"
          borderRadius="10px"
          mb={['15px', '15px', '0', '0', '15px']}
          w={['48%', '48%', '49%', '49%', 'initial']}
        >
          <Box pos="relative">
            {/* {!isView && (
              <Box
                pos="absolute"
                top="0"
                left="0"
                bottom="0"
                w="100%"
                h="100%"
                bg="rgba(255, 255, 255, 0.4);"
                zIndex="111"
                borderRadius="10px"
                backdropFilter="blur(4px)"
              />
            )} */}

            <Line
              data={{
                labels: [
                  moment()
                    .locale(t('common.lang'))
                    .subtract(4, 'days')
                    .format('DD-MM-YYYY'),
                  moment()
                    .locale(t('common.lang'))
                    .subtract(3, 'days')
                    .format('DD-MM-YYYY'),
                  moment()
                    .locale(t('common.lang'))
                    .subtract(2, 'days')
                    .format('DD-MM-YYYY'),
                  t('home.dashboard.yesterday'),
                  t('home.dashboard.today'),
                ],
                datasets: [
                  {
                    label: [t('home.dashboard.services')],
                    data: noAccess
                      ? emptyArray
                      : [
                          getServicesByDate(moment().subtract(4, 'days')),
                          getServicesByDate(moment().subtract(3, 'days')),
                          getServicesByDate(moment().subtract(2, 'days')),
                          getServicesByDate(moment().subtract(1, 'days')),
                          getServicesByDate(moment().subtract(0, 'days')),
                        ],
                    backgroundColor: '#228CB8',
                    borderColor: '#228CB8',
                    tension: 0.5,
                    borderWidth: 2,
                    pointBorderWidth: 1,
                  },
                  {
                    label: [t('home.dashboard.parts')],
                    data: noAccess
                      ? emptyArray
                      : [
                          getPartsByDate(moment().subtract(4, 'days')),
                          getPartsByDate(moment().subtract(3, 'days')),
                          getPartsByDate(moment().subtract(2, 'days')),
                          getPartsByDate(moment().subtract(1, 'days')),
                          getPartsByDate(moment().subtract(0, 'days')),
                        ],
                    backgroundColor: '#18A38F',
                    borderColor: '#18A38F',
                    tension: 0.5,
                    borderWidth: 2,
                    pointBorderWidth: 1,
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    labels: {
                      font: {
                        style: 'normal',
                        weight: '600',
                      },
                      boxHeight: 5,
                      boxWidth: 5,
                      borderRadius: 5,
                      usePointStyle: true,
                      pointSytle: 'circle',
                      padding: 10,
                    },
                    position: 'top',
                    align: 'end',
                  },
                },
                scales: {
                  y: {
                    beginAtZero: true,
                  },
                },
              }}
              style={{ width: '100%', height: '190px' }}
            />
          </Box>
        </Box>

        <Box
          bg="white"
          borderRadius="10px"
          pos="relative"
          w={['48%', '48%', '49%', '49%', 'initial']}
        >
          <Box>
            <Box pos="relative" p="10px">
              <Doughnut
                data={{
                  labels: [
                    t('home.dashboard.completed'),
                    t('home.dashboard.inProgress'),
                    t('home.dashboard.notCompleted'),
                  ],
                  datasets: [
                    {
                      labels: ['completed', 'in progress', 'in queue'],
                      data: noAccess
                        ? []
                        : [
                            data1?.data.completed,
                            data1?.data.inProgress,
                            data1?.data.inQueue,
                          ],
                      backgroundColor: ['#25DAD1', '#F9F67A', '#619BD8'],
                      borderRadius: 999,
                    },
                  ],
                }}
                options={{
                  maintainAspectRatio: false,
                  cutout: 65,
                  plugins: {
                    legend: {
                      labels: {
                        font: {
                          style: 'normal',
                          weight: '600',
                          size: '16',
                        },
                        boxHeight: 10,
                        boxWidth: 10,
                        borderRadius: 10,
                        usePointStyle: true,
                        pointSytle: 'circle',
                        padding: 20,
                      },
                      position: 'top',
                      align: 'center',
                    },
                  },
                }}
                width={200}
                height={200}
                // style={{ width: '100%', height: '190px' }}
              />
            </Box>

            <Box
              textAlign="center"
              pos="absolute"
              top="43%"
              left="50%"
              transform="translateX(-50%)"
            >
              <Text color="text.600" fontSize="32px" fontWeight="bold">
                {/* {} */}
                {isView
                  ? data1?.data.inQueue +
                    data1?.data.inProgress +
                    data1?.data.completed
                  : '--'}
              </Text>
              <Text fontSize="14px" color="gray.400" fontWeight="bold">
                {t('home.dashboard.totalProjects')}
              </Text>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default Chart;
