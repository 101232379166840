import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Text, Tr, Td, Checkbox, Flex, useToast, Box } from '@chakra-ui/react';

import {
  Filter,
  Table,
  DeletePopup,
  Pagination,
  Loading,
  Nodata,
  Error,
  CustomToast,
  SearchInput,
} from 'components/authenticated/shared';
import {
  Select,
  CustomModal,
  SimpleModal,
  CustomButton,
} from 'components/shared';

import { useQuery, useMutation } from 'react-query';
import { getAllProjects, deleteProject } from 'apis/projects';
import moment from 'moment';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';
import { AiFillSetting, AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineUpload } from 'react-icons/hi';

import { getAllInvoices } from 'apis/invoice/invoice';
import { RowInvoice, ExportModal } from 'components/authenticated/invoices';

const Invoices = ({ clientId, name, setClientInvoicesCount }) => {
  const toast = useToast();

  const { t } = useTranslation();
  const { user, branchFilter } = useContext(userContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchkey] = useState('');
  const [invoiceStatus, setInvoiceStatus] = useState({
    label: 'all',
    value: 'all',
  });
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [showExport, setShowExport] = useState(false);

  const [branch, setBranch] = useState(branchFilter);

  useEffect(() => {
    setBranch(branchFilter);
  }, [branchFilter]);

  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    ['getAllInvoices', invoiceStatus?.value, clientId, branch?.value],
    getAllInvoices,
    {
      onSuccess: res => {
        setClientInvoicesCount(res?.data.length);
      },
    }
  );

  const handleInvoiceStatus = value => {
    //setPageNumber(1);
    setInvoiceStatus(value);
  };

  const handlePageClick = async data => {
    setPageNumber(data.selected + 1);
  };

  const handleBranch = value => {
    setBranch(value);
  };

  //   let numberOfPages = data?.data.meta.totalPages;
  //   let itemsPerPage = data?.data.meta.itemsPerPage;
  //   let currentPage = data?.data.meta.currentPage;
  //   let itemCount = data?.data.meta.itemCount;
  //   let totalItems = data?.data.meta.totalItems;

  const headersWithoutCheck = [
    t('modals.id'),
    //t('modals.clientName'),
    t('modals.clientNumber'),
    //t('modals.services'),
    //t('modals.parts'),
    t('modals.totalCost'),
    t('modals.date'),
    //'status',
    t('accounting.print'),
  ];

  return (
    <>
      <Flex justifyContent="flex-end">
        <Select
          selectTitle={t('modals.selectBranch')}
          mx={['4', '', '2', '2', '4']}
          w="170px"
          h="50px"
          label="Branch"
          select={branch}
          handleSelect={handleBranch}
          selections={user.user.branches?.map((item, index) => ({
            label: item.name,
            value: item.id,
          }))}
        />

        <CustomButton
          textTransform="capitalize"
          w="180px"
          bg
          name={t('accounting.exportInvoices')}
          h="50px"
          fontSize="14px"
          icon={HiOutlineUpload}
          onClick={() => setShowExport(true)}
          // disabled={shift?.value == undefined}
        />
      </Flex>

      <Box minH="72vh">
        {isLoading && <Loading />}

        {isSuccess ? (
          data?.data.length > 0 ? (
            <Table
              headers={headersWithoutCheck}
              theadHeight="71px"
              mb="24px"
              //length={data?.data.items.length}
            >
              {data?.data.map(e => {
                return (
                  <RowInvoice
                    item={{
                      name: e?.customer.name,
                      number: e?.customer.mobileNumber,
                      id: e?.searchId,
                      salary: '100',
                      status: e?.status,
                      invoiceId: e?.id,
                      totalCost: e?.total,
                      date: e?.issuedAt,
                      invoiceUrl: e?.invoiceUrl,
                    }}
                    // index={index}

                    refetch={refetch}

                    //bg={item.deletedAt ? 'gray.200' : 'white'}
                  />
                );
              })}
            </Table>
          ) : (
            <Nodata />
          )
        ) : null}

        {isError && <Error />}
      </Box>
      {/* <Pagination
          handlePageClick={handlePageClick}
          numberOfPages={numberOfPages}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          itemCount={itemCount}
          totalItems={totalItems}
          forcePage={currentPage - 1}
        /> */}

      <SimpleModal
        title="export invoices"
        show={showExport}
        setShow={setShowExport}
        overflow="outside"
      >
        <ExportModal
          branch={branch}
          customerId={clientId}
          customerName={name}
          // data={userData?.data}
          // shifts={shifts}
          // shift={shift}
          // branch={branch}
          // createdAt={userData?.data.createdAt}
        />
      </SimpleModal>
    </>
  );
};

export default Invoices;
