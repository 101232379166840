import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Text, Tr, Td, Checkbox, Flex, useToast, Box } from '@chakra-ui/react';

import {
  Filter,
  Table,
  DeletePopup,
  Pagination,
  Loading,
  Nodata,
  Error,
  CustomToast,
} from 'components/authenticated/shared';
import { Select, CustomModal, SimpleModal } from 'components/shared';
import { ProjectPopup, RowProject } from 'components/authenticated/projects';
import { useQuery, useMutation } from 'react-query';
import { getAllProjects, deleteProject } from 'apis/projects';
import moment from 'moment';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const toast = useToast();

  const { t } = useTranslation();
  const { user, branchFilter } = useContext(userContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [branch, setBranch] = useState(branchFilter);

  useEffect(() => {
    setBranch(branchFilter);
  }, [branchFilter]);

  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    ['getAllProjects', pageNumber, searchKey, branch.value, true],
    getAllProjects
  );

  const { mutate, isLoading: loadingDelete } = useMutation(deleteProject, {
    onSuccess: () => {
      setShowDelete(false);
      refetch();
      setIsCheck([]);

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.removeMultiProjects')}
              description={t('toasts.removeMultiProjects')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.removeMultiProjects')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleBranch = value => {
    setPageNumber(1);
    setBranch(value);
  };

  const handlePageClick = async data => {
    setPageNumber(data.selected + 1);
  };

  let numberOfPages = data?.data.meta.totalPages;
  let itemsPerPage = data?.data.meta.itemsPerPage;
  let currentPage = data?.data.meta.currentPage;
  let itemCount = data?.data.meta.itemCount;
  let totalItems = data?.data.meta.totalItems;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(data?.data.items);
  }, [isLoading]);

  const headers = [
    'check',
    t('modals.id'),
    t('modals.clientNumber'),
    t('modals.services'),
    t('modals.parts'),
    t('modals.totalCost'),
    t('modals.date'),
    t('accounting.invoice'),
    'option',
  ];

  const headersWithoutCheck = [
    t('modals.id'),
    t('modals.clientNumber'),
    t('modals.services'),
    t('modals.parts'),
    t('modals.totalCost'),
    t('modals.date'),
  ];

  return (
    <>
      <Filter
        title={t('home.projects.title')}
        buttonName={t('home.projects.addNewProject')}
        setShowAdd={setShowAdd}
        setValue={setSearchKey}
        isCheck={isCheck}
        setShowDelete={setShowDelete}
        titleDelete={t('home.projects.title')}
        btResponsiveWidth="180px"
        isButtonExist={Permission('Projects') == 'Edit'}
        buttonText="delete"
      >
        <Select
          selectTitle={t('modals.selectBranch')}
          mx="4"
          w="150px"
          h="40px"
          label="Branch"
          select={branch}
          handleSelect={handleBranch}
          selections={user.user.branches?.map((item, index) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </Filter>

      {isLoading && <Loading />}

      {isSuccess &&
        (totalItems > 0 ? (
          <>
            <Box minH="72vh">
              <Table
                headers={
                  Permission('Projects') == 'Edit'
                    ? headers
                    : headersWithoutCheck
                }
                theadHeight="71px"
                mb="24px"
                setIsCheckAll={setIsCheckAll}
                setIsCheck={setIsCheck}
                isCheckAll={isCheckAll}
                list={list}
                length={data?.data.items.length}
                isCheck={isCheck}
              >
                {data?.data.items.map((item, index) => (
                  <RowProject
                    key={index}
                    item={item}
                    index={index}
                    setIsCheck={setIsCheck}
                    refetch={refetch}
                    isCheck={isCheck}
                    isCheckExist={Permission('Projects') == 'Edit'}
                    bg={item.deletedAt ? 'gray.200' : 'white'}
                  />
                ))}
              </Table>
            </Box>
            <Pagination
              handlePageClick={handlePageClick}
              numberOfPages={numberOfPages}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              itemCount={itemCount}
              totalItems={totalItems}
              forcePage={currentPage - 1}
            />
          </>
        ) : (
          <Nodata />
        ))}

      {isError && <Error />}

      <SimpleModal
        title={t('home.projects.addNewProject')}
        show={showAdd}
        setShow={setShowAdd}
      >
        <ProjectPopup setShow={setShowAdd} refetchProjects={refetch} />
      </SimpleModal>

      <SimpleModal
        title={t('modals.deactivateProjects')}
        show={showDelete}
        setShow={setShowDelete}
      >
        <DeletePopup
          handleDelete={() => {
            mutate(isCheck);
          }}
          isLoading={loadingDelete}
          title={t('modals.confirmDeactivateProjects')}
          buttonText="delete"
        />
      </SimpleModal>
    </>
  );
};

export default Projects;
