import { useRef } from 'react';
import {
  Box,
  Grid,
  Flex,
  Button,
  Image,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import {
  CustomButton,
  CustomInput,
  CustomUploadFile,
  ErrorMsg,
} from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import subCatIcon from 'assets/images/sub-cat.svg';
import arrowDirection from 'assets/images/dir-arrow.svg';
import { Formik, Form, FieldArray } from 'formik';
import { addCategoryValidation } from 'schemas/add-category';
import { useMutation } from 'react-query';
import defaultUploadIcon from 'assets/images/default-upload-icon.png';
import { editCategorie, uploadCategoryIcon } from 'apis/categories';
import { useTranslation } from 'react-i18next';

const EditCategory = ({
  id,
  name,
  nameAr,
  iconPath,
  refetchCategories,
  setShowModal,
}) => {
  const toast = useToast();
  const { t } = useTranslation();

  const { mutate, isLoading } = useMutation(editCategorie, {
    onSuccess: () => {
      refetchCategories();
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('categories.editCategory')}
              description={t('categories.editSuccess')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
      setShowModal();
    },
    onError: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('categories.editCategory')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const responseUrl = `${process.env.REACT_APP_API_BASE_URL}/uploads/categories/`;

  return (
    <>
      <Box>
        <Formik
          initialValues={{
            name,
            nameAr,
            fileName: iconPath,
          }}
          validationSchema={addCategoryValidation}
          onSubmit={values => {
            mutate({
              id,
              name: values?.name,
              nameAr: values?.nameAr,
              fileName: values?.fileName?.replace(
                `${process.env.REACT_APP_API_BASE_URL}/uploads/categories/`,
                ''
              ),
            });
          }}
        >
          {formik => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <CustomUploadFile
                  uploadEndPoint={uploadCategoryIcon}
                  textTitle={t('categories.editIcon')}
                  mb="0"
                  responseUrl={responseUrl}
                  defaultImage={iconPath ? iconPath : defaultUploadIcon}
                  radius
                  formikChange={value =>
                    formik.setFieldValue('fileName', value)
                  }
                />

                <Grid gridTemplateColumns="repeat(2,1fr)" gap="15px">
                  <Box>
                    <CustomInput
                      label={t('categories.categoryEn')}
                      onChange={formik.handleChange('name')}
                      onBlur={formik.handleBlur('name')}
                      value={formik.values.name}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <ErrorMsg text={formik.errors.name} />
                    )}
                  </Box>

                  <Box>
                    <CustomInput
                      label={t('categories.categoryAr')}
                      onChange={formik.handleChange('nameAr')}
                      onBlur={formik.handleBlur('nameAr')}
                      value={formik.values.nameAr}
                    />
                    {formik.errors.nameAr && formik.touched.nameAr && (
                      <ErrorMsg text={formik.errors.nameAr} />
                    )}
                  </Box>
                </Grid>

                <CustomButton
                  name={t('account.save')}
                  bg
                  mt="30px"
                  type="submit"
                  loading={isLoading && <Spinner me="5px" />}
                  disabled={isLoading}
                  display="flex"
                  alignItems="center"
                />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </>
  );
};

export default EditCategory;
