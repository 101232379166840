import { useState } from 'react';
import {
  Box,
  Flex,
  Grid,
  Text,
  Checkbox,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import {
  CustomInput,
  PhoneInput,
  Select,
  Countries,
  CustomButton,
  ErrorMsg,
} from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { useMutation, useQueryClient } from 'react-query';
import { AddClientSchema } from 'schemas/crm/AddClientSchema';
import { Formik, Form } from 'formik';
import { createClient, updateClient } from 'apis/client';
import { useTranslation } from 'react-i18next';

const ClientPopup = ({ setShow, data, refetch }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const toast = useToast();
  const [taxable, setTaxable] = useState(data && data.taxNumber ? true : false);

  const [errorsBack, setErrorsBack] = useState({});

  const [country, setCountry] = useState({
    label: data ? data.country : null,
    value: data ? data.country : null,
  });

  const handleCountry = e => {
    setCountry(e);
  };

  const handleSubmit = useMutation(
    ({ values, country, taxable }) =>
      data
        ? updateClient(values, country, taxable, data.id)
        : createClient(values, country, taxable),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllClients');
        if (refetch) {
          refetch();
        }

        data ? setShow({ flag: false, data: {} }) : setShow(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updateClient')
                    : t('toasts.titles.createClient')
                }
                description={
                  data ? t('toasts.clientUpdated') : t('toasts.clientCreated')
                }
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        setErrorsBack(result.response.data.errors);
        if (result.response.data.errors.mobileNumber?.alreadyExist) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={
                    data
                      ? t('toasts.titles.updateClient')
                      : t('toasts.titles.createClient')
                  }
                  description={t('signup.phoneAlreadyExists')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else if (result.response.data.errors.mobileNumber?.isPhoneNumber) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={
                    data
                      ? t('toasts.titles.updateClient')
                      : t('toasts.titles.createClient')
                  }
                  description={t('signup.isPhoneNumber')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={
                    data
                      ? t('toasts.titles.updateClient')
                      : t('toasts.titles.createClient')
                  }
                  description={t('toasts.error')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        }
      },
    }
  );

  return (
    <Flex justifyContent="center">
      <Box maxW="80%">
        <Formik
          initialValues={{
            name: data ? data.name : '',
            phone: data ? data.mobileNumber : '',
            email: data ? data.emailAddress : '',
            street: data ? data.street : '',
            city: data ? data.city : '',
            zipCode: data ? data.zipCode : '',
            notes: data ? data.note : '',
            taxNumber: data ? data.taxNumber : '',
            buildingNumber: '',
            districtName: '',
            additionalNumber: '',
          }}
          validationSchema={AddClientSchema}
          onSubmit={values => {
            if (
              taxable &&
              (values.taxNumber == null || values.taxNumber == '')
            ) {
              toast({
                render: props => {
                  return (
                    <CustomToast
                      title={t('account.taxNumber')}
                      description={t('toasts.taxNumber')}
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else {
              handleSubmit.mutate({
                values,
                country,
                taxable,
              });
            }
          }}
        >
          {formik => (
            <Form>
              <Box w="full" mb="24px">
                <CustomInput
                  type="text"
                  label={t('modals.clientName')}
                  placeholder={t('home.clients.enterName')}
                  value={formik.values.name}
                  onChange={formik.handleChange('name')}
                  onBlur={formik.handleBlur('name')}
                />
                {formik.errors.name && formik.touched.name && (
                  <ErrorMsg text={t(formik.errors.name)} />
                )}
              </Box>

              <Box w="full" mb="24px">
                <PhoneInput
                  label={t('modals.phone')}
                  value={formik.values.phone}
                  onChange={formik.handleChange('phone')}
                  onBlur={formik.handleBlur('phone')}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <ErrorMsg text={t(formik.errors.phone)} />
                )}
              </Box>

              <Box w="full" mb="24px">
                <CustomInput
                  type="email"
                  label={t('modals.email')}
                  placeholder={t('home.clients.enterEmail')}
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                />
                {formik.errors.email && formik.touched.email && (
                  <ErrorMsg text={t(formik.errors.email)} />
                )}
              </Box>

              <Flex justifyContent="space-between">
                <Box w="48%">
                  <Text fontSize="14px" mb="5px" fontWeight="bold">
                    {t('signup.country')}
                  </Text>
                  <Select
                    h="60px"
                    label={t('signup.country')}
                    select={country}
                    handleSelect={handleCountry}
                    selections={Countries.map(elm => ({
                      label: elm.name,
                      value: elm.name,
                    }))}
                  />
                </Box>

                <Box w="48%" mb="24px">
                  <CustomInput
                    type="text"
                    label={t('signup.city')}
                    placeholder={t('home.clients.enterCity')}
                    value={formik.values.city}
                    onChange={formik.handleChange('city')}
                    onBlur={formik.handleBlur('city')}
                  />
                  {formik.errors.city && formik.touched.city && (
                    <ErrorMsg text={t(formik.errors.city)} />
                  )}
                </Box>
              </Flex>

              <Flex justifyContent="space-between" mb="24px">
                <Box w="48%" mr="4">
                  <CustomInput
                    type="text"
                    label={t('signup.street')}
                    placeholder={t('home.clients.enterStreet')}
                    value={formik.values.street}
                    onChange={formik.handleChange('street')}
                    onBlur={formik.handleBlur('street')}
                  />
                  {formik.errors.street && formik.touched.street && (
                    <ErrorMsg text={t(formik.errors.street)} />
                  )}
                </Box>
                <Box w="48%">
                  <CustomInput
                    type="number"
                    label={t('signup.zipCode')}
                    placeholder={t('home.clients.enterZipcode')}
                    value={formik.values.zipCode}
                    onChange={formik.handleChange('zipCode')}
                    onBlur={formik.handleBlur('zipCode')}
                  />
                  {formik.errors.zipCode && formik.touched.zipCode && (
                    <ErrorMsg text={t(formik.errors.zipCode)} />
                  )}
                </Box>
              </Flex>

              <Grid gridTemplateColumns="repeat(2,1fr)" gap="20px" mb="24px">
                <Box>
                  <CustomInput
                    type="number"
                    label={'Building Number'}
                    placeholder={'Building Number'}
                    value={formik.values.buildingNumber}
                    onChange={formik.handleChange('buildingNumber')}
                    onBlur={formik.handleBlur('buildingNumber')}
                  />
                  {formik.errors.buildingNumber &&
                    formik.touched.buildingNumber && (
                      <ErrorMsg text={t(formik.errors.buildingNumber)} />
                    )}
                </Box>
                <Box>
                  <CustomInput
                    type="number"
                    label={'Additional Number'}
                    placeholder={'Additional Number'}
                    value={formik.values.additionalNumber}
                    onChange={formik.handleChange('additionalNumber')}
                    onBlur={formik.handleBlur('additionalNumber')}
                  />
                  {formik.errors.additionalNumber &&
                    formik.touched.additionalNumber && (
                      <ErrorMsg text={t(formik.errors.additionalNumber)} />
                    )}
                </Box>
              </Grid>

              <Box mb="24px">
                <CustomInput
                  type="text"
                  label={'District Name'}
                  placeholder={'District Name'}
                  value={formik.values.districtName}
                  onChange={formik.handleChange('districtName')}
                  onBlur={formik.handleBlur('districtName')}
                />
                {formik.errors.districtName && formik.touched.districtName && (
                  <ErrorMsg text={t(formik.errors.districtName)} />
                )}
              </Box>

              <Box w="full" maxW="800px" mb="24px">
                <CustomInput
                  type="text"
                  label={t('home.clients.note')}
                  placeholder={t('home.clients.enterNote')}
                  value={formik.values.notes}
                  onChange={formik.handleChange('notes')}
                  onBlur={formik.handleBlur('notes')}
                />
                {formik.errors.notes && formik.touched.notes && (
                  <ErrorMsg text={t(formik.errors.notes)} />
                )}
              </Box>

              <Box mb="24px">
                <Checkbox
                  isChecked={taxable}
                  onChange={e => setTaxable(!taxable)}
                >
                  {t('home.clients.clientTaxable')}
                </Checkbox>
                {taxable && (
                  <Box w="full">
                    <CustomInput
                      type="number"
                      placeholder={t('account.enterTaxNumber')}
                      value={formik.values.taxNumber}
                      onChange={formik.handleChange('taxNumber')}
                      onBlur={formik.handleBlur('taxNumber')}
                    />
                  </Box>
                )}
              </Box>

              <CustomButton
                bg
                type="submit"
                name={t('account.saveChanges')}
                w="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={handleSubmit.isLoading && <Spinner ms="5px" />}
                disabled={handleSubmit.isLoading}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default ClientPopup;
