import {
  Box,
  Text,
  Flex,
  Table as T,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
} from '@chakra-ui/react';
import moment from 'moment';

import RowInvoiceTemplate from './row-invoice-template';
const InvoiceTemplate = ({
  data,
  branch,
  companyName,
  from,
  to,
  salesAmount,
  customerName,
}) => {
  const headers = ['#', 'Date', ' Invoice No.', 'Customer', 'Amount', 'Branch'];
  function chunkArray(arr, chunkSize) {
    const resultArray = [];
    for (let i = 0; i < arr?.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      resultArray.push(chunk);
    }
    return resultArray;
  }

  const invoicesData = chunkArray(data, 13);

  const branchName = () => {
    if (typeof branch?.label == 'object') {
      return branch?.label?.en;
    }
    return branch?.label;
  };

  return (
    <>
      {invoicesData?.map((e, i) => {
        return (
          <div className="page-break">
            <Box mt={i > 0 ? '2.5rem' : '1rem'}>
              <Text fontWeight="bold" fontSize="24px">
                Sales Transactions: {companyName}
              </Text>
              <Text fontWeight="bold" fontSize="20px">
                Branch: {branchName()}
              </Text>

              <Flex justifyContent="space-between" mt="20px" mb="15px">
                <Box>
                  <Text fontWeight="bold">Type</Text>
                  <Text>Sales transactions</Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">Date Period </Text>
                  <Text>
                    {moment(from).locale('en').format('DD-MM-YYYY')} -{' '}
                    {moment(to).locale('en').format('DD-MM-YYYY')}
                  </Text>
                </Box>
                <Box>
                  <Text fontWeight="bold">Customer</Text>
                  <Text>{customerName}</Text>
                </Box>
              </Flex>

              <TableContainer overflowY="visible">
                <T
                  variant="simple"
                  bg="white"
                  borderRadius="10px"
                  id="table-to-xls"
                  border="1"
                >
                  <Thead>
                    <Tr>
                      {headers.map((header, i) => {
                        return (
                          <Th
                            key={i}
                            textTransform="capitalize"
                            fontSize="16px"
                            border="1px solid black"
                            textAlign="center"
                          >
                            {header}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {' '}
                    <RowInvoiceTemplate data={e} indexStart={i * 13} />
                  </Tbody>
                </T>
              </TableContainer>
              {i == invoicesData?.length - 1 ? (
                <Text
                  textAlign="center"
                  mt="5px"
                  display="inline"
                  position="relative"
                  left="66%"
                >
                  Sales Total : {salesAmount?.toFixed(2)} SR
                </Text>
              ) : null}
            </Box>
          </div>
        );
      })}
    </>
  );
};

export default InvoiceTemplate;
