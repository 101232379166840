import { useState, useEffect } from 'react';
import { Box, useToast, Spinner } from '@chakra-ui/react';
import PermissionChoise from './permission';
import { CustomButton } from 'components/shared';
import { getUserById, updateUserPermission } from 'apis/employee';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Loading, Error, CustomToast } from 'components/authenticated/shared';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';

const Permissions = ({ id, data }) => {
  const { t } = useTranslation();
  const [updatePermission, setUpdatePermission] = useState(null);

  const toast = useToast();
  const queryClient = useQueryClient();

  const roles = [
    { role: 'Employees', head: t('sideBar.employees') },
    { role: 'Clients', head: t('sideBar.clients') },
    { role: 'Workflow', head: t('sideBar.workflow') },
    { role: 'Products', head: t('sideBar.products') },
    { role: 'Clockin', head: t('sideBar.clockin') },
    { role: 'Projects', head: t('sideBar.projects') },
    { role: 'Documents', head: t('sideBar.documents') },
    { role: 'Branches', head: t('sideBar.branches') },
    { role: 'Dashboard', head: t('sideBar.dashboard') },
    { role: 'Setting', head: t('account.settings') },
  ];

  // const { data, isLoading, isSuccess, isError } = useQuery(
  //   ['getUserById', id],
  //   getUserById
  // );

  const { mutate, isLoading } = useMutation(updateUserPermission, {
    onSuccess: async (res, variables) => {
      await queryClient.invalidateQueries('getUserById');

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.changePermissions')}
              description={t('toasts.changePermissions')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.changePermissions')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  //to get type of permission of role name
  const handle = e => {
    const res = data?.data.roles.find(i => {
      return i.roleName == e;
    });

    return res?.accessType;
  };

  return (
    <Box p="40px">
      {roles.map(e => {
        if (e == 'Settings') {
          return (
            <PermissionChoise
              updatePermission={updatePermission}
              permissionRoles={data?.data.roles}
              setUpdatePermission={setUpdatePermission}
              head={'Genral Settings'}
              text={`${t('permissions.accessibility')} ${
                data?.data.firstName
              } ${t('permissions.to')} ${e.head} ${t('permissions.data')}:`}
              accessType={handle(e.role) || 'no-access'}
              role={e.role}
            />
          );
        } else {
          return (
            <PermissionChoise
              updatePermission={updatePermission}
              permissionRoles={data?.data.roles}
              setUpdatePermission={setUpdatePermission}
              head={e.head}
              text={`${t('permissions.accessibility')} ${
                data?.data.firstName
              } ${t('permissions.to')} ${e.head} ${t('permissions.data')}:`}
              accessType={handle(e.role) || 'no-access'}
              role={e.role}
            />
          );
        }
      })}

      {Permission('Employees') == 'Edit' && (
        <CustomButton
          name={t('account.saveChanges')}
          display="flex"
          alignItems="center"
          justifyContent="center"
          loading={isLoading && <Spinner ms="5px" />}
          w="230px"
          isDisabled={updatePermission == null || isLoading}
          bg
          onClick={() => mutate({ access: updatePermission, id })}
        />
      )}
    </Box>
  );
};

export default Permissions;
