import { Box, Text } from '@chakra-ui/react';

const StatusCardNumber = ({ bgLine, name, number }) => {
  return (
    <Box
      bg="green"
      w="31px"
      p="5px"
      fontSize="10px"
      borderRadius="5px"
      bg="#EAF4FF"
    >
      <Text textAlign="center">{name}</Text>
      <Box w="85%" h="5px" bg={bgLine} m="auto" />
      <Text textAlign="center" fontWeight="bold">
        {number}
      </Text>
    </Box>
  );
};

export default StatusCardNumber;
