import { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Loading } from 'components/authenticated/shared';
import { checkTokenValidity, resetPassword } from 'apis/iam/Login';
import { useMutation } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import ResetPassword from 'components/unauthenticated/reset-password';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(false);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    checkTokenValidity,
    {
      onSuccess: res => {},
      onError: () => {
        navigate('/');
      },
    }
  );

  useEffect(() => {
    mutate({ slug: token, usage: 'restPassword' });
  }, []);

  return (
    <Box pos="relative" minH="100vh">
      {isLoading && <Loading />}

      {isSuccess && <ResetPassword token={token} />}
    </Box>
  );
};

export default ResetPasswordPage;
