import { Box, Flex, Text, Image } from '@chakra-ui/react';
import defaultImage from 'assets/images/User-32.png';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const StaffMessage = ({ firstName, lastName, messageText, date }) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex w="100%" mt="22px" mb="25px">
        <Image src={defaultImage} w="32px" h="32px" me="21px" />
        <Box bg="white" p="14px" borderRadius="10px" w="239px" pos="relative">
          <Text color="#2C2C2E" fontSize="14px" fontWeight="bold" mb="4px">
            {firstName} {lastName}
            <Text
              as="span"
              color="#666668"
              fontSize="12px"
              fontWeight="normal"
              ms="10px"
            >
              Engineering
            </Text>
          </Text>

          <Text fontSize="14px" lineH="20px" color="#2C2C2E">
            {messageText}
          </Text>

          <Text
            fontSize="12px"
            color="#666668"
            pos="absolute"
            bottom="-20px"
            right="0"
          >
            {moment(date)
              .locale(t('common.lang'))
              .format('DD MMMM, yyyy | hh:mm A')}
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default StaffMessage;
