import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { userContext } from 'contexts/userContext';
import {
  Box,
  Flex,
  Text,
  Switch,
  Grid,
  AccordionButton,
  AccordionIcon,
  Image,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { BsFillCaretDownFill } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import { SimpleModal } from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import EditCategory from './edit-category';
import brake from 'assets/images/brake.svg';
import { useMutation } from 'react-query';
import { ChosseBranchModal } from 'components/authenticated/services';
import { activateCategory, deactivateCategory } from 'apis/categories';
import EditService from './edit-service';
import { deactivateService, activateService } from 'apis/service';
import { useTranslation } from 'react-i18next';

const SubCat = React.memo(
  ({
    name,
    noChildrens,
    id,
    nameAr,
    iconPath,
    isCustom,
    isActive,
    isThereServices,
    setShowLoad,
    refetchCategories,
    isService,
    noServices,
    totalBranches,
    isServiceEditedBefore,
    nameMaxW = ['50px', '50px', '130px', '150px'],
    branchId,
  }) => {
    const [isChecked, setIsChecked] = useState(false);
    const [showChosseBranchModal, setShowChosseBranchModal] = useState(false);
    const toast = useToast();
    const [showEdit, setShowEdit] = useState(false);
    const [showEditServ, setShowEditServ] = useState(false);
    const editRef = useRef();
    const iconSrc = useMemo(() => (iconPath ? iconPath : brake), [iconPath]);
    const { branchFilter, language } = useContext(userContext);
    const { t } = useTranslation();

    const handleShowEdit = useCallback(() => {
      setShowEdit(true);
    }, []);

    const { mutate: activeServ, isLoading: loadingActivateService } =
      useMutation(
        activateService,

        {
          onSuccess: () => {
            refetchCategories();
            toast({
              render: props => {
                return (
                  <CustomToast
                    title={t('toasts.titles.updateService')}
                    description={t('toasts.updateService')}
                    status="success"
                    onClose={props.onClose}
                  />
                );
              },
            });
          },
          onError: () => {
            toast({
              render: props => {
                return (
                  <CustomToast
                    title={t('toasts.titles.updateService')}
                    description={t('toasts.error')}
                    status="error"
                    onClose={props.onClose}
                  />
                );
              },
            });
          },
        }
      );

    const { mutate: deactivateServ, isLoading: loadingDeactivateService } =
      useMutation(deactivateService, {
        onSuccess: () => {
          refetchCategories();
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.deactivateService')}
                  description={t('toasts.deactivateService')}
                  status="success"
                  onClose={props.onClose}
                />
              );
            },
          });
        },
        onError: result => {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.deactivateService')}
                  description={t('toasts.error')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        },
      });

    const { mutate: activate, isLoading: loadingActivate } = useMutation(
      activateCategory,
      {
        onSuccess: () => {
          refetchCategories();
          toast({
            render: props => {
              return (
                <CustomToast
                  title="activate category"
                  description="category activated successfully"
                  status="success"
                  onClose={props.onClose}
                />
              );
            },
          });
        },
        onError: () => {
          toast({
            render: props => {
              return (
                <CustomToast
                  title="activate category"
                  description="some thing went wrong"
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        },
      }
    );

    const { mutate: deactivate, isLoading: loadingDeactivate } = useMutation(
      deactivateCategory,
      {
        onSuccess: () => {
          refetchCategories();
          toast({
            render: props => {
              return (
                <CustomToast
                  title="deactivate category"
                  description="category deactivated successfully"
                  status="success"
                  onClose={props.onClose}
                />
              );
            },
          });
        },
        onError: () => {
          toast({
            render: props => {
              return (
                <CustomToast
                  title="deactivate category"
                  description="some thing went wrong"
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        },
      }
    );

    useEffect(() => {
      setShowLoad(loadingActivate || loadingDeactivate);
    }, [loadingActivate, loadingDeactivate]);

    return (
      <>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="15px"
          background="whitesmoke"
          padding="20px"
          borderRadius="10px"
        >
          {/* <Flex alignItems="center">
        <Switch
          me="5px"
          size="lg"
          // colorScheme="cyan"
          onChange={e => setIsChecked(e.target.checked)}
          isChecked={isChecked}
          _focus={{}}
          _active={{}}
        />
        <Text>
          {name} {noChildrens}
        </Text>
      </Flex> */}

          <Flex alignItems="center">
            {!isService ? (
              <Image
                src={iconSrc}
                w="35px"
                h="35px"
                me="8px"
                borderRadius="50%"
              />
            ) : null}

            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              maxW={nameMaxW}
            >
              {language == 'en' ? name : nameAr}
            </Text>
          </Flex>

          <Flex alignItems="center">
            {isService ? (
              <Text
                me="5px"
                p="5px"
                px="10px"
                borderRadius="30px"
                fontSize="13px"
                bg="pink"
                fontWeight="bold"
              >
                {t('categories.service')}
              </Text>
            ) : null}

            {loadingActivate ||
            loadingDeactivate ||
            loadingDeactivateService ||
            loadingActivateService ? (
              <Spinner me="5px" w="20px" />
            ) : null}

            {!isService && isCustom ? (
              <Grid
                w="35px"
                h="35px"
                borderRadius="50%"
                bg="#e8e8e8"
                placeItems="center"
                me="5px"
                ref={editRef}
                cursor="pointer"
                onClick={handleShowEdit}
              >
                <Box as={FiEdit} pointerEvents="none" />
              </Grid>
            ) : null}

            <Switch
              size="lg"
              disabled={
                !isThereServices ||
                loadingActivate ||
                loadingDeactivate ||
                loadingDeactivateService ||
                loadingActivateService
              }
              // colorScheme="cyan"
              onChange={() => {
                if (isService) {
                  //check if there multi branches

                  if (isActive) {
                    deactivateServ({
                      products: [id],
                      branchId: branchId,
                    });
                  } else {
                    if (isCustom && !isServiceEditedBefore) {
                      setShowEditServ(true);
                    } else {
                      activeServ({
                        productId: id,
                        branchId: branchId,
                      });
                    }
                  }
                } else {
                  if (isActive) {
                    deactivate({ id, branch: branchId });
                  } else {
                    activate({ id, branch: branchId });
                  }
                }
              }}
              isChecked={isActive}
              _focus={{}}
              _active={{}}
              me="5px"
            />
            {!noChildrens ? (
              <AccordionButton
                p="0"
                display="grid"
                placeItems="center"
                w="35px"
                h="35px"
                borderRadius="50%"
                me="5px"
                bg="#cbd5e0"
                cursor="pointer"
                _focus={{ boxShadow: 'none' }}
                background="none"
              >
                <AccordionIcon
                  as={IoIosArrowDown}
                  //transform="rotate(180deg)"
                  fontSize="25px"
                  color="gray"
                />
              </AccordionButton>
            ) : null}
          </Flex>
        </Flex>

        <SimpleModal
          title={`Edit ${name}`}
          show={showEdit}
          setShow={setShowEdit}
        >
          <EditCategory
            id={id}
            name={name}
            nameAr={nameAr}
            iconPath={iconPath}
            refetchCategories={refetchCategories}
            setShowModal={setShowEdit}
          />
        </SimpleModal>

        <SimpleModal
          title={`Edit Service`}
          show={showEditServ}
          setShow={setShowEditServ}
        >
          <EditService
            id={id}
            name={name}
            branchId={branchFilter?.value}
            nameAr={nameAr}
            isDefault={!isCustom}
            setShow={setShowEditServ}
            refetchCategories={refetchCategories}
          />
        </SimpleModal>

        <SimpleModal
          title={
            'which branch do you want to activate/deactivate this service?'
          }
          show={showChosseBranchModal}
          setShow={setShowChosseBranchModal}
        >
          <ChosseBranchModal
            branches={totalBranches}
            itemId={id}
            refetchServices={refetchCategories}
          />
        </SimpleModal>
      </>
    );
  }
);

export default SubCat;
