import firebase from 'firebase/app';
import 'firebase/messaging';
import { deviceToken } from 'apis/notifications';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAcrtQvBy2hGjJ-5F4bxHlzD1PYUOEzDw8',
  authDomain: 'crm-staging-359318.firebaseapp.com',
  projectId: 'crm-staging-359318',
  storageBucket: 'crm-staging-359318.appspot.com',
  messagingSenderId: '1069976452753',
  appId: '1:1069976452753:web:8a7ba786b9525e3a9ac930',
};

firebase.initializeApp(firebaseConfig);

const messaging = firebase.messaging();

const publicKey =
  'BITmJ9mLvvZiNDl47oQ9zHbcU6YiK6pK05w6NifqaRw9FVFqEB28GOowiJYcgqEIvsoy4Gm6WtByMN6OvjoaWcs';

export function requestPermission() {
  Notification.requestPermission().then(permission => {
    if (permission === 'granted') {
      messaging
        .getToken({ vapidKey: publicKey })
        .then(currentToken => {
          if (currentToken) {
            deviceToken(currentToken);

            // we will send device token from here to backend and remove send it to firebase directly
            // axios.post(
            //   'https://fcm.googleapis.com/fcm/send',
            //   {
            //     notification: {
            //       title: 'Firebase',
            //       body: 'Firebase is awesome',
            //       click_action: 'http://localhost:3000/',
            //       icon: 'http://url-to-an-icon/icon.png',
            //     },
            //     to: currentToken,
            //   },
            //   {
            //     headers: {
            //       'Content-Type': 'application/json',
            //       Authorization:
            //         'key=AAAA-R-Q_pE:APA91bExOjBM1qlGIZjr50P7eekmRNaKGVos6k9gUzCt6ECibB7N8t6_qPSImtnewnjbwiqbXnE0hRm9xL3aLoL1qXXkE79y5RJd-IakZS5fLsv4P-Kj9bdpD9ed8Cav-Gkx_4I-Rdkc',
            //     },
            //   }
            // );
            // Send the token to your server and update the UI if necessary
            // ...
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.'
            );
            // ...
          }
        })
        .catch(err => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
    }
  });
}

export const onMessageListener = () =>
  new Promise(resolve => {
    messaging.onMessage(payload => {
      resolve(payload);
    });
  });
