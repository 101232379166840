import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import {
  Box,
  Text,
  Tr,
  Td,
  Checkbox,
  Flex,
  useToast,
  Tooltip,
} from '@chakra-ui/react';
import {
  Filter,
  Table,
  DeletePopup,
  ActivePopup,
  CustomSwitch,
  Pagination,
  Loading,
  Nodata,
  Error,
  CustomToast,
  StatusBox,
} from 'components/authenticated/shared';
import {
  Select,
  CustomModal,
  MultiSelect,
  SimpleModal,
} from 'components/shared';
import { PartPopup, PartRow } from 'components/authenticated/parts';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  getAllProducts,
  deleteProduct,
  activateProduct,
} from 'apis/catlog/Product';
import { deactivatePart } from 'apis/part';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';
import { ChosseBranchModal } from 'components/authenticated/services';

const Parts = () => {
  const { t } = useTranslation();
  const { user, branchFilter, language } = useContext(userContext);
  const queryClient = useQueryClient();
  const toast = useToast();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [status, setStatus] = useState({ label: '', value: null });
  const [stock, setStock] = useState({ label: '', value: null });
  const [branch, setBranch] = useState([branchFilter]);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showChosseBranchModal, setShowChosseBranchModal] = useState(false);

  useEffect(() => {
    if (branchFilter.value == null) {
      setBranch([]);
    } else {
      setBranch([{ label: branchFilter.label.en, value: branchFilter.value }]);
    }
  }, [branchFilter]);

  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    [
      'getAllProducts',
      pageNumber,
      searchKey,
      stock.value,
      branch,
      status.value,
    ],
    getAllProducts
  );

  const { mutate, isLoading: loadingDelete } = useMutation(deactivatePart, {
    onSuccess: async result => {
      setIsCheck([]);
      setShowDeleteAll(false);
      refetch();

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.removeMultiParts')}
              description={t('toasts.removeMultiParts')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.removeMultiParts')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const [showAdd, setShowAdd] = useState(false);

  const handleStatus = value => {
    setPageNumber(1);
    setStatus(value);
  };

  const handleStock = value => {
    setPageNumber(1);
    setStock(value);
  };

  const handleBranch = (value, check) => {
    setPageNumber(1);
    if (!check) {
      let filteredBranches = branch.filter(elm => elm.value !== value.value);
      if (branch.length == 1) {
        return false;
      } else {
        setBranch(filteredBranches);
      }
    } else setBranch(prevState => [...prevState, value]);
  };

  const handlePageClick = async data => {
    setPageNumber(data.selected + 1);
  };

  let numberOfPages = data?.data.meta.totalPages;
  let itemsPerPage = data?.data.meta.itemsPerPage;
  let currentPage = data?.data.meta.currentPage;
  let itemCount = data?.data.meta.itemCount;
  let totalItems = data?.data.meta.totalItems;

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(
      data?.data.items.filter(e =>
        e.totalBranches.some(b => b.isActive === true)
      )
    );
  }, [data]);

  const headers = [
    'check',
    t('modals.id'),
    t('home.projects.name'),
    t('modals.partNumber'),
    t('modals.quantity'),
    t('modals.status'),
    t('modals.branch'),
    'option',
  ];
  const headersWithoutChech = [
    t('modals.id'),
    t('home.projects.name'),
    t('modals.partNumber'),
    t('modals.quantity'),
    t('modals.status'),
    t('modals.branch'),
  ];

  return (
    <>
      <Filter
        title={t('home.projects.parts')}
        buttonName={t('home.storage.addPart')}
        setShowAdd={setShowAdd}
        setValue={setSearchKey}
        isCheck={isCheck}
        setShowDelete={
          branch?.length > 1 ? setShowChosseBranchModal : setShowDeleteAll
        }
        titleDelete={t('home.projects.parts')}
        defaultButtonWidth="200px"
        isButtonExist={Permission('Products') == 'Edit'}
      >
        <Select
          mx={['4', '', '2', '2', '4']}
          w={['150px', '', '115px', '115px', '150px']}
          h="40px"
          label={t('modals.stock')}
          select={stock}
          handleSelect={handleStock}
          selections={[
            { label: t('common.all'), value: null },
            { label: t('home.storage.available'), value: 'available' },
            { label: t('home.storage.unavailable'), value: 'unavailable' },
          ]}
        />

        <Select
          mx={['4', '', '2', '2', '4']}
          w={['150px', '', '115px', '115px', '150px']}
          h="40px"
          label={t('modals.status')}
          select={status}
          handleSelect={handleStatus}
          selections={[
            { label: t('common.all'), value: null },
            { label: t('common.activated'), value: 'activated' },
            { label: t('common.deactivated'), value: 'deactivated' },
          ]}
        />

        <MultiSelect
          mx={['4', '', '2', '2', '4']}
          w={['150px', '', '115px', '115px', '150px']}
          h="40px"
          label={t('filter.branch')}
          labelForMulti={t('sideBar.branches')}
          select={branch}
          handleSelect={handleBranch}
          selections={user.user.branches?.map((item, index) => ({
            label: language == 'en' ? item.name.en : item.name.ar,
            value: item.id,
          }))}
        />
      </Filter>

      {isLoading && <Loading />}

      {isSuccess &&
        (totalItems > 0 ? (
          <>
            <Box minH="72vh">
              <Table
                headers={
                  Permission('Products') == 'Edit'
                    ? headers
                    : headersWithoutChech
                }
                theadHeight="71px"
                mb="24px"
                setIsCheckAll={setIsCheckAll}
                setIsCheck={setIsCheck}
                isCheckAll={isCheckAll}
                list={list}
                length={data?.data.items.length}
                isCheck={isCheck}
              >
                {data?.data.items.map((item, index) => (
                  <PartRow
                    item={item}
                    key={index}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    refetchParts={refetch}
                    showDeleteAll={showDeleteAll}
                    setShowDeleteAll={setShowDeleteAll}
                    selectedBranch={branch}
                    branchId={branchFilter?.value}
                  />
                ))}
              </Table>
            </Box>
            <Pagination
              handlePageClick={handlePageClick}
              numberOfPages={numberOfPages}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              itemCount={itemCount}
              totalItems={totalItems}
              forcePage={currentPage - 1}
            />
          </>
        ) : (
          <Nodata />
        ))}

      <SimpleModal
        title={t('home.storage.addNewPart')}
        show={showAdd}
        setShow={setShowAdd}
      >
        <PartPopup setShow={setShowAdd} />
      </SimpleModal>

      <SimpleModal
        title={t('modals.deleteParts')}
        show={showDeleteAll}
        setShow={setShowDeleteAll}
      >
        <DeletePopup
          handleDelete={() =>
            mutate({ products: isCheck, branchId: branch[0].value })
          }
          title={t('modals.confirmDeleteParts')}
          isLoading={loadingDelete}
        />
      </SimpleModal>
      <SimpleModal
        title={'which branch do you want to activate/deactivate this parts?'}
        show={showChosseBranchModal}
        setShow={setShowChosseBranchModal}
      >
        <ChosseBranchModal
          branches={branch}
          items={isCheck}
          refetchServices={refetch}
          isPart
        />
      </SimpleModal>

      {isError && <Error />}
    </>
  );
};

export default Parts;
