import * as Yup from 'yup';

const regexAr = /^[\u0600-\u06FF0-9()&%$#-ـ ]*$/;

export const AddServiceSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'signup.minLength')
    .max(250, 'signup.maxLength')
    .required('signup.fieldRequired'),
  nameAr: Yup.string()
    .min(3, ' يجب أن يكون 3 حروف أو أكثر')
    .max(100, ' يجب أن يكون 100 حرف أو أقل')
    .required('هذا الحقل مطلوب')
    .test('onlyArabic', ' يجب أن يكون بالعربية', name =>
      regexAr.test(name) ? true : false
    ),
    price: Yup.number() ,
    applicationPrice: Yup.number().when('price', (price, schema) => {
      if (price !== undefined) {
        return schema
          .test('isLessThan20Percent', 'home.services.appPrice', function (applicationPrice) {
            const maxAppPrice = (price * 80) /100; // Calculate 20% of the 'price'
            return applicationPrice === undefined || applicationPrice <= maxAppPrice;
          });
      } else {
        return schema; // If 'price' is undefined, no validation is applied to 'applicationPrice'
      }
    }),
});
