import { Box, Spinner, Text } from '@chakra-ui/react';
import { CustomButton } from 'components/shared';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ handleComplete, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Text textAlign="center" mb="15px">
        {t('home.workflow.completeTaskConfirm')}
      </Text>

      <CustomButton
        name={t('home.workflow.confirm')}
        bg
        onClick={handleComplete}
        display="flex"
        alignItems="center"
        loading={isLoading ? <Spinner ms="5px" /> : null}
        disabled={isLoading}
      />
    </Box>
  );
};

export default ConfirmationModal;
