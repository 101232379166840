import { useState } from 'react';
import { Box, RadioGroup, Radio, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const PermissionChoise = ({
  accessType,
  permissionRoles,
  setUpdatePermission,
  updatePermission,
  head,
  text,
  role,
}) => {
  const { t } = useTranslation();
  let initialArr =
    updatePermission != null ? updatePermission : permissionRoles;
  const [value, setValue] = useState(accessType);

  const theRole = initialArr?.findIndex(i => {
    return i.roleName == role;
  });

  const handleChange = e => {
    let newArr = [...initialArr];

    //if this permission exist with user then edit it
    if (theRole != -1) {
      initialArr[theRole].accessType = e.target.value;
    } else {
      //if not exist just add it
      if (e.target.value != 'no-access') {
        initialArr = [
          ...initialArr,
          { roleName: role, accessType: e.target.value },
        ];
      }
    }

    const arrayWithoutNoAccess = initialArr.filter(e => {
      return e.accessType != 'no-access';
    });

    setUpdatePermission(arrayWithoutNoAccess);
  };

  const Choise = ({ type, permission, to }) => {
    return (
      <Box display="block">
        <Radio
          value={type}
          mb="10px"
          _focus={{}}
          onChange={e => handleChange(e)}
        >
          <Text color="text.600">
            {permission}
            <Box as="span" fontWeight="bold" textTransform="capitalize">
              {' '}
              {to}
            </Box>
          </Text>
        </Radio>
      </Box>
    );
  };

  // setUpdateRoles({ ...updateRoles, head: accessType });

  return (
    <Box mb="32px">
      <Text color="text.2100" fontSize="20px" fontWeight="bold" mb="5px">
        {head}
      </Text>
      <Text color="text.2100" fontSize="18px" fontWeight="medium" mb="16px">
        {text}
      </Text>
      <RadioGroup onChange={setValue} value={value} display="inline-block">
        <Choise
          key={Math.random()}
          type="no-access"
          to={head}
          permission={t('permissions.noAccess')}
        />
        <Choise
          key={Math.random()}
          type="View"
          to={head}
          permission={t('permissions.allowView')}
        />
        <Choise
          key={Math.random()}
          type="Edit"
          to={head}
          permission={t('permissions.allowEdit')}
        />
      </RadioGroup>
    </Box>
  );
};

export default PermissionChoise;
