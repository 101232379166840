import { useState, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, Flex } from '@chakra-ui/react';
import { FieldArray } from 'formik';
import { MultiSelect, SimpleModal } from 'components/shared';
import { MdAdd } from 'react-icons/md';
import { PartPopup } from 'components/authenticated/parts';
import AddPart from './add-part';
import { MultiSelectSearch } from 'components/authenticated/shared';

const HandleParts = memo(
  ({ options, loadingOptions, partsArray, formik, parts, setParts }) => {
    const { t } = useTranslation();

    const [showAddParts, setShowAddParts] = useState(false);

    // const [parts, setParts] = useState(
    //   partsArray
    //     ? partsArray.map(e => {
    //         return { label: e.product.name, value: e.product.id };
    //       })
    //     : []
    // );

    const handleParts = (value, check, index, e, data) => {
      if (!check) {
        let filteredBranches = parts.filter(elm => elm.value !== value.value);
        setParts(filteredBranches);
        let ind = data.findIndex(e => {
          return e.invoiceProductId == value.value;
        });
        e.remove(ind);
      } else {
        setParts(prevState => [...prevState, value]);
        e.insert(index, {
          type: 'part',
          invoiceProductId: value.value,
          searchId: value.id,
          name: value.label,
          price: '',
          quantity: '',
        });
      }
    };

    return (
      <>
        <Box w="full">
          <Text fontSize="14px" mb="5px" fontWeight="bold">
            {t('project.parts')}
          </Text>
          <FieldArray
            name="parts"
            render={arrayHelpers => (
              <>
                <Box mb="24px">
                  <Flex
                    w="full"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box w="82%">
                      <MultiSelectSearch
                        labelForMulti={t('home.dashboard.parts')}
                        h="60px"
                        label={t('modals.selectParts')}
                        select={parts}
                        handleSelect={(value, check, index) =>
                          handleParts(
                            value,
                            check,
                            index,
                            arrayHelpers,
                            formik.values.parts
                          )
                        }
                        selections={options}
                        isLoadingSelections={loadingOptions}
                      />
                    </Box>
                    <Flex
                      bg="bg.300"
                      color="white"
                      h="60px"
                      w="15%"
                      justifyContent="center"
                      alignItems="center"
                      fontSize="32px"
                      borderRadius="10px"
                      cursor="pointer"
                      onClick={() => setShowAddParts(true)}
                    >
                      <MdAdd />
                    </Flex>
                  </Flex>
                </Box>
                {formik.values.parts.length > 0 && (
                  <Box mb="20px">
                    <Flex
                      bg="#21C6E1"
                      color="white"
                      p="10px"
                      borderRadius="5px 5px 0 0"
                      borderBottom="1px solid #b7b8bb"
                    >
                      <Box w="25%">{t('home.dashboard.id')}</Box>
                      <Box w="30%">{t('home.dashboard.name')}</Box>
                      <Box w="35%">{t('modals.quantity')}</Box>
                      <Box w="30%">{t('home.dashboard.price')}</Box>
                    </Flex>
                    {formik.values.parts.map((e, i) => {
                      return (
                        <>
                          <AddPart
                            index={i}
                            key={e.id}
                            searchId={e.searchId}
                            id={e.invoiceProductId}
                            name={e.name}
                            inputQuantity={e.quantity}
                            partsArray={partsArray}
                            changePrice={`parts.${i}.price`}
                            changeQuantity={`parts.${i}.quantity`}
                            border="1px solid #BFCFD6"
                            borderTop="0"
                            formik={formik}
                            arrayHelpers={arrayHelpers}
                            setParts={setParts}
                            parts={parts}
                            borderRadius={
                              i == formik.values.parts.length - 1
                                ? '0 0 5px 5px'
                                : '0'
                            }
                          />
                        </>
                      );
                    })}
                  </Box>
                )}
              </>
            )}
          />
        </Box>

        <SimpleModal
          title={t('home.storage.addNewPart')}
          show={showAddParts}
          setShow={setShowAddParts}
        >
          <PartPopup setShow={setShowAddParts} />
        </SimpleModal>
      </>
    );
  }
);

export default HandleParts;
