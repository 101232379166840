import { Box, Flex, Text, Grid, Table, Td, Tr } from '@chakra-ui/react';

const TableData = ({ value1, value2, bg, color1, ...props }) => {
  return (
    <Td
      px="0"
      py="1px"
      border="1px solid black"
      bg={bg ? '#e3e3e3' : 'transparent'}
      {...props}
    >
      <Text textAlign="center" fontSize="12px" lineHeight="1.2" color={color1}>
        {value1}
      </Text>
      {value2 && (
        <Text textAlign="center" fontSize="12px" lineHeight="1.2">
          {value2}
        </Text>
      )}
    </Td>
  );
};

const Data = ({ name, enName, value }) => {
  return (
    <Flex borderRadius="5px" border="1px solid" p="8px" fontSize="10px">
      <Text me="5px">
        {name} -{' '}
        <Text as="span" fontSize="7px">
          {enName}
        </Text>
      </Text>
      <Text>: {value}</Text>
    </Flex>
  );
};

const BuyerInfo = ({ data, ...props }) => {
  return (
    <Box {...props}>
      <Table border="1px solid black">
        <Box
          as="caption"
          textAlign="center"
          py="5px"
          fontWeight="semibold"
          border="1px solid black"
          borderBottom="none"
          bg="#e3e3e3"
        >
          <Text textAlign="center" fontSize="12px" lineHeight="1.2">
            معلومات العميل
          </Text>
          <Text textAlign="center" fontSize="12px" lineHeight="1.2">
            Client Details
          </Text>
        </Box>
        <Tr>
          <TableData
            value1="إسم العميل"
            value2="Client Name"
            fontWeight="semibold"
          />
          <TableData value1={data?.name} colSpan="2" w="34%" />
          <TableData
            value1="الرقم الضريبي "
            value2="VAT Number"
            fontWeight="semibold"
          />
          <TableData value1={data?.vat} colSpan="2" w="34%" />
        </Tr>
        <Tr>
          <TableData
            value1="المبنى"
            value2="Building No."
            fontWeight="semibold"
          />
          <TableData value1={data?.buildingNumber} />
          <TableData value1="الشارع " value2="Street" fontWeight="semibold" />
          <TableData value1={data?.street} />
          <TableData value1="الحي" value2="District" fontWeight="semibold" />
          <TableData value1={data?.district} />
        </Tr>
        <Tr>
          <TableData value1="المدينة" value2="City" fontWeight="semibold" />
          <TableData value1={data?.city} w="20%" />
          <TableData
            value1="الرمز البريدي "
            value2="Zip Code"
            fontWeight="semibold"
          />
          <TableData value1={data?.zipCode} w="20%" />
          <TableData
            value1="الرقم الإضافي"
            value2="Extra Number"
            fontWeight="semibold"
          />
          <TableData value1={data?.extraNumber} w="20%" />
        </Tr>
        <Tr>
          <TableData value1="الدولة" value2="Country" fontWeight="semibold" />
          <TableData value1={data?.country} w="20%" />
          <TableData
            value1="السجل التجاري "
            value2="CR Number"
            fontWeight="semibold"
          />
          <TableData value1={data?.crNumber} w="20%" />
          <TableData
            value1="معلومات التواصل"
            value2="Contact Info"
            fontWeight="semibold"
          />
          <TableData value1={data?.phoneNumber} w="20%" />
        </Tr>
      </Table>
      {/* <Grid gridTemplateColumns="repeat(3,1fr)" gap="10px">
        <Data
          name=" الرفم الضريبى "
          enName="Vat Number"
          value="1234567891234567 "
        />
        <Data name=" السجل التجارى" enName="CR Number" value="1234567891" />
        <Data name="المدينة " enName="City" value=" الخبر" />
        <Data name="الدولة" enName="Country" value="السعودية " />
        <Data name=" الشارع" enName="Street" value="4د" />
        <Data name="الحى" enName="District" value=" العليا" />
        <Data name=" المبنى" enName="Building" value="3184" />
        <Data name="رقم اضافى" enName="Extra No" value="6518" />
        <Data name="الرمز البريدى" enName="Zip Code" value="34448" />

      </Grid> */}
    </Box>
  );
};

export default BuyerInfo;
