import { Box, Flex, Image, Text, Grid } from '@chakra-ui/react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Card from 'components/authenticated/tasks/assigned-tasks/cardTask';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { CustomAvatar } from 'components/shared';
import { useTranslation } from 'react-i18next';

const AssignedTasks = ({
  item,
  taskss,
  isDropDisabled,
  isDragDisabled,
  branch,
  refetch,
  ...props
}) => {
  const { t } = useTranslation();

  const { firstName, lastName, profileImageUrl, tasks, id, averageRating } =
    item;

  let totalHours = 0;

  taskss.map(e => {
    totalHours += e?.expectedTime;
  });

  const formatedTime = time => {
    var totalMinutes = time || 0;

    var hourss = Math.floor(totalMinutes / 60);
    var minutess = totalMinutes % 60;

    return hourss === 0
      ? `${minutess}min`
      : `${hourss}h ${minutess == 0 ? '' : `${minutess}min`}`;
  };

  return (
    <Box
      mb="24px"
      {...props}
      // className="bg-white rounded-md overflow-y-hidden overflow-x-auto mt-8"
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        w="350px"
        color="text.600"
        fontWeight="bold"
      >
        <Flex justifyContent="flex-start" alignItems="center" pos="relative">
          <CustomAvatar
            image={profileImageUrl}
            letter={firstName[0]}
            letterSize="16px"
            w="60px"
            h="60px"
            mb="0"
          />

          <Box ms="10px">
            <Text
              mb="3px"
              fontSize="24px"
              fontWeight="semibold"
              whiteSpace="nowrap"
            >
              {firstName + ' ' + lastName}
            </Text>
            <Box>
              <Text fontSize="16px" color="text.1100" fontWeight="bold">
                {t('home.workflow.totalTaskHours')} :{' '}
                <Text as="span" color="#21C6E1">
                  {formatedTime(Math.floor(totalHours))}
                </Text>
              </Text>
              <Text>{/* {column.user.totalHours} */}</Text>
            </Box>
          </Box>
          <Flex
            pos="absolute"
            w="50px"
            h="20px"
            bg="white"
            bottom="0"
            left="5px"
            alignItems="center"
            justifyContent="space-evenly"
            borderRadius="2px"
          >
            <Box as={AiFillStar} color="#ffc32a" />
            <Text color="#232728" fontWeight="semibold" fontSize="13px">
              {parseFloat(averageRating).toFixed(1)}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Droppable
        droppableId={id}
        key={id}
        direction="horizontal"
        isDropDisabled={isDropDisabled}
      >
        {(provided, snapshot) => {
          return (
            <Flex
              {...provided.droppableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              justifyContent="flex-start"
              alignItems="center"
              py="20px"
              maxW="100%"
              pos="relative"
              minH="280px"
              // h="280px"
              overflow="hidden"
              overflowX="auto"
            >
              {taskss?.length >= 1 ? (
                taskss?.map((item, index) => {
                  return (
                    <Draggable
                      key={item?.id}
                      draggableId={item?.id}
                      index={index}
                      isDragDisabled={
                        item?.workflowStatus == 'completed' || isDragDisabled
                      }
                    >
                      {(provided, snapshot) => {
                        return (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              userSelect: 'none',
                              // padding: '4px 16px',
                              paddingInlineEnd: '16px',
                              minHeight: '50px',
                              ...provided.draggableProps.style,
                            }}
                            opacity={
                              item?.workflowStatus == 'completed' ? '0.7' : '1'
                            }
                          >
                            <Card
                              data={item}
                              branch={branch}
                              refetch={refetch}
                            />
                          </Box>
                        );
                      }}
                    </Draggable>
                  );
                })
              ) : (
                <Grid
                  placeItems="center"
                  transition=".2s"
                  bg={
                    snapshot.isDraggingOver
                      ? 'rgb(183 183 183 / 50%)'
                      : 'rgb(183 183 183 / 20%)'
                  }
                  borderRadius="5px"
                  mt="10px"
                  pos="absolute"
                  top="0"
                  h="100%"
                  left="0"
                  w="100%"
                >
                  <Text fontWeight="bold" fontSize="20px">
                    Drag tasks here 😃
                  </Text>
                </Grid>
              )}

              {provided.placeholder}
            </Flex>
          );
        }}
      </Droppable>
    </Box>
  );
};

export default AssignedTasks;
