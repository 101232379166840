import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Text, Grid, useToast, Spinner } from '@chakra-ui/react';
import {
  CustomInput,
  Select,
  Label,
  SimpleModal,
  CustomButton,
} from 'components/shared';
import {
  SelectSearch,
  CustomToast,
  HandleCar,
} from 'components/authenticated/shared';
import { MdAdd } from 'react-icons/md';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getAllPagesClients } from 'apis/client';
import { getAllCars, createAppointment } from 'apis/appointment';
import { ClientPopup } from 'components/authenticated/clients';
import { Formik } from 'formik';
import { AddTask } from 'schemas/invoice/AddTask';
import { ErrorMsg } from 'components/shared';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const AddNewTask = ({ setShow }) => {
  const { t } = useTranslation();

  const toast = useToast();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  //user context
  const { user, branchFilter, language } = useContext(userContext);

  const branches = user.user.branches;

  const [showAdd, setShowAdd] = useState(false);

  //selections states
  const [client, setClient] = useState({ label: 'Select client', value: null });

  const [branch, setBranch] = useState(branchFilter);

  useEffect(() => {
    setBranch(branchFilter);
  }, []);

  useEffect(() => {
    if (branchFilter.value == null) {
      setBranch([]);
    } else {
      setBranch(branchFilter);
    }
  }, [branchFilter]);

  const handleClientSelect = value => {
    setClient(value);
  };

  const handleBranch = value => {
    setBranch(value);
  };

  //handle requests
  const { data: clients, refetch } = useQuery(
    'getAllPagesClients',
    getAllPagesClients
  );

  const { mutate } = useMutation(createAppointment, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllWorkflows');
      setLoading(false);
      setShow(false);

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.addTask')}
              description={t('toasts.addTask')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: () => {
      setLoading(false);
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.addTask')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  //get clientData,branchData
  const clientData = clients?.data.map(e => {
    return { label: e.name + e.mobileNumber, value: e.id };
  });

  const branchData = branches?.map(e => {
    return { label: language == 'en' ? e.name.en : e.name.ar, value: e.id };
  });

  return (
    <Flex justifyContent="center">
      <Formik
        initialValues={{
          client: '',
          macker: '',
          model: '',
          year: '',
          branch: branchFilter.value,
          date: moment(new Date()).locale('en').format('YYYY-MM-DD'),
          time: moment(new Date()).locale('en').format('hh:mm:ss'),
          // title: '',
          subject: '',
        }}
        validationSchema={AddTask}
        onSubmit={async values => {
          setLoading(true);
          mutate(values);
        }}
        // validateOnMount
      >
        {formik => {
          return (
            <Box as="form" w="full" maxW="85%" onSubmit={formik.handleSubmit}>
              <Box w="full">
                <Text fontSize="14px" mb="5px" fontWeight="bold">
                  {t('project.client')}
                </Text>
                <Flex
                  w="full"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box w="82%" mb="24px">
                    <SelectSearch
                      h="60px"
                      label={t('modals.selectClient')}
                      select={client}
                      handleSelect={value => {
                        handleClientSelect(value);
                        formik.setFieldValue('client', value.value);
                      }}
                      selections={clientData}
                    />
                    {formik.errors.client && formik.touched.client && (
                      <ErrorMsg text={t(formik.errors.client)} />
                    )}
                  </Box>
                  <Flex
                    bg="bg.300"
                    color="white"
                    h="60px"
                    w="15%"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="32px"
                    borderRadius="10px"
                    cursor="pointer"
                    onClick={() => setShowAdd(true)}
                  >
                    <MdAdd />
                  </Flex>
                </Flex>
              </Box>

              <HandleCar formik={formik} />

              <Box w="full" mb="20px">
                <Text fontSize="14px" mb="5px" fontWeight="bold">
                  {t('project.branches')}
                </Text>
                <SelectSearch
                  h="60px"
                  label="Select Branch"
                  select={branch}
                  handleSelect={value => {
                    handleBranch(value);
                    formik.setFieldValue('branch', value.value);
                  }}
                  selections={branchData}
                />
                {formik.errors.branch && formik.touched.branch && (
                  <ErrorMsg text={t(formik.errors.branch)} />
                )}
              </Box>
              {/* 
              <Box w="full" mb="20px">
                <CustomInput
                  type="text"
                  label={t('project.title')}
                  placeholder={t('project.enterTitle')}
                  value={formik.values.title}
                  onChange={formik.handleChange('title')}
                  onBlur={formik.handleBlur('title')}
                />
                {formik.errors.title && formik.touched.title && (
                  <ErrorMsg text={t(formik.errors.title)} />
                )}
              </Box> */}

              <Box w="full" mb="20px">
                <CustomInput
                  type="text"
                  label={t('project.subject')}
                  placeholder={t('project.enterSubject')}
                  value={formik.values.subject}
                  onChange={formik.handleChange('subject')}
                  onBlur={formik.handleBlur('subject')}
                />
                {formik.errors.subject && formik.touched.subject && (
                  <ErrorMsg text={t(formik.errors.subject)} />
                )}
              </Box>

              <CustomButton
                name={t('account.save')}
                bg
                m="auto"
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={loading ? <Spinner ms="10px" /> : null}
                disabled={loading}
                type="submit"
              />
            </Box>
          );
        }}
      </Formik>

      <SimpleModal
        title={t('home.clients.addNewClient')}
        show={showAdd}
        setShow={setShowAdd}
      >
        <ClientPopup setShow={setShowAdd} refetch={refetch} />
      </SimpleModal>
    </Flex>
  );
};

export default AddNewTask;
