import { useState, useEffect } from 'react';
import { Box, Flex, Grid, Text } from '@chakra-ui/react';
import { Field } from 'formik';
import { AiOutlineClose } from 'react-icons/ai';
import { IdleTransaction } from '@sentry/tracing';

const AddService = ({
  id,
  searchId,
  services,
  setServices,
  index,
  arrayHelpers,
  text,
  setSerData,
  name,
  serData,
  ...props
}) => {
  return (
    <Flex p="10px" bg="#f9fafb" pos="relative" {...props}>
      <Box w="30%">{searchId}</Box>
      <Box w="35%">
        <Text
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          maxW="100px"
          title={text}
        >
          {text}
        </Text>
      </Box>
      <Grid
        w="30px"
        h="30px"
        bg="bg.300"
        cursor="pointer"
        placeItems="center"
        borderRadius="50%"
        _focus={{}}
        _hover={{ bg: '#30d2ed' }}
        _active={{ bg: 'bg.400' }}
        color="white"
        onClick={() => {
          setServices(
            services.filter(e => {
              return e.value != id;
            })
          );
          let ind = arrayHelpers.form.values.services.findIndex(e => {
            return e.invoiceProductId == id;
          });

          arrayHelpers.remove(ind);
        }}
      >
        <Box as={AiOutlineClose} />
      </Grid>
    </Flex>
  );
};

export default AddService;
