import { useContext } from 'react';
import { userContext } from 'contexts/userContext';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  Image,
} from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const Select = ({
  labelVariable = 'label',
  label,
  selections,
  selectTitle,
  isOpen,
  select,
  handleSelect,
  isDisabled,
  buttonRadius = '10px',
  borderColor = '#BFCFD6',
  paddingButtom = '10px',
  icon,
  ...props
}) => {
  const { t } = useTranslation();

  const { language } = useContext(userContext);

  const checkObject = e => {
    if (typeof e == 'object') {
      return language == 'en' ? e.en : e.ar;
    }
    return e;
  };

  return (
    <Box {...props}>
      <Menu>
        <MenuButton
          as={Button}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          h="100%"
          px={paddingButtom}
          border="1px solid #BFCFD6"
          borderColor={borderColor}
          borderRadius={buttonRadius}
          outline="none"
          bg="transparent"
          color="text.1100"
          fontWeight="normal"
          disabled={isDisabled}
          _focus={{
            border: '1px solid #a5c6d5',
            boxShadow: 'none',
            bg: 'transparent',
          }}
          _active={{
            bg: 'transparent',
          }}
          _hover={{
            bg: 'transparent',
          }}
          _disabled={{
            opacity: 1,
            cursor: 'not-allowed',
          }}
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" w="80%">
              {icon && <Image src={icon} me="7px" />}
              <Text textOverflow="ellipsis" overflow="hidden" maxW="97%">
                {select[labelVariable]
                  ? checkObject(select[labelVariable])
                  : label}
              </Text>
            </Flex>
            <Box as={MdKeyboardArrowDown} color="black" />
          </Flex>
        </MenuButton>
        <MenuList w="100%" maxH="200px" overflowY="auto" p="0">
          {selectTitle && (
            <MenuItem
              bg="#21C6E1"
              color="white"
              fontWeight="bold"
              justifyContent="center"
              isDisabled={true}
              _disabled={{ opacity: '1', cursor: 'default' }}
              _active={{}}
            >
              {selectTitle}
            </MenuItem>
          )}

          {selections?.map((e, i) => {
            if (typeof e[labelVariable] == 'object') {
              return (
                <MenuItem key={Math.random()} onClick={() => handleSelect(e)}>
                  {i + 1}-{' '}
                  {language == 'en' ? e[labelVariable].en : e[labelVariable].ar}
                </MenuItem>
              );
            }
            return (
              <MenuItem key={Math.random()} onClick={() => handleSelect(e)}>
                {i + 1}- {e[labelVariable]}
              </MenuItem>
            );
          })}
          {selections?.length == 0 && (
            <MenuItem
              isDisabled={true}
              justifyContent="center"
              _disabled={{ opacity: '1', cursor: 'default' }}
              _active={{}}
            >
              {t('account.noData')}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default Select;
