import { Tabs as T, TabList, TabPanels, Tab } from '@chakra-ui/react';

const Tabs = ({ tabs, children, ...props }) => {
  return (
    <T variant="enclosed" {...props}>
      <TabList>
        {tabs.map(tab => (
          <Tab
            key={Math.random()}
            _focus={{ outline: 'none' }}
            _selected={{ bg: 'white', color: 'text.200' }}
            color="text.1200"
            fontWeight="bold"
          >
            {tab}
          </Tab>
        ))}
      </TabList>
      <TabPanels bg="white">{children}</TabPanels>
    </T>
  );
};

export default Tabs;
