import { Table } from 'components/authenticated/shared';
import TableRow from './table-row';

import { Loading, Error, Nodata } from 'components/authenticated/shared';
import { useTranslation } from 'react-i18next';

const RequestsTable = ({
  data,
  isLoading,
  isError,
  isSuccess,
  mutate,
  editRequest,
}) => {
  const { t } = useTranslation();

  if (data?.data.length > 0) {
    return (
      <Table
        headers={[
          t('modals.name'),
          t('home.clockin.requestType'),
          t('filter.status'),
        ]}
        ThWidths={['calc(100% / 3)', 'calc(100% / 3)', 'calc(100% / 3)']}
        bg="white"
        theadHeight="71px"
      >
        {isLoading && <Loading />}
        {isError && <Error />}
        {isSuccess &&
          data?.data.map(e => {
            return (
              <TableRow data={e} mutate={mutate} editRequest={editRequest} />
            );
          })}
      </Table>
    );
  }
  return <Nodata />;
};

export default RequestsTable;
