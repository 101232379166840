import { useState } from 'react';
import {
  Box,
  Text,
  Flex,
  useNumberInput,
  Button,
  Input,
} from '@chakra-ui/react';
import { CustomButton } from 'components/shared';
import { useTranslation } from 'react-i18next';

const TimeModal = ({ works, setWorks, id, time, setTime, setTimeShow }) => {
  const { t } = useTranslation();

  const {
    getInputProps,
    getIncrementButtonProps,
    getDecrementButtonProps,
  } = useNumberInput({
    step: 30,
    defaultValue: time,
    min: 0,
    // max: 6,
    // precision: 2,
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  const formatedTime = () => {
    var totalMinutes = getInputProps().value;

    var hourss = Math.floor(totalMinutes / 60);
    var minutess = totalMinutes % 60;

    // console.log(totalMinutes, hourss, minutess);

    // let minutes = parseInt(
    //   moment.duration(getInputProps().value, 'minutes').minutes()
    // );
    // let hours = parseInt(
    //   moment.duration(getInputProps().value, 'minutes').hours()
    // );

    return hourss === 0
      ? `${minutess}min`
      : `${hourss}h ${minutess == 0 ? '' : `${minutess}min`}`;
  };

  //   const [minutes, setMinutes] = useState(getInputProps().value / 60);

  return (
    <Box p="50px">
      <Flex h="60px">
        <Button
          {...dec}
          borderRadius="5px 0 0 5px"
          h="100%"
          w="50px"
          bg="bg.300"
          color="white"
          fontSize="18px"
          _hover={{}}
          _active={{ bg: 'bg.400' }}
        >
          -
        </Button>
        <Input
          {...input}
          readOnly
          borderRadius="0"
          borderInline="none"
          h="100%"
          textAlign="center"
          userSelect="none"
          _focus={{}}
        />
        <Button
          {...inc}
          borderRadius="0 5px 5px 0"
          h="100%"
          w="50px"
          bg="bg.300"
          color="white"
          fontSize="18px"
          _hover={{}}
          _active={{ bg: 'bg.400' }}
        >
          +
        </Button>
      </Flex>

      <Text my="20px" mb="50px" textAlign="center">
        {t('home.workflow.expectedTime')}{' '}
        <Text as="span" color="text.1000">
          {formatedTime()}
        </Text>
      </Text>
      <CustomButton
        bg
        name={t('signup.confirm')}
        onClick={() => {
          setWorks({ ...works, [id]: { expectedTime: getInputProps().value } });
          setTime(formatedTime());
          setTimeShow(false);
        }}
      />
    </Box>
  );
};

export default TimeModal;
