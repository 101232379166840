// 1. Import `extendTheme`
import { extendTheme, theme as chakraTheme } from '@chakra-ui/react';

// aliases for breakpoints
const breakpoints = ['30rem', '48rem', '62rem', '80rem', '98rem'];
[breakpoints.sm, breakpoints.md, breakpoints.lg, breakpoints.xl] = breakpoints;

const overRides = {
  ...chakraTheme,
  breakpoints,
  // custom fonts(fontFamily)
  fonts: {
    body: `Tajawal,Inter,tahoma, sans-serif`,
    heading: `Tajawal,Inter,tahoma, sans-serif`,
  },

  // custom colors
  colors: {
    teal: {
      50: '##E6FFFA',
    },
    text: {
      3000: '#25DAD1',
      2900: '#63626599',
      2800: '#ED6262',
      2700: '#12885A',
      2600: '#788596',
      2500: '#636265',
      2400: '#636265',
      2300: '#6b7284',
      2200: '#f04444',
      2100: '#232728',
      2000: '#49BF8C',
      1900: '#F04E4E',
      1800: '#151818',
      1700: '#F43838',
      1600: '#00C24E',
      1500: '#49BF8C',
      1400: '#4F4F4F',
      1300: '#262627',
      1200: '#B2C8D5',
      1100: '#A3AEB4',
      1000: '#21C6E1',
      900: '#C9D1D2',
      800: '#3C434A',
      700: '#B7BBCF',
      600: '#181F27',
      500: '#4A5159',
      400: '#414043',
      300: '#787F9E',
      200: '#1BA3EF',
      100: '#A4AAC7',
      pending: '#FC9D2E',
      completed: '#49BF8C',
      in_queue: '#fdbd72',
      in_progress: '#ED6262', // or opened
      available: '#49BF8C',
      unavailable: '#F04E4E',
      searchId: '#21C6E1',
    },
    bg: {
      3100: '#3498ff',
      3000: '#21c6e126',
      2900: '#FDBD72',
      2800: '#F04E4E',
      2700: '#FBFBF1',
      2600: '#feebe8',
      2500: '#86D2E8',
      2400: '#F86C6C',
      2300: '#1FBE80',
      2200: '#F6F6F6',
      2100: '#FDEBD3',
      2000: '#EDFBFF',
      1900: '#e37dc026',
      1800: '#7686ca26',
      1700: '#1ba3ef26',
      1600: '#25dad126',
      1500: '#f3f4f6',
      1400: '#fee2e2',
      1300: 'rgba(249,250,251,1)',
      1200: '#F6F8F9',
      1100: '#7686CA',
      1000: '#49bf8c26',
      900: '#f04e4e26',
      800: '#1ba3ef26',
      700: '#1BA3EF',
      600: '#7686CA',
      500: '#49BF8C',
      400: '#239fb3',
      300: '#21C6E1',
      200: '#EEF1F7',
      100: '#EAF4FF',
      pending: '#FFF5EA',
      completed: '#49bf8c26', // or available
      in_queue: '#fdbd7233',
      in_progress: '#ed626233', // or opened
      unavailable: '#f04e4e26',
      searchId: '#21c6e126',
      service: '#FDBD72',
      suggestions: '#49BF8C',
      technical: '#7686CA',
    },
    // btn: {
    //   primary: '',
    //   primary_h: '',
    //   secondary: '',
    //   secondary_h: '',
    // },
  },

  // custom font size
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.875rem', // 30px
    '4xl': '2.25rem', // 36px
    '4.5xl': '2.5rem', // 40px
    '5xl': '3rem', // 48px
    '6xl': '4rem', // 64px
    '7xl': '4.5rem', // 72px
    '8xl': '5rem', // 80px
    '9xl': '6rem', // 100px,
    '10xl': '7rem',
    '11xl': '8rem',
  },

  // custom margin and padding
  spacing: {
    xs: '8px',
    sm: '16px',
    md: '24px',
    lg: '32px',
  },

  // custom components
  components: {
    // customize chakra base components example
    Heading: {
      baseStyle: {
        fontWeight: '300',
      },
    },
  },
};

// 2. Call `extendTheme` and pass your custom values
const customTheme = extendTheme(overRides);
// export customTheme
export default customTheme;
