import * as Yup from 'yup';
import { checkEmailValidity } from 'apis/iam/Signup';

export const PersonalSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'signup.firstName3Char')
    .max(100, 'signup.firstName100char')
    .required('signup.fieldRequired'),
  lastName: Yup.string()
    .min(3, 'signup.lastName3Char')
    .max(100, 'signup.lastName100Char')
    .required('signup.fieldRequired'),
  email: Yup.string()
    .email('signup.invalidEmail')
    .max(250, 'signup.email250Char')
    .required('signup.fieldRequired'),
  phone: Yup.string()
    .min(10, 'signup.phone8Char')
    .max(16, 'phone12Char')
    .required('signup.fieldRequired')
    .test('phoneStartsWithZero', 'startWithZero', phone =>
      phone && phone[3] === '0' ? false : true
    ),
  password: Yup.string()
    .min(6, 'signup.password6Char')
    .max(100, 'signup.password100Char')
    .required('signup.fieldRequired'),
  confirmPassword: Yup.string()
    .min(6, 'signup.password6Char')
    .max(100, 'signup.password100Char')
    .required('signup.fieldRequired')
    .oneOf([Yup.ref('password'), null], 'signup.passwordsMatched'),
});
