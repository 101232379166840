import { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Text,
  RadioGroup,
  Radio,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { CustomButton } from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getAttendanceTime, updateAttendanceTime } from 'apis/settings';
import { useTranslation } from 'react-i18next';

const Choise = ({ isView, number, value }) => {
  const { t } = useTranslation();

  return (
    <Box display="block">
      <Radio
        value={value}
        mb="10px"
        colorScheme="cyan"
        _focus={{}}
        isDisabled={isView}
      >
        <Text color="text.600">
          {t('account.after')}{' '}
          <Box as="span" fontWeight="bold">
            {number}
          </Box>{' '}
          {t('account.minutes')}
        </Text>
      </Radio>
    </Box>
  );
};

const Attendence = ({ isView, attendanceTime, attendanceTimeSuccess }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const toast = useToast();

  const [value, setValue] = useState(null);

  const handleSubmit = useMutation(
    () => updateAttendanceTime(parseInt(value)),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAttendanceTime');

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateAttendance')}
                description={t('toasts.updateAttendance')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateAttendance')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box mt="36px">
      <Heading
        as="h3"
        fontWeight="bold"
        fontSize="20px"
        mb="5px"
        textTransform="capitalize"
      >
        {t('account.attendance')}
      </Heading>
      <Text fontWeight="bold" fontSize="18px" color="text.800" mb="10px">
        {t('account.afterHowMinutes')}
      </Text>

      {attendanceTimeSuccess && (
        <RadioGroup
          display="inline-block"
          onChange={setValue}
          value={
            value || attendanceTime?.data.attendancePermitDuration.toString()
          }
        >
          <Choise value="1" number="0" isView={isView} />
          <Choise value="15" number="15" isView={isView} />
          <Choise value="30" number="30" isView={isView} />
          <Choise value="45" number="45" isView={isView} />
        </RadioGroup>
      )}
      {!isView ? (
        <CustomButton
          onClick={handleSubmit.mutate}
          bg
          name={t('account.saveChanges')}
          w="380px"
          mt="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          loading={handleSubmit.isLoading && <Spinner ms="5px" />}
          disabled={
            !value ||
            handleSubmit.isLoading ||
            value === attendanceTime?.data.attendancePermitDuration.toString()
              ? true
              : false
          }
        />
      ) : null}
    </Box>
  );
};

export default Attendence;
