import TableRow from './table-row';
import moment from 'moment';
import { CustomTable } from 'components/authenticated/shared';
import { useTranslation } from 'react-i18next';

const ChecktimeTable = ({
  data,
  datePicker,
  endDate,
  shift,
  shifts,
  createdAt,
  ...props
}) => {
  const { t } = useTranslation();
  //function that return all days in specific month and specifc year
  const getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);

    const dates = [];

    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };

  //our current date
  const now = new Date();

  //date picker date that we passed
  const date = new Date(datePicker);

  //will store an array of days of the month that we will pass in specific year
  const dateNow = getAllDaysInMonth(date.getFullYear(), date.getMonth());

  const tableData = () => {
    return dateNow.map(e => {
      let arrShifts = [];

      if (
        parseInt(moment(e).locale('en').format('YYYY')) >
          parseInt(moment(now).locale('en').format('YYYY')) ||
        parseInt(moment(e).locale('en').format('YYYY')) <
          parseInt(moment(createdAt).locale('en').format('YYYY'))
      ) {
        return;
      } else {
        if (
          parseInt(moment(e).locale('en').format('YYYY')) ===
          parseInt(moment(now).locale('en').format('YYYY'))
        ) {
          if (
            parseInt(moment(e).locale('en').format('M')) >
              parseInt(moment(now).locale('en').format('M')) ||
            parseInt(moment(e).locale('en').format('M')) <
              parseInt(moment(createdAt).locale('en').format('M'))
          ) {
            return;
          } else {
            if (
              parseInt(moment(e).locale('en').format('M')) ===
              parseInt(moment(createdAt).locale('en').format('M'))
            ) {
              if (
                parseInt(moment(e).locale('en').format('D')) <
                parseInt(moment(createdAt).locale('en').format('D'))
              ) {
                return;
              }
            }

            if (
              parseInt(moment(e).locale('en').format('M')) ===
              parseInt(moment(now).locale('en').format('M'))
            ) {
              if (
                parseInt(moment(e).locale('en').format('D')) >
                parseInt(moment(now).locale('en').format('D'))
              ) {
                return;
              }
            }
          }
        }
      }

      if (
        shift?.weekends?.includes(
          new Date(moment(e).locale('en').format()).getDay()
        )
      ) {
        const weekend = {
          type: 'weekend',
          shiftName: shift.label,
        };
        arrShifts.push(weekend);
      } else {
        const presentObject = data?.data?.find(a => {
          return (
            a.shift.id == shift.value &&
            moment(a.day).locale('en').format('D-M-yyyy') ===
              moment(e).locale('en').format('D-M-yyyy')
          );
        });

        if (presentObject) {
          if (presentObject.leave) {
            const leave = {
              type: 'leave',
              shiftName: shift.label,
              approvedBy: presentObject.approvedBy,
            };
            arrShifts.push(leave);
          } else {
            let startTime = moment(presentObject.checkIn, 'HH:mm:ss')
              .locale(t('common.lang'))
              .format('hh:mm A');

            let endTime = moment(presentObject.checkOut, 'HH:mm:ss')
              .locale(t('common.lang'))
              .format('hh:mm A');

            const start = shift?.start;
            const end = shift?.end;

            //diff between now and the start of shift
            const d1 = moment(new Date(presentObject?.day + ' ' + start));
            const d2 = moment(new Date());
            const diffDates = d2.diff(d1, 'minutes');

            //diff between start and end hours of shift
            const dStart = moment(
              new Date(presentObject?.day + ' ' + start)
            ).locale('en');
            const dEnd = moment(
              new Date(presentObject?.day + ' ' + end)
            ).locale('en');
            const diffMinutes = dEnd.diff(dStart, 'minutes');

            let checkoutBySystem = '--:--';

            if (diffDates > diffMinutes + 30) {
              checkoutBySystem =
                // moment(checkoutTimeForce._d, 'HH:mm:ss').format(
                //   'hh:mm A'
                // ) +
                'Checkout by system';
            }

            const present = {
              type: 'present',
              shiftName: presentObject.shift.name,
              checkIn: startTime,
              checkOut:
                presentObject.checkOut != null ? endTime : checkoutBySystem,
              checkInLocation: presentObject.shift.locationName,
              checkOutLocation: presentObject.shift.locationName,
              lateCheckIn: presentObject.lateCheckIn,
              earlyCheckOut: presentObject.lateCheckOut,
              outOfRangeCheckIn: presentObject.outOfRangeCheckIn,
              outOfRangeCheckOut: presentObject.outOfRangeCheckOut,
              // workingHours: workingHours,
            };
            arrShifts.push(present);
          }
        } else {
          const absent = {
            type: 'absent',
            shiftName: shift.label,
          };
          arrShifts.push(absent);
        }
      }

      const tableRow = (
        <TableRow
          shifts={arrShifts}
          date={moment(e).locale(t('common.lang')).format('ddd, D MMMM YYYY')}
        />
      );
      arrShifts = [];

      return tableRow;
    });
  };

  return (
    <>
      <CustomTable
        headers={[
          t('common.date'),
          t('common.shift'),
          t('common.clockin'),
          t('common.clockout'),
        ]}
        thBg="bg.1200"
        mb="15px"
      >
        {tableData()}
      </CustomTable>
    </>
  );
};

export default ChecktimeTable;
