import { useState, useRef, useEffect } from 'react';
import {
  Box,
  Grid,
  Text,
  Button,
  Flex,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import {
  CustomDate,
  ExportAttendance,
  CustomToast,
} from 'components/authenticated/shared';
import { CustomButton, Label, Select } from 'components/shared';
// import ExcelTable from './excelTable';
import { useMutation, useQuery } from 'react-query';
import { getUserAttendance } from 'apis/clockin/Attendance';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import PrintInvoice from './print-invoice';
import InvoiceToExcel from './invoice-excel';
import { exportInvoice } from 'apis/invoice/invoice';
import { useToastContainer } from 'react-toastify';
import { date } from 'yup';

const ExportModal = ({ branch, customerId = null, customerName = 'All' }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { user } = JSON.parse(localStorage.getItem('user'));
  const branches = user?.branches;

  const mainCompany = branches?.find(e => {
    return e.isDefault == 'true';
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: 'Users Attendance',
  });

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);

  const [isPrepareButtonExist, setIsPrepareButtonExist] = useState(true);

  const [fileType, setFileType] = useState({
    label: 'pdf',
    value: 'pdf',
  });

  useEffect(() => {
    setIsPrepareButtonExist(true);
  }, [startDate, endDate]);

  const { mutate, isLoading, data } = useMutation(exportInvoice, {
    onSuccess: res => {
      setIsPrepareButtonExist(false);
      // const invoicesData = res?.data?.data;

      // if (invoicesData?.length > 0) {
      // } else {
      //   toast({
      //     render: props => {
      //       return (
      //         <CustomToast
      //           title={'export invoices'}
      //           description={'There is no data to export'}
      //           status="success"
      //           onClose={props.onClose}
      //         />
      //       );
      //     },
      //   });
      // }
    },
    onError: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('accounting.exportInvoices')}
              description={t('modals.somethingWentWrong')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const ButtonDowload = ({ data }) => {
    if (fileType.value == 'pdf') {
      return (
        <CustomButton
          bg
          name={t('accounting.downloadFile')}
          onClick={handlePrint}
          mt="20px"
        />
      );
    }

    return (
      <InvoiceToExcel
        data={data}
        from={startDate}
        to={endDate}
        branch={branch}
        companyName={mainCompany?.name.en}
        customerName={customerName}
      />
    );
  };

  return (
    <>
      <Box>
        <Grid templateColumns="repeat(2,1fr)" gap={6}>
          <Box>
            <Text
              textTransform="capitalize"
              color="text.600"
              fontSize="14px"
              mb="5px"
              fontWeight="semibold"
            >
              {t('common.from')}
            </Text>
            <CustomDate
              selectsStart
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MM/dd/yyyy"
              setDate={setStartDate}
              maxDate={new Date()}
            />
          </Box>
          <Box>
            <Text
              textTransform="capitalize"
              color="text.600"
              fontSize="14px"
              mb="5px"
              fontWeight="semibold"
            >
              {t('common.to')}
            </Text>
            <CustomDate
              selectsEnd
              selected={endDate}
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={new Date()}
              dateFormat="MM/dd/yyyy"
              setDate={setEndDate}
            />
          </Box>
        </Grid>

        <Box mt="20px">
          <Label text={t('accounting.typeOfFile')} />
          <Select
            selectTitle={t('accounting.typeOfFile')}
            w="100%"
            h="60px"
            label="Branch"
            select={fileType}
            handleSelect={e => setFileType(e)}
            selections={[
              { label: 'pdf', value: 'pdf' },
              { label: 'excel', value: 'excel' },
            ]}
          />
        </Box>

        {isPrepareButtonExist ? (
          <CustomButton
            name={
              isLoading
                ? t('accounting.preparingFile')
                : t('accounting.generate')
            }
            onClick={() =>
              mutate({
                from: moment(startDate).startOf("day"),
                to: moment(endDate).utc(),
                branchId: branch?.value,
                customerId,
              })
            }
            mt="20px"
            display="flex"
            alignItems="center"
            loading={isLoading ? <Spinner ms="5px" /> : null}
            disabled={isLoading}
            cursor={isLoading ? 'not-allowed' : 'pointer'}
            _hover={{}}
            _active={{}}
          />
        ) : data?.data?.data?.length > 0 ? (
          <ButtonDowload data={data} />
        ) : (
          <Text mt="20px" textAlign="center">
            {t('accounting.noInvoiceData')}
          </Text>
        )}
      </Box>

      <Box display="none">
        <PrintInvoice
          data={data?.data?.data}
          salesAmount={data?.data?.salesTotalWithTax}
          companyName={mainCompany?.name.en}
          branch={branch}
          from={startDate}
          to={endDate}
          ref={componentRef}
          customerName={customerName}
        />
      </Box>
    </>
  );
};

export default ExportModal;
