import { useState } from 'react';
import { Box, Grid, useToast, Spinner } from '@chakra-ui/react';
import {
  CustomInput,
  CustomButton,
  ErrorMsg,
  MultiSelect,
  Label,
} from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { AddDepartment } from 'schemas/department/AddDepartment';
import { Formik, Form } from 'formik';
import { createDepartment, updateDepartment } from 'apis/settings';
import { getAllPagesUsers } from 'apis/employee';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

const DepartmentPopup = ({ setShow, data }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const toast = useToast();

  const usersIds = data?.users.map(e => {
    return e.id;
  });

  const [employee, setEmployee] = useState(
    data
      ? data.numberOfEmployees == 0
        ? []
        : data.users?.map(e => {
            return { label: e.firstName, value: e.id };
          })
      : []
  );

  //requests
  const { data: employees } = useQuery('getAllPagesUsers', getAllPagesUsers);

  const { mutate: createDepart, isLoading: loadingCreate } = useMutation(
    createDepartment,
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllDepartments');

        data ? setShow({ flag: false, data: {} }) : setShow(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.addDepartment')}
                description={t('toasts.addDepartment')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.addDepartment')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const { mutate: updateDepart, isLoading: loadingUpdate } = useMutation(
    updateDepartment,
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllDepartments');

        data ? setShow({ flag: false, data: {} }) : setShow(false);
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateDepartment')}
                description={t('toasts.updateDepartment')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateDepartment')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box>
      <Formik
        initialValues={{
          name: data ? data.name : '',
          usersIds: data ? usersIds : [],
        }}
        validationSchema={AddDepartment}
        onSubmit={async values => {
          if (data) {
            updateDepart({ values, departmentId: data.id });
          } else {
            createDepart(values);
          }
        }}
      >
        {formik => (
          <Form>
            <Grid gridTemplateColumns="repeat(2,1fr)" gap={6} mb="24px">
              <Box>
                <CustomInput
                  type="text"
                  label={t('account.departmentName')}
                  placeholder={t('account.enterDepartmentName')}
                  value={formik.values.name}
                  onChange={formik.handleChange('name')}
                  onBlur={formik.handleBlur('name')}
                />
                {formik.errors.name && formik.touched.name && (
                  <ErrorMsg text={t(formik.errors.name)} />
                )}
              </Box>

              <Box>
                <Label text={t('account.selectEmployees')} />

                <MultiSelect
                  h="60px"
                  label={t('home.employees.selectEmployee')}
                  labelForMulti={t('home.employees.employees')}
                  labelForSingle={t('home.employees.employee')}
                  select={employee}
                  handleSelect={(value, check) => {
                    if (!check) {
                      let filterEmployees = employee.filter(
                        elm => elm.value !== value.value
                      );

                      let ids = filterEmployees.map(e => {
                        return e.value;
                      });

                      setEmployee(filterEmployees);
                      formik.setFieldValue('usersIds', ids);
                    } else {
                      let employeesArr = [...employee, value];
                      let employees = employeesArr.map(e => {
                        return e.value;
                      });
                      setEmployee(prevState => [...prevState, value]);

                      formik.setFieldValue('usersIds', employees);
                    }
                  }}
                  selections={employees?.data.map((item, index) => ({
                    label: item.firstName + ' ' + item.lastName,
                    value: item.id,
                  }))}
                />

                {/* {formik.errors.weekends && formik.touched.weekends && (
                    <ErrorMsg text={formik.errors.weekends} />
                  )} */}
              </Box>
            </Grid>
            <CustomButton
              bg
              type="submit"
              name={t('account.saveChanges')}
              w="100%"
              isDisabled={loadingCreate || loadingUpdate}
              display="flex"
              alignItems="center"
              justifyContent="center"
              loading={(loadingCreate || loadingUpdate) && <Spinner ms="5px" />}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DepartmentPopup;
