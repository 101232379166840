import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Grid, Text, useToast, Spinner } from '@chakra-ui/react';
import {
  UploadImage,
  UploadFile,
  CustomInput,
  CustomButton,
  MultiSelect,
  PhoneInput,
  ErrorMsg,
  CustomUploadFile,
} from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { useMutation, useQueryClient } from 'react-query';
import { AddUserSchema } from 'schemas/employee/AddUserSchema';
import { Formik, Form } from 'formik';
import { createUser, updateEmployee } from 'apis/employee';
import { useTranslation } from 'react-i18next';
import { checkEmailValidity, checkPhoneValidity } from 'apis/iam/Signup';
import profileDefault from 'assets/images/profileDefault.png';

// import { generate } from 'generate-password';

const EmployePopup = ({ setShow, data, refetch }) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadImageLoading, setUploadImageLoading] = useState(false);

  const queryClient = useQueryClient();
  const toast = useToast();

  const { user, language } = useContext(userContext);

  const [branch, setBranch] = useState(
    data
      ? data.branches.map(elm => ({ label: elm.name.en, value: elm.id }))
      : []
  );

  const [shift, setShift] = useState(
    data ? data.shifts.map(elm => ({ label: elm.name, value: elm.id })) : []
  );

  const [department, setDepartment] = useState(
    data && data.department
      ? { label: data.department.name, value: data.department.id }
      : { label: null, value: null }
  );
  const [userImage, setUserImage] = useState(null);

  const handleFile = (file, fileName) => {
    setUserImage(fileName);
  };

  const handleBranch = (value, check) => {
    if (!check) {
      let filteredBranches = branch.filter(elm => elm.value !== value.value);
      setBranch(filteredBranches);
    } else setBranch(prevState => [...prevState, value]);
  };

  const handleSubmit = useMutation(
    ({ values, branch, userImage, password }) =>
      data
        ? updateEmployee(values, branch, userImage, data.id, department, shift)
        : createUser(values, branch, userImage, password),

    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllUsers');

        if (refetch) {
          refetch();
        }

        data ? setShow({ flag: false, data: {} }) : setShow(false);
        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updateEmployee')
                    : t('toasts.titles.createEmployee')
                }
                description={
                  data
                    ? t('toasts.employeeUpdated')
                    : t('toasts.employeeCreated')
                }
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        if (result.response.data.errors?.phoneNumber?.isPhoneNumber) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={
                    data
                      ? t('toasts.titles.updateEmployee')
                      : t('toasts.titles.createEmployee')
                  }
                  description={t('signup.isPhoneNumber')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else if (result.response.data.errors?.errName) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={
                    data
                      ? t('toasts.titles.updateEmployee')
                      : t('toasts.titles.createEmployee')
                  }
                  description={t('signup.alreadyExists')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else if (result.response.data.errors?.phoneNumber?.alreadyExist) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={
                    data
                      ? t('toasts.titles.updateEmployee')
                      : t('toasts.titles.createEmployee')
                  }
                  description={t('signup.phoneAlreadyExists')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        }
        //  else if (result.response.data.errors?.shifts?.overlapping) {
        //   toast({
        //     title: data
        //       ? t('toasts.titles.updateEmployee')
        //       : t('toasts.titles.createEmployee'),
        //     description: t('signup.shiftsOverlaping'),
        //     status: 'error',
        //     duration: 5000,
        //     isClosable: true,
        //   });
        // }
        else {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={
                    data
                      ? t('toasts.titles.updateEmployee')
                      : t('toasts.titles.createEmployee')
                  }
                  description={t('toasts.error')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        }
      },
    }
  );

  return (
    <Flex justifyContent="center">
      <Box maxW="80%">
        <Formik
          initialValues={{
            firstName: data ? data.firstName : '',
            lastName: data ? data.lastName : '',
            email: data ? data.emailAddress : '',
            phone: data ? data.phoneNumber : '',
            image: '',
          }}
          validationSchema={AddUserSchema}
          onSubmit={values => {
            if (branch.length <= 0) {
              toast({
                render: props => {
                  return (
                    <CustomToast
                      title={t('toasts.titles.selectBranch')}
                      description={t('toasts.selectBranch')}
                      status="error"
                      onClose={props.onClose}
                    />
                  );
                },
              });
            } else {
              handleSubmit.mutate({
                values,
                branch,
                password: '123456',
              });
            }

            // let password = generate({
            //   length: 6,
            //   numbers: true,
            //   uppercase: false,
            // });
          }}
        >
          {formik => (
            <Form>
              {/* <UploadImage
                radius
                src={data?.profileImageUrl}
                handleFile={handleFile}
                defaultImage={profileDefault}
                formikChange={e => formik.setFieldValue('image', e)}
              /> */}
              <CustomUploadFile
                src={data?.profileImageUrl}
                handleFile={handleFile}
                defaultImage={profileDefault}
                formikChange={e => formik.setFieldValue('image', e)}
                setUploadImageLoading={setUploadImageLoading}
                radius
              />
              <Flex justifyContent="space-between" mb="24px">
                <Box w="48%">
                  <CustomInput
                    type="text"
                    label={t('signup.firstName')}
                    placeholder={t('home.employees.enterFirstName')}
                    value={formik.values.firstName}
                    onChange={formik.handleChange('firstName')}
                    onBlur={formik.handleBlur('firstName')}
                  />
                  {formik.errors.firstName && formik.touched.firstName && (
                    <ErrorMsg text={t(formik.errors.firstName)} />
                  )}
                </Box>
                <Box w="48%">
                  <CustomInput
                    type="text"
                    label={t('signup.lastName')}
                    placeholder={t('home.employees.enterLastName')}
                    value={formik.values.lastName}
                    onChange={formik.handleChange('lastName')}
                    onBlur={formik.handleBlur('lastName')}
                  />
                  {formik.errors.lastName && formik.touched.lastName && (
                    <ErrorMsg text={t(formik.errors.lastName)} />
                  )}
                </Box>
              </Flex>

              <Box mb="24px">
                <CustomInput
                  type="email"
                  label={t('signup.email')}
                  placeholder={t('home.employees.enterEmail')}
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                />
                {formik.errors.email && formik.touched.email && (
                  <ErrorMsg text={t(formik.errors.email)} />
                )}
                {/* <Text color="red">
                  {email?.errName && `email is used before`}
                </Text> */}
              </Box>

              <Box mb="24px">
                <PhoneInput
                  label={t('signup.phone')}
                  value={formik.values.phone}
                  onChange={formik.handleChange('phone')}
                  onBlur={formik.handleBlur('phone')}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <ErrorMsg text={t(formik.errors.phone)} />
                )}
                {/* <Text color="red">
                  {phone?.phoneNumber.isPhoneNumber &&
                    t('signup.isPhoneNumber')}

                  {phone?.phoneNumber.alreadyExist &&
                    t('signup.phoneAlreadyExists')}
                </Text> */}
              </Box>

              {/* <Box mb="24px">
                <CustomInput
                  type="number"
                  label={'salary'}
                  placeholder={'employer salary'}
                  value={formik.values.email}
                  onChange={formik.handleChange('email')}
                  onBlur={formik.handleBlur('email')}
                />
                {formik.errors.email && formik.touched.email && (
                  <ErrorMsg text={t(formik.errors.email)} />
                )}
                <Text color="red">
                  {email?.errName && `email is used before`}
                </Text>
              </Box> */}

              <Box mb="24px">
                <Text fontSize="14px" mb="5px" fontWeight="bold">
                  {t('home.branches.title')}
                </Text>
                <MultiSelect
                  w="100%"
                  h="60px"
                  label={t('filter.branch')}
                  labelForMulti={t('sideBar.branches')}
                  select={branch}
                  handleSelect={handleBranch}
                  selections={user?.user?.branches?.map((item, index) => ({
                    label: language == 'en' ? item.name.en : item.name.ar,
                    value: item.id,
                  }))}
                />
              </Box>

              <CustomButton
                bg
                type="submit"
                name={t('account.saveChanges')}
                w="100%"
                isDisabled={handleSubmit.isLoading || uploadImageLoading}
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={handleSubmit.isLoading && <Spinner ms="5px" />}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default EmployePopup;
