import { Text } from '@chakra-ui/react';

const Label = ({ text }) => {
  return (
    <Text
      textTransform="capitalize"
      color="text.600"
      fontSize="14px"
      mb="5px"
      fontWeight="semibold"
    >
      {text}
    </Text>
  );
};

export default Label;
