import {
  Box,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Switch,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';

const CustomSwitch = ({
  setShowEdit,
  setShowDelete,
  setShowActive,
  item,
  isActive,
  ...props
}) => {
  const checkIsActive = () => {
    if (isActive) {
      if (isActive?.isActive) {
        return true;
      } else {
        return false;
      }
    } else {
      if (item?.deletedAt == null) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Switch
      position="absolute"
      top="20px"
      right="15px"
      size="lg"
      // colorScheme="cyan"
      onChange={e => {
        if (!e.target.checked) {
          setShowDelete({ flag: true, data: item });
        } else {
          setShowActive({ flag: true, data: item });
        }
      }}
      isChecked={checkIsActive()}
      background="none"
      _focus={{ background: 'none' }}
      _active={{}}
      {...props}
    />
  );
};

export default CustomSwitch;
