import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Text, Grid, Spinner, useToast } from '@chakra-ui/react';
import {
  CustomInput,
  CustomButton,
  ErrorMsg,
  MultiSelect,
  UploadImage,
  CustomUploadFile,
  Label,
} from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { useMutation, useQueryClient } from 'react-query';
import { Formik, Form } from 'formik';
import { AddDocumentSchema } from 'schemas/document/AddDocumentSchema';
import { createDocument } from 'apis/document';
import { useTranslation } from 'react-i18next';
import UploadFile from './upload-file';

const DocumentPopup = ({ setShow }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const toast = useToast();

  const { user, language, branchFilter } = useContext(userContext);

  const [errors, setErrors] = useState([]);
  const [file, setFile] = useState(null);
  const [branch, setBranch] = useState([branchFilter]);

  useEffect(() => {
    if (branchFilter.value == null) {
      setBranch([]);
    } else {
      setBranch([branchFilter]);
    }
  }, [branchFilter]);

  const handleFile = (file, fileName) => {
    setFile(file);
  };
  const handleBranch = (value, check) => {
    if (!check) {
      let filteredBranches = branch.filter(elm => elm.value !== value.value);
      setBranch(filteredBranches);
    } else setBranch(prevState => [...prevState, value]);
  };

  const handleSubmit = useMutation(
    ({ values, branch, file }) => createDocument(values, branch, file),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllDocuments');

        setErrors([]);
        setShow(false);
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.addDocument')}
                description={t('toasts.addDocument')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.addDocument')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Flex justifyContent="center">
      <Box w="85%">
        <Formik
          initialValues={{
            name: '',
            image: '',
          }}
          validationSchema={AddDocumentSchema}
          onSubmit={values => {
            if (branch.length <= 0) {
              setErrors(prevErrors => [...prevErrors, 'branch']);
              return;
            }

            handleSubmit.mutate({
              values,
              branch,
              file,
            });
          }}
        >
          {formik => {
            console.log(formik.values);
            return (
              <Form>
                <Box w="full" mb="24px">
                  {/* <UploadImage
                textTitle={t('account.uploadPhoto')}
                radius
                // defaultImage={userData.profileImageUrl}
                formikChange={e => formik.setFieldValue('image', e)}
                mb="0"
                required={formik.errors.image}
                requiredMessage={t('modals.uploadFile')}
              /> */}

                  {/* <CustomUploadFile
                textTitle={t('account.uploadPhoto')}
                // defaultImage={userData.profileImageUrl}
                formikChange={e => formik.setFieldValue('image', e)}
                mb="0"
                required={formik.errors.image}
                requiredMessage={t('modals.uploadFile')}
              /> */}

                  {/* <UploadFile handleFile={handleFile} /> */}
                </Box>

                <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px">
                  <Box>
                    <CustomInput
                      type="text"
                      label={t('modals.documentName')}
                      placeholder={t('home.documents.enterDocument')}
                      value={formik.values.name}
                      onChange={formik.handleChange('name')}
                      onBlur={formik.handleBlur('name')}
                    />
                    {formik.errors.name && formik.touched.name && (
                      <ErrorMsg text={t(formik.errors.name)} />
                    )}
                  </Box>

                  <Box>
                    <Text fontSize="14px" mb="5px" fontWeight="bold">
                      {t('home.branches.title')}
                    </Text>
                    <MultiSelect
                      labelForMulti={t('sideBar.branches')}
                      w="100%"
                      h="60px"
                      label={t('filter.branch')}
                      select={branch}
                      handleSelect={handleBranch}
                      selections={user?.user?.branches?.map((item, index) => ({
                        label: language == 'en' ? item.name.en : item.name.ar,
                        value: item.id,
                      }))}
                    />
                    {errors.includes('branch') && (
                      <ErrorMsg text={t('signup.fieldRequired')} />
                    )}
                  </Box>
                </Grid>

                <Box my="25px">
                  <Label text={t('modals.UploadFile')} />
                  <Box
                    border={
                      formik.errors.image && formik.touched.name
                        ? '2px solid red'
                        : '1px solid #BFCFD6'
                    }
                    p="10px"
                    borderRadius="8px"
                  >
                    <UploadFile
                      formikChange={e => formik.setFieldValue('image', e)}
                    />
                  </Box>
                </Box>

                <CustomButton
                  bg
                  type="submit"
                  name={t('account.saveChanges')}
                  w="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  loading={handleSubmit.isLoading && <Spinner ms="5px" />}
                  disabled={handleSubmit.isLoading}
                />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Flex>
  );
};

export default DocumentPopup;
