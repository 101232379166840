import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Text, Box, Grid, GridItem, Flex } from '@chakra-ui/react';
import {
  Tabs,
  PageHeader,
  Filter,
  Loading,
  Nodata,
  Error,
} from 'components/authenticated/shared';
import { Card } from 'components/authenticated/clients';
import { Select, CustomModal, SimpleModal } from 'components/shared';
import { ClientPopup } from 'components/authenticated/clients';
import {
  AssignedTasks,
  UnassingedTasks,
  TaskPopup,
} from 'components/authenticated/tasks';
import { Calendar, List } from 'components/authenticated/appointments';
import { getAllAppointments } from 'apis/appointment';
import { useQuery } from 'react-query';
import moment from 'moment';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';

const Appointments = () => {
  const { t } = useTranslation();
  const { user, branchFilter } = useContext(userContext);

  const [showAdd, setShowAdd] = useState(false);

  const [branch, setBranch] = useState(branchFilter);
  const [search, setSearch] = useState('');
  const [day, setDay] = useState(
    moment(new Date()).locale('en').format('YYYY-MM-DD')
  );

  const handleBranch = value => {
    setBranch(value);
  };

  useEffect(() => {
    setBranch(branchFilter);
  }, [branchFilter]);

  const {
    data,
    isLoading,
    isError,
    isSuccess,
    refetch: refetchAllData,
  } = useQuery(
    ['getAllAppointments', branch.value, search, ''],
    getAllAppointments
  );

  const {
    data: dataDay,
    isLoading: isLoadingDay,
    isError: isErrorDay,
    isSuccess: isSuccessDay,
    refetch: refetchList,
  } = useQuery(
    ['getAllAppointments', branch.value, search, day],
    getAllAppointments
  );

  return (
    <Box>
      <Filter
        title={t('home.appointments.title')}
        buttonName={t('home.appointments.addNewAppointment')}
        setShowAdd={setShowAdd}
        btResponsiveWidth="210px"
        isButtonExist={Permission('Workflow') == 'Edit'}
        setValue={setSearch}
      >
        <Select
          selectTitle={t('modals.selectBranch')}
          mx="4"
          w="150px"
          h="40px"
          label="Branch"
          select={branch}
          handleSelect={handleBranch}
          selections={user.user.branches?.map((item, index) => ({
            label: item.name,
            value: item.id,
          }))}
        />
      </Filter>
      {isLoading && <Loading />}
      {isError && <Error />}
      {isSuccess && (
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Calendar w="74%" data={data} setDay={setDay} />
          <Box pos="relative" w="24%" minH="400px">
            <Text fontSize="32px" fontWeight="bold" mb="10px">
              {t('home.appointments.title')}
            </Text>
            {isLoadingDay && <Loading />}
            {isErrorDay && <Error />}
            {isSuccessDay && (
              <List
                data={dataDay}
                refetchAllData={refetchAllData}
                refetchList={refetchList}
              />
            )}
          </Box>
        </Flex>
      )}

      <SimpleModal
        title={t('home.appointments.addNewAppointment')}
        show={showAdd}
        setShow={setShowAdd}
        overflow="outside"
      >
        <TaskPopup
          setShow={setShowAdd}
          refetchAllData={refetchAllData}
          refetchList={refetchList}
        />
      </SimpleModal>
    </Box>
  );
};

export default Appointments;
