import { useState, useEffect, memo } from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { getAllCars } from 'apis/cars';
import { ErrorMsg } from 'components/shared';
import { SelectSearch } from 'components/authenticated/shared';

const HandleCar = memo(({ item, formik }) => {
  const { t } = useTranslation();

  const [carMacker, setCarMacker] = useState({
    label: item?.carDetails?.carManufactur || 'Macker',
    value: item?.carDetails?.carManufactur || null,
  });

  const [carModel, setCarModel] = useState({
    label: item?.carDetails?.carModel || 'Model',
    value: item?.carDetails?.carModel || null,
  });

  const [carYear, setCarYear] = useState({
    label: item?.carDetails?.carYear || 'Year',
    value: item?.carDetails?.carYear || null,
  });

  //state for cars options values
  const [cars, setCars] = useState(null);
  const [brands, setBrands] = useState(null);

  //models contain array of object that contain value and label
  const [models, setModels] = useState(null);
  //allModels contain array of object that contain name of model and array of it years
  const [allModels, setAllModels] = useState(null);

  const [years, setYears] = useState(null);
  const [loadingCars, setloadingCars] = useState(true);

  useEffect(() => {
    const getCars = async () => {
      try {
        let response = await getAllCars();
        setloadingCars(false);
        setCars(response);
      } catch (error) {
        setloadingCars(false);
        // if(error.response?.status === 401)
        //   handleLogOut();
      }
    };
    getCars();
  }, []);

  useEffect(() => {
    const sorting = cars?.map(e => {
      return e.brand;
    });

    const AllBrands = sorting?.sort().map(e => {
      return { label: e, value: e };
    });

    setBrands(AllBrands);
  }, [cars]);

  const handleCarMackerSelect = value => {
    setCarMacker(value);

    //return object that contain this brand
    const brand = cars.find(e => {
      return e.brand == value.value;
    });

    const sortingModels = brand.models.map(e => {
      return e.model;
    });

    const models = sortingModels?.sort().map(e => {
      return { label: e, value: e };
    });

    setModels(models);
    setAllModels(brand.models);

    setCarModel({
      label: 'Model',
      value: null,
    });

    setCarYear({
      label: 'Year',
      value: null,
    });
  };

  //select carModel => store car years
  const handleCarModelSelect = value => {
    setCarModel(value);

    //find object of model
    const m = allModels.find(e => {
      return e.model == value.value;
    });

    const years = m.years.map(e => {
      return { label: e, value: e };
    });

    setYears(years);

    setCarYear({
      label: 'Year',
      value: null,
    });
  };

  const handleCarYearSelect = value => {
    setCarYear(value);
  };

  return (
    <Grid gridTemplateColumns="repeat(3,1fr)" gap={5} mb="20px">
      <Box>
        <Text
          fontSize="14px"
          mb="5px"
          fontWeight="bold"
          opacity={item ? 0.3 : 1}
        >
          {t('project.maker')}
        </Text>
        <SelectSearch
          h="60px"
          label={t('project.carMaker')}
          select={carMacker}
          handleSelect={value => {
            handleCarMackerSelect(value);
            formik.setFieldValue('macker', value.value);
          }}
          isDisabled={item ? true : false}
          selections={brands}
          overflowY="auto"
          loading={loadingCars}
        />
        {formik.errors.macker && formik.touched.macker && (
          <ErrorMsg text={t(formik.errors.macker)} />
        )}
      </Box>

      <Box>
        <Text
          fontSize="14px"
          mb="5px"
          fontWeight="bold"
          opacity={carMacker.value == null || item ? 0.3 : 1}
        >
          {t('project.model')}
        </Text>
        <SelectSearch
          h="60px"
          label={t('project.carModel')}
          select={carModel}
          handleSelect={value => {
            handleCarModelSelect(value);
            formik.setFieldValue('model', value.value);
          }}
          selections={models}
          isDisabled={models == null ? true : false}
        />
        {formik.errors.model && formik.touched.model && (
          <ErrorMsg text={t(formik.errors.model)} />
        )}
      </Box>

      <Box>
        <Text
          fontSize="14px"
          mb="5px"
          fontWeight="bold"
          opacity={carModel.value == null || item ? 0.3 : 1}
        >
          {t('project.year')}
        </Text>
        <SelectSearch
          h="60px"
          label={t('project.carYear')}
          select={carYear}
          handleSelect={value => {
            handleCarYearSelect(value);
            formik.setFieldValue('year', value.value);
          }}
          selections={years}
          isDisabled={years == null ? true : false}
        />
        {formik.errors.year && formik.touched.year && (
          <ErrorMsg text={t(formik.errors.year)} />
        )}
      </Box>
    </Grid>
  );
});

export default HandleCar;
