import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Box, useToast } from '@chakra-ui/react';
import Unauthenticated from './layout/unauthenticated';
import Authenticated from './layout/authenticated';
import 'react-phone-input-2/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import './styles/global.scss';
import Signin from 'pages/unauthenticated/signin';
import { configureAxios } from './axios-config';
import Signup from 'pages/unauthenticated/signup';
import ResetPasswordPage from 'pages/unauthenticated/reset-password';
import { Loading, CustomToast } from 'components/authenticated/shared';
import { logout } from 'apis/iam/Login';
import { userContext } from 'contexts/userContext';
import Profile from 'pages/authenticated/profile';
import Clients from 'pages/authenticated/clients';
import ClientsProfile from 'pages/authenticated/client';
import Employees from 'pages/authenticated/employees';
import Employee from 'pages/authenticated/employee';
import Branches from 'pages/authenticated/branches';
import Branch from 'pages/authenticated/branch';
import Services from 'pages/authenticated/services';
import Parts from 'pages/authenticated/parts';
import Dashboard from 'pages/authenticated/dashboard';
import Attendance from 'pages/authenticated/attendance';
import Requests from 'pages/authenticated/requests';
import Projects from 'pages/authenticated/projects';
import Documents from 'pages/authenticated/documents';
import Tasks from 'pages/authenticated/tasks';
import Invoices from 'pages/authenticated/invoices';
import Appointments from 'pages/authenticated/appointments';
import EmailVerification from 'pages/client/email-verification';
import RtlProvider from './rtl-provider';
import SmallDevices from 'pages/small-devices';
import AccessPage from 'helpers/AccessPage';
import Rating from 'pages/client/rating';
import { onMessageListener } from './push-notification';
import { requestPermission } from 'push-notification';
import Salaries from 'pages/authenticated/salaries';
import MainCategories from 'pages/authenticated/main-categories';
import QuickbooksSignin from 'pages/unauthenticated/quickbooks-signin';

function App() {
  // const toast = useToast();
  //listen if there is notification comming

  // onMessageListener()
  //   .then(payload => {
  //     toast({
  //       render: props => {
  //         return (
  //           <CustomToast
  //             title={payload.data.title}
  //             description={payload.data.body}
  //             status="success"
  //             onClose={props.onClose}
  //           />
  //         );
  //       },
  //       position: 'bottom-right',
  //     });
  //   })
  //   .catch(err => console.log('failed error : ', err));

  const [user, setUser] = useState(null);
  console.log(user);
  // useEffect(() => {
  //   if (user) {
  //     if (localStorage.getItem('quickbooksAuth')) {
  //       setIsQuickBooksAuth(true);
  //     } else {
  //       setIsQuickBooksAuth(false);
  //       navigate('/quickbooks');
  //     }
  //   }
  // }, [localStorage.getItem('quickbooksAuth')]);

  const [branchFilter, setBranchFilter] = useState({
    label: 'All branches',
    value: null,
  });
  const [language, setLanguage] = useState(localStorage.getItem('i18nextLng'));
  const [windowSize, setWindowSize] = useState(false);

  const direction = localStorage.getItem('i18nextLng');

  let pathName = window.location.pathname;

  // configureAxios(user?.accessToken, language);

  useEffect(() => {
    document.documentElement.dir = direction == 'ar' ? 'rtl' : 'ltr';

    checkAuth();
  }, [user, direction]);

  useEffect(() => {
    if (window.innerWidth < 1094) {
      setWindowSize(true);
    } else {
      setWindowSize(false);
    }

    window.onresize = () => {
      if (window.innerWidth < 1094) {
        setWindowSize(true);
      } else {
        setWindowSize(false);
      }
    };

    requestPermission();
  }, []);

  const checkAuth = () => {
    if (!user) {
      let userData = localStorage.getItem('user');

      if (!userData) {
        setUser({});
        return false;
      } else {
        setUser(JSON.parse(userData));
        return true;
      }
    } else {
      return true;
    }
  };

  const handleLogOut = async () => {
    try {
      await logout();

      setUser({});
      localStorage.removeItem('user');
      localStorage.removeItem('defaultBranch');
    } catch (error) {
      setUser({});
      localStorage.removeItem('user');
      localStorage.removeItem('defaultBranch');
    }
  };

  if (!user)
    return (
      <Box pos="relative" minH="100vh">
        <Loading />
      </Box>
    );

  if (Object.keys(user).length === 0) {
    return (
      <userContext.Provider
        value={{
          user,
          setUser,
          checkAuth,
          handleLogOut,
          setLanguage,
          language,
        }}
      >
        {windowSize && pathName.includes('/rate') ? (
          <RtlProvider>
            <Routes>
              <Route path="/rate/:userId" element={<Rating />} />
            </Routes>
          </RtlProvider>
        ) : !windowSize && pathName.includes('/rate') ? (
          <RtlProvider>
            <Routes>
              <Route path="/rate/:userId" element={<Rating />} />
            </Routes>
          </RtlProvider>
        ) : windowSize ? (
          <SmallDevices />
        ) : (
          <RtlProvider>
            <Unauthenticated>
              <Routes>
                <Route exact path="/" element={<Signin />} />
                <Route path="/signup" element={<Signup />} />
                <Route
                  path="/ResetPassword/:token"
                  element={<ResetPasswordPage />}
                />
                <Route path="/rate:userId" element={<Rating />} />
                <Route
                  path="/AccountVerification/:token"
                  element={<EmailVerification />}
                />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Unauthenticated>
          </RtlProvider>
        )}
      </userContext.Provider>
    );
  } else {
    return (
      <userContext.Provider
        value={{
          user,
          setUser,
          checkAuth,
          handleLogOut,
          setLanguage,
          language,
          branchFilter,
          setBranchFilter,
        }}
      >
        {windowSize && pathName.includes('/rate') ? (
          <RtlProvider>
            <Routes>
              <Route path="/rate/:userId" element={<Rating />} />
            </Routes>
          </RtlProvider>
        ) : !windowSize && pathName.includes('/rate') ? (
          <RtlProvider>
            <Routes>
              <Route path="/rate/:userId" element={<Rating />} />
            </Routes>
          </RtlProvider>
        ) : windowSize ? (
          <SmallDevices />
        ) : (
          <RtlProvider>
            {pathName.includes('/quickbooks') ? (
              <Unauthenticated>
                <Routes>
                  <Route path="/quickbooks" element={<QuickbooksSignin />} />
                </Routes>
              </Unauthenticated>
            ) : (
              <Authenticated>
                <Routes>
                  <Route exact path="/" element={<Dashboard />} />
                  {/* <Route exact path="/salaries" element={<Salaries />} /> */}
                  <Route exact path="/invoices" element={<Invoices />} />
                  <Route
                    exact
                    path="/products/main-categories"
                    element={<MainCategories />}
                  />

                  {/* {AccessPage(user.user.roles, 'Profile') && (
              <Route path="/profile" element={<Profile />} />
            )} */}
                  <Route path="/profile" element={<Profile />} />
                  {AccessPage(user?.user.roles, 'Clients') && (
                    <Route path="/clients" element={<Clients />} />
                  )}
                  {AccessPage(user?.user.roles, 'Clients') && (
                    <Route path="/clients/:id" element={<ClientsProfile />} />
                  )}
                  {AccessPage(user?.user.roles, 'Employees') && (
                    <Route path="/employees" element={<Employees />} />
                  )}
                  {AccessPage(user?.user.roles, 'Employees') && (
                    <Route path="/employees/:id" element={<Employee />} />
                  )}
                  {AccessPage(user?.user.roles, 'Branches') && (
                    <Route path="/branches" element={<Branches />} />
                  )}
                  {AccessPage(user?.user.roles, 'Branches') && (
                    <Route path="/branches/:id" element={<Branch />} />
                  )}
                  {AccessPage(user?.user.roles, 'Products') && (
                    <Route path="/products/services" element={<Services />} />
                  )}
                  {AccessPage(user?.user.roles, 'Products') && (
                    <Route path="/products/parts" element={<Parts />} />
                  )}
                  {AccessPage(user?.user.roles, 'ClockIn') && (
                    <Route
                      path="/clockin/attendance"
                      element={<Attendance />}
                    />
                  )}
                  {AccessPage(user?.user.roles, 'ClockIn') && (
                    <Route path="/clockin/requests" element={<Requests />} />
                  )}
                  {AccessPage(user?.user.roles, 'Projects') && (
                    <Route path="/projects" element={<Projects />} />
                  )}
                  {AccessPage(user?.user.roles, 'Documents') && (
                    <Route path="/documents" element={<Documents />} />
                  )}
                  {AccessPage(user?.user.roles, 'Workflow') && (
                    <Route path="/workflow/tasks" element={<Tasks />} />
                  )}
                  {AccessPage(user?.user.roles, 'Workflow') && (
                    <Route
                      path="/workflow/appointments"
                      element={<Appointments />}
                    />
                  )}

                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
              </Authenticated>
            )}
          </RtlProvider>
        )}
      </userContext.Provider>
    );
  }
}

export default App;
