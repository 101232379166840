import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Text, Box, useToast } from '@chakra-ui/react';
import {
  Filter,
  Loading,
  CustomToast,
  Nodata,
} from 'components/authenticated/shared';
import { Select, SimpleModal } from 'components/shared';

import {
  AssignedTasks,
  UnassingedTasks,
  AddNewTask,
} from 'components/authenticated/tasks';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  getAllUsersTasks,
  getAllWorkflows,
  unassignTaskToUser,
  assignTaskToUser,
} from 'apis/invoice/Workflow';
import { useQueryClient, useQuery, useMutation } from 'react-query';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';

const Tasks = () => {
  const toast = useToast();
  const { t } = useTranslation();

  //context
  const { user, branchFilter } = useContext(userContext);

  //popup control(close-open)
  const [showAdd, setShowAdd] = useState(false);

  //filter control(branch-search)
  const [branch, setBranch] = useState(branchFilter);
  const [search, setSearch] = useState('');

  //store all works id with time duration
  const [works, setWorks] = useState({});

  //////////////unnamed states/////////
  const [isDropDisabled, setIsDropDisabled] = useState({
    column: null,
    isBlock: false,
  });

  const [datee, seDate] = useState(new Date());

  const [state, setState] = useState(null);

  const [unassignTasksCount, setUnAssignTasksCount] = useState(0);

  let initialData = {};

  //this effect to set branch select to values in main branesh that store in context
  useEffect(() => {
    setBranch(branchFilter);
  }, [branchFilter]);

  //react-query (requests)
  const queryClient = useQueryClient();

  //request to get all users with them tasks
  const { data, isLoading, isSuccess } = useQuery(
    ['getAllUsersTasks', branch.value],
    getAllUsersTasks
  );

  //request to get all tasks
  const {
    data: allWork,
    isLoading: isLoadingUnassigned,
    isSuccess: isSuccessUnassigned,
    refetch,
  } = useQuery(
    ['getAllWorkflows', datee, search, branch.value],
    getAllWorkflows
  );

  // request to assign task to user
  const { mutate: assignTask } = useMutation(assignTaskToUser, {
    refetchQueries: [{ query: getAllUsersTasks }],
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllUsersTasks');
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.moveTask')}
              description={t('toasts.assignTask')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      refetch();
    },
  });

  //request to remove assign task from user
  const { mutate: unassignTask } = useMutation(unassignTaskToUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllUsersTasks');

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.moveTask')}
              description={t('toasts.unassignTask')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      refetch();
    },
  });

  //in this effect we make initial data for tasks in dnd structure
  useEffect(() => {
    if (isSuccessUnassigned && isSuccess) {
      const tasksss = allWork?.data;

      let realTasks = {};
      let usersObject = {};
      for (let i = 0; i < tasksss.length; i++) {
        realTasks = {
          ...realTasks,
          [tasksss[i].id]: tasksss[i],
        };
      }
      //real tasks is all tasks now

      for (let i = 0; i < data?.data.length; i++) {
        usersObject = {
          unassignTasks: {
            id: 'unassignTasks',
            tasks: allWork?.data.filter(e => {
              return e.workflowStatus == 'to_be_assigned';
            }),
          },
          ...usersObject,
          [data?.data[i].id]: data?.data[i],
        };
      }

      const columensOrder = Object.keys(usersObject);

      initialData = {
        tasks: realTasks,
        columns: usersObject,
        // Facilitate reordering of the columns
        columnOrder: columensOrder,
      };

      setUnAssignTasksCount(usersObject?.unassignTasks?.tasks?.length);
    }

    setState(initialData);
  }, [isLoadingUnassigned, isLoading, allWork]);

  const handleBranch = value => {
    setBranch(value);
  };

  if (state != null) {
    const onDragEnd = result => {
      const { destination, source, draggableId } = result;

      // destination ==> object that contain id of column and index of task that i replaced with in this column
      // source ==> object that contain id of column and index of task that i drag in this column
      // draggableId==> id of task

      if (isDropDisabled.column == 'unassignTasks') {
        if (isDropDisabled.isBlock) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.selectTaskTime')}
                  description={t('toasts.selectTaskTime')}
                  status="warning"
                  onClose={props.onClose}
                />
              );
            },
          });
        }
      } else {
        if (isDropDisabled.isBlock) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.moveTask')}
                  description={t('toasts.taskMustBeInQueue')}
                  status="warning"
                  onClose={props.onClose}
                />
              );
            },
          });
        }
      }

      if (!destination) {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      //column
      const start = state.columns[source.droppableId];
      const finish = state.columns[destination.droppableId];

      if (start === finish) {
        // get tasks of the column
        const newTasks = Array.from(start.tasks);

        newTasks.splice(source.index, 1);
        newTasks.splice(destination.index, 0, draggableId);

        const newColumn = {
          ...start,
          tasks: newTasks,
        };

        const newState = {
          ...state,
          columns: {
            ...state.columns,
            [newColumn.id]: newColumn,
          },
        };

        setState(newState);

        return;
      }

      // Moving from one list to another
      const startTaskIds = Array.from(start.tasks);
      startTaskIds.splice(source.index, 1);
      const newStart = {
        ...start,
        tasks: startTaskIds,
      };

      if (destination.droppableId == 'unassignTasks') {
        unassignTask(draggableId);
      } else if (destination.droppableId != 'unassignTasks') {
        assignTask({
          workflowId: draggableId,
          userId: destination.droppableId,
          workingHours:
            works[draggableId]?.expectedTime ||
            state?.tasks[draggableId]?.expectedTime,
        });
      }

      const finishTaskIds = Array.from(finish.tasks);
      finishTaskIds.splice(destination.index, 0, draggableId);
      const newFinish = {
        ...finish,
        tasks: finishTaskIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newStart.id]: newStart,
          [newFinish.id]: newFinish,
        },
      };
      setState(newState);
    };

    //our purpose from dragStart function to validate tasks movement (working-hours , task status)
    const onDragStart = start => {
      //task
      const homeIndex = state.tasks[start.draggableId];
      //columnid
      const column = start.source.droppableId;

      setIsDropDisabled({
        column,
        isBlock: works[homeIndex.id]?.expectedTime == undefined,
      });

      if (homeIndex.workflowStatus != 'to_be_assigned') {
        setIsDropDisabled({
          column,
          isBlock: homeIndex.workflowStatus != 'in_queue',
        });
      }
    };

    const unassignedtasks = () => {
      if (isSuccessUnassigned && isSuccess && state?.columnOrder) {
        const column = state?.columns[state?.columnOrder[0]];

        const tasks = column?.tasks?.map(taskId => {
          return state?.tasks[taskId.id || taskId];
        });

        return (
          <UnassingedTasks
            key={column?.id}
            items={tasks}
            refetch={refetch}
            column={column}
            works={works}
            setWorks={setWorks}
            isDropDisabled={
              isDropDisabled.column != column?.id
                ? isDropDisabled.isBlock
                : false
            }
            isDragDisabled={Permission('Workflow') != 'Edit'}
            branch={branch}
          />
        );
      }
    };

    return (
      <Box>
        <Filter
          title={t('home.workflow.tasks')}
          buttonName={t('home.workflow.addNewTask')}
          setShowAdd={setShowAdd}
          setValue={setSearch}
          isButtonExist={Permission('Workflow') == 'Edit'}
        >
          <Select
            selectTitle={t('modals.selectBranch')}
            mx="4"
            w="150px"
            h="40px"
            label="Branch"
            select={branch}
            handleSelect={handleBranch}
            selections={user.user.branches?.map((item, index) => ({
              label: item.name,
              value: item.id,
            }))}
          />
        </Filter>

        {(isLoadingUnassigned ||
          isLoading ||
          Object.keys(state).length == 0) && <Loading />}

        {isSuccessUnassigned && isSuccess && data?.data.length == 0 && (
          <Nodata />
        )}

        {isSuccessUnassigned &&
          isSuccess &&
          data?.data.length > 0 &&
          Object.keys(state).length != 0 && (
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Text fontSize="32px" fontWeight="bold" color="#3C434A">
                {t('home.workflow.unassignedProjects')} ({unassignTasksCount})
              </Text>

              <>
                {/* unassinged tasks */}
                {unassignedtasks()}

                {/* assigned tasks */}
                <Box maxH="450px" overflowY="auto">
                  <Text
                    fontSize="32px"
                    fontWeight="bold"
                    mb="40px"
                    color="#3C434A"
                  >
                    {t('home.workflow.allEmployees')}
                  </Text>

                  {state?.columnOrder?.map((columnId, i) => {
                    const column = state?.columns[columnId];

                    const tasks = column?.tasks?.map(taskId => {
                      return state?.tasks[taskId.id || taskId];
                    });

                    if (i == 0) {
                      return;
                    } else {
                      return (
                        <Box>
                          <AssignedTasks
                            key={column.id}
                            item={column}
                            refetch={refetch}
                            taskss={tasks}
                            isDropDisabled={
                              isDropDisabled.column != column?.id
                                ? isDropDisabled.isBlock
                                : false
                            }
                            isDragDisabled={Permission('Workflow') != 'Edit'}
                            borderBottom={
                              i == state?.columnOrder?.length - 1
                                ? 'none'
                                : '1px solid #CCD5ED'
                            }
                            branch={branch}
                          />
                        </Box>
                      );
                    }
                  })}
                </Box>
              </>
            </DragDropContext>
          )}

        <SimpleModal
          title={t('home.workflow.addNewTask')}
          show={showAdd}
          setShow={setShowAdd}
        >
          <AddNewTask setShow={setShowAdd} />
        </SimpleModal>
      </Box>
    );
  }
};

export default Tasks;
