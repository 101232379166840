import { Box, Text } from '@chakra-ui/react';
import PhoneField from 'react-phone-input-2';

const PhoneInput = ({ label, ...props }) => {
  return (
    <Box>
      <Text
        textTransform="capitalize"
        color="text.600"
        fontSize="14px"
        mb="5px"
        fontWeight="semibold"
      >
        {label}
      </Text>

      <Box dir="ltr">
        <PhoneField
          buttonClass={'codeListBtn'}
          inputClass={'codeListInput'}
          dropdownClass={'codeListDrop'}
          country={'sa'}
          onlyCountries={['sa', 'bh', 'kw', 'ae', 'qa', 'om']}
          countryCodeEditable={false}
          buttonStyle={{
            backgroundColor: 'white',
            borderStartStartRadius: '10px',
            borderEndStartRadius: '10px',
            borderInlineEnd: 0,
          }}
          style={{
            borderRadius: '10px',
          }}
          inputStyle={{
            height: '60px',
            borderRadius: '10px',
            backgroundColor: 'white',
            border: '1px solid #BFCFD6',
            borderRadius: '10px',
            outline: 'none',
            fontSize: 15,
          }}
          {...props}
        />
      </Box>
    </Box>
  );
};

export default PhoneInput;
