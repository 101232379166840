import { Box, Flex, Text, Image, Grid, Table, Tr, Td } from '@chakra-ui/react';
import logo from 'assets/images/logo-small.png';
import moment from 'moment';
// const Data = ({ name, value }) => {
//   return (
//     <Flex>
//       <Text minW="35%">{name} </Text>
//       <Text>:{value}</Text>
//     </Flex>
//   );
// };

const TableData = ({ value1, value2, bg, color1 }) => {
  return (
    <Td
      px="0"
      py="1px"
      border="1px solid black"
      bg={bg ? '#e3e3e3' : 'transparent'}
    >
      <Text textAlign="center" fontSize="12px" lineHeight="1.2" color={color1}>
        {value1}
      </Text>
      {value2 && (
        <Text textAlign="center" fontSize="12px" lineHeight="1.2">
          {value2}
        </Text>
      )}
    </Td>
  );
};

const Data = ({ name, enName, value }) => {
  return (
    <Flex fontSize="12px">
      <Text w="30%">
        {name} - {enName}
      </Text>
      <Text mx="30px" me="35px">
        :
      </Text>
      <Text> {value}</Text>
    </Flex>
  );
};

const SellerInfo = ({
  data,
  pageNumber,
  totalPages,
  createdAt,
  invoiceNumber,
  ...props
}) => {
  return (
    <Box {...props}>
      <Flex
        justifyContent="space-between"
        border="1px solid black"
        p="10px"
        alignItems="center"
        mb="5px"
      >
        <Box w="75%">
          <Data name=" الرفم الضريبي " enName="Vat Number" value={data?.trn} />
          <Data
            name=" السجل التجاري"
            enName="CR Number"
            value={data?.crNumber}
          />
          <Data name="المدينة " enName="City" value={data?.city} />
          <Data name="الدولة" enName="Country" value={data?.country} />
          <Data name=" الشارع" enName="Street" value={data?.street} />
          <Data name="الحي" enName="District" value={data?.district} />
          <Data name=" المبنى" enName="Building" value={data?.buildingNumber} />
          <Data name="رقم إضافي" enName="Extra No" value={data?.extraNumber} />
          <Data name="الرمز البريدي" enName="Zip Code" value={data?.zipCode} />
        </Box>

        <Box>
          <Image src={logo} w="150px" />
        </Box>
      </Flex>
      <Table border="1px solid black" mb="10px">
        <Tr>
          <TableData value1="رقم الفاتورة" value2="Invoice No." bg />
          <TableData value1={invoiceNumber} color1="red" />
          <TableData value1=" التاريخ" value2="Date" bg />
          <TableData value1={moment(createdAt).lang('en').format('D/MM/yy')} />
          <TableData value1=" الصفحات" value2="Pages" bg />
          <TableData value1={totalPages + '-' + pageNumber} />
        </Tr>
      </Table>
    </Box>
  );
};

export default SellerInfo;
