import { useState, useRef } from 'react';
import { Box, Grid, Text, Button } from '@chakra-ui/react';
import { CustomDate } from 'components/authenticated/shared';
import ExcelTable from './excelTable';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

const ExportPopup = ({ data, shift, branch }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(startDate);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: '',
  });

  return (
    <Box>
      <Grid templateColumns="repeat(2,1fr)" gap={6}>
        <Box>
          <Text
            textTransform="capitalize"
            color="text.600"
            fontSize="14px"
            mb="5px"
            fontWeight="semibold"
          >
            {t('common.from')}
          </Text>
          <CustomDate
            selectsStart
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/dd/yyyy"
            setDate={setStartDate}
            maxDate={new Date()}
          />
        </Box>
        <Box>
          <Text
            textTransform="capitalize"
            color="text.600"
            fontSize="14px"
            mb="5px"
            fontWeight="semibold"
          >
            {t('common.to')}
          </Text>
          <CustomDate
            selectsEnd
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            maxDate={new Date()}
            dateFormat="MM/dd/yyyy"
            setDate={setEndDate}
          />
        </Box>
      </Grid>

      <Button
        h="60px"
        bg="bg.300"
        borderRadius="15px"
        w="100%"
        color="white"
        mt="50px"
        _hover={{}}
        _focus={{ outline: 'none' }}
        _active={{}}
        onClick={handlePrint}
      >
        {t('common.export')}
      </Button>

      <Box display="none">
        <ExcelTable
          shift={shift}
          branch={branch}
          data={data}
          datePicker={startDate}
          endDate={endDate}
          ref={componentRef}
        />
      </Box>
      {/* 
      <PrintComponents
        trigger={
          <CustomButton
            // as={ReactHTMLTableToExcel}
            // name="EXPORT"
            bg
            mt="50px"
            // id="test-table-xls-button"
            // className="download-table-xls-button"
            // table="table2-to-xls"
            // filename="AllUsersattendances"
            // sheet="tablexls"
            buttonText="EXPORT"
            name="Export"
          />
        }
      >
        <ExcelTable
          // id="table2-to-xls"
          shift={shift}
          branch={branch}
          data={data}
          datePicker={startDate}
          endDate={endDate}
          ref={componentRef}
          // display="none"
        />
      </PrintComponents> */}
    </Box>
  );
};

export default ExportPopup;
