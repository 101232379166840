import { useContext } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Text } from '@chakra-ui/react';
import { FaGlobe } from 'react-icons/fa';
import i18next from 'i18n';
import { useTranslation } from 'react-i18next';

const Lang = () => {
  const { setLanguage, language } = useContext(userContext);
  const { t } = useTranslation();

  const handleLang = () => {
    if (language == 'ar') {
      setLanguage('en');
      i18next.changeLanguage('en');
    } else {
      setLanguage('ar');
      i18next.changeLanguage('ar');
    }
  };

  return (
    <Box pos="absolute" top="30px" right="0" onClick={handleLang}>
      <Flex alignItems="center" cursor="pointer">
        <Text me="10px" fontSize="18px" color="text.700">
          {t('login.lang')}
        </Text>
        <Box as={FaGlobe} color="text.100" />
      </Flex>
    </Box>
  );
};

export default Lang;
