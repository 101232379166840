import { useContext, memo } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Grid, Flex, Text } from '@chakra-ui/react';
import { CustomInput } from 'components/shared';
import StaffMessage from './staff-message';
import TicketInfo from './ticket-info';
import TicketType from './ticket-type';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import UserMessage from './user-message';
import TextEditor from './text-editor';
import FileMessage from './file-message';
import { useTranslation } from 'react-i18next';

const Chat = memo(({ setShowChat, details, refetch, data }) => {
  const {
    id,
    status,
    type,
    description,
    comments,
    attachments,
    createdAt,
    searchId,
  } = details;

  const { t } = useTranslation();
  const { language } = useContext(userContext);

  return (
    <Grid
      bg="#EAF4FF"
      minH="inherit"
      w="100%"
      borderRadius="15px"
      p="21px"
      gridTemplateColumns="3fr 285px"
      gap="26px"
    >
      <Flex flexDir="column" justifyContent="space-between" dir="ltr">
        <Box
          bg="white"
          pos="relative"
          borderRadius="5px"
          display="flex"
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          py="10px"
        >
          <Text fontWeight="black" fontSize="20px">
            {t('ticket.supportTeam')}
          </Text>
          <Text fontSize="12px" color="#464C52">
            {t('ticket.WillReply')}
          </Text>
          <Box
            pos="absolute"
            left="10px"
            top="50%"
            zIndex="111"
            transform="translateY(-50%)"
            onClick={() => setShowChat(false)}
          >
            <Box
              as={language == 'en' ? HiOutlineArrowLeft : HiOutlineArrowRight}
              fontSize="25px"
              cursor="pointer"
            />
          </Box>
        </Box>

        <Box
          maxH="380px"
          minH="380px"
          overflowY="auto"
          pos="relative"
          my="15px"
        >
          {data?.length == 0 ? (
            <Box
              pos="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%,-50%)"
            >
              <Text textAlign="center" fontWeight="bold">
                {t('ticket.noMessage')}
              </Text>
              <Text textAlign="center" fontSize="14px">
                {t('ticket.writeProblem')}
              </Text>
            </Box>
          ) : (
            data?.map(e => {
              if (e.userType == 'staff') {
                return (
                  <StaffMessage
                    firstName={e.user.firstName}
                    lastName={e.user.lastName}
                    messageText={e.comment}
                    date={e.sentAt}
                  />
                );
              }
              if (e?.attachmentUrl) {
                return (
                  <FileMessage
                    date={e.sentAt}
                    file={e.attachmentUrl}
                    from={e.userType}
                  />
                );
              }
              return <UserMessage message={e.comment} date={e.sentAt} />;
            })
          )}
        </Box>

        <TextEditor id={id} refetch={refetch} />
      </Flex>

      <Box>
        <TicketInfo status={status} createdAt={createdAt} searchId={searchId} />
        <TicketType
          type={type}
          description={description}
          id={id}
          attachments={attachments}
          setShowChat={setShowChat}
          refetch={refetch}
        />
      </Box>
    </Grid>
  );
});

export default Chat;
