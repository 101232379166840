import { Text, Flex, Switch, useToast, Spinner } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { activateService, deactivateService } from 'apis/service';
import {
  deactivatePart as deactivatePartFun,
  activatePart as activatePartFun,
} from 'apis/part';
import { useTranslation } from 'react-i18next';
import { CustomToast } from 'components/authenticated/shared';

const ServiceItem = ({
  branchObject,
  refetchServices,
  itemId,
  items,
  isPart,
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const isBranchObjectContainBranch = branchObject?.branch;

  const customBranchData = {
    name: isBranchObjectContainBranch
      ? branchObject.branch.name.en
      : branchObject.label,
    isActive: isBranchObjectContainBranch ? branchObject.isActive : true,
    id: isBranchObjectContainBranch
      ? branchObject.branch.id
      : branchObject?.value,
  };

  const { mutate: deactivate, isLoading: loadingDeactivateService } =
    useMutation(deactivateService, {
      onSuccess: () => {
        refetchServices();

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deactivateService')}
                description={t('toasts.deactivateService')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deactivateService')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    });

  const { mutate: activate, isLoading: loadingActivateService } = useMutation(
    activateService,
    {
      onSuccess: () => {
        refetchServices();

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.activateService')}
                description={t('toasts.activateService')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.activateService')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const { mutate: deactivatePart, isLoading: isLoadingDeactivatePart } =
    useMutation(deactivatePartFun, {
      onSuccess: () => {
        refetchServices();

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deactivatePart')}
                description={t('toasts.deactivatePart')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.deactivatePart')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    });

  const { mutate: activatePart, isLoading: isLoadingactivatePart } =
    useMutation(activatePartFun, {
      onSuccess: () => {
        refetchServices();

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.activatePart')}
                description={t('toasts.activatePart')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.activatePart')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    });

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      mb="15px"
      background="whitesmoke"
      padding="20px"
      borderRadius="10px"
    >
      <Text
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        //maxW={nameMaxW}
      >
        {customBranchData?.name}
      </Text>

      <Flex alignItems="center">
        {loadingDeactivateService || loadingActivateService ? (
          <Spinner me="5px" w="20px" />
        ) : null}

        <Switch
          size="lg"
          disabled={loadingActivateService || loadingDeactivateService}
          onChange={e => {
            if (customBranchData?.isActive) {
              if (isPart) {
                deactivatePart({
                  products: items ? items : [itemId],
                  branchId: customBranchData.id,
                });
              } else {
                deactivate({
                  products: items ? items : [itemId],
                  branchId: customBranchData.id,
                });
              }
            } else {
              if (isPart) {
                activatePart({
                  productId: itemId,
                  branchId: customBranchData.id,
                });
              } else {
                activate({
                  productId: itemId,
                  branchId: customBranchData.id,
                });
              }
            }
          }}
          isChecked={customBranchData?.isActive}
          _focus={{}}
          _active={{}}
          me="5px"
        />
      </Flex>
    </Flex>
  );
};

export default ServiceItem;
