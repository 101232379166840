import { useContext, memo } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, TabPanel } from '@chakra-ui/react';
import {
  Tabs,
  PageHeader,
  Loading,
  Error,
} from 'components/authenticated/shared';
import {
  Profile as MyProfile,
  Workshop,
  Settings,
  Subscription,
  Tickets,
} from 'components/authenticated/profile';
import { useQuery } from 'react-query';
import { getTenantInfo } from 'apis/iam/Tenant';
import { updateEmployee } from 'apis/employee';
import AccessPage from 'helpers/AccessPage';
import { useTranslation } from 'react-i18next';

const Profile = () => {
  const { t } = useTranslation();

  const { data, isLoading, isSuccess, isError } = useQuery(
    'getTenantInfo',
    getTenantInfo
  );

  const { user } = useContext(userContext);

  let userData = JSON.parse(localStorage.getItem('user'));

  const adminTabs = [
    t('account.profile'),
    t('signup.workshop'),
    t('account.settings'),
    // t('account.subscription'),
    t('account.supportTickets'),
  ];

  let tabs = [t('account.profile'), t('account.settings')];
  if (!AccessPage(user.user.roles, 'Setting')) {
    tabs = ['profile'];
  }

  return (
    <>
      <Box mx="auto">
        <PageHeader
          text={userData.user.firstName + ' ' + userData.user.lastName}
          isArrow
        />
        <Tabs
          tabs={
            userData.user.roles[0]?.roleName !== 'SuperAdmin' ? tabs : adminTabs
          }
        >
          <TabPanel outline="none" minH="600px">
            <MyProfile userData={userData.user} />
          </TabPanel>
          {userData.user.roles[0]?.roleName == 'SuperAdmin' && (
            <TabPanel outline="none" minH="600px">
              {isLoading && <Loading />}
              {isSuccess && <Workshop data={data?.data} />}
              {isError && <Error />}
            </TabPanel>
          )}
          {AccessPage(user.user.roles, 'Setting') && (
            <TabPanel outline="none" minH="600px">
              <Settings />
            </TabPanel>
          )}

          {/* {userData.user.roles[0]?.roleName == 'SuperAdmin' && (
            <TabPanel outline="none" minH="600px">
              <Subscription />
            </TabPanel>
          )} */}

          {userData.user.roles[0]?.roleName == 'SuperAdmin' && (
            <TabPanel outline="none" minH="600px">
              <Tickets />
            </TabPanel>
          )}
        </Tabs>
      </Box>
    </>
  );
};

export default Profile;
