import { useParams } from 'react-router';
import { Box, Tr, Td, Text } from '@chakra-ui/react';
import { Table, Loading, Nodata, Error } from 'components/authenticated/shared';
import { useQuery } from 'react-query';
import { getClientTasks } from 'apis/client';
import ProjectRow from './project-row';
import { useTranslation } from 'react-i18next';

const Projects = ({ setClientProjectsCount }) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getClientTasks', id],
    getClientTasks,
    {
      onSuccess: res => {
        setClientProjectsCount(res?.data.workflows.length);
      },
    }
  );

  return (
    <Box py="8" mx="8" maxW="full">
      {isLoading && <Loading />}

      {isSuccess &&
        (data?.data.workflows.length > 0 ? (
          <Table
            headers={[
              t('modals.id'),
              t('home.employees.employee'),
              t('common.completedOn'),
              t('common.status'),
              t('common.rating'),
            ]}
            bg="bg.1200"
            theadHeight="71px"
            overflowContainer="initial"
          >
            {data?.data.workflows.map((item, index) => (
              <ProjectRow item={item} index={index} />
            ))}
          </Table>
        ) : (
          <Nodata />
        ))}

      {isError && <Error />}
    </Box>
  );
};

export default Projects;
