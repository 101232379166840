import request from 'axios-interceptors';

export const login = ({ email, password }) => {
  return request.post('/iam/auth/login', {
    emailAddress: email,
    password: password,
  });
};

export const logout = () => {
  return request.post('/iam/auth/logout');
};

export const resetPasswordRequest = email => {
  return request.put('/iam/auth/requestResetPassword', {
    emailAddress: email,
  });
};

export const changePasswordRequest = values => {
  return request.put('/iam/users/me/changePassword', {
    oldPassword: values.oldPassword,
    password: values.newPassword,
  });
};

export const checkTokenValidity = ({ slug, usage }) => {
  return request.post('iam/auth/isValidToken', {
    token: slug,
    useIn: usage,
  });
};

export const checkEmailTokenValidity = slug => {
  return request.post('iam/auth/confirmEmailAddress', {
    token: slug,
  });
};

export const resetPassword = ({ password, token }) => {
  return request.post('iam/auth/resetPassword', {
    password: password,
    token: token,
  });
};
