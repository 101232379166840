import axios from 'axios';

const request = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });

request.interceptors.request.use(
  config => {
    let userData = JSON.parse(localStorage.getItem('user'));
    // Modify the request config here if needed
    if (userData && userData.accessToken) {
      config.headers['Authorization'] = `Bearer ${userData.accessToken}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    // Handle request error here
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    // Modify the response data here if needed
    return response;
  },
  error => {
    if (
      error.response.data.statusCode == 403 &&
      error.response.data.errors.quickbooks.noQuickbooksToken ==
        'Quickbooks InValid'
    ) {
      document.location.href = '/quickbooks';
      // console.log('Interceptor error:', error.response.data);
      // console.log('Request:', error.config);
      // alert('An error occurred during the request.'); // Display a generic error message
    }

    // else {
    //   localStorage.setItem('quickbooksAuth', true);
    // }

    // Handle response error here
    return Promise.reject(error);
  }
);

export default request;
