import request from 'axios-interceptors';

export const createClient = (values, country, taxable) => {
  let formatedPhone =
    values.phone.indexOf('+') !== -1 ? values.phone : `+${values.phone}`;

  return request.post('crm/customers', {
    name: values.name,
    mobileNumber: formatedPhone,
    emailAddress: values.email,
    street: values.street,
    city: values.city,
    zipCode: values.zipCode.toString(),
    country: country.value,
    buildingNumber: values?.buildingNumber.toString(),
    district: values.districtName,
    additionalNumber: values?.additionalNumber.toString(),
    note: values.notes,
    taxNumber: taxable ? values.taxNumber.toString() : null,
  });
};

export const getAllClients = ({ queryKey }) => {
  // currentPage, searchKey, status
  return request.get(
    `crm/customers?page=${queryKey[1]}&limit=12&searchKey=${queryKey[2]}&status=${queryKey[3]}`
  );
};

export const getClientById = ({ queryKey }) => {
  return request.get(`crm/customers/findById/${queryKey[1]}`);
};

export const getClientTasks = ({ queryKey }) => {
  return request.get(`crm/customers/workflows/${queryKey[1]}`);
};

export const getClientReviews = ({ queryKey }) => {
  return request.get(`crm/customers/reviews/${queryKey[1]}`);
};

export const getAllPagesClients = () => {
  return request.get(`crm/customers/all`);
};

export const deleteClient = customerId => {
  return request.post('crm/customers/delete', {
    customers: customerId,
  });
};

export const activateClient = customerId => {
  return request.post(`crm/customers/activate/${customerId}`);
};

export const updateClient = (values, country, taxable, customerId) => {
  let formatedPhone =
    values.phone.indexOf('+') !== -1 ? values.phone : `+${values.phone}`;

  return request.put(`crm/customers/${customerId}`, {
    name: values.name,
    mobileNumber: formatedPhone,
    emailAddress: values.email,
    street: values.street,
    city: values.city,
    zipCode: values.zipCode?.toString(),
    buildingNumber: values?.buildingNumber?.toString(),
    district: values.districtName,
    additionalNumber: values?.additionalNumber?.toString(),
    country: country.value,
    note: values.notes,
    taxNumber: taxable ? values.taxNumber.toString() : null,
  });
};
