import { useState } from 'react';
import { Box, Flex, Text, Spinner } from '@chakra-ui/react';
import { Header, ContinueBack } from 'components/unauthenticated/shared';
import {
  UploadImage,
  CustomInput,
  PhoneInput,
  CustomButton,
  ErrorMsg,
  CustomUploadFile,
} from 'components/shared';
import { PersonalSchema } from 'schemas/signup/PersonalSchema';
import { FormBody } from 'components/unauthenticated/shared';
import { checkEmailValidity, checkPhoneValidity } from 'apis/iam/Signup';
import { Formik } from 'formik';
import defaultImage from 'assets/images/avatar-user.png';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import WithTranslateFormErrors from 'helpers/translate-forms-errors';

const SignupPersonalForm = ({ paginate, setPersonal, personal }) => {
  const { t } = useTranslation();

  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);

  // console.log(email);

  const { mutate: mutateEmail } = useMutation(checkEmailValidity, {
    onSuccess: () => {
      setEmail(null);
    },
    onError: err => {
      setEmail(err.response.data.errors);
      setLoading(false);
    },
  });

  const { mutate: mutatePhone } = useMutation(checkPhoneValidity, {
    onSuccess: () => {
      setLoading(false);
      paginate(1);
    },
    onError: err => {
      setPhone(err.response.data.errors);
      setLoading(false);
    },
  });

  return (
    <Box>
      <Header
        heading={t('signup.header')}
        text={t('signup.personalInformation')}
        isArrow
      />
      <Flex justifyContent="center" alignItems="center" mt="40px">
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            password: '',
            confirmPassword: '',
            image: '',
            ...personal,
          }}
          validationSchema={PersonalSchema}
          onSubmit={async values => {
            setPersonal({ ...personal, ...values });
            setLoading(true);
            // console.log('values' + values);
            mutateEmail(values.email, {
              onSuccess: () => {
                mutatePhone(values.phone);
              },
            });

            // console.log(phoneValue);
            // let one = mutateEmail(values.email);
            // let two = mutatePhone(values.phone);
            // await Promise.all([one, two]);
            // console.log('ali');

            // console.log(checks);
          }}
          // validateOnMount
        >
          {formik => {
            // console.log(formik.values);

            return (
              <FormBody
                boxShadow="0 8px 160px -100px #21B3E1"
                onSubmit={formik.handleSubmit}
              >
                <Box>
                  {/* <UploadImage
                    radius
                    setImageProfile={setPersonal}
                    profileData={personal}
                    defaultImage={defaultImage}
                    formikChange={e => formik.setFieldValue('image', e)}
                    mb="0"
                  /> */}

                  <CustomUploadFile
                    setImageProfile={setPersonal}
                    profileData={personal}
                    defaultImage={defaultImage}
                    formikChange={e => formik.setFieldValue('image', e)}
                    mb="0"
                    radius
                    setUploadImageLoading={setUploadImageLoading}
                  />
                </Box>

                <Box mb="20px">
                  <CustomInput
                    type="text"
                    label={t('signup.firstName')}
                    placeholder={t('signup.enterFirstName')}
                    value={formik.values.firstName}
                    onChange={formik.handleChange('firstName')}
                    onBlur={formik.handleBlur('firstName')}
                  />
                  {formik.errors.firstName && formik.touched.firstName && (
                    <ErrorMsg text={t(formik.errors.firstName)} />
                  )}
                </Box>

                <Box mb="20px">
                  <CustomInput
                    type="text"
                    label={t('signup.lastName')}
                    placeholder={t('signup.enterLastName')}
                    value={formik.values.lastName}
                    onChange={formik.handleChange('lastName')}
                    onBlur={formik.handleBlur('lastName')}
                  />
                  {formik.errors.lastName && formik.touched.lastName && (
                    <ErrorMsg text={t(formik.errors.lastName)} />
                  )}
                </Box>

                <Box mb="20px">
                  <CustomInput
                    type="email"
                    label={t('signup.email')}
                    placeholder={t('signup.enterEmail')}
                    value={formik.values.email}
                    onChange={e => {
                      formik.setFieldValue('email', e.target.value);
                      setEmail(null);
                    }}
                    onBlur={formik.handleBlur('email')}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <ErrorMsg text={t(formik.errors.email)} />
                  )}
                  {email?.errName && <ErrorMsg text="email is used before" />}
                </Box>

                <Box mb="20px">
                  <PhoneInput
                    label={t('signup.phone')}
                    value={formik.values.phone}
                    onChange={e => {
                      formik.setFieldValue('phone', e);
                      setPhone(null);
                    }}
                    onBlur={formik.handleBlur('phone')}
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <ErrorMsg text={t(formik.errors.phone)} />
                  )}

                  {phone?.phoneNumber.isPhoneNumber && (
                    <ErrorMsg text="phone number is not invalid" />
                  )}

                  {phone?.phoneNumber.alreadyExist && (
                    <ErrorMsg text="phone number is alreadyExist" />
                  )}
                </Box>

                <Box mb="20px">
                  <CustomInput
                    type="password"
                    label={t('signup.createPassword')}
                    placeholder={t('signup.createPassword')}
                    value={formik.values.password}
                    onChange={formik.handleChange('password')}
                    onBlur={formik.handleBlur('password')}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <ErrorMsg text={t(formik.errors.password)} />
                  )}
                </Box>

                <Box>
                  <CustomInput
                    type="password"
                    label={t('signup.confirmPassword')}
                    placeholder={t('signup.reEnterPassword')}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange('confirmPassword')}
                    onBlur={formik.handleBlur('confirmPassword')}
                  />
                  {formik.errors.confirmPassword &&
                    formik.touched.confirmPassword && (
                      <ErrorMsg text={t(formik.errors.confirmPassword)} />
                    )}
                </Box>

                <Flex justifyContent="space-between" mt="50px">
                  {/* <CustomButton
                    name="Back"
                    w="40%"
                    onClick={() => {
                      paginate(-1);
                    }}
                  /> */}
                  <CustomButton
                    // onClick={() => {
                    //   paginate(1);
                    // }}
                    type="submit"
                    // disabled={!formik.isValid}
                    name={t('signup.continue')}
                    loading={loading ? <Spinner ms="10px" /> : null}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg
                    w="100%"
                    disabled={uploadImageLoading}
                  />
                </Flex>
              </FormBody>
            );
          }}
        </Formik>
      </Flex>
    </Box>
  );
};

export default SignupPersonalForm;
