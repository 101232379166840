import React, { useState } from 'react';
import {
  Accordion,
  AccordionPanel,
  AccordionItem,
  Text,
  Box,
} from '@chakra-ui/react';
import { Loading, Nodata } from 'components/authenticated/shared';
import SubCat from './sub-category';
import { useTranslation } from 'react-i18next';

const SubCategoriesModal = React.memo(
  ({ childrens, services, refetchCategories, branchId }) => {
    const [showLoad, setShowLoad] = useState(false);
    const { t } = useTranslation();

    // function checkForActiveService(child) {
    //   if (child.services && child.services.length > 0) {
    //     for (let product of child.services) {
    //       if (product.branchProduct && product.branchProduct.length > 0) {
    //         return true; // Found non-empty branchProduct in child object
    //       }
    //     }
    //   }

    //   if (child.children && child.children.length > 0) {
    //     for (let nestedChild of child.children) {
    //       if (checkForActiveService(nestedChild)) {
    //         return true; // Recursively check nested children
    //       }
    //     }
    //   }

    //   return false; // No non-empty branchProduct found
    // }

    const checkIsEditedBefore = branchProduct => {
      return branchProduct?.find(branch => {
        return branch.price !== null;
      });
    };

    function isParentActive(data) {
      // Check if any of the services in the current level are active
      if (
        data.services &&
        data.services.some(
          service =>
            service.branchProduct &&
            service.branchProduct.some(branch => branch.isActive)
        )
      ) {
        return true;
      }

      // Check if any of the children have an active service
      if (data.children && data.children.some(child => isParentActive(child))) {
        return true;
      }

      return false;
    }

    function hasServices(obj) {
      if (obj.services && obj.services.length > 0) {
        return true; // Services found in current object
      }

      if (obj.children && obj.children.length > 0) {
        // Check children recursively
        for (let i = 0; i < obj.children.length; i++) {
          if (hasServices(obj.children[i])) {
            return true; // Services found in any child
          }
        }
      }

      return false; // No services found in current object or its children
    }

    const renderChildren = children => {
      if (children && children.length > 0) {
        return children.map(child => {
          const isActive = isParentActive(child);
          const isThereServices = hasServices(child);

          return (
            <>
              <Item
                item={
                  <SubCat
                    name={child.name}
                    nameAr={child.nameAr}
                    id={child.id}
                    iconPath={child?.iconPath}
                    isCustom={child?.custom}
                    noChildrens={
                      child?.children || child?.services.length > 0
                        ? false
                        : true
                    }
                    noServices={child?.services.length == 0}
                    isActive={isActive}
                    isThereServices={isThereServices}
                    setShowLoad={setShowLoad}
                    refetchCategories={refetchCategories}
                    nameMaxW={['50px', '50px', '280px', '300px']}
                    branchId={branchId}
                  />
                }
                key={child.name}
              >
                {renderChildren(child.children)}
                {child?.services?.map(e => {
                  return (
                    <SubCat
                      name={e.name}
                      nameAr={e.nameAr}
                      id={e.id}
                      noChildrens={true}
                      isService
                      isThereServices={true}
                      isCustom={e?.isDefault}
                      isThereBranches={e?.branchProduct}
                      isServiceEditedBefore={checkIsEditedBefore(
                        e?.branchProduct
                      )}
                      isActive={e?.branchProduct.some(
                        branch => branch.isActive
                      )}
                      totalBranches={e?.branchProduct}
                      setShowLoad={setShowLoad}
                      refetchCategories={refetchCategories}
                      nameMaxW={['50px', '50px', '280px', '300px']}
                      branchId={branchId}
                    />
                  );
                })}
              </Item>
            </>
          );
        });
      }
      return null;
    };

    const Item = React.memo(({ item, children }) => {
      return (
        <Accordion allowToggle>
          <AccordionItem border="none" padding-inline="12px">
            {item}
            <AccordionPanel paddingInlineEnd="0" paddingBlock="0">
              {children}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      );
    });

    const renderServices = () => {
      if (services) {
        return services?.map(e => {
          return (
            <SubCat
              name={e.name}
              nameAr={e.nameAr}
              id={e.id}
              noChildrens={true}
              isService
              isCustom={e?.isDefault}
              isServiceEditedBefore={checkIsEditedBefore(e?.branchProduct)}
              isActive={e?.branchProduct.some(branch => branch.isActive)}
              setShowLoad={setShowLoad}
              refetchCategories={refetchCategories}
              isThereServices={true}
              nameMaxW={['50px', '50px', '280px', '300px']}
              totalBranches={e?.branchProduct}
              branchId={branchId}
            />
          );
        });
      }
      return null;
    };

    return (
      <Box pos="relative">
        {childrens || services.length > 0 ? (
          <>
            {renderChildren(childrens)}
            {renderServices()}
          </>
        ) : (
          <Text textAlign="center">{t('account.noData')}</Text>
        )}
      </Box>
    );
  }
);

export default SubCategoriesModal;
