import { useContext } from 'react';
import { Box, Flex, Image, Text, Avatar, Grid, Switch } from '@chakra-ui/react';
import { userContext } from 'contexts/userContext';
import { AiOutlineMail } from 'react-icons/ai';
import { FiPhone } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { CustomSwitch } from 'components/authenticated/shared';
import { CustomAvatar } from 'components/shared';
import Permission from 'helpers/permission';
import mailIcon from 'assets/images/icons/mail.svg';
import phoneIcon from 'assets/images/icons/phone.svg';
import { useTranslation } from 'react-i18next';
import { BsBoxArrowInUp } from 'react-icons/bs';

const IconText = ({ icon, text, ...props }) => {
  return (
    <Flex alignItems="center" {...props}>
      <Image src={icon} me="8px" />
      <Text
        color="text.400"
        maxW="210px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {text}
      </Text>
    </Flex>
  );
};

const Card = ({
  item,
  setShowEdit,
  setShowDelete,
  setShowActive,
  ...props
}) => {
  const { t } = useTranslation();
  const { user } = useContext(userContext);

  return (
    <Box pos="relative">
      <Link to={user.user.id != item.id ? item.id : ''}>
        <Box
          // bg={
          //   item.roles[0]?.roleName === 'SuperAdmin'
          //     ? 'bg.800'
          //     : !item.deletedAt
          //     ? 'white'
          //     : 'gray.200'
          // }
          bg="white"
          border={user.user.id == item.id ? '4px solid #21C6E1' : 'none'}
          cursor={user.user.id == item.id ? 'default' : 'pointer'}
          p="20px"
          borderRadius="10px"
          _hover={{ bg: 'bg.100' }}
          {...props}
        >
          <Flex alignItems="center" justifyContent="space-between" mb="15px">
            {user.user.id == item.id ? (
              <Box
                bg="#EDFBFF"
                px="10px"
                py="5px"
                borderRadius="8px"
                color="#1BA3EF"
                fontSize="12px"
                fontWeight="semibold"
              >
                {t('home.employees.superAdmin')}
              </Box>
            ) : (
              <Grid
                bg="#21C6E1"
                borderRadius="20px"
                // py="4px"
                // px="10px"
                w="50px"
                h="28px"
                color="white"
                fontSize="13px"
                // textAlign="center"
                fontWeight="bold"
                placeItems="center"
              >
                {item.searchId}
              </Grid>
            )}
          </Flex>

          <Box my="35px">
            <CustomAvatar
              image={item.profileImageUrl}
              letter={item.firstName[0]}
            />
            {/* {item.profileImageUrl ? (
              <Image
                src={item.profileImageUrl}
                borderRadius="50%"
                w="100px"
                h="100px"
                m="auto"
                mb="16px"
              />
            ) : (
              <Grid
                m="auto"
                mb="16px"
                w="100px"
                h="100px"
                borderRadius="50%"
                placeItems="center"
                bg="#EDFBFF"
              >
                <Text
                  color="#21C6E1"
                  fontWeight="bold"
                  fontSize="48px"
                  textTransform="uppercase"
                >
                  {item.firstName[0]}
                </Text>
              </Grid>
            )} */}

            {/* <Avatar src={item.profileImageUrl} display="grid" /> */}

            <Text
              color="text.400"
              fontWeight="bold"
              textAlign="center"
              fontSize="20px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              maxW="240px"
              mx="auto"
            >
              {item.firstName} {item.lastName}
            </Text>
          </Box>

          <IconText icon={mailIcon} text={item.emailAddress} mb="16px" />
          <IconText icon={phoneIcon} text={item.phoneNumber} />
        </Box>
      </Link>

      {Permission('Employees') == 'Edit' &&
        item.roles[0]?.roleName !== 'SuperAdmin' && (
          <CustomSwitch
            item={item}
            setShowDelete={setShowDelete}
            setShowActive={setShowActive}
            setShowEdit={setShowEdit}
          />
          // <CardPopover
          //   item={item}
          //   setShowDelete={setShowDelete}
          //   setShowActive={setShowActive}
          //   setShowEdit={setShowEdit}
          // />
        )}
    </Box>
  );
};

export default Card;
