import { Box, Flex, Text, Table, Tr, Td, Image } from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import qr from 'assets/images/qr.png';

const TableData = ({ value1, value2, bg, color1, ...props }) => {
  return (
    <Td
      px="0"
      py="1px"
      border="1px solid black"
      bg={bg ? '#e3e3e3' : 'transparent'}
      {...props}
    >
      <Text
        textAlign="center"
        fontSize="12px"
        lineHeight="1.2"
        fontWeight={value2 ? 'bold' : 'initial'}
        color={color1}
      >
        {value1}
      </Text>
      {value2 && (
        <Text
          textAlign="center"
          fontSize="12px"
          fontWeight="bold"
          lineHeight="1.2"
        >
          {value2}
        </Text>
      )}
    </Td>
  );
};

const Data = ({ name, enName, value, ...props }) => {
  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      border="1px solid black"
      fontSize="14px"
      borderTop="none"
      overflow="hidden"
      boxSizing="border-box"
      {...props}
    >
      <Box w="45%" bg="#e3e3e3" borderLeft="1px solid black">
        <Text
          textAlign="center"
          lineHeight="1.2"
          fontSize="12px"
          fontWeight="semibold"
        >
          {name}
        </Text>
        <Text
          textAlign="center"
          lineHeight="1.2"
          fontSize="12px"
          fontWeight="semibold"
        >
          {enName}
        </Text>
      </Box>

      <Text w="55%" textAlign="center" lineHeight="1.2" fontSize="12px">
        {value}
      </Text>
    </Flex>
  );
};

const Total = ({
  vat,
  vatAmount,
  price,
  totalPrice,
  totalPriceInArabic,
  img,
}) => {
  return (
    <>
      <Flex justifyContent="space-between">
        <Box w="75%" h="100%">
          {/* <Data
            name="إجمالي المبلغ الخاضع للضريبة"
            enName="Total Taxable Amount"
            value="200"
            borderTop="1px solid black"
          />
          <Data name="مجموع الخصومات" enName="Total Discounts" value="200" />
          <Data
            name=" ضريبة القيمة المضافة"
            enName="Total VAT Amount"
            value="200"
          />
          <Data
            name=" الإجمالى شامل الضريبة"
            enName="Total Incl. VAT"
            value="200"
          />
          <Data name="المبلغ كتابة" enName="Amount Written" value="200" /> */}
          <Table>
            <Tr>
              <TableData
                value1="إجمالي المبلغ الخاضع للضريبة"
                value2="Total Taxable Amount"
                w="40%"
                bg
              />
              <TableData value1={price} w="60%" />
            </Tr>
            <Tr>
              <TableData
                value1="مجموع الخصومات"
                value2="Total Discounts"
                w="40%"
                bg
              />
              <TableData value1="0" w="60%" />
            </Tr>
            <Tr>
              <TableData
                value1="ضريبة القيمة المضافة"
                value2="Total VAT Amount"
                w="40%"
                bg
              />
              <TableData value1={vatAmount} w="60%" />
            </Tr>

            <Tr>
              <TableData
                value1="الإجمالي شامل الضريبة"
                value2="Total Incl. VAT"
                w="40%"
                bg
              />
              <TableData value1={totalPrice} w="60%" />
            </Tr>

            <Tr>
              <TableData
                value1="المبلغ كتابة"
                value2="Amount Written"
                w="40%"
                bg
              />
              <TableData value1={totalPriceInArabic} w="60%" />
            </Tr>
          </Table>
        </Box>

        <Flex w="22%" h="100%">
          <Image h="100%" src={img} w="100%" />
        </Flex>
      </Flex>
    </>
  );
};

export default Total;
