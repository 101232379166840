import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { BsPeople } from 'react-icons/bs';
import { CardPopover } from 'components/authenticated/shared';
import { useTranslation } from 'react-i18next';

const IconText = ({ icon, text, ...props }) => {
  return (
    <Flex alignItems="center" {...props}>
      <Box as={icon} me="5px" />
      <Text color="text.1400">{text}</Text>
    </Flex>
  );
};

const Card = ({ isView, item, setShowEdit, setShowDelete }) => {
  const { t } = useTranslation();

  return (
    <Box bg="bg.200" p="15px" borderRadius="10px">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        mb="15px"
        pos="relative"
      >
        <Heading as="h4" fontSize="18px" fontWeight="bold">
          {item.name}
        </Heading>
        {!isView ? (
          <CardPopover
            item={item}
            setShowEdit={setShowEdit}
            setShowDelete={setShowDelete}
            top="0"
            right="0"
            deleteText="delete"
          />
        ) : null}
      </Flex>

      <IconText
        icon={BsPeople}
        text={`${item.numberOfEmployees} ${t('home.employees.title')}`}
      />
    </Box>
  );
};

export default Card;
