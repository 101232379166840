import { Flex, Image, Heading, Text } from '@chakra-ui/react';
import nodata from 'assets/images/no-data.png';
import { useTranslation } from 'react-i18next';

const Nodata = () => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      mt="60px"
    >
      <Image src={nodata} w="210px" />
      <Heading as="h3" fontWeight="bold" mb="10px" mt="15px" opacity=".6">
        {t('account.noData')}
      </Heading>
    </Flex>
  );
};

export default Nodata;
