import { useState } from 'react';
import { Box, Text, Flex, Grid, useToast, Image } from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { AiOutlineCar, AiOutlinePlus } from 'react-icons/ai';
import { HiOutlinePhone } from 'react-icons/hi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { SimpleModal } from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import Addparts from './modal-parts';
import AddServices from './modal-services';
import Select from './select';
import { updateWorkflowStatus } from 'apis/invoice/Workflow';
import { useQueryClient, useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import blueCar from 'assets/images/icons/blue-car.svg';
import bluePhone from 'assets/images/icons/blue-phone.svg';
import Permission from 'helpers/permission';
import ConfirmationModal from './confirmation-modal';

const Card = ({ data, branch, refetch }) => {
  const { t } = useTranslation();
  const isEditable = Permission('Workflow') == 'Edit';

  const toast = useToast();
  const [showParts, setShowParts] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showDescribtion, setShowDescribtion] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  let servicesNumber;
  let partsNumber;

  if (data?.invoice.invoiceProducts.length == 0) {
    servicesNumber = 0;
    partsNumber = 0;
  } else {
    servicesNumber = data?.invoice.invoiceProducts.filter(
      elm => elm.type === 'service'
    ).length;

    partsNumber = data?.invoice.invoiceProducts.filter(
      elm => elm.type === 'part'
    ).length;
  }

  const [statusSelected, setStatusSelected] = useState({
    label:
      data?.workflowStatus.replaceAll('_', ' ') == 'to be assigned'
        ? t('home.workflow.in_queue')
        : data?.workflowStatus == 'in_queue'
        ? t('home.workflow.in_queue')
        : data?.workflowStatus == 'in_progress'
        ? t('home.workflow.in_progress')
        : t('home.workflow.completed'),
    value:
      data?.workflowStatus == 'to_be_assigned'
        ? 'in_queue'
        : data?.workflowStatus,
  });
  const queryClient = useQueryClient();

  const status = [
    { label: t('home.workflow.in_progress'), value: 'in_progress' },
    { label: t('home.workflow.in_queue'), value: 'in_queue' },
    { label: t('home.workflow.completed'), value: 'completed' },
  ];

  const { mutate, isLoading } = useMutation(updateWorkflowStatus, {
    onSuccess: async () => {
      await queryClient.invalidateQueries('getAllWorkflows');
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.changeStatus')}
              description={t('toasts.changeStatus')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
      setShowConfirmation(false);
    },
    onError: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.changeStatus')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
      setShowConfirmation(false);
    },
  });

  const handleStatus = value => {
    if (isEditable) {
      if (value?.value == 'completed') {
        if (servicesNumber > 0 || partsNumber > 0) {
          setShowConfirmation(true);
        } else {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.changeStatus')}
                  description={'You should add one part or service at least'}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        }
      } else {
        setStatusSelected(value);
        mutate({ workflowId: data?.id, workflowStatus: value.value });
      }
    }
  };

  const formatedTime = time => {
    var totalMinutes = time || 0;

    var hourss = Math.floor(totalMinutes / 60);
    var minutess = totalMinutes % 60;

    return hourss === 0
      ? `${minutess}min`
      : `${hourss}h ${minutess == 0 ? '' : `${minutess}min`}`;
  };

  const handleComplete = () => {
    setStatusSelected({
      label: t('home.workflow.completed'),
      value: 'completed',
    });
    mutate({ workflowId: data?.id, workflowStatus: 'completed' });
  };

  const borderColorCondition = () => {
    if (data?.timer / 60 <= data?.expectedTime) {
      if (data?.isFromAerbag) {
        return '3px solid rgb(27, 163, 239)';
      } else {
        return 'none';
      }
    }

    return '2px solid #F43838';
  };
  
  return (
    <Box
      bg="white"
      borderRadius="10px"
      padding="15px"
      w="400px"
      border={borderColorCondition}
    >
      <Flex justifyContent="space-between" alignItems="flex-start" mb="12px">
        {data?.isFromAerbag ? (
          <Text
            color="GrayText.2100"
            fontWeight="bold"
            fontSize="24px"
            overflow="hidden"
            maxW="230px"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            AerBag App
          </Text>
        ) : (
          <Text
            color="GrayText.2100"
            fontWeight="bold"
            fontSize="24px"
            overflow="hidden"
            maxW="230px"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {data?.customer.name}
          </Text>
        )}

        {/* <Box
          bg={data?.status === 'To be assigned' ? 'bg.2800' : 'bg.500'}
          color="white"
          borderRadius="5px"
          py="2px"
          px="5px"
          fontSize="14px"
        >
          <Text>{data?.workflowStatus.replaceAll('_', ' ')}</Text>
        </Box> */}
        <Select
          minW="125px"
          h="41px"
          select={statusSelected}
          handleSelect={handleStatus}
          selections={status}
          isDisabled={
            data?.workflowStatus == 'completed' ||
            Permission('Workflow') != 'Edit'
          }
        />
      </Flex>

      <Box color="text.600" mb="11px">
        <Flex justifyContent="flex-start" alignItems="center" mb="8px">
          <Image src={blueCar} />
          <Flex justifyContent="flex-start" alignItems="center" ms="10px">
            <Text>{data?.carDetails?.carManufactur}</Text>
            <Box h="15px" w="1.5px" bg="text.1100" mx="5px"></Box>
            <Text>{data?.carDetails?.carModel}</Text>
            <Box h="15px" w="1.5px" bg="text.1100" mx="5px"></Box>
            <Text>{data?.carDetails?.carYear}</Text>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-start" alignItems="center">
          <Image src={bluePhone} />
          <Text ms="10px">{data?.customer.mobileNumber}</Text>
        </Flex>
      </Box>

      <Grid gridTemplateColumns="1fr 1fr 2fr" fontSize="14px" mb="11px">
        <Box
          justifyContent="space-between"
          alignItems="center"
          fontSize="12px"
          color="text.1100"
        >
          <Text me="20px">{t('home.workflow.services')}</Text>
          <Grid
            w="27px"
            h="27px"
            borderRadius="50%"
            bg="rgba(33,198,225,15%)"
            color="#21C6E1"
            ms="8px"
            placeItems="center"
            fontSize="14px"
            cursor={data?.workflowStatus == 'completed' ? 'default' : 'pointer'}
            onClick={() => {
              if (data?.workflowStatus != 'completed') {
                setShowServices(true);
              }
            }}
          >
            {servicesNumber ? servicesNumber : <Box as={AiOutlinePlus} />}
          </Grid>
        </Box>

        {/* <Text>{servicesNumber}</Text> */}

        <Box
          justifyContent="space-between"
          alignItems="center"
          fontSize="12px"
          color="text.1100"
        >
          <Text me="20px">{t('home.workflow.parts')}</Text>
          <Grid
            w="27px"
            h="27px"
            borderRadius="50%"
            bg="rgba(33,198,225,15%)"
            color="#21C6E1"
            placeItems="center"
            ms="2px"
            fontSize="14px"
            cursor={data?.workflowStatus == 'completed' ? 'default' : 'pointer'}
            onClick={() => {
              if (data?.workflowStatus != 'completed') {
                setShowParts(true);
              }
            }}
          >
            {partsNumber ? partsNumber : <Box as={AiOutlinePlus} />}
          </Grid>
        </Box>

        <Box color="text.600" pos="relative">
          <Text fontSize="12px" color="text.1100">
            {t('home.workflow.description')} :
          </Text>
          <Box
            h="46px"
            overflow="hidden"
            border="1px solid #DEF6FB"
            borderRadius="5px"
            px="3px"
            onMouseOver={() => setShowDescribtion(true)}
            onMouseOut={() => setShowDescribtion(false)}
          >
            {data?.isFromAerbag ? (
              <>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Client Name:
                  </Text>{' '}
                  {data.appClient ? data?.appClient?.name : data?.customer.name}
                </Text>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Mobile:
                  </Text>{' '}
                  {data.appClient
                    ? data?.appClient?.mobileNumber
                    : data?.customer.mobileNumber}
                </Text>
              </>
            ) : (
              <Text>{data?.description}</Text>
            )}
          </Box>
          <Box
            pos="absolute"
            border="1px solid #DEF6FB"
            borderRadius="5px"
            bg="white"
            px="3px"
            minW="185px"
            minH="46px"
            top="18px"
            opacity={showDescribtion ? '1' : '0'}
            pointerEvents="none"
            transition=".3s"
          >
            {data?.isFromAerbag ? (
              <>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Client Name:
                  </Text>{' '}
                  {data.appClient ? data?.appClient?.name : data?.customer.name}
                </Text>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Mobile:
                  </Text>{' '}
                  {data.appClient
                    ? data?.appClient?.mobileNumber
                    : data?.customer.mobileNumber}
                </Text>
              </>
            ) : (
              <Text>{data?.description}</Text>
            )}
          </Box>
        </Box>
      </Grid>
      <Flex justifyContent="space-between" alignItems="center" w="full">
        <Box>
          <Text fontSize="12px" color="text.1100">
            {t('home.workflow.duration')}
          </Text>
          <Text>{formatedTime(data?.expectedTime)}</Text>
        </Box>

        <Box
          bg="bg.3000"
          borderRadius="5px"
          px="3px"
          py="1"
          mt="auto"
          minW="121px"
          ms="20px"
        >
          <Text
            textAlign="center"
            textTransform="uppercase"
            fontSize="14px"
            color="text.1000"
            fontWeight="semibold"
          >
            {data?.searchId}
          </Text>
        </Box>

        <Box>
          <Text fontSize="12px" color="text.1100">
            {t('home.workflow.timer')}
          </Text>
          <Text>{formatedTime(Math.floor(data?.timer / 60))}</Text>
        </Box>
      </Flex>

      <SimpleModal
        title={t('home.storage.addPart')}
        show={showParts}
        setShow={setShowParts}
      >
        <Addparts
          invoiceProducts={data?.invoice?.invoiceProducts}
          id={data?.invoice?.id}
          setShow={setShowParts}
          branch={branch}
          refetch={refetch}
        />
      </SimpleModal>

      <SimpleModal
        title={t('home.services.addServices')}
        show={showServices}
        setShow={setShowServices}
      >
        <AddServices
          invoiceProducts={data?.invoice?.invoiceProducts}
          id={data?.invoice?.id}
          setShow={setShowServices}
          branch={branch}
          refetch={refetch}
        />
      </SimpleModal>

      <SimpleModal
        title={t('home.workflow.completeTask')}
        show={showConfirmation}
        setShow={setShowConfirmation}
      >
        <ConfirmationModal
          handleComplete={handleComplete}
          isLoading={isLoading}
        />
      </SimpleModal>
    </Box>
  );
};

export default Card;
