import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Text, Box, Tr, Td } from '@chakra-ui/react';
import {
  Table,
  Loading,
  Nodata,
  Error,
  StatusBox,
  Pagination,
} from 'components/authenticated/shared';
import { useQuery } from 'react-query';
import { getBranchTasks } from 'apis/branch';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getAllProjects } from 'apis/projects';

const Projects = ({ setProjectsCount }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [pageNumber, setPageNumber] = useState(1);

  // const { data, isLoading, isSuccess, isError } = useQuery(
  //   ['getBranchTasks', id],
  //   getBranchTasks,
  //   {
  //     onSuccess: res => {
  //       setProjectsCount(res?.data?.projects?.length);
  //     },
  //   }
  // );

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getAllProjects', pageNumber, null, id, true],
    getAllProjects
  );

  const handlePageClick = async data => {
    setPageNumber(data.selected + 1);
  };

  let numberOfPages = data?.data.meta.totalPages;
  let itemsPerPage = data?.data.meta.itemsPerPage;
  let currentPage = data?.data.meta.currentPage;
  let itemCount = data?.data.meta.itemCount;
  let totalItems = data?.data.meta.totalItems;

  useEffect(() => {
    setProjectsCount(totalItems || 0);
  }, [totalItems]);

  return (
    <Box p="40px">
      {isLoading && <Loading />}

      {isSuccess &&
        (data?.data?.items?.length > 0 ? (
          <>
            <Box minH="70vh">
              <Table
                headers={['ID', 'Subject', 'Completed at', 'Status']}
                bg="bg.1200"
                theadHeight="71px"
              >
                {data?.data.items.map((item, index) => (
                  <Tr key={index} borderTop="2px solid white">
                    <Td borderBottom="0" color="text.200">
                      {item?.searchId}
                    </Td>
                    <Td borderBottom="0">{item?.title}</Td>
                    <Td borderBottom="0">
                      {item.workflowStatus === 'completed' &&
                        moment(item.endAt)
                          .locale(t('common.lang'))
                          .format('YYYY-MM-DD')}
                    </Td>

                    <Td borderBottom="0" my="5px">
                      <StatusBox
                        status={item?.workflowStatus}
                        text={
                          item?.workflowStatus == 'in_queue'
                            ? t('status.inqueue')
                            : item?.workflowStatus == 'in_progress'
                            ? t('status.ongoing')
                            : item?.workflowStatus == 'completed'
                            ? t('status.completed')
                            : t('status.toBeAssigned')
                        }
                      />
                    </Td>
                  </Tr>
                ))}
              </Table>
            </Box>
            <Box mt="15px">
              <Pagination
                handlePageClick={handlePageClick}
                numberOfPages={numberOfPages}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                itemCount={itemCount}
                totalItems={totalItems}
                forcePage={currentPage - 1}
              />
            </Box>
          </>
        ) : (
          <Nodata />
        ))}

      {isError && <Error />}
    </Box>
  );
};

export default Projects;
