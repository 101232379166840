import * as Yup from 'yup';
// import { checkEmailValidity } from '../../../apis/iam/Signup';
// import validator from 'validator';

export const AddTask = Yup.object().shape({
  // client: Yup.object().shape({
  //   label: Yup.string().required('Required'),
  //   value: Yup.string().required('Required'),
  // }),
  // macker: Yup.object().shape({
  //   label: Yup.string().required('Required'),
  //   value: Yup.string().required('Required'),
  // }),
  // model: Yup.object().shape({
  //   label: Yup.string().required('Required'),
  //   value: Yup.string().required('Required'),
  // }),
  // year: Yup.object().shape({
  //   label: Yup.string().required('Required'),
  //   value: Yup.string().required('Required'),
  // }),
  // branch: Yup.object().shape({
  //   label: Yup.string().required('Required'),
  //   value: Yup.string().required('Required'),
  // }),
  // date: Yup.string().required('Required'),
  // time: Yup.string().required('Required'),

  client: Yup.string().required('signup.fieldRequired'),
  macker: Yup.string().required('signup.fieldRequired'),
  model: Yup.string().required('signup.fieldRequired'),
  year: Yup.string().required('signup.fieldRequired'),
  branch: Yup.string().required('signup.fieldRequired'),
  // title: Yup.string().required('signup.fieldRequired'),
  subject: Yup.string()
    .required('signup.fieldRequired')
    .max(255, 'signup.maxLength'),
});
