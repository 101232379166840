import { useState, useRef, useEffect, useContext } from 'react';
import {
  Box,
  Checkbox,
  Flex,
  Grid,
  Switch,
  Text,
  Image,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { userContext } from 'contexts/userContext';
import { SimpleModal } from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { AiFillSetting } from 'react-icons/ai';
import SubCategoriesModal from './sub-categories-modal';
import brake from 'assets/images/brake.svg';
import { useMutation } from 'react-query';
import { activateCategory, deactivateCategory } from 'apis/categories';
import { FiEdit } from 'react-icons/fi';
import EditCategory from './edit-category';
import { useTranslation } from 'react-i18next';

const ServiceCard = ({
  id,
  name,
  nameAr,
  iconPath,
  childrens,
  refetchCategories,
  isActive,
  isCustom,
  setShowLoad,
  services,
  isThereServices,
  branch,
}) => {
  const toast = useToast();
  const [isSelected, setIsSelected] = useState(isActive);
  const [showSub, setShowSub] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const editRef = useRef();
  const { t } = useTranslation();
  const { language } = useContext(userContext);

  const catName = language == 'en' ? name : nameAr;

  const { mutate: activate, isLoading: loadingActivate } = useMutation(
    activateCategory,
    {
      onSuccess: () => {
        refetchCategories();
        toast({
          render: props => {
            return (
              <CustomToast
                title="activate category"
                description="category activated successfully"
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title="activate category"
                description="some thing went wrong"
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  const { mutate: deactivate, isLoading: loadingDeactivate } = useMutation(
    deactivateCategory,
    {
      onSuccess: () => {
        refetchCategories();
        toast({
          render: props => {
            return (
              <CustomToast
                title="deactivate category"
                description="category deactivated successfully"
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title="deactivate category"
                description="some thing went wrong"
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  useEffect(() => {
    setShowLoad(loadingActivate || loadingDeactivate);
  }, [loadingActivate, loadingDeactivate]);

  return (
    <Box
      bg="white"
      p="20px"
      borderRadius="15px"
      opacity={isActive ? 1 : 0.7}
      pos="relative"
      overflow="hidden"
      border={!isCustom ? '4px solid #21c6e1' : 'none'}
      onClick={e => {
        if (
          e.target.localName != 'input' &&
          e.target.localName != 'span' &&
          e.target != editRef.current
        ) {
          setShowSub(true);
        }
      }}
      cursor="pointer"
    >
      <Flex justifyContent="space-between" alignItems="center">
        {/* <Checkbox
          isChecked={isSelected}
          onChange={e => setIsSelected(e.target.checked)}
          size="lg"
          //colorScheme="red"
          defaultChecked
          borderRadius="50%"
        >
          ServiceCard
        </Checkbox> */}
        <Flex alignItems="center">
          <Image
            src={iconPath ? iconPath : brake}
            w="35px"
            h="35px"
            me="8px"
            borderRadius="50%"
          />
          <Text
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            maxW={['50px', '50px', '130px', '150px']}
          >
            {catName}
          </Text>
        </Flex>

        {/* <Grid
          placeItems="center"
          bg="#e0e0e0"
          borderRadius="50%"
          cursor="pointer"
          w="35px"
          h="35px"
          onClick={() => setShowSub(true)}
        >
          <Box as={AiFillSetting} />
        </Grid> */}
        <Flex alignItems="center">
          {loadingDeactivate || loadingActivate ? (
            <Spinner me="5px" w="20px" />
          ) : null}

          {isCustom ? (
            <Grid
              w="35px"
              h="35px"
              borderRadius="50%"
              bg="#e8e8e8"
              placeItems="center"
              me="5px"
              ref={editRef}
              onClick={() => setShowEdit(true)}
            >
              <Box as={FiEdit} pointerEvents="none" />
            </Grid>
          ) : null}

          <Switch
            size="lg"
            // colorScheme="cyan"
            onChange={() => {
              if (isActive) {
                deactivate({ id, branch });
              } else {
                activate({ id, branch });
              }
            }}
            disabled={!isThereServices || loadingActivate || loadingDeactivate}
            isChecked={isActive}
            _focus={{}}
            _active={{}}
            me="5px"
          />
        </Flex>
      </Flex>

      <SimpleModal title={catName} show={showSub} setShow={setShowSub}>
        <SubCategoriesModal
          childrens={childrens}
          services={services}
          refetchCategories={refetchCategories}
          branchId={branch}
        />
      </SimpleModal>

      <SimpleModal
        title={`${t('modals.edit')} ${catName}`}
        show={showEdit}
        setShow={setShowEdit}
      >
        <EditCategory
          id={id}
          name={name}
          nameAr={nameAr}
          iconPath={iconPath}
          refetchCategories={refetchCategories}
          setShowModal={setShowEdit}
        />
      </SimpleModal>
    </Box>
  );
};

export default ServiceCard;
