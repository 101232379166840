import {
  Box,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
} from '@chakra-ui/react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const CardPopover = ({
  item,
  deleteText,
  setShowEdit,
  setShowDelete,
  setShowActive,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <Button
          position="absolute"
          top="25px"
          right="15px"
          color="text.1100"
          fontSize="18px"
          h="18px"
          justifyContent="flex-end"
          bg="transparent"
          p="0"
          minW="5px"
          _focus={{}}
          _hover={{}}
          _active={{}}
          {...props}
        >
          <Box as={BsThreeDotsVertical} />
        </Button>
      </PopoverTrigger>
      <PopoverContent w="110px" _focus={{}}>
        <PopoverArrow />
        <PopoverBody>
          <Box
            onClick={() => setShowEdit({ flag: true, data: item })}
            cursor="pointer"
            textAlign="center"
            my="10px"
            textTransform="capitalize"
          >
            {t('modals.edit')}
          </Box>
          <Box
            onClick={() =>
              !item.deletedAt
                ? setShowDelete({ flag: true, data: item })
                : setShowActive({ flag: true, data: item })
            }
            cursor="pointer"
            textAlign="center"
            my="10px"
            color={!item.deletedAt ? 'text.1900' : 'text.2000'}
            textTransform="capitalize"
          >
            {!item.deletedAt
              ? deleteText == 'delete'
                ? t('account.delete')
                : t('modals.delete')
              : t('account.activate')}
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default CardPopover;
