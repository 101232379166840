import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, Text, Grid, useToast, Spinner } from '@chakra-ui/react';
import { Label, CustomButton, SimpleModal } from 'components/shared';
import {
  PickTime,
  CustomDate,
  SelectSearch,
  CustomToast,
  HandleCar,
} from 'components/authenticated/shared';
import { MdAdd } from 'react-icons/md';
import { Formik } from 'formik';
import { addProject } from 'schemas/projects/addProject';
import { ErrorMsg } from 'components/shared';
import { useQuery, useMutation } from 'react-query';
import { getAllPagesClients } from 'apis/client';
import { getAllPagesProducts } from 'apis/catlog/Product';
import { getAllPagesServices } from 'apis/service';
import { createProject, updateProject } from 'apis/projects';
import { getAllPagesUsers, getAllUsersByBranch } from 'apis/employee';
import { PartPopup } from 'components/authenticated/parts';
import { ServicePopup } from 'components/authenticated/services';
import { ClientPopup } from 'components/authenticated/clients';
import { EmployePopup } from 'components/authenticated/employees';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import HandleParts from './handle-parts';
import HandleServices from './handle-services';

const ProjectPopup = ({ item, setShow, refetchProjects }) => {
  const { t } = useTranslation();
  const toast = useToast();

  //user context
  const { user, branchFilter } = useContext(userContext);
  const branches = user.user.branches;

  //get services
  const servicesArray = item?.invoice.invoiceProducts.filter(e => {
    return e.type == 'service';
  });

  // get parts
  const partsArray = item?.invoice.invoiceProducts.filter(e => {
    return e.type == 'part';
  });

  //selections states for (client , car details , branch , employee)
  const [client, setClient] = useState({
    label: item?.customer?.name || 'Select client',
    value: item?.customer?.name || null,
  });

  const [branch, setBranch] = useState({
    label: item?.branch?.branchName.en || branchFilter.label,
    value: item?.branch.branchId || branchFilter.value,
  });

  const [employee, setEmployee] = useState({
    label:
      item?.user.userFirstName + ' ' + item?.user.userLastName ||
      'Select employee',
    value: item?.user.userFirstName || null,
  });

  const [services, setServices] = useState(
    servicesArray
      ? servicesArray.map(e => {
          return { label: e.product.name, value: e.product.id };
        })
      : []
  );

  const [parts, setParts] = useState(
    partsArray
      ? partsArray.map(e => {
          return { label: e.product.name, value: e.product.id };
        })
      : []
  );

  //states for show modals
  const [showAddParts, setShowAddParts] = useState(false);
  const [showAddServices, setShowAddServices] = useState(false);
  const [showClient, setShowClient] = useState(false);
  const [showEmployee, setShowEmployee] = useState(false);

  // useEffect(() => {
  //   if (!item) {
  //     setBranch(branchFilter);
  //   }
  // }, []);

  useEffect(() => {
    if (branchFilter.value == null) {
      setBranch([]);
    } else {
      if (!item) {
        setBranch(branchFilter);
      }
    }
  }, [branchFilter]);

  /**********handle selections**********/

  const handleClientSelect = value => {
    setClient(value);
  };

  const handleEmployeeSelect = value => {
    setEmployee(value);
  };

  const handleBranch = value => {
    setBranch(value);
  };

  /*************handle requests*************/

  //get clients data
  const { data: clients, refetch: refetchClients } = useQuery(
    'getAllPagesClients',
    getAllPagesClients
  );

  //get services data
  const { data: servicess, isLoading: loadingServices } = useQuery(
    ['getAllPagesServices', branch.value],
    getAllPagesServices
  );

  //get parts data
  const { data: partss, isLoading: loadingParts } = useQuery(
    ['getAllPagesProducts', branch.value],
    getAllPagesProducts
  );

  const { data: employees, refetch: refetchEmployees } = useQuery(
    ['getAllUsersByBranch', [branch.value]],
    getAllUsersByBranch
  );

  //create new project
  const { mutate, isLoading } = useMutation(createProject, {
    onSuccess: () => {
      setShow(false);
      if (refetchProjects) {
        refetchProjects();
      }
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.addProject')}
              description={t('toasts.addProject')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: err => {
      const notAvaliablePart =
        err.response?.data?.errors?.products?.lessThanAvailable.split(',');

      if (err.response?.data?.errors?.products?.lessThanAvailable) {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.addProject')}
                description={`${notAvaliablePart[0]} ${t(
                  'toasts.noAvailableQuantity'
                )} ${notAvaliablePart[1]}`}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      } else
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.addProject')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
    },
  });

  //update project
  const { mutate: update, isLoading: isLoadingUpdate } = useMutation(
    updateProject,
    {
      onSuccess: () => {
        setShow(false);
        if (refetchProjects) {
          refetchProjects();
        }
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateProject')}
                description={t('toasts.updateProject')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: err => {
        const notAvaliablePart =
          err.response?.data?.errors?.products?.lessThanAvailable.split(',');

        if (err.response?.data?.errors?.products?.lessThanAvailable) {
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.updateProject')}
                  description={`${notAvaliablePart[0]}  not have enough quantity the available is ${notAvaliablePart[1]}`}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
        } else
          toast({
            render: props => {
              return (
                <CustomToast
                  title={t('toasts.titles.updateProject')}
                  description={t('toasts.error')}
                  status="error"
                  onClose={props.onClose}
                />
              );
            },
          });
      },
    }
  );

  const clientData = clients?.data.map(e => {
    return { label: e.name + e.mobileNumber, value: e.id };
  });

  const employeData = employees?.data?.items?.map(e => {
    return { label: e.firstName + ' ' + e.lastName, value: e.id };
  });

  const servicesData = servicess?.data.map(e => {
    let currentBranch = e.totalBranches.filter(i => {
      return (i.id = e.id);
    });
    return {
      id: e.sku,
      label: e.name,
      value: e.id,
      price: currentBranch[0].price,
    };
  });

  const filterPartsAvalibel = partss?.data.filter(e => {
    return e.totalQuantity > 0;
  });

  const partsData = filterPartsAvalibel?.map(e => {
    return {
      quantity: e.totalQuantity,
      id: e.sku,
      label: e.name,
      value: e.id,
    };
  });

  const branchData = branches?.map(e => {
    return { label: e.name.en, value: e.id };
  });

  return (
    <Flex justifyContent="center">
      <Formik
        initialValues={{
          client: item?.customer.name || '',
          employee: item?.user.userFirstName || '',
          macker: item?.carDetails.carManufactur || '',
          model: item?.carDetails.carModel || '',
          year: item?.carDetails.carYear || '',
          branch: item?.branch.branchId || branchFilter.value,
          date: moment(item?.dueDate).locale('en').format('YYYY-MM-DD') || '',
          time: moment(item?.dueDate).locale('en').format('hh:mm:ss') || '',
          // subject: item?.description || '',
          services: servicesArray
            ? servicesArray.map(e => {
                return {
                  type: 'service',
                  invoiceProductId: e.product.id,
                  name: e.product.name,
                  quantity: 1,
                  searchId: e.product.sku,
                  price: e.price,
                };
              })
            : [],
          parts: partsArray
            ? partsArray.map(e => {
                return {
                  type: 'part',
                  invoiceProductId: e.product.id,
                  name: e.product.name,
                  quantity: e.quantity,
                  searchId: e.product.sku,
                  price: e.price,
                };
              })
            : [],
        }}
        validationSchema={addProject}
        onSubmit={async values => {
          if (values.services.length != 0 || values.parts.length != 0) {
            if (item) {
              update({
                projectId: item.id,
                date: values.date,
                time: values.time,
                formatedProducts: [...values.services, ...values.parts],
              });
            } else {
              mutate({
                // title: values.subject,
                selectedClient: values.client,
                maker: values.macker,
                model: values.model,
                year: values.year,
                formatedDate: values.date,
                formatedTime: values.time,
                branch: values.branch,
                selectedUser: values.employee,
                formatedProducts: [...values.services, ...values.parts],
              });
            }
          } else {
            toast({
              render: props => {
                return (
                  <CustomToast
                    title={t('toasts.titles.updateProject')}
                    description={t('toasts.selectPartServiceAtLeast')}
                    status="error"
                    onClose={props.onClose}
                  />
                );
              },
            });
          }
        }}
        // validateOnMount
      >
        {formik => {
          return (
            <Box as="form" w="full" maxW="85%" onSubmit={formik.handleSubmit}>
              <Box w="full">
                <Text
                  fontSize="14px"
                  mb="5px"
                  fontWeight="bold"
                  opacity={item ? 0.3 : 1}
                >
                  {t('project.client')}
                </Text>
                <Flex
                  w="full"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box w={item ? '100%' : '82%'} mb="24px">
                    <SelectSearch
                      h="60px"
                      label={t('modals.selectClient')}
                      select={client}
                      handleSelect={value => {
                        handleClientSelect(value);
                        formik.setFieldValue('client', value.value);
                      }}
                      selections={clientData}
                      isDisabled={item ? true : false}
                    />
                    {formik.errors.client && formik.touched.client && (
                      <ErrorMsg text={t(formik.errors.client)} />
                    )}
                  </Box>
                  {item ? null : (
                    <Flex
                      bg="bg.300"
                      color="white"
                      h="60px"
                      w="15%"
                      justifyContent="center"
                      alignItems="center"
                      fontSize="32px"
                      borderRadius="10px"
                      cursor="pointer"
                      onClick={() => setShowClient(true)}
                    >
                      <MdAdd />
                    </Flex>
                  )}
                </Flex>
              </Box>

              <HandleCar item={item} formik={formik} />

              <Box w="full" mb="20px">
                <Text
                  fontSize="14px"
                  mb="5px"
                  fontWeight="bold"
                  opacity={item ? 0.3 : 1}
                >
                  {t('home.branches.title')}
                </Text>
                <SelectSearch
                  h="60px"
                  label="Branches"
                  select={branch}
                  handleSelect={value => {
                    handleBranch(value);
                    formik.values.parts = [];
                    formik.values.services = [];
                    setServices([]);
                    setParts([]);
                    formik.setFieldValue('branch', value.value);
                  }}
                  selections={branchData}
                  isDisabled={item ? true : false}
                />
                {formik.errors.branch && formik.touched.branch && (
                  <ErrorMsg text={t(formik.errors.branch)} />
                )}
              </Box>

              <Grid gridTemplateColumns="repeat(2,1fr)" gap={6} mb="20px">
                <Box>
                  <Label text={t('modals.selectDate')} />
                  <CustomDate
                    h="60px"
                    bg="white"
                    border="1px solid #E2E8F0"
                    handleDate={value => formik.setFieldValue('date', value)}
                    placeholder={t('modals.enterDate')}
                    defaultDate={item?.dueDate}
                    maxDate={new Date()}
                    dateFormat="dd MMMM yyyy"
                  />
                  {formik.errors.date && formik.touched.date && (
                    <ErrorMsg text={t(formik.errors.date)} />
                  )}
                </Box>
                <Box>
                  <Label text={t('modals.selectTime')} />

                  <PickTime
                    h="60px"
                    bg="white"
                    border="1px solid #E2E8F0"
                    handleTime={value => formik.setFieldValue('time', value)}
                    defaultTime={item?.dueDate}
                    defaultText={t('modals.enterTime')}
                  />
                  {formik.errors.time && formik.touched.time && (
                    <ErrorMsg text={t(formik.errors.time)} />
                  )}
                </Box>
              </Grid>

              {/* <Box w="full" mb="20px">
                <CustomInput
                  type="text"
                  label={t('project.subject')}
                  placeholder={t('project.enterSubject')}
                  value={formik.values.subject}
                  onChange={formik.handleChange('subject')}
                  onBlur={formik.handleBlur('subject')}
                  isDisabled={item ? true : false}
                  textOpacity={item ? '0.3' : 1}
                />
                {formik.errors.subject && formik.touched.subject && (
                  <ErrorMsg text={t(formik.errors.subject)} />
                )}
              </Box> */}

              <HandleServices
                options={servicesData}
                loadingOptions={loadingServices}
                servicesArray={servicesArray}
                formik={formik}
                services={services}
                setServices={setServices}
              />

              <HandleParts
                options={partsData}
                loadingOptions={loadingParts}
                partsArray={partsArray}
                formik={formik}
                parts={parts}
                setParts={setParts}
              />

              <Box w="full">
                <Text
                  fontSize="14px"
                  mb="5px"
                  fontWeight="bold"
                  opacity={item ? 0.3 : 1}
                >
                  {t('home.employees.title')}
                </Text>
                <Flex
                  w="full"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Box w={item ? '100%' : '82%'} mb="24px">
                    <SelectSearch
                      h="60px"
                      label={t('modals.selectEmployee')}
                      select={employee}
                      handleSelect={value => {
                        handleEmployeeSelect(value);
                        formik.setFieldValue('employee', value.value);
                      }}
                      selections={employeData}
                      isDisabled={item ? true : false}
                    />
                    {formik.errors.employee && formik.touched.employee && (
                      <ErrorMsg text={t(formik.errors.employee)} />
                    )}
                  </Box>
                  {item ? null : (
                    <Flex
                      bg="bg.300"
                      color="white"
                      h="60px"
                      w="15%"
                      justifyContent="center"
                      alignItems="center"
                      fontSize="32px"
                      borderRadius="10px"
                      cursor="pointer"
                      onClick={() => setShowEmployee(true)}
                    >
                      <MdAdd />
                    </Flex>
                  )}
                </Flex>
              </Box>
              {item ? (
                <CustomButton
                  loading={isLoadingUpdate && <Spinner ms="5px" />}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  name={t('account.save')}
                  bg
                  type="submit"
                  isDisabled={!formik.dirty || isLoadingUpdate}
                />
              ) : (
                <CustomButton
                  name={t('account.save')}
                  bg
                  type="submit"
                  loading={isLoading && <Spinner ms="5px" />}
                  disabled={isLoading}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                />
              )}
            </Box>
          );
        }}
      </Formik>

      <SimpleModal
        title={t('home.storage.addNewPart')}
        show={showAddParts}
        setShow={setShowAddParts}
      >
        <PartPopup setShow={setShowAddParts} />
      </SimpleModal>

      <SimpleModal
        title={t('home.services.addNewService')}
        show={showAddServices}
        setShow={setShowAddServices}
      >
        <ServicePopup setShow={setShowAddServices} />
      </SimpleModal>

      <SimpleModal
        title={t('home.clients.addNewClient')}
        show={showClient}
        setShow={setShowClient}
      >
        <ClientPopup setShow={setShowClient} refetch={refetchClients} />
      </SimpleModal>

      <SimpleModal
        title={t('home.employees.addNewEmployee')}
        show={showEmployee}
        setShow={setShowEmployee}
      >
        <EmployePopup setShow={setShowEmployee} refetch={refetchEmployees} />
      </SimpleModal>
    </Flex>
  );
};

export default ProjectPopup;
