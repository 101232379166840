import { useState, useEffect, useRef } from 'react';
import { Input, Text, Flex } from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';

const SearchInput = ({
  type,
  placeholder,
  setValue,
  searchWidth = '200px',
  ...props
}) => {
  const inputRef = useRef(null);

  const [openSearch, setOpenSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (openSearch) inputRef.current.focus();
  }, [openSearch]);
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      h="10"
      border="1px solid #BFCFD6"
      borderColor="#BFCFD6"
      borderRadius="10px"
      w={openSearch ? searchWidth : '50px'}
      transition=".5s"
      {...props}
    >
      <Input
        type={type}
        placeholder={placeholder}
        onKeyDown={e => e.keyCode === 13 && setValue(e.target.value)}
        w={openSearch ? 'full' : 0}
        paddingInline={openSearch ? '16px' : 0}
        h="full"
        border="none"
        outline="none"
        _focus={{ border: 'none', boxShadow: 'none' }}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        transition=".5s"
        ref={inputRef}
        onChange={e => setSearchText(e.target.value)}
      />
      <Text
        color="text.500"
        fontSize="22px"
        h="100%"
        w="45px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor={searchText == '' && 'pointer'}
        onClick={() => {
          if (searchText == '') {
            setOpenSearch(!openSearch);
          }
        }}
      >
        <MdSearch />
      </Text>
    </Flex>
  );
};

export default SearchInput;
