import * as Yup from 'yup';

const addPart = Yup.object().shape({
  parts: Yup.array()
    .of(
      Yup.object().shape({
        // price: Yup.string().required('Required'), // these constraints take precedence
        quantity: Yup.string().required('Required'), // these constraints take precedence
      })
    )
    .required('Must have parts'), // these constraints are shown if and only if inner constraints are satisfied
  // .min(3, 'Minimum of 3 friends'),
});

export default addPart;
