import { useState, useContext } from 'react';
import { userContext } from 'contexts/userContext';
import {
  Box,
  Flex,
  Text,
  useToast,
  Grid,
  Spinner,
  Button,
} from '@chakra-ui/react';
import {
  UploadFile,
  CustomInput,
  Map,
  Select,
  Countries,
  CustomButton,
  ErrorMsg,
  CustomUploadFile,
  Label,
} from 'components/shared';
import { AiOutlinePlus, AiOutlineClose } from 'react-icons/ai';
import { CustomToast, PickTime } from 'components/authenticated/shared';
import { useMutation, useQueryClient } from 'react-query';
import { AddBranchSchema } from 'schemas/branch/AddBranchSchema';
import { Formik, Form } from 'formik';
import { createBranch, updateBranch } from 'apis/branch';
import { useTranslation } from 'react-i18next';
import defaultImage from 'assets/images/defaultBranch.png';
import moment from 'moment';

const BranchPopup = ({ setShow, data }) => {
  const { t } = useTranslation();
  const { setUser, user } = useContext(userContext);
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [marker, setMarker] = useState();
  const [isThereSecondWorkingTime, setIsThereSecondWorkingTime] =
    useState(false);

  const queryClient = useQueryClient();

  const toast = useToast();

  const [errors, setErrors] = useState([]);

  const [country, setCountry] = useState({
    label: data ? data.country : null,
    value: data ? data.country : null,
  });

  const [branchImage, setBranchImage] = useState(null);

  const handleFile = (file, fileName) => {
    setBranchImage(fileName);
  };

  const handleCountry = e => {
    setCountry(e);
  };

  function convertTo24HourTime(time12Hour) {
    const isAM = /AM/i.test(time12Hour);
    const isPM = /PM/i.test(time12Hour);

    if (isPM && !time12Hour.startsWith('12')) {
      return moment(time12Hour, 'hh:mm:ss a')
        .locale('en')
        .add('12', 'hours')
        .format('HH:mm:ss');
    } else if (isAM && !time12Hour.startsWith('12')) {
      return moment(time12Hour, 'hh:mm:ss a').locale('en').format('HH:mm:ss');
    } else if (isAM && time12Hour.startsWith('12')) {
      // Handle midnight (12:xx:xx AM)
      return moment(time12Hour, 'hh:mm:ss a')
        .locale('en')
        .add('12', 'hours')
        .format('HH:mm:ss');
    } else {
      return moment(time12Hour, 'hh:mm:ss a').locale('en').format('HH:mm:ss');
    }
  }

  const handleSubmit = useMutation(
    ({ values, marker, country, branchImage }) =>
      data
        ? updateBranch(values, country, data.id)
        : createBranch(values, country),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getAllBranches');

        setErrors([]);
        data ? setShow({ flag: false, data: {} }) : setShow(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updateBranch')
                    : t('toasts.titles.addBranch')
                }
                description={
                  data ? t('toasts.updateBranch') : t('toasts.addBranch')
                }
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });

        const user = JSON.parse(localStorage.getItem('user'));
        const branches = user.user.branches;

        let newBranchesData;
        if (!data) {
          newBranchesData = [...branches, result.data];
        } else {
          newBranchesData = branches.map(e => {
            let defaultBranch = JSON.parse(
              localStorage.getItem('defaultBranch')
            );

            if (result.data.id == defaultBranch.id) {
              localStorage.setItem(
                'defaultBranch',
                JSON.stringify({
                  name: result.data.name.en,
                  id: defaultBranch.id,
                })
              );
            }

            if (e.id == result.data.id) {
              return result.data;
            }
            return e;
          });
        }

        localStorage.setItem(
          'user',
          JSON.stringify({
            user: { ...user.user, branches: newBranchesData },
            accessToken: user.accessToken,
          })
        );

        setUser(JSON.parse(localStorage.getItem('user')));
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={
                  data
                    ? t('toasts.titles.updateBranch')
                    : t('toasts.titles.addBranch')
                }
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );
  return (
    <Flex justifyContent="center">
      <Box w="85%">
        <Formik
          initialValues={{
            enName: data ? data.name.en : '',
            arName: data ? data.name.ar : '',
            street: data ? data.street : '',
            startTime1: data ? data?.startTime1 : '',
            endTime1: data ? data?.endTime1 : '',
            startTime2: data ? data?.startTime2 : null,
            endTime2: data ? data?.endTime2 : null,
            city: data ? data.city : '',
            countrySelected: data ? data?.country : '',
            mapSelected: data
              ? { lat: data?.lat, lng: data?.long }
              : { lat: '', lng: '' },
            zipCode: data ? data.zipCode : '',
            commercialNumber: data ? data.commercialNumber : '',
            image: data ? data?.branchImageUrl : '',
          }}
          validationSchema={AddBranchSchema}
          onSubmit={values => {
            // if (!country.value) {
            //   setErrors(prevErrors => [...prevErrors, 'country']);
            //   return;
            // } else if (!marker) {
            //   setErrors(prevErrors => [...prevErrors, 'marker']);
            //   return;
            // }

            handleSubmit.mutate({
              values,
              // marker,

              // branchImage,
            });
          }}
        >
          {formik => (
            <Form>
              <CustomUploadFile
                defaultImage={formik.values.image || defaultImage}
                formikChange={e => formik.setFieldValue('image', e)}
                radius={false}
                setUploadImageLoading={setUploadImageLoading}
              />

              <Grid
                gridTemplateColumns="repeat(2,1fr)"
                justifyContent="space-between"
                mb="24px"
                gap="25px"
              >
                <Box>
                  <CustomInput
                    type="text"
                    label={`${t('account.workshopName')} (EN)`}
                    placeholder="Enter your workshop name"
                    value={formik.values.enName}
                    onChange={formik.handleChange('enName')}
                    onBlur={formik.handleBlur('enName')}
                  />
                  {formik.errors.enName && formik.touched.enName && (
                    <ErrorMsg text={t(formik.errors.enName)} />
                  )}
                </Box>

                <Box>
                  <CustomInput
                    type="text"
                    label={`${t('account.workshopName')} (AR)`}
                    placeholder="ادخل اسم ورشتك"
                    value={formik.values.arName}
                    onChange={formik.handleChange('arName')}
                    onBlur={formik.handleBlur('arName')}
                  />
                  {formik.errors.arName && formik.touched.arName && (
                    <ErrorMsg text={t(formik.errors.arName)} />
                  )}
                </Box>
              </Grid>

              <Box mb="24px">
                <CustomInput
                  type="number"
                  label={t('account.commercialNumber')}
                  placeholder={t('home.clients.enterCommercial')}
                  value={formik.values.commercialNumber}
                  onChange={formik.handleChange('commercialNumber')}
                  onBlur={formik.handleBlur('commercialNumber')}
                />
                {formik.errors.commercialNumber &&
                  formik.touched.commercialNumber && (
                    <ErrorMsg text={t(formik.errors.commercialNumber)} />
                  )}
              </Box>

              <Box>
                <Label text={t('home.branches.workingTimes')} />
                <Flex justifyContent="space-between" mb="24px">
                  <Grid
                    gridTemplateColumns="repeat(2,1fr)"
                    gap="25px"
                    // w={
                    //   isThereSecondWorkingTime || formik.values?.startTime2
                    //     ? '100%'
                    //     : '88%'
                    // }
                    w="88%"
                  >
                    <Box>
                      <PickTime
                        defaultText={t('home.branches.startTime')}
                        intervals={30}
                        // maxTime={formik.values.checkoutTime}
                        // minTime={formik.values.checkinTime}
                        handleTime={value =>
                          formik.setFieldValue(
                            'startTime1',
                            convertTo24HourTime(value)
                          )
                        }
                        defaultTime={
                          data && new Date(moment(data?.startTime1, 'HH:mm:ss'))
                        }
                      />
                      {/* {errors.includes('clockIn') && (
                <ErrorMsg text={'This field is required'} />
              )} */}
                      {formik.errors.startTime1 &&
                        formik.touched.startTime1 && (
                          <ErrorMsg text={t(formik.errors.startTime1)} />
                        )}
                    </Box>

                    <Box>
                      <PickTime
                        handleTime={value =>
                          formik.setFieldValue(
                            'endTime1',
                            convertTo24HourTime(value)
                          )
                        }
                        defaultText={t('home.branches.endTime')}
                        intervals={30}
                        // minTime={formik.values.checkinTime}
                        // maxTime={formik.values.clockOut}
                        defaultTime={
                          data && new Date(moment(data?.endTime1, 'HH:mm:ss'))
                        }
                      />
                      {formik.errors.endTime1 && formik.touched.endTime1 && (
                        <ErrorMsg text={t(formik.errors.endTime1)} />
                      )}
                    </Box>
                  </Grid>

                  <Button
                    w="10%"
                    py="20px"
                    bg="bg.300"
                    border="none"
                    borderRadius="10px"
                    color="white"
                    title="Add another working time "
                    _hover={{
                      bg: 'bg.300',
                      color: 'white',
                      border: 'none',
                    }}
                    _focus={{ outline: 'none' }}
                    _active={{ bg: 'bg.400' }}
                    alignItems="center"
                    onClick={() => setIsThereSecondWorkingTime(true)}
                    disabled={
                      isThereSecondWorkingTime || formik.values?.startTime2
                    }
                  >
                    <Box as={AiOutlinePlus} fontSize="35px" />
                  </Button>
                </Flex>
              </Box>

              {isThereSecondWorkingTime || formik.values?.startTime2 ? (
                <Flex justifyContent="space-between" mb="24px">
                  <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px" w="88%">
                    <Box>
                      <PickTime
                        defaultText={t('home.branches.startTime')}
                        intervals={30}
                        // maxTime={formik.values.checkoutTime}
                        // minTime={formik.values.checkinTime}
                        handleTime={value =>
                          formik.setFieldValue(
                            'startTime2',
                            convertTo24HourTime(value)
                          )
                        }
                        defaultTime={
                          data && formik.values?.startTime2 != null
                            ? new Date(moment(data?.startTime2, 'HH:mm:ss'))
                            : null
                        }
                      />
                      {/* {errors.includes('clockIn') && (
                <ErrorMsg text={'This field is required'} />
              )} */}
                      {formik.errors.startTime2 &&
                        formik.touched.startTime2 && (
                          <ErrorMsg text={t(formik.errors.startTime2)} />
                        )}
                    </Box>

                    <Box>
                      <PickTime
                        handleTime={value =>
                          formik.setFieldValue(
                            'endTime2',
                            convertTo24HourTime(value)
                          )
                        }
                        defaultText={t('home.branches.endTime')}
                        intervals={30}
                        // minTime={formik.values.checkinTime}
                        // maxTime={formik.values.clockOut}
                        defaultTime={
                          data && formik.values?.endTime2 != null
                            ? new Date(moment(data?.endTime2, 'HH:mm:ss'))
                            : null
                        }
                      />
                      {formik.errors.endTime2 && formik.touched.endTime2 && (
                        <ErrorMsg text={t(formik.errors.endTime2)} />
                      )}
                    </Box>
                  </Grid>

                  <Button
                    w="10%"
                    py="20px"
                    bg="#ff2323"
                    border="none"
                    borderRadius="10px"
                    color="white"
                    title="Add another working time "
                    _hover={{
                      bg: 'red',
                      color: 'white',
                      border: 'none',
                    }}
                    _focus={{ outline: 'none' }}
                    _active={{ bg: 'red' }}
                    alignItems="center"
                    onClick={() => {
                      setIsThereSecondWorkingTime(false);
                      formik.setFieldValue('startTime2', null);
                      formik.setFieldValue('endTime2', null);
                    }}
                  >
                    <Box as={AiOutlineClose} fontSize="35px" />
                  </Button>
                </Flex>
              ) : null}

              <Box mb="24px">
                <Map
                  buttonName={t('modals.chooseLocation')}
                  errMsg={
                    formik.errors.mapSelected && formik.touched.mapSelected
                      ? true
                      : false
                  }
                  marker={marker}
                  handleMarker={(values, country, city, street, zipcode) => {
                    setMarker(values);

                    formik.setFieldValue('mapSelected', values);

                    formik.setFieldValue('city', city);
                    formik.setFieldValue('countrySelected', country);
                    formik.setFieldValue('street', street);
                    formik.setFieldValue('zipCode', zipcode);
                  }}
                />
              </Box>

              <Box mb="24px">
                <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px">
                  <Box>
                    <CustomInput
                      type="text"
                      label={t('signup.country')}
                      placeholder={t('home.clients.enterCity')}
                      value={formik.values.countrySelected}
                      disabled
                      isReadOnly
                      _disabled={{ opacity: '0.8', cursor: 'not-allowed' }}
                    />
                    {/* <Text
                      textTransform="capitalize"
                      color="text.600"
                      fontSize="14px"
                      mb="5px"
                      fontWeight="semibold"
                    >
                      {t('signup.country')}
                    </Text>
                    <Select
                      h="60px"
                      label={t('signup.country')}
                      select={country}
                      handleSelect={handleCountry}
                      selections={Countries.map(elm => ({
                        label: elm.name,
                        value: elm.name,
                      }))}
                    /> */}
                  </Box>

                  <Box>
                    <CustomInput
                      type="text"
                      label={t('signup.city')}
                      placeholder={t('home.clients.enterCity')}
                      value={formik.values.city}
                      onChange={formik.handleChange('city')}
                      onBlur={formik.handleBlur('city')}
                    />
                    {formik.errors.city && formik.touched.city && (
                      <ErrorMsg text={t(formik.errors.city)} />
                    )}
                  </Box>

                  {/* <Flex
                    color="text.1000"
                    alignItems="center"
                    cursor="pointer"
                    pt="24px"
                  >
                    <Box
                      as={HiOutlineLocationMarker}
                      color="text.1000"
                      me="5px"
                    />
                    <Map marker={marker} handleMarker={handleMarker} />
                  </Flex> */}
                </Grid>
                {/* {errors.includes('country') && (
                  <ErrorMsg text={'This field is required'} />
                )}
                {errors.includes('marker') && (
                  <ErrorMsg text={'Please select in map'} />
                )} */}
              </Box>

              <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px" mb="24px">
                <Box>
                  <CustomInput
                    type="text"
                    label={t('signup.street')}
                    placeholder={t('home.clients.enterStreet')}
                    value={formik.values.street}
                    onChange={formik.handleChange('street')}
                    onBlur={formik.handleBlur('street')}
                  />
                  {formik.errors.street && formik.touched.street && (
                    <ErrorMsg text={t(formik.errors.street)} />
                  )}
                </Box>

                <Box>
                  <CustomInput
                    type="number"
                    label={t('signup.zipCode')}
                    placeholder={t('home.clients.enterZipcode')}
                    value={formik.values.zipCode}
                    onChange={formik.handleChange('zipCode')}
                    onBlur={formik.handleBlur('zipCode')}
                  />
                  {formik.errors.zipCode && formik.touched.zipCode && (
                    <ErrorMsg text={t(formik.errors.zipCode)} />
                  )}
                </Box>
              </Grid>

              <CustomButton
                bg
                type="submit"
                name={t('account.saveChanges')}
                w="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                loading={handleSubmit.isLoading && <Spinner ms="5px" />}
                disabled={handleSubmit.isLoading || uploadImageLoading}
              />
            </Form>
          )}
        </Formik>
      </Box>
    </Flex>
  );
};

export default BranchPopup;
