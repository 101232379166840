import { useState } from 'react';
import { useParams } from 'react-router';
import { Box, TabPanel } from '@chakra-ui/react';
import {
  Tabs,
  PageHeader,
  Loading,
  Error,
} from 'components/authenticated/shared';
import {
  Profile as MyProfile,
  Reviews,
  Projects,
  Invoices,
} from 'components/authenticated/clients';
import { useQuery } from 'react-query';
import { getClientById } from 'apis/client';
import { useTranslation } from 'react-i18next';

const ClientsProfile = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [clientProjectsCount, setClientProjectsCount] = useState(0);
  const [clientInvoicesCount, setClientInvoicesCount] = useState(0);

  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getClientById', id],
    getClientById
  );

  return (
    <>
      <Box mx="auto">
        <PageHeader text={data?.data.name} isArrow />
        <Tabs
          tabs={[
            t('account.profile'),
            `${t('account.projects')} (${clientProjectsCount})`,
            `${t('accounting.invoices')} (${clientInvoicesCount})`,
          ]}
        >
          <TabPanel outline="none" minH="600px">
            {isLoading && <Loading />}
            {isSuccess && <MyProfile data={data?.data} />}
            {isError && <Error />}
          </TabPanel>
          {/* <TabPanel outline="none" minH="600px">
            <Reviews />
          </TabPanel> */}
          <TabPanel outline="none" minH="600px">
            <Projects setClientProjectsCount={setClientProjectsCount} />
          </TabPanel>

          <TabPanel outline="none" minH="600px">
            <Invoices
              clientId={id}
              name={data?.data.name}
              setClientInvoicesCount={setClientInvoicesCount}
            />
          </TabPanel>
        </Tabs>
      </Box>
    </>
  );
};

export default ClientsProfile;
