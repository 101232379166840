import { useContext, useRef } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Text, Tr, Td, Flex, Button } from '@chakra-ui/react';
import { Cards, Chart } from 'components/authenticated/dashboard';
import { useQuery } from 'react-query';
import { getAllProjects } from 'apis/projects';
import { getLastProjects, getProjectsCount } from 'apis/dashboard';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Permission from 'helpers/permission';
import { Loading } from 'components/authenticated/shared';
import { Invoice, SimpleModal } from 'components/shared';
import ProjectsTable from 'components/authenticated/dashboard/table';
import AccessPage from 'helpers/AccessPage';

const Dashboard = () => {
  const { t } = useTranslation();
  const { user, branchFilter } = useContext(userContext);

  const {
    data: getAllProjectss,
    isLoading,
    isSuccess,
    isError,
  } = useQuery(['getAllProjects', 1, '', null, false], getAllProjects);

  const {
    data,
    isLoading: getLastProjectsLoading,
    isSuccess: isSuccess2,
  } = useQuery(['getLastProjects'], getLastProjects);

  const {
    data: data1,
    isLoading: getProjectsCountLoading,
    isSuccess: isSuccess3,
    isError: isError1,
  } = useQuery(['getProjectsCount'], getProjectsCount);

  let totalItems = getAllProjectss?.data.meta.totalItems;

  if (!AccessPage(user.user.roles, 'Projects')) {
    return (
      <>
        <Cards mb="40px" totalItems={totalItems} />

        <Box pos="relative">
          <Chart
            mb="40px"
            isView={Permission('Dashboard') != undefined}
            data={data}
            data1={data1}
            noAccess
          />
        </Box>
        <Box>
          <Text
            fontSize="24px"
            fontWeight="bold"
            bg="white"
            p="6"
            borderTopRadius="10px"
            mb="1px"
          >
            {t('home.dashboard.recentProjects')}
          </Text>

          <Box pos="relative">
            <ProjectsTable getAllProjectss={getAllProjectss} noAccess />
            {/* {Permission('Dashboard') == undefined && (
              <Box
                pos="absolute"
                top="0"
                left="0"
                bottom="0"
                w="100%"
                h="100%"
                bg="rgba(255, 255, 255, 0.4);"
                zIndex="111"
                borderRadius="10px"
                backdropFilter="blur(4px)"
              />
            )} */}
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Box>
      {/* <SimpleModal title={t('quickbooks.title')} show={true}>
        <QuickbooksLoginModal />
      </SimpleModal> */}
      {(isLoading || getLastProjectsLoading || getProjectsCountLoading) && (
        <Loading />
      )}

      {isSuccess && isSuccess2 && isSuccess3 && (
        <>
          <Cards mb="40px" totalItems={totalItems} />

          <Box pos="relative">
            {/* <Box
          pos="absolute"
          top="0"
          left="0"
          bottom="0"
          w="100%"
          h="calc(100% + 30px)"
          bg="rgba(255, 255, 255, 0.7);"
          zIndex="111"
          borderRadius="10px"
          backdropFilter="blur(4px)"
        /> */}
            <Chart
              mb="40px"
              isView={Permission('Dashboard') != undefined}
              data={data}
              data1={data1}
            />
          </Box>
          <Box>
            <Text
              fontSize="24px"
              fontWeight="bold"
              bg="white"
              p="6"
              borderTopRadius="10px"
              mb="1px"
            >
              {t('home.dashboard.recentProjects')}
            </Text>

            <Box pos="relative">
              <ProjectsTable getAllProjectss={getAllProjectss} />
              {Permission('Dashboard') == undefined && (
                <Box
                  pos="absolute"
                  top="0"
                  left="0"
                  bottom="0"
                  w="100%"
                  h="100%"
                  bg="rgba(255, 255, 255, 0.4);"
                  zIndex="111"
                  borderRadius="10px"
                  backdropFilter="blur(4px)"
                />
              )}
            </Box>

            {isError && <Text>Sorry !!!</Text>}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Dashboard;
