import { Box, Flex, Text, Grid } from '@chakra-ui/react';

const InvoiceHead = ({ ...props }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" {...props}>
      <Box w="50%">
        <Text fontSize="20px" fontWeight="bold">
          شركة فلاة الحلول للخدمات التجارية
        </Text>
        <Text fontSize="20px" fontWeight="bold">
          Falah Al-Hulul Co. fro trading
        </Text>
      </Box>

      <Box>
        <Text fontSize="20px" fontWeight="bold">
          فاتورة ضريبية
        </Text>
        <Text fontSize="20px" fontWeight="bold">
          Tax Invoice
        </Text>
      </Box>
    </Flex>
  );
};

export default InvoiceHead;
