import request from 'axios-interceptors';

export const createProduct = (values, branch) => {
  // let formatedBranch =
  //   branch.length > 0
  //     ? branch.map(elm => {
  //         return { branchId: elm.value };
  //       })
  //     : null;

  
  return request.post('cat-log/products', {
    name: values.name,
    nameAr:values.nameAr,
    partNumber: values.partNumber,
    type: 'part',
    branches: branch,
  });
};

export const updateProduct = (values, branch, productId) => {
  // let formatedBranch =
  //   branch.length > 0
  //     ? branch.map(elm => {
  //         return { branchId: elm.value };
  //       })
  //     : null;

  return request.put(`cat-log/products/${productId}`, {
    name: values.name,
    partNumber: values.partNumber,
    type: 'part',
    branches: branch,
  });
};

export const getAllProducts = ({ queryKey }) => {
  //   currentPage, searchKey, stock, branch, status

  let formatedBranch =
    queryKey[4].length > 0 ? queryKey[4].map(elm => elm.value) : null;

  return request.get(
    `cat-log/products?type=part&page=${queryKey[1]}&limit=10&searchKey=${queryKey[2]}&stock=${queryKey[3]}&branch=${formatedBranch}&status=${queryKey[5]}`
  );
};

export const getAllPagesProducts = ({ queryKey }) => {
  return request.get(`cat-log/products/all?type=part&branch=${queryKey[1]}`);
};

export const checkProductName = productName => {
  return request.get(
    `cat-log/products/isProductExist?type=part&productName=${productName}`
  );
};

export const deleteProduct = productId => {
  return request.post('cat-log/products/delete', {
    products: productId,
  });
};

export const activateProduct = productId => {
  return request.post(`cat-log/products/activate/${productId}`);
};
