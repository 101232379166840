import { useState } from 'react';
import { Switch } from '@chakra-ui/react';
import { ChosseBranchModal } from 'components/authenticated/services';
import { SimpleModal } from 'components/shared';

const PartSwitch = ({
  setShowDelete,
  setShowActive,
  item,
  isActive,
  setShowChosseBranchModal,
  showChosseBranchModal,
  refetchParts,
  ...props
}) => {
  const checkIsActive = () => {
    if (isActive) {
      if (isActive?.isActive) {
        return true;
      } else {
        return false;
      }
    } else {
      if (item?.deletedAt == null) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <Switch
        position="absolute"
        top="20px"
        right="15px"
        size="lg"
        // colorScheme="cyan"
        onChange={e => {
          if (item?.totalBranches.length > 1) {
            setShowChosseBranchModal(true);
          } else {
            if (!e.target.checked) {
              setShowDelete(true);
            } else {
              setShowActive(true);
            }
          }
        }}
        isChecked={isActive}
        background="none"
        _focus={{ background: 'none' }}
        _active={{}}
        {...props}
      />
      <SimpleModal
        title={'which branch do you want to activate/deactivate this part?'}
        show={showChosseBranchModal}
        setShow={setShowChosseBranchModal}
      >
        <ChosseBranchModal
          branches={item?.totalBranches}
          itemId={item?.id}
          refetchServices={refetchParts}
          isPart
        />
      </SimpleModal>
    </>
  );
};

export default PartSwitch;
