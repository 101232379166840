import { useState, useContext, useEffect } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Text, Grid, GridItem, Flex, useToast } from '@chakra-ui/react';
import {
  Filter,
  DeletePopup,
  Pagination,
  Nodata,
  Error,
  Loading,
  CustomToast,
} from 'components/authenticated/shared';
import { Card } from 'components/authenticated/documents';
import {
  Select,
  CustomModal,
  MultiSelect,
  SimpleModal,
} from 'components/shared';
import { DocumentPopup } from 'components/authenticated/documents';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getAllDocuments } from 'apis/document';
import { deleteDocument } from 'apis/document';
import { useTranslation } from 'react-i18next';
import Permission from 'helpers/permission';

const Documents = () => {
  const { user, branchFilter, language } = useContext(userContext);
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const toast = useToast();
  const [showAdd, setShowAdd] = useState(false);
  const [showDelete, setShowDelete] = useState({ flag: false, data: {} });

  //filter and search data
  const [pageNumber, setPageNumber] = useState(1);
  const [searchKey, setSearchKey] = useState('');
  const [branch, setBranch] = useState([branchFilter]);

  useEffect(() => {
    if (branchFilter.value == null) {
      setBranch([]);
    } else {
      setBranch([branchFilter]);
    }
  }, [branchFilter]);

  // currentPage, searchKey, branch
  const { data, isLoading, isSuccess, isError } = useQuery(
    ['getAllDocuments', pageNumber, searchKey, branch],
    getAllDocuments
  );

  const handleBranch = (value, check) => {
    setPageNumber(1);
    if (!check) {
      let filteredBranches = branch.filter(elm => elm.value !== value.value);
      setBranch(filteredBranches);
    } else setBranch(prevState => [...prevState, value]);
  };

  const handleDelete = useMutation(() => deleteDocument([showDelete.data.id]), {
    onSuccess: async result => {
      await queryClient.invalidateQueries('getAllDocuments');

      setShowDelete({ flag: false, data: {} });

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateDocument')}
              description={t('toasts.deactivateDocument')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateDocument')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handlePageClick = async data => {
    setPageNumber(data.selected + 1);
  };

  let numberOfPages = data?.data.meta.totalPages;
  let itemsPerPage = data?.data.meta.itemsPerPage;
  let currentPage = data?.data.meta.currentPage;
  let itemCount = data?.data.meta.itemCount;
  let totalItems = data?.data.meta.totalItems;

  return (
    <Box>
      <Filter
        title={t('home.documents.title')}
        buttonName={t('home.documents.addNewDocument')}
        setShowAdd={setShowAdd}
        btResponsiveWidth="200px"
        isButtonExist={Permission('Documents') == 'Edit'}
      >
        <MultiSelect
          mx="4"
          w="150px"
          h="40px"
          label={t('filter.branch')}
          labelForMulti={t('sideBar.branches')}
          select={branch}
          handleSelect={handleBranch}
          selections={user.user.branches?.map((item, index) => ({
            label: language == 'en' ? item.name.en : item.name.ar,
            value: item.id,
          }))}
        />
      </Filter>

      {isLoading && <Loading />}

      {isSuccess &&
        (data?.data.meta.itemCount > 0 ? (
          <>
            <Box minH="72vh">
              <Grid templateColumns="repeat(4, 1fr)" gap="8" mb="24px">
                {data?.data.items.map((item, index) => (
                  <GridItem key={index}>
                    <Card item={item} setShowDelete={setShowDelete} />
                  </GridItem>
                ))}
              </Grid>
            </Box>

            <CustomModal
              title={t('modals.deleteDocument')}
              show={showDelete}
              setShow={setShowDelete}
            >
              <DeletePopup
                handleDelete={handleDelete.mutate}
                title={t('modals.deleteDocumentText')}
                isLoading={handleDelete.isLoading}
                buttonText="delete"
              />
            </CustomModal>

            <Pagination
              handlePageClick={handlePageClick}
              numberOfPages={numberOfPages}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              itemCount={itemCount}
              totalItems={totalItems}
              forcePage={currentPage - 1}
            />
          </>
        ) : (
          <Nodata />
        ))}
      <SimpleModal
        title={t('home.documents.addNewDocument')}
        show={showAdd}
        setShow={setShowAdd}
      >
        <DocumentPopup setShow={setShowAdd} />
      </SimpleModal>

      {isError && <Error />}
    </Box>
  );
};

export default Documents;
