import { useState } from 'react';
import {
  Text,
  Box,
  Flex,
  Checkbox,
  useToast,
  Grid,
  Spinner,
} from '@chakra-ui/react';
import {
  CustomInput,
  PhoneInput,
  Select,
  Countries,
  CustomButton,
  SimpleModal,
  ErrorMsg,
} from 'components/shared';
import {
  DeletePopup,
  ActivePopup,
  CustomToast,
} from 'components/authenticated/shared';
import { deleteClient, activateClient, updateClient } from 'apis/client';
import { Formik, Form } from 'formik';
import { useMutation, useQueryClient } from 'react-query';
import { AddClientSchema } from 'schemas/crm/AddClientSchema';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Profile = ({ data }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [read, setRead] = useState(false);
  const toast = useToast();

  const [showDelete, setShowDelete] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [taxable, setTaxable] = useState(data.taxNumber ? true : false);
  const [initCountry, setInitCountry] = useState({
    label: data.country,
    value: data.country,
  });
  const [country, setCountry] = useState({
    label: data.country,
    value: data.country,
  });

  const showDeleteModal = () => {
    setShowDelete(true);
  };

  const showActiveModal = () => {
    setShowActive(true);
  };

  const handleCountry = e => {
    setCountry(e);
  };

  const handleDelete = useMutation(() => deleteClient([data.id]), {
    onSuccess: result => {
      setShowDelete(false);
      data.deletedAt = new Date();

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateClient')}
              description={t('toasts.deactivateClient')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateClient')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleActivate = useMutation(() => activateClient(data.id), {
    onSuccess: result => {
      setShowActive(false);
      data.deletedAt = null;

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateClient')}
              description={t('toasts.activateClient')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateClient')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleSubmit = useMutation(
    ({ values, country, taxable }) =>
      updateClient(values, country, taxable, data.id),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getClientById');
        setInitCountry({
          label: result.data.country,
          value: result.data.country,
        });

        setRead(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateClient')}
                description={t('toasts.clientUpdated')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateClient')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );

  return (
    <Box py="8" ml="8" maxW="800px" pos="relative">
      <Text
        color="text.1200"
        fontSize="12"
        mt="-22px"
        pos="absolute"
        top="5"
        right="0"
      >
        {t('myProfile.joined')}{' '}
        {moment(data.createdAt).locale(t('common.lang')).format('DD-MM-YYYY')}
        {/* .format('ddd MMM D yyyy')} */}
      </Text>
      <Formik
        initialValues={{
          name: data.name,
          phone: parseInt(data.mobileNumber, 10)?.toString(),
          email: data.emailAddress ? data.emailAddress : '',
          street: data.street,
          city: data.city,
          zipCode: data.zipCode,
          notes: data.note,
          taxNumber: data.taxNumber || '',
          buildingNumber: data?.buildingNumber,
          districtName: data?.district,
          additionalNumber: data?.additionalNumber,
        }}
        validationSchema={AddClientSchema}
        onSubmit={values => {
          if (taxable && (values.taxNumber == null || values.taxNumber == '')) {
            toast({
              render: props => {
                return (
                  <CustomToast
                    title={t('toasts.taxNumber')}
                    description={t('account.taxNumber')}
                    status="error"
                    onClose={props.onClose}
                  />
                );
              },
            });
          } else
            handleSubmit.mutate({
              values,
              country,
              taxable,
            });
        }}
      >
        {formik => (
          <Form>
            <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px" mb="24px">
              <Box>
                <CustomInput
                  type="text"
                  label={t('modals.clientName')}
                  placeholder={t('home.clients.enterName')}
                  value={!read ? data.name : formik.values.name}
                  onChange={formik.handleChange('name')}
                  onBlur={formik.handleBlur('name')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.name && formik.touched.name && (
                  <ErrorMsg text={t(formik.errors.name)} />
                )}
              </Box>
              <Box>
                <PhoneInput
                  label={t('signup.phone')}
                  value={
                    !read
                      ? parseInt(data.mobileNumber, 10)?.toString()
                      : formik.values.phone
                  }
                  onChange={formik.handleChange('phone')}
                  onBlur={formik.handleBlur('phone')}
                  disabled={read ? false : true}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <ErrorMsg text={t(formik.errors.phone)} />
                )}
              </Box>
            </Grid>

            <Box w="full" maxW="800px" mb="24px">
              <CustomInput
                type="email"
                label={t('home.clients.email')}
                placeholder={t('home.clients.enterEmail')}
                value={!read ? data.emailAddress : formik.values.email}
                onChange={formik.handleChange('email')}
                onBlur={formik.handleBlur('email')}
                isReadOnly={read ? false : true}
              />
              {formik.errors.email && formik.touched.email && (
                <ErrorMsg text={t(formik.errors.email)} />
              )}
            </Box>

            <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px" mb="24px">
              <Box>
                <Flex justifyContent="space-between">
                  <Box w="full">
                    <Text fontSize="14px" mb="5px" fontWeight="bold">
                      {t('signup.country')}
                    </Text>

                    <Select
                      h="60px"
                      label={t('signup.country')}
                      select={!read ? initCountry : country}
                      handleSelect={handleCountry}
                      selections={Countries.map(elm => ({
                        label: elm.name,
                        value: elm.name,
                      }))}
                      isDisabled={!read}
                    />
                  </Box>
                </Flex>
              </Box>

              <Box>
                <CustomInput
                  type="text"
                  label={t('signup.city')}
                  placeholder={t('home.clients.enterCity')}
                  value={!read ? data.city : formik.values.city}
                  onChange={formik.handleChange('city')}
                  onBlur={formik.handleBlur('city')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.city && formik.touched.city && (
                  <ErrorMsg text={t(formik.errors.city)} />
                )}
              </Box>
            </Grid>

            <Grid gridTemplateColumns="repeat(2,1fr)" gap="25px" mb="24px">
              <Box>
                <CustomInput
                  type="text"
                  label={t('signup.street')}
                  placeholder={t('home.clients.enterStreet')}
                  value={!read ? data.street : formik.values.street}
                  onChange={formik.handleChange('street')}
                  onBlur={formik.handleBlur('street')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.street && formik.touched.street && (
                  <ErrorMsg text={t(formik.errors.street)} />
                )}
              </Box>
              <Box>
                <CustomInput
                  type="number"
                  label={t('signup.zipCode')}
                  placeholder={t('home.clients.enterZipcode')}
                  value={!read ? data.zipCode : formik.values.zipCode}
                  onChange={formik.handleChange('zipCode')}
                  onBlur={formik.handleBlur('zipCode')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.zipCode && formik.touched.zipCode && (
                  <ErrorMsg text={t(formik.errors.zipCode)} />
                )}
              </Box>
            </Grid>

            <Grid gridTemplateColumns="repeat(3,1fr)" gap="25px" mb="24px">
              <Box>
                <CustomInput
                  type="number"
                  label={t('common.buildingNumber')}
                  placeholder={t('common.buildingNumber')}
                  value={formik.values.buildingNumber}
                  onChange={formik.handleChange('buildingNumber')}
                  onBlur={formik.handleBlur('buildingNumber')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.buildingNumber &&
                  formik.touched.buildingNumber && (
                    <ErrorMsg text={t(formik.errors.buildingNumber)} />
                  )}
              </Box>
              <Box>
                <CustomInput
                  type="text"
                  label={t('common.districtName')}
                  placeholder={t('common.districtName')}
                  value={formik.values.districtName}
                  onChange={formik.handleChange('districtName')}
                  onBlur={formik.handleBlur('districtName')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.districtName && formik.touched.districtName && (
                  <ErrorMsg text={t(formik.errors.districtName)} />
                )}
              </Box>

              <Box>
                <CustomInput
                  type="number"
                  label={t('common.additionalNumber')}
                  placeholder={t('common.additionalNumber')}
                  value={formik.values.additionalNumber}
                  onChange={formik.handleChange('additionalNumber')}
                  onBlur={formik.handleBlur('additionalNumber')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.additionalNumber &&
                  formik.touched.additionalNumber && (
                    <ErrorMsg text={t(formik.errors.additionalNumber)} />
                  )}
              </Box>
            </Grid>

            <Box w="full" maxW="800px" mb="24px">
              <CustomInput
                type="text"
                label={t('home.clients.note')}
                placeholder={t('home.clients.enterNote')}
                value={!read ? data.note : formik.values.notes}
                onChange={formik.handleChange('notes')}
                onBlur={formik.handleBlur('notes')}
                isReadOnly={read ? false : true}
              />
              {formik.errors.notes && formik.touched.notes && (
                <ErrorMsg text={t(formik.errors.notes)} />
              )}
            </Box>

            <Box w="full" maxW="800px">
              <Checkbox
                isChecked={read ? taxable : data.taxNumber}
                onChange={e => read && setTaxable(!taxable)}
              >
                {t('home.clients.clientTaxable')}
              </Checkbox>
              {(read ? taxable : data.taxNumber) && (
                <Box w="full">
                  <CustomInput
                    type="number"
                    placeholder={t('account.enterTaxNumber')}
                    mb="20px"
                    value={!read ? data.taxNumber : formik.values.taxNumber}
                    onChange={formik.handleChange('taxNumber')}
                    onBlur={formik.handleBlur('taxNumber')}
                    isReadOnly={read ? false : true}
                  />
                </Box>
              )}
            </Box>

            {Permission('Workflow') == 'Edit' ? (
              !read ? (
                <CustomButton
                  bg
                  name={t('myProfile.update')}
                  w="100%"
                  display="block"
                  m="auto"
                  mt="30px"
                  onClick={() => setRead(true)}
                />
              ) : (
                <Box>
                  <Flex justifyContent="space-between" alignItems="center">
                    <CustomButton
                      bg
                      type="submit"
                      name={t('account.saveChanges')}
                      w="48%"
                      display="block"
                      mt="30px"
                      display="flex"
                      alignItems="center"
                      loading={
                        handleSubmit.isLoading ? <Spinner ms="5px" /> : null
                      }
                      disabled={handleSubmit.isLoading}
                    />
                    <CustomButton
                      name={
                        data.deletedAt
                          ? t('home.clients.activateClient')
                          : t('home.clients.deactivateClient')
                      }
                      w="48%"
                      display="block"
                      mt="30px"
                      onClick={() =>
                        data.deletedAt ? showActiveModal() : showDeleteModal()
                      }
                    />
                  </Flex>

                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    h="60px"
                    borderRadius="15px"
                    w="100%"
                    mt="24px"
                    textAlign="center"
                    color="text.1000"
                    fontWeight="bold"
                    cursor="pointer"
                    bg="gray.100"
                    onClick={() => setRead(false)}
                  >
                    <Text>{t('myProfile.cancel')}</Text>
                  </Flex>
                </Box>
              )
            ) : null}
          </Form>
        )}
      </Formik>

      <SimpleModal
        title={t('home.clients.deactivateClient')}
        show={showDelete}
        setShow={setShowDelete}
      >
        <DeletePopup
          handleDelete={handleDelete.mutate}
          title={t('home.clients.confirmDeactivate')}
          isLoading={handleDelete.isLoading}
        />
      </SimpleModal>

      <SimpleModal
        title={t('home.clients.activateClient')}
        show={showActive}
        setShow={setShowActive}
      >
        <ActivePopup
          handleActivate={handleActivate.mutate}
          title={t('home.clients.confirmActivate')}
          isLoading={handleActivate.isLoading}
        />
      </SimpleModal>
    </Box>
  );
};

export default Profile;
