import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import { MdLocationOn } from 'react-icons/md';
import { FormBody } from 'components/unauthenticated/shared';
import right from 'assets/images/right.png';
import { useTranslation } from 'react-i18next';
import defaultWorkshop from 'assets/images/defaultBranch.png';

const IsRating = ({ data }) => {
  const { t } = useTranslation();

  return (
    <Flex
      // w="400px"
      alignItems="center"
      justifyContent="center"
      mt="40px"
      // w="100vw"
      // h="100vh"
    >
      <FormBody boxShadow="0 8px 160px -100px #21B3E1">
        <Box>
          <Heading
            as="h2"
            fontWeight="bold"
            fontSize={['17px', '', '20px', '20px']}
            color="#151818"
          >
            {data?.data.tenant.name.en}
          </Heading>
          {/* <Flex mt="10px" alignItems="center">
            <Box as={MdLocationOn} me="5px" />
            <Text fontSize={['14px', '', '16px', '']}>{data?.data.title}</Text>
          </Flex> */}
        </Box>

        <Image
          src={
            data?.data.tenant.imagePath
              ? `${process.env.REACT_APP_API_BASE_URL}/uploads/image/` +
                data?.data.tenant.imagePath
              : defaultWorkshop
          }
          w="100%"
          h="198px"
          objectFit="cover"
          borderRadius="10px"
          mt="13px"
          mb="20px"
        />

        <Image src={right} mt="75px" mb="40px" mx="auto" />
        <Text textAlign="center" fontWeight="bold" color="#4A4A4A" mb="100px">
          {t('rating.thankYou')}
        </Text>
      </FormBody>
    </Flex>
  );
};

export default IsRating;
