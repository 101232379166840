import request from 'axios-interceptors';

export const getAllAttendance = ({ queryKey }) => {
  return request.get(
    `iam/users/all/attendance?searchKey=${queryKey[1]}&day=${queryKey[2]}&shift=${queryKey[3]}&branch=${queryKey[4]}`
  );
};

export const getAllPagesAttendance = ({ queryKey }) => {
  return request.get(
    `iam/users/all/attendance?shift=${queryKey[1]}&branch=${queryKey[2]}`
  );
};

export const getUserAttendance = ({ queryKey }) => {
  return request.get(
    `iam/users/attendance/${queryKey[1]}?month=${queryKey[2]}&shift=${queryKey[3]}&branch=${queryKey[4]}&range`
  );
};
