import { useState, useContext } from 'react';
import { userContext } from 'contexts/userContext';
import {
  Box,
  Flex,
  Grid,
  Text,
  Spinner,
  useToast,
  Button,
} from '@chakra-ui/react';
import {
  CustomInput,
  Map,
  CustomButton,
  Select,
  Countries,
  SimpleModal,
  ErrorMsg,
  Label,
  CustomUploadFile,
} from 'components/shared';
import {
  SaveDelete,
  DeletePopup,
  ActivePopup,
  CustomToast,
  PickTime,
} from 'components/authenticated/shared';
import moment from 'moment';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { HiOutlineLocationMarker } from 'react-icons/hi';

import { Formik, Form, FieldArray } from 'formik';
import { AddBranchSchema } from 'schemas/branch/AddBranchSchema';
import { useMutation, useQueryClient } from 'react-query';
import { updateBranch, deleteBranch, activateBranch } from 'apis/branch';
import Permission from 'helpers/permission';
import { useTranslation } from 'react-i18next';
import defaultImage from 'assets/images/defaultBranch.png';

const Profile = ({ data }) => {
  const { setUser, user } = useContext(userContext);

  const { id } = data;
  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const [read, setRead] = useState(false);
  const toast = useToast();

  const [showDelete, setShowDelete] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [uploadImageLoading, setUploadImageLoading] = useState(false);

  const [isThereSecondWorkingTime, setIsThereSecondWorkingTime] =
    useState(false);

  const [initCountry, setInitCountry] = useState({
    label: data.country,
    value: data.country,
  });
  const [marker, setMarker] = useState();
  const [country, setCountry] = useState({
    label: data.country,
    value: data.country,
  });

  const showDeleteModal = () => {
    setShowDelete(true);
  };

  const showActiveModal = () => {
    setShowActive(true);
  };

  const handleCountry = e => {
    setCountry(e);
  };

  function convertTo24HourTime(time12Hour) {
    const isAM = /AM/i.test(time12Hour);
    const isPM = /PM/i.test(time12Hour);

    if (isPM && !time12Hour.startsWith('12')) {
      return moment(time12Hour, 'hh:mm:ss a')
        .locale('en')
        .add('12', 'hours')
        .format('HH:mm:ss');
    } else if (isAM && !time12Hour.startsWith('12')) {
      return moment(time12Hour, 'hh:mm:ss a').locale('en').format('HH:mm:ss');
    } else if (isAM && time12Hour.startsWith('12')) {
      // Handle midnight (12:xx:xx AM)
      return '00' + time12Hour.slice(2);
    } else {
      return time12Hour;
    }
  }

  const handleDelete = useMutation(() => deleteBranch([data.id]), {
    onSuccess: result => {
      setShowDelete(false);
      data.deletedAt = new Date();

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateBranch')}
              description={t('toasts.deactivateBranch')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      const user = JSON.parse(localStorage.getItem('user'));
      const branches = user.user.branches;

      const newBranchesData = branches.filter(e => {
        return e.id != data.id;
      });

      localStorage.setItem(
        'user',
        JSON.stringify({
          user: { ...user.user, branches: newBranchesData },
          accessToken: user.accessToken,
        })
      );

      setUser(JSON.parse(localStorage.getItem('user')));
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.deactivateBranch')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleActivate = useMutation(() => activateBranch(data.id), {
    onSuccess: result => {
      setShowActive(false);
      data.deletedAt = null;

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateBranch')}
              description={t('toasts.activateBranch')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      const user = JSON.parse(localStorage.getItem('user'));
      const branches = user.user.branches;

      const newBranchesData = [...branches, result.data];

      localStorage.setItem(
        'user',
        JSON.stringify({
          user: { ...user.user, branches: newBranchesData },
          accessToken: user.accessToken,
        })
      );

      setUser(JSON.parse(localStorage.getItem('user')));
    },
    onError: result => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.activateBranch')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  const handleSubmit = useMutation(
    ({ values, country }) => updateBranch(values, country, data?.id),
    {
      onSuccess: async result => {
        await queryClient.invalidateQueries('getBranchById');
        setInitCountry({
          label: result.data.country,
          value: result.data.country,
        });

        setRead(false);

        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateBranch')}
                description={t('toasts.updateBranch')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });

        const user = JSON.parse(localStorage.getItem('user'));
        const branches = user.user.branches;

        const newBranchesData = branches.map(e => {
          let defaultBranch = JSON.parse(localStorage.getItem('defaultBranch'));

          if (result.data.id == defaultBranch.id) {
            localStorage.setItem(
              'defaultBranch',
              JSON.stringify({
                name: result.data.name.en,
                id: defaultBranch.id,
              })
            );
          }

          if (e.id == result.data.id) {
            return result.data;
          }
          return e;
        });

        localStorage.setItem(
          'user',
          JSON.stringify({
            user: { ...user.user, branches: newBranchesData },
            accessToken: user.accessToken,
          })
        );

        setUser(JSON.parse(localStorage.getItem('user')));
      },
      onError: result => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('toasts.titles.updateBranch')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    }
  );
  const branchData = data;
  return (
    <Box p="40px" maxW="880px">
      <Formik
        initialValues={{
          enName: data.name.en,
          arName: data.name.ar,
          street: data.street,
          city: data.city,
          countrySelected: data.country,
          mapSelected: data.mapSelected
            ? {
                lat: data.mapSelected.lat,
                lng: data.mapSelected.lng,
              }
            : {
                lat: branchData?.location.x,
                lng: branchData.location.y,
              },
          zipCode: data.zipCode,
          commercialNumber: data.commercialNumber,
          startTime1: data ? data?.startTime1 : '',
          endTime1: data ? data?.endTime1 : '',
          startTime2: data ? data?.startTime2 : null,
          endTime2: data ? data?.endTime2 : null,
          image: data ? data?.branchImageUrl : '',
        }}
        validationSchema={AddBranchSchema}
        onSubmit={values => {
          handleSubmit.mutate({
            values,
            country,
          });
        }}
      >
        {formik => (
          <Form>
            <CustomUploadFile
              defaultImage={formik.values.image || defaultImage}
              formikChange={e => formik.setFieldValue('image', e)}
              radius={false}
              disabled={!read}
              setUploadImageLoading={setUploadImageLoading}
              width="196px"
            />

            <Flex justifyContent="space-between" mb="24px">
              <Box w="full" me="30px">
                <CustomInput
                  type="text"
                  label={`${t('account.workshopName')} (EN)`}
                  placeholder={t('signup.enterWorkshopName')}
                  value={!read ? data.name.en : formik.values.enName}
                  onChange={formik.handleChange('enName')}
                  onBlur={formik.handleBlur('enName')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.enName && formik.touched.enName && (
                  <ErrorMsg text={t(formik.errors.enName)} />
                )}
              </Box>

              <Box w="full">
                <CustomInput
                  type="text"
                  label={`${t('account.workshopName')} (AR)`}
                  placeholder={t('signup.enterWorkshopName')}
                  value={!read ? data.name.ar : formik.values.arName}
                  onChange={formik.handleChange('arName')}
                  onBlur={formik.handleBlur('arName')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.arName && formik.touched.arName && (
                  <ErrorMsg text={t(formik.errors.arName)} />
                )}
              </Box>
            </Flex>

            <Box w="full" mb="24px">
              <CustomInput
                type="text"
                label={t('signup.commercialNumber')}
                placeholder={t('home.clients.enterCommercial')}
                value={
                  !read ? data.commercialNumber : formik.values.commercialNumber
                }
                onChange={formik.handleChange('commercialNumber')}
                onBlur={formik.handleBlur('commercialNumber')}
                isReadOnly={read ? false : true}
              />
              {formik.errors.commercialNumber &&
                formik.touched.commercialNumber && (
                  <ErrorMsg text={t(formik.errors.commercialNumber)} />
                )}
            </Box>
            <Box mb="24px">
              <Map
                buttonName={t('modals.chooseLocation')}
                errMsg={
                  formik.errors.mapSelected && formik.touched.mapSelected
                    ? true
                    : false
                }
                marker={marker}
                handleMarker={(values, country, city, street, zipcode) => {
                  setMarker(values);
                  formik.setFieldValue('mapSelected', values);
                  formik.setFieldValue('city', city);
                  formik.setFieldValue('countrySelected', country);
                  formik.setFieldValue('street', street);
                  formik.setFieldValue('zipCode', zipcode);
                }}
              />
            </Box>

            <Flex justifyContent="space-between">
              <Box w="full" me="30px">
                <Flex justifyContent="space-between">
                  <Box w="full">
                    {/* <Text fontSize="14px" mb="5px" fontWeight="bold">
                      {t('signup.country')}
                    </Text>

                    <Select
                      mb="24px"
                      h="60px"
                      label={t('signup.country')}
                      select={!read ? initCountry : country}
                      handleSelect={handleCountry}
                      selections={Countries.map(elm => ({
                        label: elm.name,
                        value: elm.name,
                      }))}
                    /> */}
                    <CustomInput
                      type="text"
                      label={t('signup.country')}
                      placeholder={t('home.clients.enterCity')}
                      value={formik.values.countrySelected}
                      disabled
                      isReadOnly
                      _disabled={{ opacity: '0.8', cursor: 'not-allowed' }}
                    />
                  </Box>
                </Flex>
              </Box>

              <Box w="full" mb="24px">
                <CustomInput
                  type="text"
                  label={t('signup.city')}
                  placeholder={t('home.clients.enterCity')}
                  value={!read ? data.city : formik.values.city}
                  onChange={formik.handleChange('city')}
                  onBlur={formik.handleBlur('city')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.city && formik.touched.city && (
                  <ErrorMsg text={t(formik.errors.city)} />
                )}
              </Box>
            </Flex>

            <Flex justifyContent="space-between" mb="24px">
              <Box w="full" me="30px">
                <CustomInput
                  type="text"
                  label={t('signup.street')}
                  placeholder={t('home.clients.enterStreet')}
                  value={!read ? data.street : formik.values.street}
                  onChange={formik.handleChange('street')}
                  onBlur={formik.handleBlur('street')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.street && formik.touched.street && (
                  <ErrorMsg text={t(formik.errors.street)} />
                )}
              </Box>
              <Box w="full">
                <CustomInput
                  type="number"
                  label={t('signup.zipCode')}
                  placeholder={t('home.clients.enterZipcode')}
                  value={!read ? data.zipCode : formik.values.zipCode}
                  onChange={formik.handleChange('zipCode')}
                  onBlur={formik.handleBlur('zipCode')}
                  isReadOnly={read ? false : true}
                />
                {formik.errors.zipCode && formik.touched.zipCode && (
                  <ErrorMsg text={t(formik.errors.zipCode)} />
                )}
              </Box>
            </Flex>

            <Box>
              <Label text={t('home.branches.workingTimes')} />
              <Flex justifyContent="space-between" mb="24px">
                <Grid
                  gridTemplateColumns="repeat(2,1fr)"
                  gap="25px"
                  // w={
                  //   isThereSecondWorkingTime || formik.values?.startTime2
                  //     ? '100%'
                  //     : '88%'
                  // }
                  w={read ? '88%' : '100%'}
                >
                  <Box>
                    <PickTime
                      defaultText={t('home.branches.startTime')}
                      intervals={30}
                      // maxTime={formik.values.checkoutTime}
                      // minTime={formik.values.checkinTime}
                      handleTime={value =>
                        formik.setFieldValue(
                          'startTime1',
                          convertTo24HourTime(value)
                        )
                      }
                      defaultTime={
                        data && new Date(moment(data?.startTime1, 'HH:mm:ss'))
                      }
                      disabled={read ? false : true}
                      _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                    />
                    {/* {errors.includes('clockIn') && (
                <ErrorMsg text={'This field is required'} />
              )} */}
                    {formik.errors.startTime1 && formik.touched.startTime1 && (
                      <ErrorMsg text={t(formik.errors.startTime1)} />
                    )}
                  </Box>

                  <Box>
                    <PickTime
                      handleTime={value =>
                        formik.setFieldValue(
                          'endTime1',
                          convertTo24HourTime(value)
                        )
                      }
                      defaultText={t('home.branches.endTime')}
                      intervals={30}
                      // minTime={formik.values.checkinTime}
                      // maxTime={formik.values.clockOut}
                      defaultTime={
                        data && new Date(moment(data?.endTime1, 'HH:mm:ss'))
                      }
                      disabled={read ? false : true}
                      _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                    />
                    {formik.errors.endTime1 && formik.touched.endTime1 && (
                      <ErrorMsg text={t(formik.errors.endTime1)} />
                    )}
                  </Box>
                </Grid>

                {read ? (
                  <Button
                    w="10%"
                    py="20px"
                    bg="bg.300"
                    border="none"
                    borderRadius="10px"
                    color="white"
                    title="Add another working time "
                    _hover={{
                      bg: 'bg.300',
                      color: 'white',
                      border: 'none',
                    }}
                    _focus={{ outline: 'none' }}
                    _active={{ bg: 'bg.400' }}
                    alignItems="center"
                    onClick={() => setIsThereSecondWorkingTime(true)}
                    disabled={
                      isThereSecondWorkingTime || formik.values?.startTime2
                    }
                  >
                    <Box as={AiOutlinePlus} fontSize="25px" />
                  </Button>
                ) : null}
              </Flex>
            </Box>

            {isThereSecondWorkingTime || formik.values?.startTime2 ? (
              <Flex justifyContent="space-between" mb="24px">
                <Grid
                  gridTemplateColumns="repeat(2,1fr)"
                  gap="25px"
                  w={read ? '88%' : '100%'}
                >
                  <Box>
                    <PickTime
                      defaultText={t('home.branches.startTime')}
                      intervals={30}
                      // maxTime={formik.values.checkoutTime}
                      // minTime={formik.values.checkinTime}
                      handleTime={value =>
                        formik.setFieldValue(
                          'startTime2',
                          convertTo24HourTime(value)
                        )
                      }
                      defaultTime={
                        data && formik.values?.startTime2 != null
                          ? new Date(moment(data?.startTime2, 'HH:mm:ss'))
                          : null
                      }
                      disabled={read ? false : true}
                      _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                    />
                    {/* {errors.includes('clockIn') && (
                <ErrorMsg text={'This field is required'} />
              )} */}
                    {formik.errors.startTime2 && formik.touched.startTime2 && (
                      <ErrorMsg text={t(formik.errors.startTime2)} />
                    )}
                  </Box>

                  <Box>
                    <PickTime
                      handleTime={value =>
                        formik.setFieldValue(
                          'endTime2',
                          convertTo24HourTime(value)
                        )
                      }
                      defaultText={t('home.branches.endTime')}
                      intervals={30}
                      // minTime={formik.values.checkinTime}
                      // maxTime={formik.values.clockOut}
                      defaultTime={
                        data && formik.values?.endTime2 != null
                          ? new Date(moment(data?.endTime2, 'HH:mm:ss'))
                          : null
                      }
                      disabled={read ? false : true}
                      _disabled={{ opacity: 1, cursor: 'not-allowed' }}
                    />
                    {formik.errors.endTime2 && formik.touched.endTime2 && (
                      <ErrorMsg text={t(formik.errors.endTime2)} />
                    )}
                  </Box>
                </Grid>

                {read ? (
                  <Button
                    w="10%"
                    py="20px"
                    bg="#ff2323"
                    border="none"
                    borderRadius="10px"
                    color="white"
                    title="Add another working time "
                    _hover={{
                      bg: 'red',
                      color: 'white',
                      border: 'none',
                    }}
                    _focus={{ outline: 'none' }}
                    _active={{ bg: 'red' }}
                    alignItems="center"
                    onClick={() => {
                      setIsThereSecondWorkingTime(false);
                      formik.setFieldValue('startTime2', null);
                      formik.setFieldValue('endTime2', null);
                    }}
                  >
                    <Box as={AiOutlineClose} fontSize="25px" />
                  </Button>
                ) : null}
              </Flex>
            ) : null}

            {Permission('Branches') == 'Edit' ? (
              !read ? (
                <CustomButton
                  bg
                  name={t('myProfile.update')}
                  w="100%"
                  display="block"
                  m="auto"
                  mt="30px"
                  onClick={() => setRead(true)}
                />
              ) : (
                <Box>
                  <Flex justifyContent="space-between" alignItems="center">
                    <CustomButton
                      bg
                      type="submit"
                      name={t('account.saveChanges')}
                      w="48%"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      loading={handleSubmit.isLoading && <Spinner ms="5px" />}
                      disabled={handleSubmit.isLoading}
                      mt="30px"
                    />
                    <CustomButton
                      name={
                        data.deletedAt
                          ? t('toasts.titles.activateBranch')
                          : t('toasts.titles.deactivateBranch')
                      }
                      w="48%"
                      display="block"
                      mt="30px"
                      onClick={() =>
                        data.deletedAt ? showActiveModal() : showDeleteModal()
                      }
                    />
                  </Flex>

                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    h="60px"
                    borderRadius="15px"
                    w="100%"
                    mt="24px"
                    textAlign="center"
                    color="text.1000"
                    fontWeight="bold"
                    cursor="pointer"
                    bg="gray.100"
                    onClick={() => setRead(false)}
                  >
                    <Text>{t('modals.cancel')}</Text>
                  </Flex>
                </Box>
              )
            ) : null}
          </Form>
        )}
      </Formik>

      <SimpleModal
        title={t('toasts.titles.deactivateBranch')}
        show={showDelete}
        setShow={setShowDelete}
      >
        <DeletePopup
          handleDelete={handleDelete.mutate}
          title={t('modals.deleteBranchText')}
          isLoading={handleDelete.isLoading}
        />
      </SimpleModal>

      <SimpleModal
        title={t('toasts.titles.activateBranch')}
        show={showActive}
        setShow={setShowActive}
      >
        <ActivePopup
          handleActivate={handleActivate.mutate}
          title={t('modals.confirmationActivateBranch')}
          isLoading={handleActivate.isLoading}
        />
      </SimpleModal>
    </Box>
  );
};

export default Profile;
