import request from 'axios-interceptors';

export const updatePart = (values, branch, productId) => {
  return request.put(`cat-log/products/updatePart/${productId}`, {
    name: values?.name,
    nameAr: values?.nameAr,
    type: 'part',
    partNumber: values.partNumber,
    branches: branch,
    price: values?.price,
    applicationPrice: values?.applicationPrice,
  });
};

export const deactivatePart = ({ products, branchId }) => {
  return request.post('cat-log/products/deletePart', {
    products,
    branchId,
  });
};

export const activatePart = ({
  price,
  applicationPrice,
  productId,
  branchId,
}) => {
  return request.post('cat-log/products/activatePart', {
    isActive: true,
    price,
    applicationPrice,
    productId,
    branchId,
  });
};
