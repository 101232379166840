import { Box, Text, Flex, Image } from '@chakra-ui/react';
import {
  HiOutlineMail,
  HiOutlinePhone,
  HiOutlineLocationMarker,
} from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { CardPopover } from 'components/authenticated/shared';
import Permission from 'helpers/permission';
import mailIcon from 'assets/images/icons/mail.svg';
import phoneIcon from 'assets/images/icons/phone.svg';
import mapIcon from 'assets/images/icons/Map.svg';
import { useTranslation } from 'react-i18next';

const Card = ({ item, setShowEdit, setShowDelete, setShowActive }) => {
  const { t } = useTranslation();

  return (
    <Box
      position="relative"
      bg={item.deletedAt ? 'gray.200' : 'white'}
      borderRadius="10px"
      p="20px"
      cursor="pointer"
      border={false ? '4px solid #21C6E1' : 'none'}
      _hover={{ bg: 'bg.100' }}
    >
      {true
        ? Permission('Clients') == 'Edit' && (
            <CardPopover
              item={item}
              setShowDelete={setShowDelete}
              setShowEdit={setShowEdit}
              setShowActive={setShowActive}
            />
          )
        : null}

      <Link to={item.id}>
        <Box mb="16px" fontWeight="bold">
          <Text
            fontSize="24px"
            maxW="210px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {item.name}
          </Text>
        </Box>

        <Flex fontSize="16" alignItems="center" mb="12px">
          <Image src={mailIcon} me="2" />
          <Text>
            {item.emailAddress
              ? item.emailAddress
              : t('common.noEmailProvided')}
          </Text>
        </Flex>

        <Flex fontSize="16" alignItems="center" mb="12px">
          <Image src={phoneIcon} me="2" />
          <Text>{item.mobileNumber}</Text>
        </Flex>

        <Flex fontSize="16" alignItems="center" mb="12px">
          <Image src={mapIcon} me="2" />
          <Text
            maxW="210px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {item.street && item.city && item.zipCode && item.country
              ? `${item.street}, ${item.city} ${item.zipCode}, ${item.country}`
              : t('common.noLocationProvided')}
          </Text>
        </Flex>
      </Link>
    </Box>
  );
};

export default Card;
