import { useContext } from 'react';
import { userContext } from 'contexts/userContext';
import { Grid, Image, Box } from '@chakra-ui/react';
import { Card } from 'components/authenticated/dashboard';
import { Loading } from 'components/authenticated/shared';
import {
  getBranchesCount,
  getEmployeesCount,
  getClientsCount,
} from 'apis/dashboard';
import { useQuery } from 'react-query';
import branchesIcon from 'assets/images/icons/dashboard-branches.svg';
import employeesIcon from 'assets/images/icons/dashboard-employees.svg';
import clientsIcon from 'assets/images/icons/dashboard-clients.svg';
import projectsIcon from 'assets/images/icons/dashboard-projects.svg';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AccessPage from 'helpers/AccessPage';

const Cards = ({ totalItems, ...props }) => {
  const { t } = useTranslation();
  const { user } = useContext(userContext);

  const { data: data1 } = useQuery(['getBranchesCount'], getBranchesCount);

  const { data: data2 } = useQuery(['getEmployeesCount'], getEmployeesCount);

  const { data: data3 } = useQuery(['getClientsCount'], getClientsCount);

  return (
    <Grid templateColumns="repeat(4, 1fr)" gap="8" {...props}>
      {AccessPage(user.user.roles, 'Branches') ? (
        <NavLink to="/branches">
          <Card
            text={t('home.dashboard.totalBranches')}
            number={data1?.data}
            icon={branchesIcon}
            bg="bg.1600"
          />
        </NavLink>
      ) : (
        <Card
          text={t('home.dashboard.totalBranches')}
          number="--"
          icon={branchesIcon}
          bg="bg.1600"
        />
      )}
      {AccessPage(user.user.roles, 'Employees') ? (
        <NavLink to="/employees">
          <Card
            text={t('home.dashboard.totalEmployees')}
            number={data2?.data}
            icon={employeesIcon}
            bg="bg.1700"
          />
        </NavLink>
      ) : (
        <Card
          text={t('home.dashboard.totalEmployees')}
          number="--"
          icon={employeesIcon}
          bg="bg.1700"
        />
      )}

      {AccessPage(user.user.roles, 'Clients') ? (
        <NavLink to="/clients">
          <Card
            text={t('home.dashboard.totalClients')}
            number={data3?.data}
            icon={clientsIcon}
            bg="bg.1800"
          />
        </NavLink>
      ) : (
        <Card
          text={t('home.dashboard.totalClients')}
          number="--"
          icon={clientsIcon}
          bg="bg.1800"
        />
      )}
      {AccessPage(user.user.roles, 'Projects') ? (
        <NavLink to="/projects">
          <Card
            text={t('home.dashboard.totalProjects')}
            number={totalItems}
            icon={projectsIcon}
            bg="bg.1900"
          />
        </NavLink>
      ) : (
        <Card
          text={t('home.dashboard.totalProjects')}
          number="--"
          icon={projectsIcon}
          bg="bg.1900"
        />
      )}
    </Grid>
  );
};

export default Cards;
