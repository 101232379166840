import { Flex, Text, Box, Tr, Td } from '@chakra-ui/react';
import { BsFiles } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const NoDataRow = ({ colSpan }) => {
  const { t } = useTranslation();

  return (
    <Tr bg="white">
      <Td colSpan={colSpan}>
        <Flex
          justifyContent="center"
          bg="white"
          alignItems="center"
          opacity="0.5"
        >
          <Box as={BsFiles} me="5px" />
          <Text>{t('account.noData')}</Text>
        </Flex>
      </Td>
    </Tr>
  );
};

export default NoDataRow;
