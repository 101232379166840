// import { useState } from 'react';
import { useContext } from 'react';
import { userContext } from 'contexts/userContext';
import { Box, Flex, chakra, Text } from '@chakra-ui/react';
import {
  SignupPersonalForm,
  SignupWorkshopForm,
  SignupConfirmForm,
  Success,
} from 'components/unauthenticated/signup';
import { motion, AnimatePresence } from 'framer-motion';
import * as React from 'react';
import { useState } from 'react';
import { wrap } from 'popmotion';
import { HiOutlineArrowLeft, HiOutlineArrowRight } from 'react-icons/hi';
import { FaGlobe } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Lang } from 'components/unauthenticated/shared';

const ChakraBox = chakra(motion.div);

const forms = [
  <SignupPersonalForm />,
  <SignupWorkshopForm />,
  <SignupConfirmForm />,
  <Success />,
];

const variants = {
  enter: direction => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
      transition: { duration: 0, type: 'tween' },
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    transition: { duration: 0.3, type: 'tween' },
  },
  exit: direction => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
      transition: { duration: 2, type: 'tween' },
    };
  },
};

const Dot = ({ ...props }) => {
  return <Box borderRadius="50%" me="8px" {...props} />;
};

const Signup = () => {
  const { language } = useContext(userContext);
  const navigate = useNavigate();

  const [[page, direction], setPage] = useState([0, 0]);
  const [personal, setPersonal] = useState({});
  const [workshop, setWorkshop] = useState({});
  const [success, setSuccess] = useState({});

  const Forms = wrap(0, forms.length, page);

  const paginate = newDirection => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <Box pos="relative" overflowX="hidden">
      <Box pos="relative">
        <Box
          as={language == 'en' ? HiOutlineArrowLeft : HiOutlineArrowRight}
          fontSize="24px"
          color="text.500"
          cursor="pointer"
          pos="absolute"
          top="30px"
          left="0"
          // visibility={isArrow ? 'visible' : 'hidden'}
          onClick={() => {
            if (page == 0) {
              navigate('/signin');
            } else {
              paginate(-1);
            }
          }}
        />
        <Lang />
      </Box>

      <AnimatePresence initial={false} custom={direction}>
        <ChakraBox
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          //   exit="exit"
          transition={{ duration: 2, type: 'tween' }}
        >
          {Forms == 0 && (
            <SignupPersonalForm
              paginate={paginate}
              setPersonal={setPersonal}
              personal={personal}
            />

            // <Success success={success} />
          )}
          {Forms == 1 && (
            <SignupWorkshopForm
              paginate={paginate}
              setWorkshop={setWorkshop}
              workshop={workshop}
            />
          )}
          {Forms == 2 && (
            <SignupConfirmForm
              paginate={paginate}
              personal={personal}
              workshop={workshop}
              setSuccess={setSuccess}
            />
          )}

          {Forms == 3 && <Success success={success} />}
        </ChakraBox>
        {page == forms.length - 1 ? null : (
          <Flex alignItems="center" justifyContent="center" mt="15px">
            {forms.map((e, i) => {
              if (i == forms.length - 1) {
                return;
              }
              return (
                <Dot
                  w={i == page ? '12px' : '8px'}
                  h={i == page ? '12px' : '8px'}
                  bg={i == page ? '#228CB8' : '#86C6E0'}
                />
              );
            })}
          </Flex>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default Signup;
