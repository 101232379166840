import { useState, useEffect } from 'react';
import { Box, Flex, Input, Text, Grid } from '@chakra-ui/react';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import { useMutation } from 'react-query';
import { deleteProductInProject } from 'apis/projects';

const AddService = ({
  id,
  searchId,
  text,
  setSerData,
  name,
  serData,
  formik,
  index,
  arrayHelpers,
  servicesArray,
  setServices,
  services,
  key,
  ...props
}) => {
  const { t } = useTranslation();

  const [data, setData] = useState({ id, name, quantity: 1, price: '' });

  useEffect(() => {
    setSerData([...serData, data]);
  }, [data]);

  // const { mutate } = useMutation(deleteProductInProject);

  const productId = servicesArray?.find(e => {
    return e.product.id == id;
  })?.id;

  return (
    <Flex p="10px" bg="#f9fafb" {...props}>
      <Box w="30%">{searchId}</Box>
      <Box w="35%">
        <Text
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          maxW="100px"
          title={text}
        >
          {text}
        </Text>
      </Box>
      <Box w="30%">
        <Input
          as={Field}
          type="number"
          h="30px"
          w="75px"
          p="10px"
          _focus={{}}
          name={name}
        />
        {formik.errors &&
          formik.errors.services &&
          formik.errors.services[index] &&
          formik.errors.services[index].price &&
          formik.touched &&
          formik.touched.services &&
          formik.touched.services[index] &&
          formik.touched.services[index].price && (
            <Box fontSize="14px" color="red">
              {t('modals.enterPrice')}
            </Box>
          )}
      </Box>
      <Grid
        w="30px"
        h="30px"
        bg="bg.300"
        cursor="pointer"
        placeItems="center"
        borderRadius="50%"
        _focus={{}}
        _hover={{ bg: '#30d2ed' }}
        _active={{ bg: 'bg.400' }}
        color="white"
        onClick={() => {
          setServices(
            services.filter(e => {
              return e.value != id;
            })
          );

          // mutate(productId);

          arrayHelpers.remove(index);
        }}
      >
        <Box as={AiOutlineClose} />
      </Grid>
    </Flex>
  );
};

export default AddService;
