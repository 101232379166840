import { useState, useRef, useEffect } from 'react';
import {
  Table as T,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  Checkbox,
} from '@chakra-ui/react';
import { FaHourglassEnd } from 'react-icons/fa';

const Table = ({
  theadHeight,
  theadBg,
  headers,
  children,
  ThWidths,
  setIsCheckAll,
  setIsCheck,
  isCheckAll,
  list,
  length,
  isCheck,
  tableHeadPadding = '25px',
  overflowContainer = 'auto',
  ...props
}) => {
  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map(li => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const ref = useRef(null);

  useEffect(() => {
    if (ref?.current?.checked && isCheck.length == 0) {
      setIsCheckAll(false);
    }
    if (isCheck) {
      if (isCheck?.length == length && isCheck?.length > 0) {
        setIsCheckAll(true);
      }
    }
  }, [isCheck]);

  return (
    <TableContainer overflowX={overflowContainer} overflowY="visible">
      <T variant="simple" bg="white" borderRadius="10px" {...props}>
        <Thead h={theadHeight}>
          <Tr bg={theadBg}>
            {headers.map((header, i) => {
              if (header === 'check')
                return (
                  <Th key={i}>
                    <Checkbox
                      colorScheme="cyan"
                      id="selectAll"
                      onChange={e => handleSelectAll(e)}
                      isChecked={isCheckAll}
                      ref={ref}
                      isIndeterminate={
                        length > isCheck?.length && isCheck?.length > 0
                      }
                    ></Checkbox>
                  </Th>
                );
              else if (header === 'option') return <Th key={i}></Th>;

              return (
                <Th
                  key={i}
                  textTransform="capitalize"
                  fontSize="16px"
                  w={ThWidths ? ThWidths[i] : 'initial'}
                  padding={tableHeadPadding}
                >
                  {header}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </T>
    </TableContainer>
  );
};

export default Table;
