import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  Checkbox,
  Input,
} from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';

const MultiSelectSearch = ({
  label,
  selections,
  isOpen,
  select,
  handleSelect,
  isDisabled,
  isLoadingSelections,
  disapledCheck,
  labelForMulti,
  labelForSingle,
  ...props
}) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const filteredSelections = selections?.filter(item =>
    item.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  function keepInputFocused() {
    inputRef.current.focus();
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchValue, isMenuOpen]);
  // Call the function to set focus every 1000 milliseconds (1 second)
  //   setInterval(keepInputFocused, 500);

  return (
    <Box pos="relative" {...props}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsMenuOpen(false);
        }}
      >
        <>
          <Button
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            h="60px"
            border="1px solid #BFCFD6"
            borderColor="#BFCFD6"
            borderRadius="10px"
            outline="none"
            bg="transparent"
            color="text.1100"
            fontWeight="normal"
            _focus={{
              border: '1px solid #a5c6d5',
              boxShadow: 'none',
              bg: 'transparent',
            }}
            _active={{
              bg: 'transparent',
            }}
            _hover={{
              bg: 'transparent',
            }}
            _disabled={{
              opacity: 1,
              cursor: 'not-allowed',
            }}
            isDisabled={isDisabled}
            onClick={() => setIsMenuOpen(true)}
          >
            <Flex alignItems="center" justifyContent="space-between" w="100%">
              {select?.length > 0 ? (
                <Text>
                  {select.length > 1
                    ? select.length + ' ' + labelForMulti
                    : select.length +
                      ' ' +
                      (labelForSingle != undefined ? labelForSingle : label)}
                </Text>
              ) : (
                <Text>{label}</Text>
              )}

              <Box as={MdKeyboardArrowDown} color="black" />
            </Flex>
          </Button>

          {isMenuOpen ? (
            <Box
              maxH="200px"
              overflowY="auto"
              bg="white"
              pos="absolute"
              zIndex="11"
              boxShadow="0 1px 2px 0 rgba(0, 0, 0, 0.05)"
              border="1px solid #BFCFD6"
              borderRadius="5px"
              top="calc(100% + 5px)"
            >
              {/* Search inpu(t field */}
              <Box p="10px">
                <Input
                  type="text"
                  ref={inputRef}
                  onChange={e => setSearchValue(e.target.value)}
                  value={searchValue}
                  placeholder="Search..."
                  _focus={{}}
                />
              </Box>

              {isLoadingSelections && (
                <Box
                  display="block"
                  textAlign="center"
                  isDisabled={true}
                  _disabled={{ opacity: '0.7', cursor: 'default' }}
                  _active={{}}
                >
                  {t('common.loading')}...
                </Box>
              )}

              {filteredSelections?.map((e, index) => {
                return (
                  <Box key={e.value} closeOnSelect={false} p="0">
                    <Box width="full" height="full">
                      <Checkbox
                        width="full"
                        height="full"
                        p="10px"
                        isChecked={
                          select?.filter(elm => elm.value === e.value).length >
                          0
                        }
                        onChange={event => {
                          handleSelect(e, event.target.checked, index);
                          inputRef.current.focus();
                        }}
                        isDisabled={disapledCheck}
                      >
                        <Text
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          maxW="200px"
                        >
                          {e.label}
                        </Text>
                      </Checkbox>
                    </Box>
                  </Box>
                );
              })}
              {filteredSelections?.length === 0 && (
                <Box
                  display="block"
                  textAlign="center"
                  isDisabled={true}
                  _disabled={{ opacity: '0.7', cursor: 'default' }}
                  _active={{}}
                  py="15px"
                  pt="5px"
                >
                  {t('account.noData')}
                </Box>
              )}
            </Box>
          ) : null}
        </>
      </OutsideClickHandler>
    </Box>
  );
};

export default MultiSelectSearch;
