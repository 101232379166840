import { useState, useRef, useEffect, useContext } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { Loading, Error } from 'components/authenticated/shared';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import { CustomDate } from 'components/authenticated/shared';
import { GrNext, GrPrevious } from 'react-icons/gr';
import { getAllAppointments } from 'apis/appointment';
import { useQuery } from 'react-query';
import moment from 'moment';
import { userContext } from 'contexts/userContext';
import 'styles/calendar.scss';

const Calendar = ({
  isLoading,
  isError,
  isSuccess,
  data,
  setDay,
  ...props
}) => {
  const [date, setDate] = useState(new Date());
  const [start, setStart] = useState(new Date());
  const { user, branchFilter } = useContext(userContext);
  const calendarComponentRef = useRef();

  const [branch, setBranch] = useState(branchFilter);

  const handleDateClick = arg => {
    // bind with an arrow function
    setDay(
      moment(new Date(arg.dateStr)).utc().locale('en').format('YYYY-MM-DD')
    );
  };

  useEffect(() => {
    calendarComponentRef?.current?.getApi().gotoDate(date);
    // setStart(date);
  }, [date]);

  const events = data?.data.map(e => {
    return {
      title: e.customer.name,
      date: moment(new Date(e.dueDate)).utc().locale('en').format('YYYY-MM-DD'),
      color: '#EAF4FF',
      textColor: '#1BA3EF',
    };
  });

  return (
    <Box {...props}>
      <Flex mb="15px" dir="ltr">
        <Button
          p="0"
          minW="initial"
          me="10px"
          _focus={{}}
          _active={{}}
          _hover={{}}
          onClick={() => {
            calendarComponentRef.current.getApi().prev();
            setStart(calendarComponentRef.current.getApi().getDate());
          }}
        >
          <Box as={GrPrevious} />
        </Button>

        <CustomDate
          setDate={setDate}
          start={start}
          showMonthYearPicker
          border="1px solid #BFCFD6"
        />

        <Button
          p="0"
          minW="initial"
          ms="10px"
          _focus={{}}
          _active={{}}
          _hover={{}}
          onClick={() => {
            calendarComponentRef.current.getApi().next();
            setStart(calendarComponentRef.current.getApi().getDate());
          }}
        >
          <Box as={GrNext} />
        </Button>
      </Flex>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        dateClick={handleDateClick}
        initialView="dayGridMonth"
        viewClassNames="viewClassNames"
        eventClassNames="eventClassNames"
        initialDate={date}
        ref={calendarComponentRef}
        headerToolbar={false}
        // headerToolbar={false}
        dayMaxEvents={(true, 2)}
        events={events}
        nowIndicator="true"
        selectable="true"
        showNonCurrentDates="false"
        contentHeight="600px"
      />
    </Box>
  );
};

export default Calendar;
