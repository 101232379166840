import { useState, useEffect } from 'react';
import { Box, Grid, Text, Image, useToast } from '@chakra-ui/react';
import { checkEmailTokenValidity } from 'apis/iam/Login';
import { sendVerification } from 'apis/iam/Signup';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { FormBody } from 'components/unauthenticated/shared';
import { CustomToast } from 'components/authenticated/shared';
import right from 'assets/images/right.png';
import { RiErrorWarningLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

const EmailVerification = () => {
  const toast = useToast();
  const { token } = useParams();
  const [errors, setErrors] = useState();

  const { mutate, isSuccess } = useMutation(checkEmailTokenValidity, {
    onError: err => {
      setErrors(err.response.data.errors);
    },
  });

  useEffect(() => {
    mutate(token);
  }, []);

  const { mutate: resendEmail } = useMutation(sendVerification, {
    onSuccess: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title="Send Email"
              description="check your email"
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  if (isSuccess) {
    return (
      <Grid placeItems="center" minH="100vh">
        <FormBody boxShadow="0 8px 160px -60px #21B3E1">
          <Image src={right} mb="40px" mx="auto" />

          <Text
            textAlign="center"
            color="#4A4A4A"
            mb="5px"
            fontSize="20px"
            fontWeight="bold"
          >
            Thank you for verifying your email!
          </Text>
          <Text textAlign="center" color="#4A4A4A" fontSize="14px">
            Your accout is ready, you can loging from{' '}
            <Box
              as={Link}
              to="/"
              color="text.200"
              cursor="pointer"
              fontWeight="bold"
            >
              here
            </Box>
          </Text>
        </FormBody>
      </Grid>
    );
  }

  return (
    <Grid placeItems="center" minH="100vh">
      <FormBody boxShadow="0 8px 160px -60px #21B3E1">
        {/* <Image src={right} mt="75px" mb="40px" mx="auto" /> */}
        <Box
          as={RiErrorWarningLine}
          color="#f7d900"
          fontSize="100px"
          mb="40px"
          mx="auto"
        />
        <Text
          textAlign="center"
          color="#4A4A4A"
          mb="5px"
          fontSize="20px"
          fontWeight="bold"
        >
          Your link has expired!
        </Text>

        {/* that is i will show if only token is expired */}
        {errors?.email ? (
          <Text textAlign="center" color="#4A4A4A" fontSize="14px">
            <Text
              as="span"
              color="text.200"
              cursor="pointer"
              fontWeight="bold"
              onClick={() => resendEmail(errors?.email)}
            >
              Resend
            </Text>{' '}
            email verification link.
          </Text>
        ) : null}
      </FormBody>
    </Grid>
  );
};

export default EmailVerification;
