import { useState } from 'react';
import {
  Tr,
  Td,
  Checkbox,
  Flex,
  Text,
  useToast,
  Box,
  Spinner,
  Link,
  Tooltip,
} from '@chakra-ui/react';
import {
  DeletePopup,
  CustomPopover,
  CustomToast,
} from 'components/authenticated/shared';
import { CustomButton, SimpleModal } from 'components/shared';
import { ProjectPopup } from 'components/authenticated/projects';
import { useMutation } from 'react-query';
import { deleteProject } from 'apis/projects';
import { issueInvoice } from 'apis/invoice/invoice';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { AiFillSetting, AiFillPrinter } from 'react-icons/ai';
import { motion } from 'framer-motion';

const RowProject = ({
  item,
  index,
  setIsCheck,
  isCheck,
  isCheckExist,
  refetch,
  ...props
}) => {
  const { t } = useTranslation();

  const toast = useToast();

  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const handleClick = e => {
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter(item => item !== id));
    }
  };

  const services = item.invoice.invoiceProducts.filter(
    elm => elm.type === 'service'
  );

  const parts = item.invoice.invoiceProducts.filter(elm => elm.type === 'part');

  const { mutate, isLoading: loadingDelete } = useMutation(deleteProject, {
    onSuccess: () => {
      setShowDelete(false);
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.removeProject')}
              description={t('toasts.removeProject')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      refetch();
    },
  });

  const { mutate: mutateIssueInvoice, isLoading: loadingIssueInvoice } =
    useMutation(issueInvoice, {
      onSuccess: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('accounting.issueInvoice')}
                description={t('accounting.issuedSucces')}
                status="success"
                onClose={props.onClose}
              />
            );
          },
        });

        refetch();
      },
      onError: () => {
        toast({
          render: props => {
            return (
              <CustomToast
                title={t('accounting.issueInvoice')}
                description={t('toasts.error')}
                status="error"
                onClose={props.onClose}
              />
            );
          },
        });
      },
    });

  const LoadingAnimation = () => {
    const dotVariants = {
      hidden: { opacity: 0, scale: 0 },
      visible: { opacity: 1, scale: 0.8 },
    };

    const trasnitions = [
      {
        duration: 0.5,
        repeat: Infinity,
        repeatType: 'reverse',
      },
      {
        duration: 0.5,
        repeat: Infinity,
        repeatType: 'reverse',
        delay: 0.2,
      },
      {
        duration: 0.5,
        repeat: Infinity,
        repeatType: 'reverse',
        delay: 0.4,
      },
    ];

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginInlineStart: '5px',
        }}
      >
        {trasnitions.map(e => {
          return (
            <motion.div
              style={{
                width: '5px',
                height: '5px',
                borderRadius: '50%',
                background: 'black',
              }}
              variants={dotVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
              transition={e}
            ></motion.div>
          );
        })}
      </div>
    );
  };

  return (
    <Tr {...props}>
      {isCheckExist && (
        <Td>
          <Checkbox
            colorScheme="cyan"
            id={item.id}
            onChange={e => handleClick(e)}
            isChecked={isCheck.includes(item.id)}
          ></Checkbox>
        </Td>
      )}

      <Td textTransform="uppercase">{item.searchId}</Td>
      <Td>{item.customer?.mobileNumber}</Td>
      <Td>
        <Tooltip
          hasArrow
          borderRadius="5px"
          label={
            <Box>
              <Text mb="2" textAlign="center">
                {t('home.projects.servicesList')}
              </Text>

              {services.map((elm, ind) => (
                <Text>
                  {ind + 1}:{' '}
                  {/* {language == 'en' ? elm?.name : elm.branch.name.ar} */}
                  {elm?.product?.name}
                </Text>
              ))}
            </Box>
          }
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            bg="bg.800"
            color="text.200"
            borderRadius="10px"
            w="32px"
            h="32px"
            fontWeight="bold"
            fontSize="14px"
            cursor="default"
          >
            <Text>{services?.length}</Text>
          </Flex>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip
          hasArrow
          borderRadius="5px"
          label={
            <Box>
              <Text mb="2" textAlign="center">
                {t('home.projects.partsList')}
              </Text>

              {parts.map((elm, ind) => (
                <Text>
                  {ind + 1}:{' '}
                  {/* {language == 'en' ? elm?.name : elm.branch.name.ar} */}
                  {elm?.product?.name}
                </Text>
              ))}
            </Box>
          }
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            bg="bg.800"
            color="text.200"
            borderRadius="10px"
            w="32px"
            h="32px"
            fontWeight="bold"
            fontSize="14px"
            cursor="default"
          >
            <Text>{parts?.length}</Text>
          </Flex>
        </Tooltip>
      </Td>
      <Td>
        <Flex>
          {item.invoice.total.toFixed(2)}
          <Text fontSize="12px" color="text.1100" p="0.5">
            SR
          </Text>
        </Flex>
      </Td>
      <Td>
        {moment(item.endAt).locale(t('common.lang')).format('DD-MM-YYYY')}
      </Td>
      {item?.invoice?.status != 'draft' ? (
        <Td>
          {item?.invoice?.pdfInvoiceUrl ? (
            <Flex>
              <Link
                ms="15px"
                href={
                  item?.invoice?.pdfInvoiceUrl
                    ? item?.invoice?.pdfInvoiceUrl
                    : null
                }
                target="_blank"
                download="file-1"
                _active={{}}
                _focus={{}}
              >
                <Box
                  as={AiFillPrinter}
                  cursor="pointer"
                  fontSize="20px"
                  color="text.200"
                />
              </Link>
            </Flex>
          ) : (
            <Flex>
              <Text>processing </Text>
              <LoadingAnimation />
              {/* <Spinner ms="5px" /> */}
            </Flex>
          )}
        </Td>
      ) : (
        <Td>
          <Flex alignItems="center" h="100%">
            <CustomButton
              name={t('accounting.issueInvoice')}
              onClick={() => mutateIssueInvoice(item?.invoice?.id)}
              bg
              h="45px"
              display="flex"
              alignItems="center"
              disabled={loadingIssueInvoice}
              loading={loadingIssueInvoice ? <Spinner ms="5px" /> : null}
            />
          </Flex>
        </Td>

        // <Box
        //   onClick={() => mutateIssueInvoice(item?.invoice?.id)}
        //   cursor="pointer"
        //   textAlign="center"
        //   my="10px"
        //   textTransform="capitalize"
        // >
        //   Issue Invoice
        // </Box>
      )}

      {isCheckExist && item?.invoice?.status != 'completed' && (
        <Td>
          <CustomPopover
            setShowDelete={setShowDelete}
            setShowEdit={setShowEdit}
            pos="relative"
            inset="0"
            menuWidth="120px"
          />
        </Td>
      )}

      <SimpleModal
        title={t('modals.editProject')}
        show={showEdit}
        setShow={setShowEdit}
      >
        <ProjectPopup
          item={item}
          setShow={setShowEdit}
          refetchProjects={refetch}
        />
      </SimpleModal>

      <SimpleModal
        title={t('modals.deleteProject')}
        show={showDelete}
        setShow={setShowDelete}
      >
        <DeletePopup
          title={t('modals.deleteProjectText')}
          id={item.id}
          setShow={setShowDelete}
          handleDelete={() => mutate([item.id])}
          isLoading={loadingDelete}
          buttonText="delete"
        />
      </SimpleModal>
    </Tr>
  );
};

export default RowProject;
