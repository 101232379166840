import { useState } from 'react';
import {
  Box,
  Collapse,
  useDisclosure,
  Flex,
  Text,
  Grid,
  useToast,
  Image,
} from '@chakra-ui/react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { AiOutlineCar } from 'react-icons/ai';
import { HiOutlinePhone } from 'react-icons/hi';
import { CustomButton, SimpleModal } from 'components/shared';
import { DeletePopup, CustomToast } from 'components/authenticated/shared';
import { TaskPopup } from 'components/authenticated/tasks';
import { useMutation } from 'react-query';
import { deleteWorkflow } from 'apis/invoice/Workflow';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/small-logo.svg';

const Card = ({ itemInfo, refetchList, refetchAllData }) => {
  const { t } = useTranslation();

  const toast = useToast();
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const { isOpen, onToggle } = useDisclosure();
  const {
    customer,
    dueDate,
    carDetails,
    title,
    description,
    id,
    isFromAerbag,
    invoice,
    searchId,
    appClient,
  } = itemInfo;

  const prouducts = invoice?.invoiceProducts;

  const { mutate, isLoading } = useMutation(deleteWorkflow, {
    onSuccess: async () => {
      setShowDelete(false);

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.removeTask')}
              description={t('toasts.removeTask')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });

      refetchList();
      refetchAllData();
    },
    onError: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.removeTask')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Box
      w="full"
      bg="white"
      mb="20px"
      borderRadius="10px"
      p="3"
      color="GrayText.2100"
      border={isFromAerbag ? '3px solid rgb(27, 163, 239)' : null}
      borderColor="#21C6E1"
    >
      <Flex
        justifyContent="space-between"
        alignItems="flex-start"
        cursor="pointer"
        onClick={onToggle}
      >
        <Box>
          {isFromAerbag ? (
            <Flex>
              <Image src={logo} w="30px" me="10px" />
              <Text fontSize="16px" fontWeight="bold" mb="10px">
                AerBag App
              </Text>
            </Flex>
          ) : (
            <Text fontSize="16px" fontWeight="bold" mb="10px">
              {customer.name}
            </Text>
          )}

          <Text fontSize="12px">
            {moment(new Date(dueDate))
              .utc()
              .locale(t('common.lang'))
              .format('DD MMMM YYYY -  h A')}
          </Text>
        </Box>

        <Box
          as={MdKeyboardArrowDown}
          transition=".3s"
          transform={isOpen ? 'rotate(180deg)' : 'rotate(0)'}
          fontSize="20px"
        />
      </Flex>

      <Collapse in={isOpen} animateOpacity w="100%">
        <Box pt="10px">
          <Box fontSize="14px" fontWeight="medium" color="text.600">
            <Flex justifyContent="flex-start" alignItems="center">
              <Box as={AiOutlineCar} color="text.1100"></Box>
              <Flex justifyContent="flex-start" alignItems="center" ms="5px">
                <Text>{carDetails?.carModel}</Text>
                <Box h="15px" w="1.5px" bg="text.1100" mx="5px"></Box>
                <Text>{carDetails?.carManufactur}</Text>
                <Box h="15px" w="1.5px" bg="text.1100" mx="5px"></Box>
                <Text>{carDetails?.carYear}</Text>
              </Flex>
            </Flex>
            <Flex justifyContent="flex-start" alignItems="center">
              <Box as={HiOutlinePhone} color="text.1100"></Box>
              <Text ms="5px">{customer?.mobileNumber}</Text>
            </Flex>
          </Box>

          <Box
            bg="bg.3000"
            borderRadius="5px"
            px="3px"
            py="1"
            my="15px"
            minW="121px"
          >
            <Text
              textAlign="center"
              textTransform="uppercase"
              fontSize="14px"
              color="text.1000"
              fontWeight="semibold"
            >
              {searchId}
            </Text>
          </Box>

          <Box color="text.600">
            <Text fontSize="12px" color="text.1100">
              {t('home.workflow.description')}
            </Text>
            {isFromAerbag ? (
              <>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Client Name:
                  </Text>{' '}
                  <Text fontSize="14px">{appClient?.name}</Text>
                </Text>
                <Text fontSize="14px">
                  <Text as="span" fontWeight="bold">
                    Mobile:
                  </Text>{' '}
                  <Text fontSize="14px">{appClient?.mobileNumber}</Text>
                </Text>
                <Text fontSize="14px" fontWeight="bold">
                  Services
                </Text>
                {prouducts?.map((e, i) => {
                  return (
                    <>
                      <Text>
                        {e?.quantity}x {e?.product?.name}
                      </Text>
                      {/* <Text>{e?.type}</Text> */}
                      {/* <Text>{e?.quantity}</Text> */}
                    </>
                  );
                })}
              </>
            ) : (
              <Text fontSize="14px">{description}</Text>
            )}
          </Box>
          {isFromAerbag ? null : (
            <Grid
              gridTemplateColumns="repeat(2,1fr)"
              columnGap={6}
              alignItems="center"
              mb="10px"
            >
              <CustomButton
                borderRadius="7px"
                fontWeight="medium"
                fontSize="16px"
                h="35px"
                name={t('account.delete')}
                display="block"
                m="auto"
                mt="30px"
                onClick={() => setShowDelete(true)}
              />
              <CustomButton
                bg
                borderRadius="7px"
                fontWeight="medium"
                fontSize="16px"
                h="35px"
                name={t('modals.edit')}
                display="block"
                m="auto"
                mt="30px"
                onClick={() => setShowEdit(true)}
              />
            </Grid>
          )}

          {/* 
          <CustomButton
            bg
            fontSize
            borderRadius="7px"
            fontWeight="medium"
            fontSize="16px"
            h="35px"
            name="Print"
            display="block"
            m="auto"
            // onClick={() => setRead(true)}
          /> */}
        </Box>
      </Collapse>

      <SimpleModal
        title={t('modals.editTask')}
        show={showEdit}
        setShow={setShowEdit}
      >
        <TaskPopup
          itemInfo={itemInfo}
          setShow={setShowEdit}
          refetchAllData={refetchAllData}
          refetchList={refetchList}
        />
      </SimpleModal>

      <SimpleModal
        title={t('modals.deactivateTask')}
        show={showDelete}
        setShow={setShowDelete}
      >
        <DeletePopup
          buttonText="delete"
          title={t('modals.confirmDeactivateTask')}
          handleDelete={() => mutate([id])}
          isLoading={isLoading}
        />
      </SimpleModal>
    </Box>
  );
};

export default Card;
