import { Flex, Box } from '@chakra-ui/react';
import Side from './side';

const Unauthenticated = ({ children }) => {
  return (
    <Flex>
      <Side />
      <Box py="55px" px="55px" w="calc(100% - 476px)">
        {children}
      </Box>
    </Flex>
  );
};

export default Unauthenticated;
