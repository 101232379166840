import { Box, Flex, Text } from '@chakra-ui/react';

const StatusCard = ({ index, length, name, lineColor, ...props }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      bg="green"
      w="31px"
      p="5px"
      fontSize="10px"
      borderRadius="5px"
      me={index == length - 1 ? '0' : '1px'}
      border="1px solid #EAF4FF"
      {...props}
    >
      <Text textAlign="center">{name}</Text>
      <Box w="85%" h="5px" bg={lineColor} m="auto" my="2px" />
    </Flex>
  );
};

export default StatusCard;
