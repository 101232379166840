import { Box, Image, Tr, Td, Text, Grid, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { StatusBox } from 'components/authenticated/shared';

const AbsentRow = ({ shift, i }) => {
  const { t } = useTranslation();

  return (
    <Grid
      gridTemplateColumns="2fr 2fr 2fr 2fr "
      borderTop={i != 0 && '1px solid #edf2f7'}
      py="22px"
      paddingInlineEnd="45px"
    >
      <Box>{shift}</Box>
      <StatusBox text={t('status.absent')} status="to_be_assigned" />

      {/* <Box
        as="span"
        fontSize="12px"
        bg="rgba(237,98,98,0.1)"
        py="10px"
        borderRadius="10px"
        color="text.2800"
        display="Grid"
        placeItems="center"
        w="100px"
        fontWeight="bold"
      >
        {t('status.absent')}
      </Box> */}
    </Grid>
  );
};

export default AbsentRow;
