import { Suspense, useState } from 'react';
import { Table } from 'components/authenticated/shared';
import { CustomButton } from 'components/shared';
import { MdAdd } from 'react-icons/md';
import { Text, Box, Tr, Td } from '@chakra-ui/react';
import { getTickets } from 'apis/tickets';
import { useQuery } from 'react-query';
import { Loading, Error, Nodata } from 'components/authenticated/shared';
import ProjectRow from './project-row';
import Chat from './chat';
import { SimpleModal } from 'components/shared';
import CreateTicket from './create-ticket-modal';
import { useTranslation } from 'react-i18next';

// import ProjectRow from './project-row';

const Tickets = () => {
  const { t } = useTranslation();

  const user = localStorage.getItem('user');
  const userId = JSON.parse(user).user.id;
  const [show, setShow] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [chatDetails, setChatDetials] = useState(null);
  const [index, setIndex] = useState(null);

  const { data, isLoading, isError, refetch } = useQuery(
    'getTickets',
    getTickets
  );

  return (
    <Box
      p="40px"
      // minH={data?.data.tasks.length > 0 ? 'initial' : '600px'}
      minH="600px"
    >
      {!showChat ? (
        <>
          <CustomButton
            bg
            mb="15px"
            name={t('ticket.addNewTicket')}
            icon={MdAdd}
            onClick={() => setShow(true)}
          />
          <>
            <Table
              headers={[
                t('modals.id'),
                t('ticket.ticketType'),
                t('project.subject'),
                t('modals.date'),
                t('modals.status'),
              ]}
              bg="bg.1200"
              theadHeight="71px"
              overflowContainer={[
                'initial',
                'initial',
                'auto',
                'auto',
                'initial',
              ]}
            >
              {/* {data?.data.tasks.map((item, index) => {
            return <ProjectRow item={item} index={index} />;
          })} */}
              {data?.data.map((e, i) => {
                return (
                  <ProjectRow
                    type={e.type}
                    status={e.status}
                    description={e.description}
                    createdAt={e.createdAt}
                    setShowChat={setShowChat}
                    id={e.searchId}
                    onClick={() => {
                      setShowChat(true);
                      setChatDetials(e);
                      setIndex(e.id);
                    }}
                  />
                );
              })}
            </Table>
            <SimpleModal
              title={t('ticket.addTicket')}
              show={show}
              setShow={setShow}
            >
              <CreateTicket setShow={setShow} refetch={refetch} />
            </SimpleModal>
          </>
        </>
      ) : (
        <Suspense fallback={<Box>loading...</Box>}>
          <Chat
            setShowChat={setShowChat}
            details={chatDetails}
            refetch={refetch}
            data={
              data?.data[data?.data.findIndex(e => e.id == index)]?.comments
            }
          />
        </Suspense>
      )}
    </Box>
  );
};

export default Tickets;
