import { useState } from 'react';
import { Box, Grid, useToast, Text, Spinner, Flex } from '@chakra-ui/react';
import { CustomInput, CustomButton } from 'components/shared';
import { CustomToast } from 'components/authenticated/shared';
import { Formik } from 'formik';
import { NewPasswordSchema } from 'schemas/change-password';
import { ErrorMsg } from 'components/shared';
import { changePasswordRequest } from 'apis/iam/Login';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

const ChangePassowrdModal = () => {
  const { t } = useTranslation();

  const toast = useToast();
  const [backErrors, setBackErrors] = useState(null);

  const { mutate, isLoading } = useMutation(changePasswordRequest, {
    onSuccess: () => {
      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.changePassword')}
              description={t('toasts.changePassword')}
              status="success"
              onClose={props.onClose}
            />
          );
        },
      });
    },
    onError: err => {
      setBackErrors(err.response.data.errors);

      toast({
        render: props => {
          return (
            <CustomToast
              title={t('toasts.titles.changePassword')}
              description={t('toasts.error')}
              status="error"
              onClose={props.onClose}
            />
          );
        },
      });
    },
  });

  return (
    <Box>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        validationSchema={NewPasswordSchema}
        onSubmit={async values => {
          mutate(values);
        }}
        validateOnMount
      >
        {formik => (
          <Box as="form" onSubmit={formik.handleSubmit}>
            {backErrors?.oldPassword.isMatch == 'Invalid old password' && (
              <Flex justifyContent="center">
                <ErrorMsg text={t('resetPassword.invalidOldPassword')} />
              </Flex>
            )}

            <Box mb="20px">
              <CustomInput
                type="password"
                placeholder={t('resetPassword.reWritePassword')}
                value={formik.values.oldPassword}
                onChange={formik.handleChange('oldPassword')}
                onBlur={formik.handleBlur('oldPassword')}
              />
              {formik.errors.oldPassword && formik.touched.oldPassword && (
                <ErrorMsg text={t(formik.errors.oldPassword)} />
              )}
            </Box>

            <Grid gridTemplateColumns="repeat(2,1fr)" gap={6} mb="20px">
              <Box>
                <CustomInput
                  type="password"
                  placeholder={t('resetPassword.newPassword')}
                  value={formik.values.password}
                  onChange={formik.handleChange('newPassword')}
                  onBlur={formik.handleBlur('newPassword')}
                />
                {formik.errors.newPassword && formik.touched.newPassword && (
                  <ErrorMsg text={t(formik.errors.newPassword)} />
                )}
              </Box>

              <Box>
                <CustomInput
                  type="password"
                  placeholder={t('resetPassword.confirmNewPassword')}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange('confirmPassword')}
                  onBlur={formik.handleBlur('confirmPassword')}
                />
                {formik.errors.confirmPassword &&
                  formik.touched.confirmPassword && (
                    <ErrorMsg text={t(formik.errors.confirmPassword)} />
                  )}
              </Box>
            </Grid>

            <CustomButton
              bg
              type="submit"
              name={t('account.save')}
              display="flex"
              alignItems="center"
              justifyContent="center"
              loading={isLoading && <Spinner ms="5px" />}
              disabled={isLoading}
            />
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default ChangePassowrdModal;
